import React, {useEffect, useRef, useState} from 'react';
import {Col, Row, Modal} from 'antd';

//local imports
import {getUserHood} from '../../../navigation/auth/auth';

import {
  UTILITY_RED,
  UTILITY_YELLOW,
  UTILITY_BLUE,
} from '../../../constants/themeColors';
import LoadingContent from '../../loading/LoadingContent';
import NoContent from '../../empty_screens/empty_content/NoContent';
import FeatureIcon from '../../../general_components/FeatureIcon/FeatureIcon';
import OwnerFormEditor from './OwnerFormEditor';
import apiInstance from '../../../network/axios';
import {FormInstance} from 'antd/es/form/Form';
import {CircleButton} from '../../../atoms';

interface IOwnerEditor {
  visible: boolean;
  closeModalTrigger: () => void;
  closeRefreshAfterCreate: () => void;
  ownerObject: any;
}

const OwnerEditor = ({
  closeModalTrigger,
  closeRefreshAfterCreate,
  visible,
  ownerObject,
}: IOwnerEditor) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [streets, setStreets] = useState<any[]>();

  const formRef = useRef<FormInstance>();

  useEffect(() => {
    retrieveStreetsData();
  }, []);

  const validatePut = async () => {
    const values = await formRef.current.validateFields();
    setLoading(true);
    putEditUser(values);
  };

  const retrieveStreetsData = async () => {
    try {
      const userNeighborhoodID = getUserHood();
      const url = `/neighborhoods/${userNeighborhoodID}/streets`;

      const response = await apiInstance.get(url);
      const result = response.data;
      setStreets(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const putEditUser = async (form_object) => {
    try {
      const url = `/users/${ownerObject.id}`;
      let homeId;
      if (
        form_object?.home_id !== undefined &&
        form_object?.home_id.length >= 2
      ) {
        homeId = form_object.home_id[1];
      } else {
        homeId = ownerObject.home.id;
      }
      const body = {
        role: 'OWNER',
        name: form_object.name,
        last_name: form_object.last_name,
        phone_number: form_object.phone,
        username: form_object.email,
        approved: form_object.active,
        home: homeId,
      };

      await apiInstance.put(url, body);
      closeRefreshAfterCreate();
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const confirmDeleteOwner = () => {
    if (window.confirm('¿Esta seguro que desea eliminar este elemento?')) {
      setLoading(true);
      deleteOwner();
    }
  };

  const deleteOwner = async () => {
    try {
      const url = `/users/${ownerObject.id}`;
      await apiInstance.delete(url);
      setLoading(false);
      closeRefreshAfterCreate();
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const toggleEditMode = () => {
    if (editMode) {
      if (window.confirm('¿Descartar los cambios realizados?')) {
        setEditMode(!editMode);
      } else {
        return;
      }
    } else {
      setEditMode(!editMode);
    }
  };

  return (
    <Modal
      centered
      visible={visible}
      onOk={validatePut}
      onCancel={closeModalTrigger}
      okText="CREAR"
      cancelText="CANCELAR"
      maskClosable={false}
      destroyOnClose={true}
      closable={!loading}
      footer={
        error ? null : (
          <Row>
            <Col span={2}>
              <CircleButton
                variant="del"
                activeColor={UTILITY_RED}
                disabled={loading}
                onClick={confirmDeleteOwner}
              />
            </Col>
            <Col span={22}>
              <CircleButton
                variant="genericEdit"
                activeColor={UTILITY_YELLOW}
                disabled={loading}
                onClick={toggleEditMode}
                clickedMode={editMode}
              />
              <CircleButton
                variant="save"
                activeColor={UTILITY_BLUE}
                disabled={!editMode ? true : loading}
                onClick={validatePut}
              />
            </Col>
          </Row>
        )
      }
      title={
        <Row>
          <Col xs={5} sm={4} md={3}>
            <FeatureIcon feature="user" />
          </Col>
          <Col xs={19} sm={20} md={21} style={{fontWeight: 'bold'}}>
            {ownerObject?.name ?? 'Usuario'}
            {ownerObject?.last_name ? ' - ' + ownerObject?.last_name : ''}
          </Col>
        </Row>
      }>
      {loading ? (
        <LoadingContent />
      ) : error ? (
        <NoContent emptyWhat={'network'} />
      ) : (
        <Row>
          <Col span={24}>
            <OwnerFormEditor
              streetsObject={streets}
              formRef={formRef}
              defaultValues={ownerObject ? ownerObject : null}
              editMode={editMode}
            />
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default OwnerEditor;
