import React, {useEffect, useState} from 'react';
import {Col, Row, Modal, Input, Button, Select, Switch} from 'antd';

//local imports
import {
  getUserHood,
  getHomesName,
  getStreetsName,
  getLevelConfig,
} from '../../../navigation/auth/auth';
import LoadingContent from '../../loading/LoadingContent';
import NoContent from '../../empty_screens/empty_content/NoContent';
import FeatureIcon from '../../../general_components/FeatureIcon/FeatureIcon';
import apiInstance from '../../../network/axios';

const HomeCreator = (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState<string>('network');
  const [homeNum, setHomeNum] = useState('');
  const [homeInsideNum, setHomeInsideNum] = useState('');
  const [streets, setStreets] = useState([]);
  const [streetId, setStreetId] = useState('');
  const [activatedHome, setActivatedHome] = useState('ACTIVATED');

  const validatePost = () => {
    if (!homeNum) {
      alert('Ingrese Número para continuar');
      return;
    }
    if (!streetId) {
      alert(
        'Seleccione una ' + getStreetsName().toLowerCase() + ' para continuar',
      );
      return;
    }
    postCreateHome();
  };

  const retrieveStreetsData = async () => {
    try {
      const userNeighborhoodID = getUserHood();
      const url = `/neighborhoods/${userNeighborhoodID}`;
      const response = await apiInstance.get(url);
      const result = response.data.streets;

      setLoading(false);
      setStreets([...result]);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const postCreateHome = async () => {
    try {
      setLoading(true);
      const userNeighborhoodID = getUserHood();
      const url = `/neighborhoods/${userNeighborhoodID}/homes`;

      const body = {
        street: streetId,
        current_status: activatedHome,
        number: homeNum,
        inside_number: homeInsideNum ?? undefined,
      };

      const resp = await apiInstance.post(url, body);
      console.log(resp.data.result);

      if (getLevelConfig() < 4) {
        localStorage.setItem('levelConfig', '3');
        if (props.onFirstCreate !== null) {
          props.onFirstCreate();
        }
      }
      setLoading(false);
      props.closeRefreshAfterCreate();
    } catch (error) {
      const errorMessage = error?.response?.data?.message ?? '';
      if (errorMessage === 'No more Subscriptions Available') {
        setErrorType('subscription');
      }

      setLoading(false);
      setError(true);
    }
  };

  const retryAfterNetErr = () => {
    setLoading(false);
    setError(false);
  };

  useEffect(() => {
    retrieveStreetsData();
  }, []);

  return (
    <Modal
      centered
      visible={props.visible}
      onOk={validatePost}
      onCancel={props.closeModalTrigger}
      okText="AGREGAR"
      cancelText="CANCELAR"
      maskClosable={false}
      destroyOnClose={true}
      closable={loading}
      footer={[
        <Button key="back" disabled={loading} onClick={props.closeModalTrigger}>
          {'CANCELAR'}
        </Button>,
        error ? (
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={retryAfterNetErr}>
            {'REINTENTAR'}
          </Button>
        ) : (
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={validatePost}
            disabled={streets.length === 0}>
            {'AGREGAR'}
          </Button>
        ),
      ]}
      title={
        <Row>
          <Col xs={5} sm={4} md={3}>
            <FeatureIcon feature="home" />
          </Col>
          <Col xs={19} sm={20} md={21} style={{fontWeight: 'bold'}}>
            {'Agregar ' + getHomesName()}
          </Col>
        </Row>
      }>
      {loading ? (
        <LoadingContent />
      ) : error ? (
        <NoContent emptyWhat={errorType} />
      ) : (
        <Row>
          {streets.length === 0 ? (
            <Col span={24}>
              <h4>{`Para poder realizar esta operación es necesario que primero agregue un(a) ${getStreetsName()}.`}</h4>
            </Col>
          ) : (
            <Col span={24}>
              <h3>{'Número de ' + getHomesName()}</h3>
              <Input
                value={homeNum}
                placeholder="Número de Casa"
                style={{marginBottom: '20px'}}
                onChange={(event) => setHomeNum(event.target.value)}
              />
              <h3>{'Número Interior'}</h3>
              <Input
                value={homeInsideNum}
                placeholder="Número Interior"
                style={{marginBottom: '20px'}}
                onChange={(event) => setHomeInsideNum(event.target.value)}
              />
              <h3>{getStreetsName()}</h3>
              <Select
                disabled={streets.length <= 0}
                style={{width: '100%'}}
                dropdownStyle={{zIndex: 3000}}
                onChange={(event) => setStreetId(event)}>
                {streets.length > 0 &&
                  streets.map((item) => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.name}
                    </Select.Option>
                  ))}
              </Select>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  marginTop: '30px',
                }}>
                <Switch
                  size="small"
                  defaultChecked
                  onChange={(event) =>
                    setActivatedHome(event ? 'ACTIVATED' : 'DEACTIVATED')
                  }
                  style={{marginTop: '3px'}}
                />
                <h4
                  style={{
                    padding: '0px 0px 0px 20px',
                    marginBottom: '20px',
                  }}>
                  {'Habilitado'}
                </h4>
              </div>
            </Col>
          )}
        </Row>
      )}
    </Modal>
  );
};

export default HomeCreator;
