import React from 'react';
import {UilPlus} from '@iconscout/react-unicons';
import {Col, Row, Button} from 'antd';
import {BASE_BLUE, BASE_WHITE} from '../constants/themeColors';
import * as Unicons from '@iconscout/react-unicons';

const AddButton = (props) => {
  return (
    <Button
      style={{
        backgroundColor: props?.color ?? BASE_BLUE,
        color: '#FFFFFF',
        fontWeight: 'bold',
        borderColor: '#FFFFFF',
        padding: '5px',
        borderRadius: '5px',
      }}
      onClick={props.onClick}>
      {props.small ? (
        <Row justify="center" align="middle">
          <Col xs={24} sm={0}>
            <div
              style={{
                padding: '0px 10px',
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <UilPlus size="16" color="#FFFFFF" />
              <Unicons.UilDollarSign size={18} color={BASE_WHITE} />
            </div>
          </Col>
          <Col xs={0} sm={24}>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <div
                style={{
                  padding: '0px 10px',
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <UilPlus size="16" color="#FFFFFF" />
              </div>
              <div
                style={{
                  paddingRight: '10px',
                }}>
                {props.title}
              </div>
            </div>
          </Col>
        </Row>
      ) : (
        <Row justify="center" align="middle">
          <Col span={24}>
            <div
              style={{
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                maxHeight: '95%',
              }}>
              <div
                style={{
                  padding: '0px 2px',
                  display: 'flex',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <UilPlus size="16" color="#FFFFFF" />
              </div>
              <div
                style={{
                  paddingRight: '10px',
                }}>
                {props.title}
              </div>
            </div>
          </Col>
        </Row>
      )}
    </Button>
  );
};

export default AddButton;
