import React, {useEffect, useState} from 'react';
import {Col, Row, Button, Input} from 'antd';
import UilKeyholeSquareFull from '@iconscout/react-unicons/icons/uil-keyhole-square-full';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';

//local imports
import {getUserHood} from '../../navigation/auth/auth';
import FeatureIcon from '../../general_components/FeatureIcon/FeatureIcon';
import DoorCreator from './components/DoorCreator';
import DoorEditor from './components/DoorEditor';
import CommonLayout from '../../layouts/CommonLayout';
import {DoorsStyles as styles} from './styles';
import apiInstance from '../../network/axios';
import CustomTable from '../../molecules/CustomTable';
import {SearchOutlined} from '@ant-design/icons';

const Doors = () => {
  const [modalCreateDoor, setModalCreateDoor] = useState(false);
  const [viewDoorModal, setViewDoorModal] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searchFor, setSearchFor] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(10);
  const [dataForTable, setDataForTable] = useState([]);

  useEffect(() => {
    retrieveDoorsData(1);
  }, []);

  useEffect(() => {
    formatDataForTable(data);
  }, [data]);

  const handleSearchSubmit = () => {
    if (!searchFor) {
      return;
    }
    setError(false);
    setSearching(true);
    searchDoors(1);
  };

  const searchDoors = async (pageNum) => {
    try {
      setLoading(true);
      const userNeighborhoodID = getUserHood();
      const url = `/neighborhoods/${userNeighborhoodID}/doors?limit=10&page=${pageNum}&query=${searchFor}`;
      const response = await apiInstance.get(url);
      const result = response.data;
      setLoading(false);
      setTotalItems(
        response.headers['x-paginate-total']
          ? parseInt(response.headers['x-paginate-total'])
          : 10,
      );
      setData(result);
    } catch (error) {
      setLoading(false);
      setError(true);
      setSearchFor('');
    }
  };

  const searchModeOff = () => {
    setSearching(false);
    setSearchFor('');
    goToPage(1, false);
  };

  const goToPage = (pageNum: number, isSearching: boolean) => {
    setPage(pageNum);
    isSearching ? searchDoors(pageNum) : retrieveDoorsData(1);
  };

  const retrieveDoorsData = async (pageNum) => {
    try {
      setLoading(true);
      const userNeighborhoodID = getUserHood();
      const url = `/neighborhoods/${userNeighborhoodID}/doors?limit=10&page=${pageNum}`;
      const response = await apiInstance.get(url);
      const result = response.data;

      setTotalItems(
        response.headers['x-paginate-total']
          ? parseInt(response.headers['x-paginate-total'])
          : 10,
      );
      setData(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const closeDoorModalAndRefresh = () => {
    setModalCreateDoor(false);
    setViewDoorModal(false);
    setSearchFor('');
    setSearching(false);
    goToPage(1, false);
  };

  const formatDataForTable = (data = []) => {
    const formatedData = [];
    data.forEach((item, index) => {
      const statusIcon = (
        <FeatureIcon feature="user" randomColor={Math.floor(index % 5)} />
      );
      const formatedElement = [];

      formatedElement.push(
        <Row align="middle">
          {statusIcon}
          <Col xs={0} md={12} style={styles.itemIcon}>
            {item.number ?? ''}
          </Col>
        </Row>,
      );
      formatedElement.push(item?.description ?? '');
      formatedElement.push(item?.user ?? '');
      formatedElement.push(item?.host ?? '');
      formatedElement.push(item?.port ?? '');
      formatedElement.push(
        <Button
          block
          onClick={() => setViewDoorModal(item)}
          style={styles.itemButton}>
          {'VER'}
        </Button>,
      );

      formatedData.push({
        data: formatedElement,
        aditionalData: {
          active: true,
        },
      });
    });
    setDataForTable(formatedData);
  };

  return (
    <CommonLayout
      title={'Puertas'}
      buttonAdd={
        <Button style={styles.button} onClick={() => setModalCreateDoor(true)}>
          <Row justify="center" align="middle">
            <Col xs={24} sm={0}>
              <div style={styles.container}>
                <UilPlus size="16" color="#FFFFFF" />
                <UilKeyholeSquareFull size="18" color="#FFFFFF" />
              </div>
            </Col>
            <Col xs={0} sm={24}>
              <div style={styles.iconContainer}>
                <div style={styles.icon}>
                  <UilKeyholeSquareFull size="18" color="#FFFFFF" />
                </div>
                <div style={styles.buttonTitle}>{'AGREGAR PUERTA'}</div>
              </div>
            </Col>
          </Row>
        </Button>
      }
      filters={
        <Row style={{width: '100%'}}>
          <Col span={12}>
            <Input
              prefix={<SearchOutlined />}
              value={searchFor}
              disabled={searching}
              onChange={(value) => setSearchFor(value.target.value)}
              style={{width: '70%'}}
              placeholder={searching ? 'Búsqueda Completada' : 'Buscar...'}
              onPressEnter={handleSearchSubmit}
            />
          </Col>
          <Col
            span={12}
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            {searching ? (
              <Button onClick={searchModeOff}>{'REGRESAR'}</Button>
            ) : (
              <Button onClick={handleSearchSubmit}>{'BUSCAR'}</Button>
            )}
          </Col>
        </Row>
      }>
      <CustomTable
        loading={loading}
        error={{
          isThereError: error,
          emptyWhat: 'network',
          onRetry: () => goToPage(1, searching),
        }}
        filters={{
          applyFilters: null,
          setApplyFilters: null,
          filtersText: null,
        }}
        pagination={{
          searching,
          page,
          totalItems,
          goToPage,
        }}
        columns={[
          {
            columnTitle: 'Puerta',
            sizes: {xs: 9, sm: 7, md: 4, xl: 3},
          },
          {
            columnTitle: 'Descripción',
            sizes: {xs: 0, md: 5, xl: 6},
          },
          {columnTitle: 'Usuario', sizes: {xs: 0, md: 5, xl: 6}},
          {columnTitle: 'Host', sizes: {xs: 9, sm: 7, md: 5, xl: 4}},
          {columnTitle: 'Puerto', sizes: {xs: 0, sm: 7, md: 3}},
          {columnTitle: 'Acción', sizes: {xs: 6, sm: 3, md: 2}},
        ]}
        items={dataForTable}
      />
      {modalCreateDoor && (
        <DoorCreator
          visible={modalCreateDoor}
          closeModalTrigger={() => setModalCreateDoor(false)}
          closeRefreshAfterCreate={closeDoorModalAndRefresh}
        />
      )}
      {viewDoorModal && (
        <DoorEditor
          visible={viewDoorModal !== true}
          closeModalTrigger={() => setViewDoorModal(false)}
          closeRefreshAfterCreate={closeDoorModalAndRefresh}
          doorObject={viewDoorModal}
        />
      )}
    </CommonLayout>
  );
};

export default Doors;
