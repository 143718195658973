export const normalizeColSizes = (
  sizes = {xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 1, xxxl: 1},
) => {
  const xs = sizes.xs;
  const sm = sizes?.sm ?? xs;
  const md = sizes?.md ?? sm;
  const lg = sizes?.lg ?? md;
  const xl = sizes?.xl ?? lg;
  const xxl = sizes?.xxl ?? xl;
  const xxxl = sizes?.xxxl ?? xxl;
  return {xs, sm, md, lg, xl, xxl, xxxl};
};

export const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 24},
    md: {span: 24},
    lg: {span: 24},
    xl: {span: 24},
    xxl: {span: 24},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 24},
    md: {span: 24},
    lg: {span: 24},
    xl: {span: 24},
    xxl: {span: 24},
  },
};
