import React, {useRef, useState} from 'react';
import {Col, Row, Modal, Button} from 'antd';

import {
  UTILITY_RED,
  UTILITY_YELLOW,
  DISABLED_GRAY,
  UTILITY_BLUE,
} from '../../../constants/themeColors';
import LoadingContent from '../../loading/LoadingContent';
import NoContent from '../../empty_screens/empty_content/NoContent';
import FeatureIcon from '../../../general_components/FeatureIcon/FeatureIcon';
import HoodFormEditor from './HoodFormEditor';
import * as Compress from 'compress.js';
import apiInstance from '../../../network/axios';
import {FormInstance} from 'antd/es/form/Form';
import {CircleButton} from '../../../atoms';

interface IHoodEditor {
  visible: boolean;
  closeModalTrigger: () => void;
  closeRefreshAfterCreate: () => void;
  hoodObject: any;
}
const HoodEditor = ({
  closeModalTrigger,
  closeRefreshAfterCreate,
  hoodObject,
  visible,
}: IHoodEditor) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [selectedImageFile, setSelectedImageFile] = useState<any>(null);
  const formRef = useRef<FormInstance>();

  const validatePut = async () => {
    const values = await formRef.current.validateFields();
    setLoading(true);
    putEditHood(values);
  };

  const putEditHood = async (form_object) => {
    try {
      const url = `/neighborhoods/${hoodObject._id}`;
      const active_services = [];

      if (form_object.id_service_enabled) {
        active_services.push('ID_DETECTION');
      }
      if (form_object.call_service_enabled) {
        active_services.push('CALLS');
      }
      if (form_object.directory_service_enabled) {
        active_services.push('STREET_DIR');
      }
      if (form_object.selfie_service_enabled) {
        active_services.push('SELFIE_ID');
      }
      if (form_object.exit_service_enabled) {
        active_services.push('EXIT_LOG');
      }
      if (form_object.face_id_enabled) {
        active_services.push('FACE_ID');
      }

      const body = {
        description: form_object.hood_description,
        number: form_object.hood_name,
        address: form_object.address,
        house_name: form_object.house_name,
        street_name: form_object.street_name,
        activated: form_object.activated,
        status: form_object.activated ? 'ACTIVATED' : 'DEACTIVATED',
        active_services: [...active_services],
      };

      await apiInstance.put(url, body);

      setLoading(false);
      closeRefreshAfterCreate();
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const confirmDeleteHood = () => {
    if (window.confirm('¿Esta seguro que desea eliminar este vecindario?')) {
      setLoading(true);
      deleteHood();
    }
  };

  const deleteHood = async () => {
    try {
      await apiInstance.delete(`/neighborhoods/${hoodObject._id}`);
      setLoading(false);
      closeRefreshAfterCreate();
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const onChooseImgFile = (event) => {
    resizeImageFn(event.target.files[0]);
  };

  const uploadPhoto = () => {
    if (!selectedImageFile) {
      alert('Seleccione imagen');
      return;
    }
    setLoading(true);
    setError(false);
    sendImgToServer();
  };

  const sendImgToServer = async () => {
    try {
      const url = `/neighborhoods/${hoodObject._id}/image`;
      let formData = new FormData();
      formData.append('image', selectedImageFile);
      await apiInstance.put(url, formData);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const toggleEditMode = () => {
    if (editMode) {
      if (window.confirm('¿Abandonar modo de edición?')) {
        setEditMode(!editMode);
      } else {
        return;
      }
    } else {
      setEditMode(!editMode);
    }
  };

  const resizeImageFn = async (file) => {
    const compress = new Compress();
    const resizedImage = await compress.compress([file], {
      size: 2,
      maxWidth: 1024,
      maxHeight: 1024,
      resize: true,
      quality: 0.95,
    });
    const img = resizedImage[0];
    const dataURLtoFile = (dataurl, filename) => {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type: mime});
    };
    const _file = dataURLtoFile(
      `data:image/png;base64,${img.data}`,
      `${hoodObject._id}_image.png`,
    );
    setSelectedImageFile(_file);
  };

  return (
    <Modal
      centered
      visible={visible}
      onOk={validatePut}
      onCancel={closeModalTrigger}
      okText="AGREGAR"
      cancelText="CANCELAR"
      maskClosable={false}
      zIndex={2000}
      destroyOnClose={true}
      closable={!loading}
      footer={
        error ? null : (
          <Row>
            <Col span={2}>
              <CircleButton
                variant="del"
                activeColor={UTILITY_RED}
                disabled={loading}
                onClick={confirmDeleteHood}
              />
            </Col>
            <Col span={22}>
              <CircleButton
                variant="genericEdit"
                activeColor={UTILITY_YELLOW}
                disabled={loading}
                onClick={toggleEditMode}
                clickedMode={editMode}
              />
              <CircleButton
                variant="save"
                activeColor={UTILITY_BLUE}
                disabled={!editMode ? true : loading}
                onClick={validatePut}
              />
            </Col>
          </Row>
        )
      }
      title={
        <Row>
          <Col xs={5} sm={4} md={3}>
            <FeatureIcon feature="hood" />
          </Col>
          <Col xs={19} sm={20} md={21} style={{fontWeight: 'bold'}}>
            {hoodObject?.number ?? ''}
          </Col>
        </Row>
      }>
      {loading ? (
        <LoadingContent />
      ) : error ? (
        <NoContent emptyWhat={'network'} />
      ) : (
        <Row>
          <Col span={24}>
            <HoodFormEditor
              formRef={formRef}
              defaultValues={hoodObject ?? null}
              editMode={editMode}
            />
            <h3>{'Imagen del vecindario'}</h3>
            <input
              type="file"
              name="file"
              accept="image/*"
              onChange={onChooseImgFile}
            />
            <Button
              key="save_img"
              block
              onClick={uploadPhoto}
              loading={loading}
              style={{
                color: '#FFFFFF',
                paddingTop: '4px',
                backgroundColor: loading ? DISABLED_GRAY : UTILITY_BLUE,
              }}>
              {'Guardar Imagen'}
            </Button>
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default HoodEditor;
