import {UilPlus} from '@iconscout/react-unicons';
import {Col, DatePicker, Input, Row, Select} from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import Axios from 'axios';
import React, {useEffect, useState} from 'react';
import {
  BASE_URL,
  DEVELOPMENT,
  NETWORK_TIMEOUT,
} from '../../../constants/network';
import {Modal} from '../../../molecules/Modal';
import {getUserHood, getUserToken} from '../../../navigation/auth/auth';
import NoContent from '../../empty_screens/empty_content/NoContent';
import {
  BASE_GREEN,
  BASE_RED,
  UTILITY_BLUE,
  UTILITY_RED,
  UTILITY_YELLOW,
} from '../../../constants/themeColors';
import LoadingContent from '../../loading/LoadingContent';
import {styles} from './styles';
import numeral from 'numeral';
import {CircleButton} from '../../../atoms';

const EditEntry = (props) => {
  const [concept, setConcept] = useState('');
  const [amount, setAmount] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [date, setDate] = useState(null);
  const [validating, setValidating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [errorToDelete, setErrorToDelete] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  useEffect(() => {
    setConcept(props?.data?.description ?? '');
    setAmount(props?.data?.amount ?? 0);
    setCategoryId(props?.data?.category._id ?? '');
    setDate(props?.data?.date ?? new Date().toISOString());
  }, [props]);

  const resetForm = () => {
    setLoading(false);
    setError(false);
    setConcept('');
    setAmount('');
    setDate(null);
    setValidating(false);
    setEditMode(false);
    setLoadingDelete(false);
    setErrorToDelete(false);
  };

  const handleUpdateEntry = () => {
    if (concept === '' || amount === '' || categoryId === '' || date === null) {
      setValidating(true);
      return;
    }
    setError(false);
    setLoading(true);
    updateEntry();
  };
  const updateEntry = async () => {
    setLoading(true);
    try {
      const userNeighborhoodID = getUserHood();
      const userToken = getUserToken();
      const url = `${BASE_URL}/neighborhoods/${userNeighborhoodID}/accounting/entry`;
      const options = {
        headers: {
          'X-App': 'admin',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + userToken,
        },
        timeout: NETWORK_TIMEOUT,
      };
      const body = {
        type: props?.data?.type ?? 'expense',
        description: concept,
        category_id: categoryId,
        amount: numeral(amount).value(),
        entry_id: props.data._id,
        date,
      };
      const response = await Axios.put(url, body, options);

      if (response.data.success) {
        resetForm();
        props.onCancel();
        props.reload();
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      if (DEVELOPMENT) {
        console.log('Error updating entry', error?.response ?? error);
      }
      setLoading(false);
      setError(true);
    }
  };
  const handleDeleteEntry = () => {
    if (window.confirm('¿Esta seguro que desea eliminar este elemento?')) {
      setLoading(true);
      deleteEntry();
    }
  };
  const deleteEntry = async () => {
    setLoadingDelete(true);
    try {
      const userNeighborhoodID = getUserHood();
      const userToken = getUserToken();
      const url = `${BASE_URL}/neighborhoods/${userNeighborhoodID}/accounting/entry`;
      const options = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'X-App': 'admin',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + userToken,
        },
        data: {
          entry_id: props.data._id,
        },
        timeout: NETWORK_TIMEOUT,
      };
      const response = await Axios.delete(url, options);
      if (response.data.success) {
        setLoadingDelete(false);
        setEditMode(false);
        props.onCancel();
        props.reload();
      } else {
        setLoadingDelete(false);
        setErrorToDelete(true);
      }
    } catch (error) {
      if (DEVELOPMENT) {
        console.log('Error deleting entry', error?.response ?? error);
      }
      setLoading(false);
      setLoadingDelete(false);
      setErrorToDelete(true);
    }
  };
  const onChangeDate = (date) => {
    setDate(date);
  };
  const {Option} = Select;
  return (
    <Modal
      onOk={handleUpdateEntry}
      icon={
        <div
          style={{
            width: 40,
            height: 40,
            borderRadius: 20,
            backgroundColor:
              props?.data?.type === 'income' ? BASE_GREEN : BASE_RED,
            padding: 5,
          }}>
          <UilPlus size="30" color="#FFFFFF" />
        </div>
      }
      title={props.title}
      visible={props.visible}
      onCancel={() => {
        props.onCancel();
        resetForm();
      }}
      loading={loading}
      body={
        <div>
          {loadingDelete && <LoadingContent />}
          {error || errorToDelete ? (
            <NoContent
              emptyWhat={'network'}
              withRetry
              onClickMethod={() => {
                setError(false);
                setLoading(true);
                if (errorToDelete) {
                  handleDeleteEntry();
                } else {
                  handleUpdateEntry();
                }
              }}
            />
          ) : (
            <Row>
              <Col span={24}>
                <h3 style={styles.h3}>{'Concepto'}</h3>
                <Input
                  disabled={!editMode}
                  value={concept}
                  onChange={(value) => setConcept(value.target.value)}
                />
                {validating && concept === '' && (
                  <p style={styles.validation}>El concepto es requerido.</p>
                )}
                <h3 style={styles.h3}>{'Cantidad'}</h3>
                <Input
                  disabled={!editMode}
                  prefix={'$'}
                  value={amount}
                  onChange={(value) => setAmount(value.target.value)}
                />
                {validating && (amount === '' || isNaN(parseFloat(amount))) && (
                  <p style={styles.validation}>Ingrese una cantidad valida.</p>
                )}
                <h3 style={styles.h3}>{'Fecha'}</h3>
                <DatePicker
                  disabled={!editMode}
                  onChange={onChangeDate}
                  format={'DD/MM/YYYY'}
                  showToday={false}
                  locale={locale}
                  style={styles.item}
                />
                {validating && date === null && (
                  <p style={styles.validation}>La fecha es requerida.</p>
                )}
                <h3 style={styles.h3}>{'Categoría'}</h3>
                <Select
                  disabled={!editMode}
                  style={styles.item}
                  placeholder={'Categoria'}
                  value={categoryId}
                  onChange={(value) => {
                    setCategoryId(value);
                  }}>
                  {props.categories.map((item) => {
                    return (
                      <Option key={item._id} value={item._id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
                {validating && categoryId === '' && (
                  <p style={styles.validation}>La fecha es requerida.</p>
                )}
              </Col>
            </Row>
          )}
        </div>
      }
      footer={
        error ? null : (
          <Row>
            <Col span={2}>
              <CircleButton
                variant="del"
                activeColor={UTILITY_RED}
                disabled={loading}
                onClick={handleDeleteEntry}
              />
            </Col>
            <Col span={22}>
              <CircleButton
                variant="genericEdit"
                activeColor={UTILITY_YELLOW}
                disabled={loading}
                onClick={() => setEditMode(!editMode)}
                clickedMode={editMode}
              />
              <CircleButton
                variant="save"
                activeColor={UTILITY_BLUE}
                disabled={!editMode || loading}
                onClick={handleUpdateEntry}
              />
            </Col>
          </Row>
        )
      }
    />
  );
};

export default EditEntry;
