import React from 'react';
import {withRouter} from 'react-router-dom';

import ResetPwd from './components/ResetPwd';
import BrandLogo from '../../navigation/topoptionbar/components/BrandLogo';

const PwdRecovery = (props: any) => {
  const {requester, token} = props.match.params;

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        backgroundColor: '#081023',
        height: '100vh',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <ResetPwd userEmail={requester} retrievalToken={token} />
      <div
        style={{
          position: 'absolute',
          top: '2vh',
          left: '4vh',
        }}>
        <BrandLogo />
      </div>
    </div>
  );
};

export default withRouter(PwdRecovery);
