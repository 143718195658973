export const formatMins = (mins: any) => {
  const hours = mins < 60 ? 0 : Math.floor(mins / 60);
  const _mins = hours > 0 ? mins - hours * 60 : mins;

  const auxDate = new Date();
  auxDate.setHours(hours);
  auxDate.setMinutes(_mins);
  const localHours = auxDate.getHours();
  const localMins = auxDate.getMinutes();

  return `${localHours > 9 ? localHours : '0' + localHours}:${
    localMins > 9 ? localMins : '0' + localMins
  }`;
};

export const getRemaningDaysInSubscription = (lastPaymentDate = '') => {
  const lastDate = new Date(lastPaymentDate);
  const nextDate = new Date(lastDate);
  nextDate.setMonth(lastDate.getMonth() + 1);
  const difference = nextDate.getTime() - new Date().getTime();
  return Math.floor(difference / (1000 * 3600 * 24));
};
