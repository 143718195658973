import React, {useState} from 'react';
import Homes from '../homes/Homes';
import {useHistory} from 'react-router';
import {Col, Row} from 'antd';
import RegisterIndicator from './components/RegisterIndicator';
import {DJStepHeaderStyles as styles} from './styles';
import {getLevelConfig} from '../../navigation/auth/auth';

const DJCreateHomes = () => {
  const history = useHistory();
  const [levelConfig, setLevelConfig] = useState(getLevelConfig());
  return (
    <div>
      <Row style={styles.header}>
        <Col xs={24} sm={12} />
        <Col xs={24} sm={12}>
          <RegisterIndicator
            level={3}
            disabledNext={levelConfig <= 2}
            onClickPrev={() => {
              history.push('/digitalJourney/createStreets');
            }}
            onClickNext={() => {
              if (levelConfig > 2) {
                history.push('/digitalJourney/createOwners');
              }
            }}
          />
        </Col>
      </Row>
      <Homes history={history} onFirstCreate={() => setLevelConfig(3)} />
    </div>
  );
};

export default DJCreateHomes;
