/* eslint-disable react-hooks/exhaustive-deps */
import {Button, Col, Row} from 'antd';
import axios from 'axios';
import React, {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {BASE_URL, DEVELOPMENT, NETWORK_TIMEOUT} from '../../constants/network';
import {AuthContext} from '../../context/AuthContext';
import BrandLogo from '../../navigation/topoptionbar/components/BrandLogo';
import Done from '../../res/img/passwordCreated.png';
import NoContent from '../empty_screens/empty_content/NoContent';
import LoadingContent from '../loading/LoadingContent';
import {DJVerifyEmailStyles as styles} from './styles';
const DJVerifyEmail = (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const context: any = useContext(AuthContext);
  useEffect(() => {
    localStorage.clear();
    context.setGlobalState({showTopBar: false});
    verifyEmail();
  }, []);

  const verifyEmail = async () => {
    try {
      const {email, token} = props.match.params;
      const url = `${BASE_URL}/verify_email/${email}/${token}`;

      const options = {
        headers: {
          'X-App': 'admin',
          'Content-Type': 'application/json',
        },
        timeout: NETWORK_TIMEOUT,
      };

      const resp = await axios.get(url, options);
      // console.log('RESP VERYFY EMAIL', resp);
      if (resp.status === 200) {
        setLoading(false);
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (e) {
      if (DEVELOPMENT) {
        console.log('ERROR=> ', e?.response ?? e);
      }
      setLoading(false);
      setError(true);
    }
  };

  if (loading) {
    return <LoadingContent />;
  }
  if (error) {
    return (
      <NoContent
        emptyWhat={'verifyEmail'}
        withRetry
        onClickMethod={() => {
          setLoading(true);
          verifyEmail();
          setError(false);
        }}
      />
    );
  }

  return (
    <div style={styles.main}>
      <div style={styles.logo}>
        <BrandLogo />
      </div>
      <Row gutter={[50, 15]}>
        <Col span={24}>
          <div style={styles.left}>
            <img src={Done} alt={'Todo listo'} style={styles.imageDone} />
            <h1 style={styles.title}>¡Todo listo!</h1>
            <p style={styles.message}>
              Ya puedes comenzar a disfrutar de Portos
            </p>
            <Link to="/">
              <Button style={styles.button}>{'COMENZAR'}</Button>
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default DJVerifyEmail;
