import BOWLING from '../../../res/commonAreas/BOWLING.svg';
import CINEMA from '../../../res/commonAreas/CINEMA.svg';
import CLUB from '../../../res/commonAreas/CLUB.svg';
import COFFEE from '../../../res/commonAreas/COFFEE.svg';
import DOG from '../../../res/commonAreas/DOG.svg';
import GOLF from '../../../res/commonAreas/GOLF.svg';
import GRILL from '../../../res/commonAreas/GRILL.svg';
import GYM from '../../../res/commonAreas/GYM.svg';
import JACUZZI from '../../../res/commonAreas/JACUZZI.svg';
import KIDS from '../../../res/commonAreas/KIDS.svg';
import PARK from '../../../res/commonAreas/PARK.svg';
import PONG from '../../../res/commonAreas/PONG.svg';
import SALOON from '../../../res/commonAreas/SALOON.svg';
import SAUNA from '../../../res/commonAreas/SAUNA.svg';
import SWIM_TRACK from '../../../res/commonAreas/SWIM_TRACK.svg';
import SWIMMING_POOL from '../../../res/commonAreas/SWIMMING_POOL.svg';
import TENNIS from '../../../res/commonAreas/TENNIS.svg';
import TRACK from '../../../res/commonAreas/BOWLING.svg';
import {CommonAreasStyles as styles} from '../styles';
import React from 'react';

export const ICONS = {
  TENNIS: <img style={styles.icon} src={TENNIS} alt="TENNIS" />,
  SAUNA: <img style={styles.icon} src={SAUNA} alt="SAUNA" />,
  SALOON: <img style={styles.icon} src={SALOON} alt="SALOON" />,
  TRACK: <img style={styles.icon} src={TRACK} alt="TRACK" />,
  PONG: <img style={styles.icon} src={PONG} alt="PONG" />,
  DOG: <img style={styles.icon} src={DOG} alt="DOG" />,
  PARK: <img style={styles.icon} src={PARK} alt="PARK" />,
  JACUZZI: <img style={styles.icon} src={JACUZZI} alt="JACUZZI" />,
  GYM: <img style={styles.icon} src={GYM} alt="GYM" />,
  GOLF: <img style={styles.icon} src={GOLF} alt="GOLF" />,
  CINEMA: <img style={styles.icon} src={CINEMA} alt="CINEMA   " />,
  CLUB: <img style={styles.icon} src={CLUB} alt="CLUB" />,
  SWIM_TRACK: <img style={styles.icon} src={SWIM_TRACK} alt="SWIM_TRACK" />,
  COFFEE: <img style={styles.icon} src={COFFEE} alt="COFFEE" />,
  BOWLING: <img style={styles.icon} src={BOWLING} alt="BOWLING" />,
  GRILL: <img style={styles.icon} src={GRILL} alt="GRILL" />,
  KIDS: <img style={styles.icon} src={KIDS} alt="KIDS" />,
  SWIMMING_POOL: (
    <img style={styles.icon} src={SWIMMING_POOL} alt="SWIMMING_POOL" />
  ),
};
