import {BlobServiceClient} from '@azure/storage-blob';
import {v4 as uuidv4} from 'uuid';

export const generateImageName = (imageType: string, fileType?: string) => {
  return `${imageType}-${uuidv4()}.${fileType || '.jpeg'}`;
};

const separateUrl = (url: string) => {
  const lastSlashIndex = url.lastIndexOf('/');
  if (lastSlashIndex !== -1) {
    const base = url.substring(0, lastSlashIndex);
    const container = url.substring(lastSlashIndex + 1);
    return {base, container};
  } else {
    return {base: url, container: ''};
  }
};

const createBlobInContainer = async (
  containerClient: any,
  file: File,
  name: string,
) => {
  try {
    const blobClient = containerClient.getBlockBlobClient(name);
    const options = {blobHTTPHeaders: {blobContentType: file.type}};
    await blobClient.uploadBrowserData(file, options);
  } catch (e2) {
    console.error({e2});
  }
};

export const uploadFileToBlob = async (
  file: File,
  url: string,
  token: string,
): Promise<string | null> => {
  try {
    if (!url || !token || !file) return null;

    const {container, base} = separateUrl(url);
    const name = generateImageName('profile', file.type.split('/')[1]);
    const blobService = new BlobServiceClient(`${base}?${token}`);
    const containerClient = blobService.getContainerClient(container);
    await createBlobInContainer(containerClient, file, name);
    return `${url}/${name}`;
  } catch (e) {
    console.error({e});
    return null;
  }
};
