/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Col, Row, Button, DatePicker} from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import {
  requiredDateValForServer,
  getDateDisplay,
} from '../../utils/locale/chronos';
import {getUserHood, getHomesName} from '../../navigation/auth/auth';
import FeatureIcon from '../../general_components/FeatureIcon/FeatureIcon';
import CallDetailModal from './components/CallDetailModal';
import CommonLayout from '../../layouts/CommonLayout';
import CustomTable from '../../molecules/CustomTable';
import apiInstance from '../../network/axios';

import {RecordsStyles as styles} from './components/styles';

const Records = (props) => {
  const [loading, setLoading] = useState(true);
  const [recordsData, setRecordsData] = useState([]);
  const [error, setError] = useState(false);
  const [recordId, setRecordId] = useState(null);
  const [searchingFor, setSearchingFor] = useState('');
  const [searching, setSearching] = useState(false);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(10);
  const [dataForTable, setDataForTable] = useState<any>();

  const motif = props.motif ?? 'ENTRANCE';

  const onChangeDate = (date, dateString) => {
    setSearchingFor(date);
  };
  const retrieveRecordsData = async (pageNum = 1, search = false) => {
    try {
      setLoading(true);
      const userNeighborhoodID = getUserHood();
      let url = '';
      if (search) {
        const validSearchDate = requiredDateValForServer(searchingFor);
        url = `/neighborhoods/${userNeighborhoodID}/calls?limit=10&page=${pageNum}&motif=${motif}&date=${validSearchDate}`;
      } else {
        url = `/neighborhoods/${userNeighborhoodID}/calls?limit=10&page=${pageNum}&motif=${motif}`;
      }

      const response = await apiInstance.get(url);
      const result = response?.data ?? [];

      setTotalItems(
        response.headers['x-paginate-total']
          ? parseInt(response.headers['x-paginate-total'])
          : 10,
      );

      setLoading(false);
      if (!search) {
        setSearchingFor('');
      }
      setRecordsData(result);
    } catch (error) {
      setLoading(false);
      setError(true);
      setSearchingFor('');
    }
  };
  const goToPage = (pageNum: number, isSearching: boolean) => {
    setPage(pageNum);
    setError(false);
    retrieveRecordsData(pageNum, isSearching);
  };
  const searchModeOff = () => {
    setSearching(false);
    goToPage(1, false);
  };
  const handleSearchSubmit = () => {
    if (!searchingFor) {
      return;
    }
    setPage(1);
    setSearching(true);
    setError(false);
    setRecordsData([]);
    retrieveRecordsData(1, true);
  };

  const formatDataForTable = (data = []) => {
    const formatedData = [];
    data.forEach((item) => {
      const timeDisplay = getDateDisplay(item.date, item.time_ago);
      const formatedElement = [];
      const type = motif === 'ENTRANCE' ? 'Entrada' : 'Salida';
      if (item.status === 'CALLING') return <div></div>;
      let accessMethod = 'Entrada por QQR';
      let statusIcon = <FeatureIcon feature="qqr-code" />;

      if (item.status === 'FINISHED') {
        accessMethod = `${type} por llamada`;
        statusIcon = <FeatureIcon feature="received-call" />;
      } else if (item.status === 'LOST') {
        accessMethod = 'Llamada perdida';
        statusIcon = <FeatureIcon feature="missed-call" />;
      } else if (item.call_type === 'IDCARD') {
        accessMethod = `${type} por ID`;
        statusIcon = <FeatureIcon feature="id-card" />;
      } else if (item.call_type === 'REGISTRATION') {
        accessMethod = `${type} manual`;
        statusIcon = <FeatureIcon feature="hood" />;
      } else if (item.call_type === 'EVENT') {
        accessMethod = `${type} por QR`;
        statusIcon = <FeatureIcon feature="qr-code" />;
      } else if (item.call_type === 'SELFIE') {
        accessMethod = `${type} por foto`;
        statusIcon = <FeatureIcon feature="selfie" />;
      } else if (item.call_type === 'FACE_ID') {
        accessMethod = `${type} por Face ID${
          item.status === 'DENIED' ? ' (Rechazada)' : ''
        }`;
        statusIcon = (
          <FeatureIcon feature="face-id" denied={item.status === 'DENIED'} />
        );
      }

      formatedElement.push(
        <Row align="middle">
          {statusIcon}
          <Col xs={0} md={18} style={styles.itemIcon}>
            {accessMethod}
          </Col>
        </Row>,
      );
      formatedElement.push(
        (item?.home?.number ?? '') +
          (item?.home?.inside_number ? ' - ' + item?.home?.inside_number : ''),
      );
      formatedElement.push(timeDisplay);
      formatedElement.push(
        <Button
          block
          onClick={() => setRecordId(item.id)}
          style={styles.button}>
          {'VER'}
        </Button>,
      );

      formatedData.push({
        data: formatedElement,
        aditionalData: {
          active: true,
        },
      });
    });
    setDataForTable(formatedData);
  };

  useEffect(() => {
    retrieveRecordsData(1);
  }, []);

  useEffect(() => {
    formatDataForTable(recordsData);
  }, [recordsData]);

  return (
    <CommonLayout
      title={motif === 'ENTRANCE' ? 'Entradas' : 'Salidas'}
      filters={
        <Row style={{width: '100%'}}>
          <Col xs={24} sm={9} md={10} lg={9} xl={8}>
            <DatePicker
              disabled={searching}
              onChange={onChangeDate}
              format={'DD/MM/YYYY'}
              showToday={false}
              locale={locale}
              style={styles.datePicker}
              placeholder={
                searching ? 'Búsqueda Completada' : 'Seleccionar Fecha'
              }
            />
          </Col>
          <Col xs={0} sm={9} md={10} lg={9} xl={8} />
          <Col xs={24} sm={6} md={4} lg={6} xl={8} style={styles.filterButton}>
            <Button onClick={searching ? searchModeOff : handleSearchSubmit}>
              {searching ? 'REGRESAR' : 'BUSCAR'}
            </Button>
          </Col>
        </Row>
      }>
      <CustomTable
        loading={loading}
        error={{
          isThereError: error,
          emptyWhat: 'network',
          onRetry: () => goToPage(1, searching),
        }}
        filters={{
          applyFilters: null,
          setApplyFilters: null,
          filtersText: null,
        }}
        pagination={{
          searching,
          page,
          totalItems,
          goToPage,
        }}
        columns={[
          {
            columnTitle: motif === 'ENTRANCE' ? 'Entrada' : 'Salida',
            sizes: {xs: 10, sm: 9, md: 8, xl: 9},
          },
          {
            columnTitle: getHomesName(),
            sizes: {xs: 8, sm: 6, md: 5},
          },
          {columnTitle: 'Fecha', sizes: {xs: 0, sm: 5, md: 7}},
          {columnTitle: 'Detalle', sizes: {xs: 6, sm: 3}},
        ]}
        items={dataForTable}
      />
      {recordId !== null && (
        <CallDetailModal
          callId={recordId}
          visible
          closeModalTrigger={() => setRecordId(null)}
        />
      )}
    </CommonLayout>
  );
};

export default Records;
