import React, {useEffect, useRef, useState} from 'react';
import {Col, Row, Modal, Button} from 'antd';

//local imports
import {getUserHood, getLevelConfig} from '../../../navigation/auth/auth';
import LoadingContent from '../../loading/LoadingContent';
import NoContent from '../../empty_screens/empty_content/NoContent';
import FeatureIcon from '../../../general_components/FeatureIcon/FeatureIcon';
import OwnerFormEditor from './OwnerFormEditor';
import apiInstance from '../../../network/axios';
import {FormInstance} from 'antd/es/form/Form';

interface IOwnerCreator {
  visible: boolean;
  closeModalTrigger: () => void;
  closeRefreshAfterCreate: () => void;
  onFirstCreate?: () => void | null;
}

const OwnerCreator = ({
  closeModalTrigger,
  closeRefreshAfterCreate,
  visible,
  onFirstCreate,
}: IOwnerCreator) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState<string>('network');
  const [streets, setStreets] = useState<any[]>();

  useEffect(() => {
    retrieveStreetsData();
  }, []);

  const formRef = useRef<FormInstance>();

  const validatePost = async () => {
    const values = await formRef.current.validateFields();
    setLoading(true);
    postCreateOwner(values);
  };
  const retrieveStreetsData = async () => {
    try {
      const userNeighborhoodID = getUserHood();
      const url = `/neighborhoods/${userNeighborhoodID}/streets`;
      const response = await apiInstance.get(url);
      const result = response.data;
      setStreets(result);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const postCreateOwner = async (form_object) => {
    try {
      const url = `/homes/${form_object.home_id[1]}/users`;

      const body = {
        role: 'OWNER',
        name: form_object.name,
        last_name: form_object.last_name,
        phone_number: form_object.phone,
        username: form_object.email,
        approved: form_object.active,
      };

      await apiInstance.post(url, body);
      if (getLevelConfig() < 4) {
        localStorage.setItem('levelConfig', '4');
        if (onFirstCreate !== null) {
          localStorage.setItem('availableRoutes', 'FREE');
          onFirstCreate();
        }
      }
      setLoading(false);
      closeRefreshAfterCreate();
    } catch (e) {
      if (e.response) {
        if (
          e.response.data.message.includes(
            'E11000 duplicate key error collection:',
          )
        ) {
          setErrorType('userExist');
        }
      }
      setLoading(false);
      setError(true);
    }
  };

  const retryAfterNetErr = () => {
    setLoading(false);
    setError(false);
    setErrorType('network');
  };

  return (
    <Modal
      centered
      visible={visible}
      onOk={validatePost}
      onCancel={closeModalTrigger}
      okText="CREAR"
      cancelText="CANCELAR"
      maskClosable={false}
      destroyOnClose={true}
      closable={!loading}
      footer={[
        <Button key="back" disabled={loading} onClick={closeModalTrigger}>
          {'CANCELAR'}
        </Button>,
        error ? (
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={retryAfterNetErr}>
            {'REINTENTAR'}
          </Button>
        ) : (
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={validatePost}>
            {'CREAR'}
          </Button>
        ),
      ]}
      title={
        <Row>
          <Col xs={5} sm={4} md={3}>
            <FeatureIcon feature="user" />
          </Col>
          <Col xs={19} sm={20} md={21} style={{fontWeight: 'bold'}}>
            {'Crear Usuario'}
          </Col>
        </Row>
      }>
      {loading ? (
        <LoadingContent />
      ) : error ? (
        <NoContent emptyWhat={errorType} />
      ) : (
        <Row>
          <Col span={24}>
            <OwnerFormEditor
              streetsObject={streets}
              formRef={formRef}
              editMode={true}
            />
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default OwnerCreator;
