import React from 'react';
import * as Unicons from '@iconscout/react-unicons';

//local imports
import {
  BASE_BLUE,
  BRAND_LAGOON,
  BRAND_BLUE,
  BASE_WHITE,
  BASE_PURPLE,
  BASE_GREEN,
  BASE_RED,
  BASE_ORANGE,
  BASE_LIGHT_GRAY,
  BRAND_TURQUOISE,
} from '../../constants/themeColors';
import BrandIcon from './components/BrandIcon';

const FeatureIcon = (props: any) => {
  const {feature, randomColor, denied, entryType = 'expense'} = props;
  const size = '20px';
  const containerHeight = '40px';
  const containerRadius = '20px';
  const randomColorSelect = [
    BASE_GREEN,
    BASE_RED,
    BASE_ORANGE,
    BASE_PURPLE,
    BASE_BLUE,
  ];
  const entryColor = entryType === 'income' ? BASE_GREEN : BASE_RED;
  let containerColor =
    feature === 'super-admin'
      ? BRAND_BLUE
      : feature === 'polls'
      ? BASE_BLUE
      : feature === 'reports'
      ? BASE_BLUE
      : feature === 'user'
      ? BASE_PURPLE
      : feature === 'home'
      ? BRAND_TURQUOISE
      : feature === 'homeAccounting'
      ? entryColor
      : feature === 'door'
      ? BASE_BLUE
      : feature === 'accounting'
      ? BASE_BLUE
      : feature === 'emptyChart'
      ? BASE_WHITE
      : denied
      ? BASE_RED
      : BRAND_LAGOON;

  if (randomColor >= 0) {
    containerColor =
      randomColor >= 0 && randomColor <= 5
        ? randomColorSelect[randomColor]
        : BASE_LIGHT_GRAY;
  }

  //icon displayed, door as default
  const CenterIcon =
    feature === 'missed-call' ? (
      <Unicons.UilMissedCall size={size} color={BASE_WHITE} />
    ) : feature === 'id-card' ? (
      <Unicons.UilCardAtm size={size} color={BASE_WHITE} />
    ) : feature === 'qr-code' ? (
      <Unicons.UilQrcodeScan size={size} color={BASE_WHITE} />
    ) : feature === 'qqr-code' ? (
      <Unicons.UilBolt size={size} color={BASE_WHITE} />
    ) : feature === 'face-id' ? (
      <Unicons.UilSmile size={size} color={BASE_WHITE} />
    ) : feature === 'selfie' ? (
      <Unicons.UilCamera size={size} color={BASE_WHITE} />
    ) : feature === 'recieved-call' ? (
      <Unicons.UilIncomingCall size={size} color={BASE_WHITE} />
    ) : feature === 'message' ? (
      <Unicons.UilEnvelopeAlt size={size} color={BASE_WHITE} />
    ) : feature === 'polls' ? (
      <Unicons.UilClipboardNotes size={size} color={BASE_WHITE} />
    ) : feature === 'reports' ? (
      <Unicons.UilCommentMessage size={size} color={BASE_WHITE} />
    ) : feature === 'home' ? (
      <Unicons.UilHome size={size} color={BASE_WHITE} />
    ) : feature === 'homeAccounting' ? (
      <Unicons.UilHome size={size} color={BASE_WHITE} />
    ) : feature === 'street' ? (
      <Unicons.UilSignAlt size={size} color={BASE_WHITE} />
    ) : feature === 'user' ? (
      <Unicons.UilUser size={size} color={BASE_WHITE} />
    ) : feature === 'cam' ? (
      <Unicons.UilVideo size={size} color={BASE_WHITE} />
    ) : feature === 'door' ? (
      <Unicons.UilKeyholeSquare size={size} color={BASE_WHITE} />
    ) : feature === 'hood' ? (
      <Unicons.UilBuilding size={size} color={BASE_WHITE} />
    ) : feature === 'error' ? (
      <Unicons.UilClosedCaptioning size={size} color={BASE_WHITE} />
    ) : feature === 'accounting' ? (
      <Unicons.UilDollarSign size={size} color={BASE_WHITE} />
    ) : feature === 'emptyChart' ? (
      <Unicons.UilDollarSign size={size} color={'#707070'} />
    ) : feature === 'super-admin' ? (
      <BrandIcon />
    ) : (
      <Unicons.UilSignin size={size} color={BASE_WHITE} />
    );

  return (
    <div
      style={{
        height: containerHeight,
        width: containerHeight,
        backgroundColor: containerColor,
        borderRadius: containerRadius,
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {CenterIcon}
      </div>
    </div>
  );
};

export default FeatureIcon;
