import React, {useEffect, useState} from 'react';
import {Col, Row} from 'antd';

//local imports
import {getUserId} from '../../navigation/auth/auth';
import LoadingContent from '../loading/LoadingContent';
import NoContent from '../empty_screens/empty_content/NoContent';
import AdminUserEditor from './components/AdminUserEditor';
import apiInstance from '../../network/axios';

const UserProfile = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [userData, setUserData] = useState<any>({});

  useEffect(() => {
    retrieveUserData();
  }, []);

  const retrieveUserData = async () => {
    try {
      const userID = getUserId();

      const url = `/users/${userID}`;

      const response = await apiInstance.get(url);
      const result = response.data;

      setUserData({...result});
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  if (loading) {
    return <LoadingContent />;
  }

  if (error) {
    return (
      <NoContent
        emptyWhat={'network'}
        withRetry
        onClickMethod={() => retrieveUserData()}
      />
    );
  }

  return (
    <div style={{padding: '6.25% 4.166%'}}>
      <Row
        gutter={{xs: 8, sm: 0, md: 0, lg: 0}}
        style={{backgroundColor: 'transparent'}}>
        <Col
          span={24}
          style={{fontSize: '20px', fontWeight: 'bold', height: '33px'}}>
          {'Perfil'}
        </Col>
      </Row>

      <Row>
        <Col span={24} style={{height: '32px'}} />
      </Row>

      <AdminUserEditor defaultValues={userData} />
    </div>
  );
};

export default UserProfile;
