import {Button, Col, Modal, Row} from 'antd';
import React from 'react';
import {BASE_RED, BASE_WHITE} from '../../../constants/themeColors';
export default (props) => {
  const {onCancel, onContinue, visible, remaningDays} = props;
  return (
    <Modal
      onCancel={props.closeModal}
      centered
      visible={visible}
      width={'400px'}
      destroyOnClose
      title={null}
      footer={null}>
      {
        <Row>
          <Col span={24}>
            <h3 style={{marginTop: 30, textAlign: 'center', marginBottom: 30}}>
              {remaningDays > 0
                ? `Aún te quedan ${remaningDays} dias en tu suscripción. ¿Deseas continuar?`
                : 'Deseas cancelar tu suscripción'}
            </h3>
            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
              <Button
                style={{
                  backgroundColor: BASE_RED,
                  opacity: 0.7,
                  color: BASE_WHITE,
                }}
                onClick={onContinue}>
                {'CONTINUAR'}
              </Button>
              <Button onClick={onCancel}>{'CANCELAR'}</Button>
            </div>
          </Col>
        </Row>
      }
    </Modal>
  );
};
