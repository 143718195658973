import React from 'react';

//local imports
import logo from '../../../res/img/trans_128.png';

const BrandIcon = () => {
  return (
    <img
      src={logo}
      alt="brand-logo"
      style={{
        maxWidth: '80%',
        height: 'auto',
      }}
    />
  );
};

export default BrandIcon;
