import {CSSProperties} from 'react';

export const HomesStyles: Record<string, CSSProperties> = {
  container: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    borderColor: '#FFFFFF',
    padding: '0px',
    borderRadius: '5px',
    marginTop: '10px',
  },
  iconContainer: {
    padding: '0px 10px',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  largeButon: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  largeButonIcon: {
    padding: '0px 10px',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    paddingRight: '10px',
  },
  itemButton: {borderColor: '#545454'},
  itemIcon: {
    fontWeight: 'bold',
    paddingLeft: 10,
  },
  updateSubsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: 0,
  },
  updateSubsText: {
    marginBottom: 0,
    textAlign: 'end',
  },
};
