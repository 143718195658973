import {Col, Modal, Row} from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import React from 'react';
import {PaymentDetailStyles as styles} from '../styles';
import {UilDollarSign} from '@iconscout/react-unicons';
import {BASE_WHITE} from '../../../constants/themeColors';

export const PaymentDetail = (props) => {
  const {data} = props;
  return (
    <Modal
      onCancel={props.closeModal}
      centered
      visible={true}
      width={'600px'}
      destroyOnClose={true}
      closable={true}
      maskClosable={false}
      title={
        <Row>
          <Col xs={5} sm={4} md={3}>
            <div style={styles.icon}>
              <UilDollarSign size={30} color={BASE_WHITE} />
            </div>
          </Col>
          <Col xs={19} sm={20} md={21} style={styles.modalTitle}>
            {'Detalle del cargo'}
          </Col>
        </Row>
      }
      footer={null}>
      {
        <Row>
          <Col span={24}>
            <h3>{'Mónto'}</h3>
            <p style={styles.paragraph}>{`${data?.currency ?? ''} ${numeral(
              data?.total ?? '',
            ).format('$0,0.00')}`}</p>

            <h3>{'Fecha'}</h3>
            <p style={styles.paragraph}>
              {moment(data.createTime).format('ll')}
            </p>

            <h3>{'Descripción'}</h3>
            <p style={styles.paragraph}>{data?.summary ?? ''}</p>
          </Col>
        </Row>
      }
    </Modal>
  );
};
