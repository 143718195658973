/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import CommonLayout from '../../layouts/CommonLayout';
import PaymentCreator from './components/PaymentCreator';
import {Button, Col, DatePicker, Row, Select} from 'antd';
import UilSignAlt from '@iconscout/react-unicons/icons/uil-sign-alt';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';
import {StreetsStyles as styles} from '../streets/styles';
import {TPayment} from '../../types/manualPaymentTypes';
import apiInstance from '../../network/axios';
import FeatureIcon from '../../general_components/FeatureIcon/FeatureIcon';
import CustomTable from '../../molecules/CustomTable';
import numeral from 'numeral';
import moment from 'moment';
import PaymentEditor from './components/PaymentEditor';
import locale from 'antd/es/date-picker/locale/es_ES';

const Payments = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [payments, setPayments] = useState<TPayment[]>();
  const [page, setPage] = useState<number>(1);
  const [totalItems, setTotalItems] = useState<number>(10);
  const [dataForTable, setDataForTable] = useState([]);
  const [createPayment, setCreatePayment] = useState(false);
  const [viewPayment, setViewPayment] = useState<TPayment | null>(null);
  const [neighborhoods, setNeighborhoods] = useState<any[]>();
  const [selectedHood, setSelectedHood] = useState<string>();
  const [selectedType, setSelectedType] = useState<'All' | 'MANUAL'>();
  const [selectedRange, setSelectedRange] = useState<moment.Moment[]>([]);

  const getPayments = async (pageNum: number = 1) => {
    setLoading(true);
    try {
      const range =
        selectedRange?.length > 1
          ? `&startDate=${moment
              .utc(selectedRange[0])
              .format()}&endDate=${moment.utc(selectedRange[1]).format()}`
          : '';
      const paymentMode =
        selectedType === 'MANUAL' ? '&manualPayment=true' : '';
      const neighborhoodId =
        selectedHood && selectedHood !== 'ALL'
          ? `&neighborhoodId=${selectedHood}`
          : '';

      const url = `/paypal/totalPayments?limit=10&page=${pageNum}${paymentMode}${range}${neighborhoodId}`;
      const resp = await apiInstance.get(url);
      setTotalItems(
        resp.headers['x-paginate-total']
          ? parseInt(resp.headers['x-paginate-total'])
          : 10,
      );
      setPayments(resp.data);

      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
      console.error(e);
    }
  };
  const getNeighborhoods = async () => {
    try {
      const response = await apiInstance.get('/neighborhoods');
      const result = response.data;

      if (result.length === 0) {
        setLoading(false);
        return;
      }
      setNeighborhoods(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };
  const goToPage = (pageNum) => {
    setPage(pageNum);
    setError(false);
    getPayments(pageNum);
  };
  const formatDataForTable = (data: TPayment[] = []) => {
    const formatedData = [];
    data.forEach((item, index) => {
      const statusIcon = <FeatureIcon feature="accounting" />;
      const formatedElement = [];

      formatedElement.push(
        <Row align="middle">
          {statusIcon}
          <Col span={12} style={styles.itemIcon}>
            <p
              style={{
                padding: 0,
                margin: 0,
                fontWeight: 'bold',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
              {item?.neighborhood?.number ?? 'Sin Nombre'}
            </p>
          </Col>
        </Row>,
      );
      formatedElement.push(
        <p style={{padding: 0, margin: 0}}>
          {`${item?.currency ?? 'MXN'}${numeral(item.total).format('$0,0.00')}`}
        </p>,
      );
      formatedElement.push(
        <p style={{padding: 0, margin: 0}}>
          {moment(item.createTime).format('ll')}
        </p>,
      );
      formatedElement.push(
        <Button
          block
          onClick={() => setViewPayment(item)}
          style={styles.itemButton}>
          {'VER'}
        </Button>,
      );

      formatedData.push({
        data: formatedElement,
        aditionalData: {
          active: true,
        },
      });
    });
    setDataForTable(formatedData);
  };

  const refresh = () => {
    setPage(1);
    setCreatePayment(false);
    setLoading(true);
    setError(false);
    getPayments();
    setViewPayment(null);
  };

  useEffect(() => {
    formatDataForTable(payments);
  }, [payments]);

  useEffect(() => {
    getNeighborhoods();
  }, []);

  useEffect(() => {
    setPage(1);
    getPayments();
  }, [selectedHood, selectedType, selectedRange]);

  return (
    <CommonLayout
      title={'Pagos'}
      buttonAdd={
        <Button style={styles.container} onClick={() => setCreatePayment(true)}>
          <Row justify="center" align="middle">
            <Col xs={24} sm={0}>
              <div style={styles.iconContainer}>
                <UilPlus size="16" color="#FFFFFF" />
                <UilSignAlt size="18" color="#FFFFFF" />
              </div>
            </Col>
            <Col xs={0} sm={24}>
              <div style={styles.largeButon}>
                <div style={styles.largeButonIcon}>
                  <UilSignAlt size="18" color="#FFFFFF" />
                </div>
                <div style={styles.title}>{'AGREGAR PAGO'}</div>
              </div>
            </Col>
          </Row>
        </Button>
      }
      filters={
        <Row style={{width: '100%'}} gutter={[15, 15]}>
          <Col xs={24} md={12} lg={8}>
            <Select
              style={{width: '100%'}}
              dropdownStyle={{zIndex: 3000}}
              value={selectedHood}
              onChange={(e) => setSelectedHood(e)}
              placeholder={'Vecindario'}>
              <Select.Option value={'ALL'}>{'Todos'}</Select.Option>
              {neighborhoods &&
                neighborhoods.map((item) => (
                  <Select.Option value={item.id} key={item.id}>
                    {item?.number ?? 'Sin Nombre'}
                  </Select.Option>
                ))}
            </Select>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <Select
              style={{width: '100%'}}
              dropdownStyle={{zIndex: 3000}}
              value={selectedType}
              onChange={(e) => setSelectedType(e)}
              placeholder={'Tipo de pago'}>
              <Select.Option value={'ALL'}>{'Todos'}</Select.Option>
              <Select.Option value={'MANUAL'}>{'Pagos manuales'}</Select.Option>
            </Select>
          </Col>
          <Col xs={24} md={12} lg={8}>
            <DatePicker.RangePicker
              style={{width: '100%'}}
              onChange={(dates) => setSelectedRange(dates)}
              format={'DD/MM/YYYY'}
              locale={locale}
              placeholder={['Seleccionar Fecha', 'Seleccionar Fecha']}
            />
          </Col>
        </Row>
      }>
      <CustomTable
        loading={loading}
        error={{
          isThereError: error,
          emptyWhat: 'network',
          onRetry: () => goToPage(1),
        }}
        pagination={{
          searching: false,
          page,
          totalItems,
          goToPage,
        }}
        emptyText="Sin registros"
        columns={[
          {columnTitle: 'Neighborhood', sizes: {xs: 9, lg: 8}},
          {columnTitle: 'Total', sizes: {xs: 8, lg: 6, xl: 6}},
          {columnTitle: 'Fecha', sizes: {xs: 0, lg: 5, xl: 5}},
          {columnTitle: 'Detalle', sizes: {xs: 7, lg: 5, xl: 5}},
        ]}
        items={dataForTable}
      />
      {createPayment && (
        <PaymentCreator
          visible={createPayment}
          closeModalTrigger={() => setCreatePayment(false)}
          closeRefreshAfterCreate={refresh}
        />
      )}
      {viewPayment && (
        <PaymentEditor
          visible={viewPayment !== null}
          closeRefreshAfterCreate={refresh}
          closeModalTrigger={() => setViewPayment(null)}
          payment={viewPayment}
        />
      )}
    </CommonLayout>
  );
};

export default Payments;
