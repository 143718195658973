/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {AuthContext} from '../../context/AuthContext';
import {isAuthenticated, getAccessLevel} from './auth';

export default function SuperShieldedRoute({children, ...rest}) {
  const context: any = useContext(AuthContext);
  useEffect(() => {
    context.setGlobalState({showMenu: true});
  }, []);
  return (
    <Route
      {...rest}
      render={({location}) =>
        isAuthenticated() && getAccessLevel() === 'SUPER_ADMIN' ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: {from: location},
            }}
          />
        )
      }
    />
  );
}
