import {Col, FormInstance, Modal, Row} from 'antd';
import React, {useCallback, useRef, useState} from 'react';
import {CircleButton} from '../../../atoms';
import {PLANS} from '../../../constants/Routes';
import {
  UTILITY_BLUE,
  UTILITY_RED,
  UTILITY_YELLOW,
} from '../../../constants/themeColors';
import FeatureIcon from '../../../general_components/FeatureIcon/FeatureIcon';
import apiInstance from '../../../network/axios';
import NoContent from '../../empty_screens/empty_content/NoContent';
import LoadingContent from '../../loading/LoadingContent';
import {TBell} from '../types';
import BellForm from './BellForm';

const BellEditor = ({
  visible,
  onClose,
  onSuccess,
  bell,
}: {
  visible: boolean;
  onClose: () => void;
  onSuccess: () => void;
  bell: TBell;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const formRef = useRef<FormInstance>();

  const validateForm = useCallback(async () => {
    const values = await formRef.current.validateFields();
    const currentPlan = localStorage.getItem('currentPlan');
    const updateBell = async (form_object: TBell) => {
      setLoading(true);
      try {
        const url = `/users/${bell?._id}`;
        const body: TBell = {
          role: 'BELL',
          name: form_object.name,
          last_name: form_object.last_name,
          phone_number: form_object.phone_number,
          username: form_object.username,
          approved: true,
          sub_role:
            currentPlan === PLANS.VIGILANCE ? 'MANUAL' : form_object.sub_role,
        };
        if (form_object.password) {
          body['password'] = form_object.password;
        }
        await apiInstance.put(url, body);
        setLoading(false);
        onSuccess();
      } catch (error) {
        setLoading(false);
        setIsError(true);
      }
    };
    setLoading(true);
    updateBell(values);
  }, [formRef, onSuccess, bell]);

  const confirmDeleteUser = useCallback(() => {
    if (window.confirm('¿Esta seguro que desea eliminar este elemento?')) {
      setLoading(true);
    }
    const deleteUser = async () => {
      try {
        const url = `/users/${bell._id}`;
        await apiInstance.delete(url);

        setLoading(false);
        onSuccess();
      } catch (error) {
        setLoading(false);
        setIsError(true);
      }
    };
    deleteUser();
  }, [bell, onSuccess]);
  return (
    <Modal
      centered
      visible={visible}
      onCancel={onClose}
      maskClosable={false}
      zIndex={2000}
      destroyOnClose={true}
      closable={!loading}
      footer={
        isError ? null : (
          <Row>
            <Col span={2}>
              <CircleButton
                variant="del"
                activeColor={UTILITY_RED}
                disabled={loading}
                onClick={confirmDeleteUser}
              />
            </Col>
            <Col span={22}>
              <CircleButton
                variant="genericEdit"
                activeColor={UTILITY_YELLOW}
                disabled={loading}
                onClick={() => setEditMode(!editMode)}
                clickedMode={editMode}
              />
              <CircleButton
                variant="save"
                activeColor={UTILITY_BLUE}
                disabled={!editMode ? true : loading}
                onClick={validateForm}
              />
            </Col>
          </Row>
        )
      }
      title={
        <Row>
          <Col xs={5} sm={4} md={3}>
            <FeatureIcon feature="user" />
          </Col>
          <Col xs={19} sm={20} md={21} style={{fontWeight: 'bold'}}>
            {bell?.name ?? 'Timbre'}
            {bell?.last_name ?? ''}
          </Col>
        </Row>
      }>
      {loading ? (
        <LoadingContent />
      ) : isError ? (
        <NoContent emptyWhat={'network'} />
      ) : (
        <Row>
          <Col span={24}>
            <BellForm
              formRef={formRef}
              defaultValues={bell}
              editMode={editMode}
            />
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default BellEditor;
