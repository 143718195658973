import {Button, Col, DatePicker, List, Row} from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import axios from 'axios';
import moment from 'moment';
import numeral from 'numeral';
import React, {useEffect, useState} from 'react';
import {Doughnut} from 'react-chartjs-2';
import {BASE_URL, DEVELOPMENT, NETWORK_TIMEOUT} from '../../constants/network';
import {
  BASE_GREEN,
  BASE_RED,
  dashBoardColors,
  UTILITY_BLUE,
} from '../../constants/themeColors';
import FeatureIcon from '../../general_components/FeatureIcon/FeatureIcon';
import {getUserHood, getUserToken} from '../../navigation/auth/auth';
import NoContent from '../empty_screens/empty_content/NoContent';
import LoadingContent from '../loading/LoadingContent';
import AddModal from './components/AddModal';
import {styles} from './styles';

const Balance = () => {
  const [searchingFor, setSearchingFor] = useState([]);
  const [searching, setSearching] = useState(false);
  const [incomesChart, setIncomesChart] = useState<any>({});
  const [expensesChart, setExpensesChart] = useState<any>({});
  const [categories, setCategories] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState({});
  const [allEntries, setAllEntries] = useState({});
  const [dataForList, setDataForList] = useState([]);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    getAll();
    // eslint-disable-next-line
  }, []);

  const getAll = async () => {
    await getCategories();
    getDashboardData(firstDay, lastDay);
  };

  const date = new Date();
  const firstDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    1,
  ).toISOString();
  const lastDay = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
  ).toISOString();
  const onChangeDate = (dates) => {
    if (dates?.length > 1) {
      setSearchingFor([
        new Date(dates[0]._d).toISOString(),
        new Date(dates[1]._d).toISOString(),
      ]);
    }
  };

  const getDashboardData = async (_minDate, _maxDate) => {
    try {
      const userNeighborhoodID = getUserHood();
      const userToken = getUserToken();
      const url = `${BASE_URL}/neighborhoods/${userNeighborhoodID}/accounting/totals?min_date=${_minDate}&max_date=${_maxDate}&type=balance`;
      const options = {
        headers: {
          'X-App': 'admin',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + userToken,
        },
        timeout: NETWORK_TIMEOUT,
      };
      const response = await axios.get(url, options);
      if (response.data.success) {
        setAllEntries(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      if (DEVELOPMENT) {
        console.log('Error getting dashboardData', error?.response ?? error);
      }
      setLoading(false);
      setError(true);
    }
  };

  const getCategories = async () => {
    try {
      const userNeighborhoodID = getUserHood();
      const userToken = getUserToken();
      const url = `${BASE_URL}/neighborhoods/${userNeighborhoodID}/accounting/categories`;
      const options = {
        headers: {
          'X-App': 'admin',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + userToken,
        },
        timeout: NETWORK_TIMEOUT,
      };
      const response = await axios.get(url, options);
      if (response.data.success) {
        setCategories(response.data.categories);
        setLoading(false);
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      if (DEVELOPMENT) {
        console.log('Error getting categories', error?.response ?? error);
      }
      setLoading(false);
      setError(true);
    }
  };

  const formatData = (data) => {
    const dataIncomes = {
      datasets: [
        {
          data: [],
          backgroundColor: [],
          borderColor: [],
          borderWidth: 1,
        },
      ],
      labelsObj: {},
      labels: [],
    };
    const dataExpenses = {
      datasets: [
        {
          data: [],
          backgroundColor: [],
          borderColor: [],
          borderWidth: 1,
        },
      ],
      labelsObj: {},
      labels: [],
    };
    let _total = 0;
    const labelsObjIncomes = {};
    const labelsObjExpenses = {};
    Object.keys(data).forEach((e: any, index) => {
      if (Object.keys(data[e]).includes('income')) {
        labelsObjIncomes[data[e].categoryName] = {
          income: data[e].income,
          color: data[e].color,
        };
      }
      if (Object.keys(data[e]).includes('expense')) {
        labelsObjExpenses[data[e].categoryName] = {
          expense: data[e].expense,
          color: data[e].color,
        };
      }
      _total = _total + ((data[e]?.income ?? 0) - (data[e]?.expense ?? 0));
    });

    const incomeLabels = [];
    const incomeValues = [];
    const incomeColors = [];
    let otherIncomes = 0;
    const incomeEntries = Object.entries(labelsObjIncomes).sort(
      (a: any, b: any) => b[1].income - a[1].income,
    );
    incomeEntries.forEach((e: any) => {
      if (incomeValues.length <= 8) {
        incomeLabels.push(e[0]);
        incomeValues.push(e[1].income);
        incomeColors.push(e[1].color);
      } else {
        otherIncomes = otherIncomes + e[1].income;
      }
    });
    if (otherIncomes > 0) {
      incomeLabels.push('Otras categorías');
      incomeValues.push(otherIncomes);
      incomeColors.push('#fa8100');
    }
    dataIncomes.labels = incomeLabels;
    dataIncomes.datasets[0].data = incomeValues;
    dataIncomes.datasets[0].backgroundColor = incomeColors;
    dataIncomes.datasets[0].borderColor = incomeColors;

    const expenseLabels = [];
    const expenseValues = [];
    const expenseColors = [];

    let otherExpenses = 0;
    const expenseEntries = Object.entries(labelsObjExpenses).sort(
      (a: any, b: any) => b[1].expense - a[1].expense,
    );
    expenseEntries.forEach((e: any) => {
      if (expenseValues.length <= 8) {
        expenseLabels.push(e[0]);
        expenseValues.push(e[1].expense);
        expenseColors.push(e[1].color);
      } else {
        otherExpenses = otherExpenses + e[1].expense;
      }
    });
    if (otherExpenses > 0) {
      expenseLabels.push('Otras categorías');
      expenseValues.push(otherExpenses);
      expenseColors.push('#fa8100');
    }
    dataExpenses.labels = expenseLabels;
    dataExpenses.datasets[0].data = expenseValues;
    dataExpenses.datasets[0].backgroundColor = expenseColors;
    dataExpenses.datasets[0].borderColor = expenseColors;

    setTotal(_total);
    setIncomesChart(dataIncomes);
    setExpensesChart(dataExpenses);
  };

  const addCategoryName = () => {
    const _allData = allEntries;
    const _dataForList = [];
    let aux = 0;
    Object.keys(allEntries).forEach((e) => {
      const category = categories.filter((item) => e === item._id);
      if (category.length > 0) {
        _allData[e].categoryName = category[0].name;
        _allData[e].color = dashBoardColors[aux];
        if (aux === dashBoardColors.length - 1) {
          aux = 0;
        } else {
          aux++;
        }
      }
      if (e === 'undefined') {
        delete allEntries[e];
      }
    });
    Object.keys(_allData).forEach((e, index) => {
      const item = {
        id: e,
        name: _allData[e].categoryName,
        income: _allData[e]?.income ?? 0,
        expense: _allData[e]?.expense ?? 0,
        color: _allData[e].color,
      };
      _dataForList.push(item);
    });
    setDataForList(_dataForList);
    setAllData(_allData);
  };

  const searchModeOff = () => {
    setSearching(false);
    setSearchingFor([]);
    setLoading(true);
    getDashboardData(firstDay, lastDay);
  };
  const handleSearchSubmit = () => {
    setSearching(true);
    setLoading(true);
    getDashboardData(searchingFor[0], searchingFor[1]);
  };

  useEffect(() => {
    addCategoryName();
    // eslint-disable-next-line
  }, [allEntries]);

  useEffect(() => {
    formatData(allData);
  }, [allData]);

  if (loading) {
    return <LoadingContent />;
  }

  if (error) {
    return (
      <NoContent
        emptyWhat={'network'}
        withRetry
        onClickMethod={async () => {
          setError(false);
          setLoading(true);
          await getCategories();
          getDashboardData(firstDay, lastDay);
        }}
      />
    );
  }
  if (categories.length === 0) {
    return (
      <AddModal
        visible={categories.length === 0}
        title={'Añadir categoria'}
        cancelable={false}
        iconColor={UTILITY_BLUE}
        reload={() => {
          getAll();
        }}
      />
    );
  }
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'right' as 'right',
      },
    },
  };

  const {RangePicker} = DatePicker;

  return (
    <div style={styles.mainContainer}>
      <Row gutter={16} style={{backgroundColor: 'transparent'}}>
        <Col xs={24} sm={13} md={12} style={styles.bold}>
          {'Balance General'}
        </Col>
        <Col xs={14} sm={6} md={7} lg={6}>
          <RangePicker
            disabled={searching}
            onChange={onChangeDate}
            format={'DD/MM/YYYY'}
            locale={locale}
            style={styles.item}
            placeholder={[
              searching ? 'Búsqueda Completada' : 'Seleccionar Fechas',
              searching ? 'Búsqueda Completada' : 'Seleccionar Fechas',
            ]}
            value={[
              moment(searchingFor.length > 1 ? searchingFor[0] : firstDay),
              moment(searchingFor.length > 1 ? searchingFor[1] : lastDay),
            ]}
          />
        </Col>
        <Col xs={0} sm={1} lg={2} />
        <Col xs={10} sm={4} style={styles.configTablePaginationColumnCenter}>
          {searching ? (
            <Button onClick={searchModeOff}>{'REGRESAR'}</Button>
          ) : (
            <Button
              disabled={searchingFor.length === 0}
              onClick={handleSearchSubmit}>
              {'BUSCAR'}
            </Button>
          )}
        </Col>
      </Row>
      <Row style={styles.rowSeparator} />
      <Row gutter={[20, 20]}>
        <Col xs={24} lg={12}>
          <div style={styles.dashboardList}>
            <Row style={styles.dashboardListTitle}>{'Ingresos'}</Row>
            <Row>
              <Col span={24} style={styles.emptyChart}>
                {incomesChart.datasets[0].data.length === 0 ? (
                  <>
                    <FeatureIcon feature="emptyChart" />
                    {'Sin Registros'}
                  </>
                ) : (
                  <Doughnut
                    style={{maxHeight: 250}}
                    options={options}
                    data={incomesChart}
                  />
                )}
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={24} lg={12}>
          <div style={styles.dashboardList}>
            <Row style={styles.dashboardListTitle}>{'Egresos'}</Row>
            <Row>
              <Col span={24} style={styles.emptyChart}>
                {expensesChart.datasets[0].data.length === 0 ? (
                  <>
                    <FeatureIcon feature="emptyChart" />
                    {'Sin Registros'}
                  </>
                ) : (
                  <Doughnut
                    style={{maxHeight: 250}}
                    data={expensesChart}
                    options={options}
                  />
                )}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row style={styles.configTablePaginationRow}>
        <Col span={24}>
          <div style={styles.configTablePaginationColumnCenter}>
            {`Del ${moment(
              searchingFor.length === 0 ? firstDay : searchingFor[0],
            ).format('DD MMM YY')} al ${moment(
              searchingFor.length === 0 ? lastDay : searchingFor[1],
            ).format('DD MMM YY')}`}
          </div>
        </Col>
      </Row>
      <Row style={styles.configTableHeader}>
        <Col xs={3} sm={7}>
          <Row>
            <Col xs={0} sm={7} style={styles.dashboardListTitle}>
              {'Categoría'}
            </Col>
          </Row>
        </Col>
        <Col span={7} style={styles.dashboardListTitle}>
          {'Ingresos'}
        </Col>
        <Col span={7} style={styles.dashboardListTitle}>
          {'Egresos'}
        </Col>
        <Col xs={7} sm={3} style={styles.dashboardListTitle}>
          {'Total'}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <List
            locale={{emptyText: 'Sin Registros'}}
            style={styles.configList}
            dataSource={dataForList}
            renderItem={(item) => {
              return (
                <List.Item
                  style={{
                    minHeight: '60px',
                  }}>
                  <Col xs={3} sm={7}>
                    <Row gutter={10}>
                      <Col
                        style={{
                          marginLeft: 10,
                          width: 20,
                          height: 20,
                          borderRadius: 10,
                          backgroundColor: item.color,
                        }}></Col>
                      <Col xs={0} sm={18}>
                        {item.name}
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    span={7}
                    style={{
                      fontWeight: '500',
                      color: BASE_GREEN,
                    }}>
                    {numeral(item.income).format('$0,0.00')}
                  </Col>
                  <Col
                    span={7}
                    style={{
                      fontWeight: '500',
                      color: BASE_RED,
                    }}>
                    {numeral(item.expense * -1).format('$0,0.00')}
                  </Col>
                  <Col
                    xs={7}
                    sm={3}
                    style={{
                      fontWeight: '500',
                      color:
                        item.income - item.expense <= 0 ? BASE_RED : BASE_GREEN,
                    }}>
                    {numeral(item.income - item.expense).format('$0,0.00')}
                  </Col>
                </List.Item>
              );
            }}
          />
        </Col>
      </Row>
      <Row style={styles.rowSeparator} />
      <Row>
        <Col
          xs={{span: 16, offset: 8}}
          sm={{span: 8, offset: 16}}
          md={{span: 10, offset: 14}}
          lg={{span: 8, offset: 16}}>
          <div style={styles.smallPaper}>
            <p style={styles.bold}>{'Total'}</p>
            <div
              style={{
                ...styles.tintGreenCenter,
                color: total > 0 ? BASE_GREEN : BASE_RED,
              }}>
              {numeral(total ?? 0).format('$0,0.00')}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Balance;
