/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {InputNumber, Modal} from 'antd';
import apiInstance from '../../../network/axios';
import NoContent from '../../empty_screens/empty_content/NoContent';
import LoadingContent from '../../loading/LoadingContent';
import {PayPalScriptProvider} from '@paypal/react-paypal-js';
import {ButtonWrapper} from '../../../molecules/Paypal';
import {useHistory} from 'react-router';

interface IUpdateSubscriptionQuantity {
  visible: boolean;
  quantity: number;
  currentHomesQuantity: number;
  onClose: () => void;
  onApprove: () => void;
}

const UpdateSubscriptionQuantity = ({
  visible,
  quantity,
  onClose,
  currentHomesQuantity,
  onApprove,
}: IUpdateSubscriptionQuantity) => {
  const history = useHistory();
  const currentPlan = localStorage.getItem('currentPlan');

  const [newQuantity, setNewQuantity] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [plan, setPlan] = useState<any>({});
  const [clientId, setClientId] = useState<string>('');
  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState('network');
  const [currentHomes, setCurrentHomes] = useState(0);

  const getPlansInfo = async () => {
    try {
      const resp = await apiInstance.get('/paypal/plans');
      const plans = resp.data?.plans ?? [];
      setClientId(resp?.data?.paypalClientId ?? '');
      const current = plans.find((item: any) => item.name === currentPlan);

      setPlan(current);
      setLoading(false);
    } catch (e) {
      console.error('error getting plans', e);
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    setNewQuantity(quantity);
    setCurrentHomes(currentHomesQuantity);
  }, [quantity, currentHomesQuantity]);

  useEffect(() => {
    getPlansInfo();
  }, []);

  return (
    <Modal
      visible={visible}
      footer={null}
      onCancel={onClose}
      destroyOnClose={true}
      centered>
      {loading && <LoadingContent />}
      {error && <NoContent emptyWhat={errorType} />}
      <h1>Modificar suscripción</h1>
      <h4>{`Actualmente cuentas con ${quantity} casas en tu suscripción`}</h4>
      <InputNumber
        style={{width: '80%'}}
        value={newQuantity}
        onChange={(e) => setNewQuantity(e)}
        defaultValue={quantity}
        min={currentHomes}
      />
      <p style={{fontSize: '12px'}}>
        Seleccione el numero de casas que necesita y elija un metodo de pago
        para modificar su suscripción.
      </p>
      <div style={{minHeight: '148px'}}>
        <PayPalScriptProvider
          options={{
            'client-id': clientId,
            components: 'buttons',
            intent: 'subscription',
            vault: true,
          }}>
          <ButtonWrapper
            type="subscription"
            planId={plan?.id ?? ''}
            quantity={
              newQuantity > 0
                ? newQuantity.toString()
                : currentHomesQuantity.toString()
            }
            history={history}
            plan={currentPlan}
            onError={{setError, setErrorType}}
            onApprove={() => {
              onApprove();
              onClose();
            }}
            disabled={newQuantity < currentHomes}
          />
        </PayPalScriptProvider>
      </div>
    </Modal>
  );
};

export default UpdateSubscriptionQuantity;
