export const RoutesManifest = {
  cameras: {
    name: 'cameras',
    path: '/cameras',
  },
  reports: {
    name: 'reports',
    path: '/reports',
  },
  calendar: {
    name: 'calendar',
    path: '/calendar',
  },
  doors: {
    name: 'doors',
    path: '/doors',
  },
  home: {
    name: 'home',
    path: '/home',
  },
  hoods: {
    name: 'hoods',
    path: '/hoods',
  },
  feedback: {
    name: 'feedback',
    path: '/feedback',
  },
  users: {
    name: 'users',
    path: '/users',
  },
  entrance: {
    name: 'entrance',
    path: '/records/entrance',
  },
  exit: {
    name: 'exit',
    path: '/record/exit',
  },
  streets: {
    name: 'streets',
    path: '/streets',
  },
  homes: {
    name: 'homes',
    path: '/homes',
  },
  owners: {
    name: 'owners',
    path: '/owners',
  },
  notifications: {
    name: 'notifications',
    path: '/notifications',
  },
  settings: {
    name: 'settings',
    path: '/settings',
  },
  subscribe: {
    name: 'subscribe',
    path: '/subscribe',
  },
  messages: {
    name: 'messages',
    path: '/messages',
  },
  polls: {
    name: 'polls',
    path: '/polls',
  },
  recovery: {
    name: 'recovery',
    path: '/recovery/:requester/:token',
  },
  profile: {
    name: 'profile',
    path: '/user_profile',
  },
  accountingDashboard: {
    name: 'accounting',
    path: '/accounting',
  },
  accountingHomes: {
    name: 'accounting-homes',
    path: '/accountinghomes',
  },
  accountingBalance: {
    name: 'balance',
    path: '/balance',
  },
  accountingConfig: {
    name: 'config',
    path: '/accountingconfig',
  },
  accountingIncomeExpenses: {
    name: 'incomeExpenses',
    path: '/accounting/incomeexpenses',
  },
  djDasboard: {
    name: 'dj-dashboard',
    path: '/digitalJourney/dashboard',
  },
  djIntro: {
    name: 'dj-intro',
    path: '/digitalJourney/intro',
  },
  djCreateNeighborhood: {
    name: 'dj-create-neighborhood',
    path: '/digitalJourney/createNeighborhood',
  },
  djStreets: {
    name: 'dj-streets',
    path: '/digitalJourney/createStreets',
  },
  djHomes: {
    name: 'dj-homes',
    path: '/digitalJourney/createHomes',
  },
  djOwners: {
    name: 'dj-owners',
    path: '/digitalJourney/createOwners',
  },
  createPassword: {
    name: 'create-password',
    path: '/createPassword/:email/:token',
  },
  verifyEmail: {
    name: 'verify-email',
    path: '/verify_email/:email/:token',
  },
  accessDenied: {
    name: 'access-denied',
    path: '/accessDenied',
  },
  updatePlan: {
    name: 'update-plan',
    path: '/updatePlan',
  },
  viewNeighborhood: {
    name: 'view-neigborhood',
    path: '/view_neighborhood',
  },
  subscription: {
    name: 'subscription',
    path: '/subscription',
  },
  commonAreas: {
    name: 'common-areas',
    path: '/common_areas',
  },
  commonAreasReservations: {
    name: 'common-areas-reservations',
    path: '/common_areas/reservations',
  },
  dashboard: {
    name: 'main-dasboard',
    path: '/dashboard',
  },
  payments: {
    name: 'payments',
    path: '/payments',
  },
  login: {
    name: 'login',
    path: '/',
  },
};
