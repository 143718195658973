import React from 'react';
import featureGraphic from '../../../res/img/featureGraphic_1024x500.png';

const RightSideImage = () => {
  return (
    <img
      src={featureGraphic}
      alt="login-img"
      style={{
        width: 'auto',
        height: '100%',
        maxHeight: '380px',
        display: 'block',
      }}
    />
  );
};

export default RightSideImage;
