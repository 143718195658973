/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Col, Row, Button, DatePicker, Input} from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';

//local imports
import {
  isExpired,
  requiredDateValForServer,
  getDateDisplay,
} from '../../utils/locale/chronos';
import {getUserHood, getAccessLevel} from '../../navigation/auth/auth';
import FeatureIcon from '../../general_components/FeatureIcon/FeatureIcon';
import {UilFilePlusAlt, UilPlus} from '@iconscout/react-unicons';
import PollEditor from './components/PollEditor';
import PollViewer from './components/PollViewer';
import CommonLayout from '../../layouts/CommonLayout';
import {PollsStyles as styles} from './styles';
import apiInstance from '../../network/axios';
import CustomTable from '../../molecules/CustomTable';
import {resizeString} from '../../utils/strings';
import {SearchOutlined} from '@ant-design/icons';

const Polls = () => {
  const sudo = getAccessLevel() === 'SUPER_ADMIN';
  const [pollCreateModal, setPollCreateModal] = useState(false);
  const [pollDetailModal, setPollDetailModal] = useState(null);
  const [searching, setSearching] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [searchDate, setSearchDate] = useState<any>('');
  const [pollsData, setPollsData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(10);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [includeExpired, setIncludeExpired] = useState(true);
  const [dataForTable, setDataForTable] = useState([]);

  useEffect(() => {
    retrievePollsData();
  }, []);

  useEffect(() => {
    formatDataForTable(pollsData);
  }, [pollsData]);

  const searchPolls = async (pageNum: number) => {
    try {
      if (searchDate === '' && searchInput === '') {
        return;
      }
      setLoading(true);
      setError(false);
      const userNeighborhoodID = getUserHood();
      const validSearchDate = searchDate
        ? '&date=' + requiredDateValForServer(searchDate)
        : '';
      const validSearchInput = searchInput ? '&query=' + searchInput : '';

      let url;
      if (sudo) {
        url = `/neighborhoods/admin/messages?type=POLL${validSearchDate}${validSearchInput}&limit=10&page=${pageNum}`;
      } else {
        url = `/neighborhoods/${userNeighborhoodID}/messages?type=POLL${validSearchDate}${validSearchInput}&limit=10&page=${pageNum}`;
      }

      const response = await apiInstance.get(url);
      const result = response.data;

      setTotalItems(
        response.headers['x-paginate-total']
          ? parseInt(response.headers['x-paginate-total'])
          : 10,
      );

      setPollsData(result);
      setLoading(false);
      setSearching(true);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const handleSearchSubmit = () => {
    if (!(searchDate === '') && !(searchInput === '')) {
      return;
    }
    setPage(1);
    searchPolls(1);
  };

  const searchModeOff = () => {
    setSearching(false);
    setSearchInput('');
    setSearchDate('');
    setPage(1);
    goToPage(1, false);
  };
  const goToPage = (pageNum: number, isSearching: boolean) => {
    setPage(pageNum);
    isSearching ? searchPolls(pageNum) : retrievePollsData(pageNum);
  };

  const retrievePollsData = async (pageNum = 1) => {
    try {
      setLoading(true);
      setError(false);
      const userNeighborhoodID = getUserHood();
      let url: string;
      if (sudo) {
        url = `/neighborhoods/admin/messages?type=POLL&limit=10&page=${pageNum}`;
      } else {
        url = `/neighborhoods/${userNeighborhoodID}/messages?limit=10&page=${pageNum}&type=POLL`;
      }

      const response = await apiInstance.get(url);
      const result = response.data;

      setTotalItems(
        response.headers['x-paginate-total']
          ? parseInt(response.headers['x-paginate-total'])
          : 10,
      );
      setPollsData(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const formatDataForTable = (data = []) => {
    const formatedData = [];
    data.forEach((item) => {
      const formatedElement = [];
      const timeDisplay = getDateDisplay(item.date, item.time_ago);
      const expired = isExpired(item.expires);
      const statusIcon =
        item.creator.role === 'SUPER_ADMIN' ? (
          <FeatureIcon feature="super-admin" />
        ) : (
          <FeatureIcon feature="polls" />
        );

      formatedElement.push(
        <Row align="middle">
          {statusIcon}
          <Col xs={0} md={18} style={styles.itemIcon}>
            {resizeString(item?.title ?? '', 50)}
          </Col>
        </Row>,
      );
      formatedElement.push(item?.message ?? '');
      formatedElement.push(timeDisplay);
      formatedElement.push(
        <Button
          block
          onClick={() => setPollDetailModal(item)}
          style={{borderColor: '#545454'}}>
          {'VER'}
        </Button>,
      );

      formatedData.push({
        data: formatedElement,
        aditionalData: {
          active: !expired,
        },
      });
    });
    setDataForTable(formatedData);
  };

  return (
    <CommonLayout
      title={'Encuestas'}
      buttonAdd={
        <Button
          style={styles.buttonAdd}
          onClick={() => setPollCreateModal(true)}>
          <Row justify="center" align="middle">
            <Col xs={24} sm={0}>
              <div style={styles.addButtonContainer}>
                <UilPlus size="16" color="#FFFFFF" />
                <UilFilePlusAlt size="18" color="#FFFFFF" />
              </div>
            </Col>
            <Col xs={0} sm={24}>
              <div style={styles.iconContainer}>
                <div style={styles.icon}>
                  <UilFilePlusAlt size="18" color="#FFFFFF" />
                </div>
                <div style={styles.buttonTitle}>{'CREAR ENCUESTA'}</div>
              </div>
            </Col>
          </Row>
        </Button>
      }
      filters={
        <div style={{width: '100%'}}>
          <Row>
            <Col span={24} style={styles.filtersText}>
              {'Busqueda por contenido y/ó fecha:'}
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={9} md={10} lg={9} xl={8}>
              <Input
                prefix={<SearchOutlined />}
                disabled={searching}
                value={searchInput}
                onChange={(event) => setSearchInput(event.target.value)}
                style={styles.filtersInput}
                placeholder={searching ? 'Búsqueda Completada' : 'Buscar...'}
                onPressEnter={handleSearchSubmit}
              />
            </Col>
            <Col xs={12} sm={9} md={10} lg={9} xl={8}>
              <DatePicker
                disabled={searching}
                onChange={(date) => setSearchDate(date)}
                format={'DD/MM/YYYY'}
                showToday={false}
                locale={locale}
                style={{width: '90%'}}
                placeholder={
                  searching ? 'Búsqueda Completada' : 'Seleccionar Fecha'
                }
              />
            </Col>
            <Col xs={24} sm={6} md={4} lg={6} xl={8} style={styles.fiterButton}>
              {searching ? (
                <Button onClick={searchModeOff}>{'REGRESAR'}</Button>
              ) : (
                <Button onClick={handleSearchSubmit}>{'BUSCAR'}</Button>
              )}
            </Col>
          </Row>
        </div>
      }>
      <CustomTable
        loading={loading}
        error={{
          isThereError: error,
          emptyWhat: 'network',
          onRetry: () => goToPage(1, searching),
        }}
        filters={{
          applyFilters: includeExpired,
          setApplyFilters: setIncludeExpired,
          filtersText: 'INCLUIR EXPIRADOS',
        }}
        pagination={{
          searching,
          page,
          totalItems,
          goToPage,
        }}
        columns={[
          {
            columnTitle: 'Título',
            sizes: {xs: 7, sm: 8, lg: 7, xl: 8},
          },
          {
            columnTitle: 'Mensaje',
            sizes: {xs: 12, sm: 8, md: 10, lg: 11, xl: 10},
          },
          {columnTitle: 'Fecha', sizes: {xs: 0, sm: 5, md: 4}},
          {columnTitle: 'Detalle', sizes: {xs: 5, sm: 3, md: 2}},
        ]}
        items={
          includeExpired
            ? dataForTable
            : dataForTable.filter((item) => item.aditionalData.active)
        }
      />
      {pollCreateModal && (
        <PollEditor
          visible={pollCreateModal}
          closeModalTrigger={() => setPollCreateModal(false)}
          closeRefreshAfterCreate={() => {
            searchModeOff();
            setPollCreateModal(false);
          }}
          executivePrivilege={sudo}
        />
      )}
      {pollDetailModal !== null && (
        <PollViewer
          visible={pollDetailModal}
          closeModalTrigger={() => setPollDetailModal(null)}
          closeRefreshAfterCreate={() => {
            searchModeOff();
            setPollDetailModal(null);
          }}
          pollObject={pollDetailModal}
          executivePrivilege={sudo}
        />
      )}
    </CommonLayout>
  );
};

export default Polls;
