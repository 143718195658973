import {Checkbox, Col, List, Pagination, Row} from 'antd';
import React, {CSSProperties, Dispatch, SetStateAction} from 'react';
import {BASE_LIGHT_GRAY, BASE_WHITE} from '../constants/themeColors';
import NoContent from '../screens/empty_screens/empty_content/NoContent';
import {normalizeColSizes} from '../utils/cols';
import {CustomListStyles as styles} from './styles';

interface IFilters {
  applyFilters: boolean | null;
  setApplyFilters: Dispatch<SetStateAction<boolean>> | null;
  filtersText: string | null;
}

interface IPagination {
  searching: boolean;
  page: number;
  totalItems: number;
  goToPage: (num: number, searching?: boolean) => void;
}
interface IError {
  isThereError: boolean;
  emptyWhat: string;
  onRetry: () => void;
}

interface IGrid {
  gutter?: number;
  column?: number;
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
  xxl?: number;
}

interface ICustomList {
  filters: IFilters;
  pagination: IPagination;
  error: IError;
  grid: IGrid;
  columns: any[];
  items: any[];
  loading: boolean;
  emptyText: string;
}

const CustomList = ({
  filters: {applyFilters, setApplyFilters, filtersText},
  pagination: {searching, page, totalItems, goToPage},
  error: {isThereError, emptyWhat, onRetry},
  grid,
  columns,
  items,
  loading,
  emptyText,
}: ICustomList) => {
  if (isThereError) {
    return (
      <NoContent emptyWhat={emptyWhat} withRetry onClickMethod={onRetry} />
    );
  }
  return (
    <div>
      <Row align="middle" style={styles.header}>
        <Col xs={24} sm={10} md={8}>
          {filtersText && (
            <Checkbox
              checked={applyFilters}
              onChange={() => setApplyFilters(!applyFilters)}
              style={{paddingLeft: '10px'}}>
              {filtersText}
            </Checkbox>
          )}
        </Col>
        <Col xs={24} sm={14} md={16}>
          {page && (
            <div style={styles.pagination as CSSProperties}>
              <Pagination
                showSizeChanger={false}
                defaultCurrent={page}
                current={page}
                total={totalItems}
                size={'small'}
                onChange={(pageNum) => goToPage(pageNum, searching)}
              />
            </div>
          )}
        </Col>
      </Row>

      <List
        locale={{emptyText: emptyText ?? 'Sin Registros'}}
        grid={grid}
        bordered
        style={styles.list}
        dataSource={items}
        loading={loading}
        renderItem={(item: any) => {
          return (
            <List.Item
              style={{
                ...styles.item,
                backgroundColor: item.aditionalData.active
                  ? BASE_WHITE
                  : BASE_LIGHT_GRAY,
              }}>
              {item.data.map((i, index) => {
                const sizes = normalizeColSizes(columns[index].sizes);
                return (
                  <Col
                    key={index.toString()}
                    xs={sizes.xs}
                    sm={sizes.sm}
                    md={sizes.md}
                    lg={sizes.lg}
                    xl={sizes.xl}
                    xxl={sizes.xxl}
                    style={styles.cell}>
                    {i}
                  </Col>
                );
              })}
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default CustomList;
