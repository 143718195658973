import React, {useEffect, useState} from 'react';
import {Col, Row, Button, Input} from 'antd';
import UilVideo from '@iconscout/react-unicons/icons/uil-video';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';

//local imports
import {getUserHood} from '../../navigation/auth/auth';
import FeatureIcon from '../../general_components/FeatureIcon/FeatureIcon';
import CamCreator from './components/CamCreator';
import CamEditor from './components/CamEditor';
import {resizeString} from '../../utils/strings';
import CommonLayout from '../../layouts/CommonLayout';
import {CamsStyles as styles} from './styles';
import apiInstance from '../../network/axios';
import CustomTable from '../../molecules/CustomTable';
import {SearchOutlined} from '@ant-design/icons';

const SecurityCams = () => {
  const [createCamModal, setCreateCamModal] = useState(false);
  const [viewCamModal, setViewCamModal] = useState<any>(false);
  const [searching, setSearching] = useState(false);
  const [searchFor, setSearchFor] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(10);
  const [dataForTable, setDataForTable] = useState([]);

  useEffect(() => {
    retrieveCamsData();
  }, []);

  useEffect(() => {
    formatDataForTable(data);
  }, [data]);

  const handleSearchSubmit = () => {
    if (!searchFor) {
      return;
    }
    setSearching(true);
    setError(false);
    searchCams(1);
  };

  const searchCams = async (pageNum: number) => {
    try {
      setLoading(true);
      const userNeighborhoodID = getUserHood();
      const url = `/neighborhoods/${userNeighborhoodID}/cameras?limit=10&page=${pageNum}&query=${searchFor}`;

      const response = await apiInstance.get(url);
      const result = response.data;
      setTotalItems(
        response.headers['x-paginate-total']
          ? parseInt(response.headers['x-paginate-total'])
          : 10,
      );

      setData(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const searchModeOff = () => {
    setSearchFor('');
    setSearching(false);
    goToPage(1, false);
  };
  const goToPage = (pageNum: number, isSearching: boolean) => {
    setPage(pageNum);
    setError(false);
    isSearching ? searchCams(pageNum) : retrieveCamsData(pageNum);
  };

  const retrieveCamsData = async (pageNum = 1) => {
    try {
      setLoading(true);
      const userNeighborhoodID = getUserHood();
      const url = `/neighborhoods/${userNeighborhoodID}/cameras?limit=10&page=${pageNum}`;
      const response = await apiInstance.get(url);
      const result = response.data;
      setTotalItems(
        response.headers['x-paginate-total']
          ? parseInt(response.headers['x-paginate-total'])
          : 10,
      );
      setLoading(false);
      setData(result);
      setSearchFor('');
    } catch (error) {
      setLoading(false);
      setError(true);
      setSearchFor('');
    }
  };
  const formatDataForTable = (data = []) => {
    const formatedData = [];
    data.forEach((item, index) => {
      const statusIcon = (
        <FeatureIcon feature="user" randomColor={Math.floor(index % 5)} />
      );
      const formatedElement = [];

      formatedElement.push(
        <Row align="middle">
          {statusIcon}
          <Col xs={0} md={12} style={styles.itemIcon}>
            {item?.number ?? ''}
          </Col>
        </Row>,
      );
      formatedElement.push(item.take_snap ? 'Si' : 'No');
      formatedElement.push(
        <Button
          type="link"
          block
          onClick={() => window.open(item.video_url, '_blank')}
          style={{
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            textAlign: 'start',
            padding: 0,
          }}>
          {resizeString(item.video_url ? item.video_url : ' ', 15)}
        </Button>,
      );
      formatedElement.push(resizeString(item?.snap_url ?? '', 15));
      formatedElement.push(
        <Button
          block
          onClick={() => setViewCamModal(item)}
          style={styles.itemButton}>
          {'VER'}
        </Button>,
      );

      formatedData.push({
        data: formatedElement,
        aditionalData: {
          active: true,
        },
      });
    });
    setDataForTable(formatedData);
  };

  const closeCamModalAndRefresh = () => {
    setCreateCamModal(false);
    setViewCamModal(false);
    setError(false);
    setSearchFor('');
    setSearching(false);
    goToPage(1, false);
  };

  return (
    <CommonLayout
      title={'Cámaras'}
      buttonAdd={
        <Button
          style={styles.container}
          onClick={() => setCreateCamModal(true)}>
          <Row justify="center" align="middle">
            <Col xs={24} sm={0}>
              <div style={styles.buton}>
                <UilPlus size="16" color="#FFFFFF" />
                <UilVideo size="18" color="#FFFFFF" />
              </div>
            </Col>
            <Col xs={0} sm={24}>
              <div style={styles.iconButtonContainer}>
                <div style={styles.iconButton}>
                  <UilVideo size="18" color="#FFFFFF" />
                </div>
                <div style={styles.buttonTitle}>{'AGREGAR CÁMARA'}</div>
              </div>
            </Col>
          </Row>
        </Button>
      }
      filters={
        <Row style={{width: '100%'}}>
          <Col span={12}>
            <Input
              prefix={<SearchOutlined />}
              disabled={searching}
              onChange={(value) => setSearchFor(value.target.value)}
              style={{width: '70%'}}
              placeholder={searching ? 'Búsqueda Completada' : 'Buscar...'}
              onPressEnter={handleSearchSubmit}
            />
          </Col>
          <Col
            span={12}
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            {searching ? (
              <Button onClick={searchModeOff}>{'REGRESAR'}</Button>
            ) : (
              <Button onClick={handleSearchSubmit}>{'BUSCAR'}</Button>
            )}
          </Col>
        </Row>
      }>
      <CustomTable
        loading={loading}
        error={{
          isThereError: error,
          emptyWhat: 'network',
          onRetry: () => goToPage(1, searching),
        }}
        filters={{
          applyFilters: null,
          setApplyFilters: null,
          filtersText: null,
        }}
        pagination={{
          searching,
          page,
          totalItems,
          goToPage,
        }}
        columns={[
          {
            columnTitle: 'Cámara',
            sizes: {xs: 9, sm: 5, md: 4, xl: 3},
          },
          {
            columnTitle: 'Snap',
            sizes: {xs: 9, sm: 4, md: 2},
          },
          {columnTitle: 'Video URL', sizes: {xs: 0, sm: 6, md: 8, xl: 9}},
          {columnTitle: 'Snap URL', sizes: {xs: 0, sm: 6, md: 7}},
          {columnTitle: 'Acción', sizes: {xs: 6, sm: 3, md: 3}},
        ]}
        items={dataForTable}
      />
      {createCamModal && (
        <CamCreator
          visible={createCamModal}
          closeModalTrigger={() => setCreateCamModal(false)}
          closeRefreshAfterCreate={closeCamModalAndRefresh}
        />
      )}
      {viewCamModal && (
        <CamEditor
          visible={viewCamModal !== false}
          closeModalTrigger={() => setViewCamModal(false)}
          closeRefreshAfterCreate={closeCamModalAndRefresh}
          camObject={viewCamModal}
        />
      )}
    </CommonLayout>
  );
};

export default SecurityCams;
