import React, {useRef, useState} from 'react';
import {Col, Row, Modal, Button} from 'antd';

//local imports
import {getUserHood} from '../../../navigation/auth/auth';
import LoadingContent from '../../loading/LoadingContent';
import NoContent from '../../empty_screens/empty_content/NoContent';
import FeatureIcon from '../../../general_components/FeatureIcon/FeatureIcon';
import DoorFormEditor from './DoorFormEditor';
import apiInstance from '../../../network/axios';
import {FormInstance} from 'antd/es/form/Form';
interface IDoorCreator {
  visible: boolean;
  closeModalTrigger: () => void;
  closeRefreshAfterCreate: () => void;
}

const DoorCreator = ({
  closeModalTrigger,
  closeRefreshAfterCreate,
  visible,
}: IDoorCreator) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const formRef = useRef<FormInstance>();

  const validatePost = async () => {
    const values = await formRef.current.validateFields();
    setLoading(true);
    postCreateDoor(values);
  };

  const postCreateDoor = async (form_object) => {
    try {
      const userHood = getUserHood();
      const url = `/neighborhoods/${userHood}/doors`;
      const doorHost = `${form_object.host_1}.${form_object.host_2}.${form_object.host_3}.${form_object.host_4}`;

      const body = {
        number: form_object.door_number,
        user: form_object.user,
        description: form_object.description,
        password: form_object.password,
        closing_time: form_object.seconds,
        host: doorHost,
        port: form_object.port,
        normally_closed: form_object.normally_closed,
      };
      await apiInstance.post(url, body);
      closeRefreshAfterCreate();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const retryAfterNetErr = () => {
    setLoading(false);
    setError(true);
  };

  return (
    <Modal
      centered
      visible={visible}
      onOk={validatePost}
      onCancel={closeModalTrigger}
      okText="AGREGAR"
      cancelText="CANCELAR"
      maskClosable={false}
      zIndex={2000}
      destroyOnClose={true}
      closable={!loading}
      footer={[
        <Button key="back" disabled={loading} onClick={closeModalTrigger}>
          {'CANCELAR'}
        </Button>,
        error ? (
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={retryAfterNetErr}>
            {'REINTENTAR'}
          </Button>
        ) : (
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={validatePost}>
            {'AGREGAR'}
          </Button>
        ),
      ]}
      title={
        <Row>
          <Col xs={5} sm={4} md={3}>
            <FeatureIcon feature="door" />
          </Col>
          <Col xs={19} sm={20} md={21} style={{fontWeight: 'bold'}}>
            {'Puerta'}
          </Col>
        </Row>
      }>
      {loading ? (
        <LoadingContent />
      ) : error ? (
        <NoContent emptyWhat={'network'} />
      ) : (
        <Row>
          <Col span={24}>
            <DoorFormEditor formRef={formRef} editMode={true} />
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default DoorCreator;
