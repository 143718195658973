import React, {useState} from 'react';
import Streets from '../streets/Streets';
import {useHistory} from 'react-router';
import {DJStepHeaderStyles as styles} from './styles';
import {Col, Row} from 'antd';
import RegisterIndicator from './components/RegisterIndicator';
import {getLevelConfig} from '../../navigation/auth/auth';

const DJCreateStreets = () => {
  const history = useHistory();
  const [levelConfig, setLevelConfig] = useState(getLevelConfig());

  return (
    <div>
      <Row style={styles.header}>
        <Col xs={24} sm={12} />
        <Col xs={24} sm={12}>
          <RegisterIndicator
            level={2}
            disabledNext={levelConfig <= 1}
            onClickPrev={() => {
              history.push('/view_neighborhood');
            }}
            onClickNext={() => {
              if (levelConfig > 1) {
                history.push('/digitalJourney/createHomes');
              }
            }}
          />
        </Col>
      </Row>
      <Streets history={history} onFirstCreate={() => setLevelConfig(2)} />;
    </div>
  );
};

export default DJCreateStreets;
