import React from 'react';

import {Form, Input, InputNumber, Row, Col, Switch} from 'antd';
import {DoorFormEditorStyles as styles} from '../styles';

//local imports
const DoorForm = (props: any) => {
  const hostArr =
    props?.defaultValues?.host.split('.', 4) ?? '0.0.0.0'.split('.', 4);
  const editMode = props?.editMode ?? false;
  return (
    <Form ref={props.formRef}>
      <Form.Item
        labelCol={{span: 24}}
        required={false}
        label="Usuario"
        name="user"
        rules={[
          {required: true, message: 'Ingrese nombre!'},
          {whitespace: true, message: 'Ingrese nombre!'},
          {
            max: 40,
            message: 'Maximo de 40 caracteres!',
          },
        ]}
        initialValue={props?.defaultValues?.user ?? ''}>
        <Input placeholder={'Usuario'} readOnly={!editMode} />
      </Form.Item>

      <Form.Item
        labelCol={{span: 24}}
        required={false}
        label="Descripción"
        name="description"
        rules={[
          {required: true, message: 'Ingrese descripción!'},
          {whitespace: true, message: 'Ingrese descripción!'},
          {
            max: 40,
            message: 'Maximo de 40 caracteres!',
          },
        ]}
        initialValue={props?.defaultValues?.description ?? ''}>
        <Input placeholder={'Descripción'} readOnly={!editMode} />
      </Form.Item>

      <Form.Item
        labelCol={{span: 24}}
        required={false}
        label="Contraseña"
        hasFeedback
        name="password"
        rules={[
          {
            required: true,
            message: 'Ingrese contraseña!',
          },
          {whitespace: true, message: 'Evite espacios! '},
          {
            min: 5,
            message: 'Contraseña debe contener al menos 5 caracteres!',
          },
        ]}
        initialValue={props?.defaultValues?.password ?? ''}>
        <Input.Password placeholder={'Contraseña'} readOnly={!editMode} />
      </Form.Item>

      <Form.Item
        labelCol={{span: 24}}
        required={false}
        label="Verificar Contraseña"
        hasFeedback
        name="confirm"
        rules={[
          {
            required: true,
            message: 'Verifique contraseña!',
          },
          ({getFieldValue}) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error('The two passwords that you entered do not match!'),
              );
            },
          }),
        ]}
        initialValue={props?.defaultValues?.password ?? ''}>
        <Input.Password
          placeholder={'Verificar Contraseña'}
          readOnly={!editMode}
        />
      </Form.Item>

      <Form.Item
        labelCol={{span: 24}}
        required={false}
        label="Número de puerta"
        name="door_number"
        rules={[
          {
            required: true,
            message: 'Ingrese # de puerta!',
          },
        ]}
        initialValue={props?.defaultValues?.number ?? null}>
        <InputNumber min={1} max={3} readOnly={!editMode} />
      </Form.Item>

      <Form.Item
        labelCol={{span: 24}}
        required={false}
        label="Segundos de apertura"
        name="seconds"
        rules={[
          {
            required: true,
            message: 'Ingrese # de segundos!',
          },
        ]}
        initialValue={props?.defaultValues?.closing_time ?? null}>
        <InputNumber min={1} max={300} readOnly={!editMode} />
      </Form.Item>

      <Form.Item
        labelCol={{span: 24}}
        required={false}
        label="Puerto"
        name={'port'}
        rules={[
          {
            required: true,
            message: 'Ingrese # de puerto!',
          },
        ]}
        initialValue={props?.defaultValues?.port ?? null}>
        <InputNumber min={1} max={65535} readOnly={!editMode} />
      </Form.Item>

      <Row>
        <Col span={24} style={{fontWeight: '600', marginBottom: '10px'}}>
          {'Host'}
        </Col>
      </Row>

      <Row align="bottom">
        <Col span={5}>
          <Form.Item
            labelCol={{span: 24}}
            required={false}
            name={'host_1'}
            rules={[
              {
                required: true,
                message: 'Ingrese #!',
              },
            ]}
            initialValue={props.defaultValues ? hostArr[0] : null}
            style={{margin: 0}}>
            <InputNumber
              style={{width: '100%'}}
              min={0}
              max={255}
              readOnly={!editMode}
            />
          </Form.Item>
        </Col>
        <Col span={1} style={{fontSize: '25px', textAlign: 'center'}}>
          {'.'}
        </Col>

        <Col span={5}>
          <Form.Item
            labelCol={{span: 24}}
            required={false}
            name={'host_2'}
            rules={[
              {
                required: true,
                message: 'Ingrese #!',
              },
            ]}
            style={{margin: 0}}
            initialValue={props.defaultValues ? hostArr[1] : null}>
            <InputNumber
              style={{width: '100%'}}
              min={0}
              max={255}
              readOnly={!editMode}
            />
          </Form.Item>
        </Col>

        <Col span={1} style={{fontSize: '25px', textAlign: 'center'}}>
          {'.'}
        </Col>

        <Col span={5}>
          <Form.Item
            labelCol={{span: 24}}
            required={false}
            name={'host_3'}
            rules={[
              {
                required: true,
                message: 'Ingrese #!',
              },
            ]}
            initialValue={props.defaultValues ? hostArr[2] : null}
            style={{margin: 0}}>
            <InputNumber
              style={{width: '100%'}}
              min={0}
              max={255}
              readOnly={!editMode}
            />
          </Form.Item>
        </Col>

        <Col span={1} style={{fontSize: '25px', textAlign: 'center'}}>
          {'.'}
        </Col>

        <Col span={5}>
          <Form.Item
            labelCol={{span: 24}}
            required={false}
            name={'host_4'}
            rules={[
              {
                required: true,
                message: 'Ingrese #!',
              },
            ]}
            initialValue={props.defaultValues ? hostArr[3] : null}
            style={{margin: 0}}>
            <InputNumber
              style={{width: '100%'}}
              min={0}
              max={255}
              readOnly={!editMode}
            />
          </Form.Item>
        </Col>

        <Col span={1} style={{fontSize: '25px', textAlign: 'center'}}>
          {' '}
        </Col>
      </Row>
      <div style={styles.container}>
        <Form.Item
          required={false}
          name={'normally_closed'}
          style={styles.item}
          valuePropName="checked"
          initialValue={props?.defaultValues?.normally_closed ?? false}>
          <Switch size="small" style={styles.switch} disabled={!editMode} />
          <h4 style={styles.h4}>{'Normalmente cerrada'}</h4>
        </Form.Item>
      </div>
    </Form>
  );
};
export default DoorForm;
