import {Button, Form, FormInstance, Input, Select} from 'antd';
import React, {useState} from 'react';
import {PLANS} from '../../../constants/Routes';
import {TBell} from '../types';

interface IBellForm {
  formRef: React.RefObject<FormInstance<any>>;
  editMode: boolean;
  defaultValues?: TBell;
}

const BellForm = (props: IBellForm) => {
  const [subRole, setSubRole] = useState<'ENTRANCE' | 'EXIT' | 'MANUAL'>(
    props?.defaultValues?.sub_role ?? 'MANUAL',
  );
  const [pwordChange, setPwordChange] = useState<boolean>(false);
  const currentPlan = localStorage.getItem('currentPlan');

  return (
    <Form ref={props.formRef}>
      <Form.Item
        labelCol={{span: 24}}
        label="Nombre"
        required={false}
        name={'name'}
        rules={[
          {required: true, message: '¡Ingrese nombre!'},
          {whitespace: true, message: 'Ingrese nombre!'},
        ]}
        initialValue={props?.defaultValues?.name ?? ''}>
        <Input placeholder={'Nombre'} readOnly={!props.editMode} />
      </Form.Item>

      <Form.Item
        labelCol={{span: 24}}
        label="Apellido"
        required={false}
        name="last_name"
        rules={[
          {required: true, message: '¡Ingrese apellido!'},
          {whitespace: true, message: 'Ingrese apellido!'},
        ]}
        initialValue={props?.defaultValues?.last_name ?? ''}>
        <Input placeholder={'Apellido'} readOnly={!props.editMode} />
      </Form.Item>

      <Form.Item
        labelCol={{span: 24}}
        label="Correo Electrónico"
        required={false}
        name="username"
        rules={[
          {
            type: 'email',
            message: "Ingrese formato valido. Ej. 'usuario@portos.xyz'",
          },
          {
            required: true,
            message: 'Ingrese correo electrónico!',
          },
        ]}
        initialValue={props?.defaultValues?.username ?? ''}>
        <Input placeholder={'Correo Electrónico'} readOnly={!props.editMode} />
      </Form.Item>

      <Form.Item
        labelCol={{span: 24}}
        label="Teléfono"
        required={false}
        name="phone_number"
        rules={[
          {required: true, message: '¡Ingrese teléfono!'},
          {whitespace: true, message: '¡Ingrese teléfono!'},
        ]}
        initialValue={props?.defaultValues?.phone_number ?? ''}>
        <Input placeholder={'Teléfono'} readOnly={!props.editMode} />
      </Form.Item>

      {props.defaultValues ? null : (
        <Form.Item
          labelCol={{span: 24}}
          label="Contraseña"
          hasFeedback
          required={false}
          name="password"
          rules={[
            {
              required: true,
              message: '¡Ingrese contraseña!',
            },
            {whitespace: true, message: '¡Evite espacios! '},
            {
              min: 5,
              message: '¡Contraseña debe contener al menos 5 caracteres!',
            },
          ]}>
          <Input.Password placeholder={'Contraseña'} />
        </Form.Item>
      )}
      {props.defaultValues ? null : (
        <Form.Item
          labelCol={{span: 24}}
          required={false}
          label="Verificar Contraseña"
          hasFeedback
          name="confirm"
          rules={[
            {
              required: true,
              message: 'Verifique contraseña!',
            },
            ({getFieldValue}) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('¡Las contraseñas no coinciden!'),
                );
              },
            }),
          ]}>
          <Input.Password
            placeholder={'Verificar Contraseña'}
            readOnly={!props.editMode}
          />
        </Form.Item>
      )}

      <div>
        <p style={{fontWeight: '600'}}>{'Rol'}</p>
        <p style={{paddingLeft: '12px'}}>Timbre</p>
        {currentPlan === PLANS.SECURITY && (
          <Form.Item
            labelCol={{span: 24}}
            label={'Tipo de timbre'}
            required={false}
            name="sub_role"
            rules={[{required: true, message: 'Selecciona un tipo'}]}
            initialValue={props?.defaultValues?.sub_role ?? null}>
            <Select
              value={subRole}
              disabled={!props.editMode}
              style={{width: '100%'}}
              dropdownStyle={{zIndex: 3000}}
              onChange={(value) => {
                setSubRole(value);
              }}>
              <Select.Option value="ENTRANCE">Entrada</Select.Option>
              <Select.Option value="EXIT">Salida</Select.Option>
              <Select.Option value="MANUAL">Manual</Select.Option>
            </Select>
          </Form.Item>
        )}
      </div>

      {props.defaultValues && (
        <Button
          style={{
            color: pwordChange ? 'red' : null,
            padding: 0,
            marginBottom: '4.166%',
          }}
          type="link"
          onClick={() => setPwordChange(!pwordChange)}
          disabled={!props.editMode}>
          {'Cambiar contraseña'}
        </Button>
      )}

      {pwordChange && (
        <Form.Item
          labelCol={{span: 24}}
          label="Contraseña"
          hasFeedback
          required={false}
          name={'password'}
          rules={[
            {
              required: true,
              message: 'Ingrese contraseña! ',
            },
            {whitespace: true, message: 'Evite espacios! '},
            {
              min: 5,
              message: 'Contraseña debe contener al menos 5 caracteres! ',
            },
          ]}>
          <Input.Password
            placeholder={'Contraseña'}
            readOnly={!props.editMode}
          />
        </Form.Item>
      )}

      {pwordChange && (
        <Form.Item
          labelCol={{span: 24}}
          required={false}
          label="Verificar Contraseña"
          hasFeedback
          name="confirm"
          rules={[
            {
              required: true,
              message: 'Verifique contraseña!',
            },
            ({getFieldValue}) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!'),
                );
              },
            }),
          ]}>
          <Input.Password
            placeholder={'Verificar Contraseña'}
            readOnly={!props.editMode}
          />
        </Form.Item>
      )}
    </Form>
  );
};

export default BellForm;
