import React, {useRef, useState} from 'react';
import {Col, Row, Modal, Button} from 'antd';

import LoadingContent from '../../loading/LoadingContent';
import NoContent from '../../empty_screens/empty_content/NoContent';
import FeatureIcon from '../../../general_components/FeatureIcon/FeatureIcon';
import HoodFormEditor from './HoodFormEditor';
import apiInstance from '../../../network/axios';
import {FormInstance} from 'antd/es/form/Form';

interface IHoodCreator {
  visible: boolean;
  closeModalTrigger: () => void;
  closeRefreshAfterCreate: () => void;
}

const HoodCreator = ({
  closeModalTrigger,
  closeRefreshAfterCreate,
  visible,
}: IHoodCreator) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const formRef = useRef<FormInstance>();
  const validatePost = async () => {
    const values = await formRef.current.validateFields();
    setLoading(true);
    postCreateHood(values);
  };

  const postCreateHood = async (form_object) => {
    try {
      const url = '/neighborhoods';
      const active_services = [];

      if (form_object.id_service_enabled) {
        active_services.push('ID_DETECTION');
      }
      if (form_object.call_service_enabled) {
        active_services.push('CALLS');
      }
      if (form_object.directory_service_enabled) {
        active_services.push('STREET_DIR');
      }
      if (form_object.selfie_service_enabled) {
        active_services.push('SELFIE_ID');
      }
      if (form_object.exit_service_enabled) {
        active_services.push('EXIT_LOG');
      }
      if (form_object.face_id_enabled) {
        active_services.push('FACE_ID');
      }

      const body = {
        description: form_object.hood_description,
        number: form_object.hood_name,
        address: form_object.address,
        house_name: form_object.house_name,
        street_name: form_object.street_name,
        activated: form_object.activated,
        active_services: [...active_services],
      };

      await apiInstance.post(url, body);
      setLoading(false);
      closeRefreshAfterCreate();
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const retryAfterNetErr = () => {
    setLoading(false);
    setError(false);
  };

  return (
    <Modal
      centered
      visible={visible}
      onOk={validatePost}
      onCancel={closeModalTrigger}
      okText="AGREGAR"
      cancelText="CANCELAR"
      maskClosable={false}
      zIndex={2000}
      destroyOnClose={true}
      closable={!loading}
      footer={[
        <Button key="back" disabled={loading} onClick={closeModalTrigger}>
          {'CANCELAR'}
        </Button>,
        error ? (
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={retryAfterNetErr}>
            {'REINTENTAR'}
          </Button>
        ) : (
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={validatePost}>
            {'AGREGAR'}
          </Button>
        ),
      ]}
      title={
        <Row>
          <Col xs={5} sm={4} md={3}>
            <FeatureIcon feature="hood" />
          </Col>
          <Col xs={19} sm={20} md={21} style={{fontWeight: 'bold'}}>
            {'Vecindario'}
          </Col>
        </Row>
      }>
      {loading ? (
        <LoadingContent />
      ) : error ? (
        <NoContent emptyWhat={'network'} />
      ) : (
        <Row>
          <Col span={24}>
            <HoodFormEditor formRef={formRef} editMode={true} />
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default HoodCreator;
