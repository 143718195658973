import {UilPlus} from '@iconscout/react-unicons';
import {Button, Col, Input, Row} from 'antd';
import Axios from 'axios';
import React, {useState} from 'react';
import {
  BASE_URL,
  DEVELOPMENT,
  NETWORK_TIMEOUT,
} from '../../../constants/network';
import {Modal} from '../../../molecules/Modal';
import {getUserHood, getUserToken} from '../../../navigation/auth/auth';
import NoContent from '../../empty_screens/empty_content/NoContent';
import {styles} from './styles';

const AddModal = (props) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [validating, setValidating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const resetForm = () => {
    setLoading(false);
    setError(true);
    setName('');
    setDescription('');
    setValidating(false);
  };
  const handleCreateCategory = () => {
    if (name === '' || description === '') {
      setValidating(true);
      return;
    }
    setError(false);
    setLoading(true);
    createCategory();
  };
  const createCategory = async () => {
    setLoading(true);
    try {
      const userNeighborhoodID = getUserHood();
      const userToken = getUserToken();
      const url = `${BASE_URL}/neighborhoods/${userNeighborhoodID}/accounting/category`;
      const options = {
        headers: {
          'X-App': 'admin',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + userToken,
        },
        timeout: NETWORK_TIMEOUT,
      };
      const body = {
        name,
        description,
      };
      const response = await Axios.post(url, body, options);
      if (response.data.success) {
        setLoading(false);
        setName('');
        setDescription('');
        setValidating(false);
        if (props.onCancel) {
          props.onCancel();
        }
        props.reload();
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      if (DEVELOPMENT) {
        console.log('Error creating category', error?.response ?? error);
      }
      setLoading(false);
      setError(true);
    }
  };

  const {TextArea} = Input;
  return (
    <Modal
      onOk={handleCreateCategory}
      icon={
        <div
          style={{
            width: 40,
            height: 40,
            borderRadius: 20,
            backgroundColor: props.iconColor ?? 'red',
            padding: 5,
          }}>
          <UilPlus size="30" color="#FFFFFF" />
        </div>
      }
      title={props.title}
      visible={props.visible}
      onCancel={props.onCancel}
      loading={loading}
      body={
        <div>
          {error ? (
            <NoContent
              emptyWhat={'network'}
              withRetry
              onClickMethod={() => {
                setError(false);
                setLoading(true);
                handleCreateCategory();
              }}
            />
          ) : (
            <Row>
              <Col span={24}>
                <h3 style={styles.h3}>{'Nombre'}</h3>
                <Input
                  value={name}
                  onChange={(value) => setName(value.target.value)}
                />
                {validating && name === '' && (
                  <p style={styles.validation}>El nombre es requerido.</p>
                )}
                <h3 style={styles.h3}>{'Descripción'}</h3>
                <TextArea
                  rows={4}
                  value={description}
                  onChange={(value) => setDescription(value.target.value)}
                />
                {validating && description === '' && (
                  <p style={styles.validation}>La descripción es requerida.</p>
                )}
              </Col>
            </Row>
          )}
        </div>
      }
      footer={[
        <Button
          key="back"
          disabled={props.loading || !props.cancelable}
          onClick={() => {
            if (props.onCancel) {
              props.onCancel();
            }
            resetForm();
          }}>
          {'CANCELAR'}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          disabled={loading}
          onClick={handleCreateCategory}>
          {'CREAR'}
        </Button>,
      ]}
    />
  );
};

export default AddModal;
