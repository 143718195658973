import {Button} from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import React, {useEffect, useState} from 'react';
import CommonLayout from '../../layouts/CommonLayout';
import CustomTable from '../../molecules/CustomTable';
import apiInstance from '../../network/axios';
import {resizeString} from '../../utils/strings';
import {PaymentDetail} from './components/PaymentDetail';
import {SubscriptionStyles as styles} from './styles';

const Subscription = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [payments, setPayments] = useState([]);
  const [viewDetail, setViewDetail] = useState(false);
  const [dataForTable, setDataForTable] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(10);

  useEffect(() => {
    getPayments(1);
  }, []);
  useEffect(() => {
    formatDataForTable(payments);
  }, [payments]);
  const getPayments = async (pageNum) => {
    try {
      setLoading(true);
      const url = `/paypal/payments?limit=10&page=${pageNum}`;
      const resp = await apiInstance.get(url);
      setTotalItems(parseInt(resp.headers['x-paginate-total']));
      setLoading(false);
      setPayments(resp.data.payments);
    } catch (e) {
      console.log('e GETTING PAYMENTS => ', e?.response?.data ?? e);
      setLoading(false);
      setError(true);
    }
  };

  const goToPage = (pageNum) => {
    setPage(pageNum);
    getPayments(pageNum);
  };

  const formatDataForTable = (data = []) => {
    const formatedData = [];
    data.forEach((item) => {
      const formatedElement = [];
      formatedElement.push(
        `${item?.currency ?? ''} ${numeral(item?.total ?? '').format(
          '$0,0.00',
        )}`,
      );
      formatedElement.push(resizeString(item?.summary ?? '', 20));
      formatedElement.push(moment(item.createTime).format('ll'));
      formatedElement.push(
        <Button block onClick={() => setViewDetail(item)} style={styles.button}>
          {'VER'}
        </Button>,
      );

      formatedData.push({
        data: formatedElement,
        aditionalData: {
          active: true,
        },
      });
    });
    setDataForTable(formatedData);
  };

  return (
    <CommonLayout title={'Mi subscripción'}>
      <CustomTable
        loading={loading}
        error={{
          isThereError: error,
          emptyWhat: 'network',
          onRetry: () => goToPage(1),
        }}
        filters={{
          applyFilters: null,
          setApplyFilters: null,
          filtersText: null,
        }}
        pagination={{
          searching: false,
          page,
          totalItems,
          goToPage,
        }}
        columns={[
          {
            columnTitle: 'Cantidad',
            sizes: {xs: 8, sm: 7},
          },
          {
            columnTitle: 'Plan',
            sizes: {xs: 8, sm: 7},
          },
          {columnTitle: 'Fecha', sizes: {xs: 0, sm: 7}},
          {columnTitle: 'Detalle', sizes: {xs: 8, sm: 3}},
        ]}
        items={dataForTable}
      />
      {viewDetail && (
        <PaymentDetail
          data={viewDetail}
          closeModal={() => setViewDetail(false)}
        />
      )}
    </CommonLayout>
  );
};
export default Subscription;
