import React, {useState, useEffect} from 'react';
import {Col, Row, Button, DatePicker, Select} from 'antd';
import {Moment} from 'moment';
import {requiredDateValForServer} from '../../utils/locale/chronos';
import locale from 'antd/es/date-picker/locale/es_ES';
import {
  BRAND_LAGOON,
  BRAND_BLUE,
  UTILITY_RED,
} from '../../constants/themeColors';
import FeedbackDetail from './components/FeedbackDetail';
import CommonLayout from '../../layouts/CommonLayout';
import CustomTable from '../../molecules/CustomTable';
import apiInstance from '../../network/axios';
import {resizeString} from '../../utils/strings';
import {FeedbackStyles as styles} from './styles';
import {
  CheckOutlined,
  ExclamationCircleOutlined,
  MessageOutlined,
} from '@ant-design/icons';

function Feedback() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [searching, setSearching] = useState(false);
  const [searchCategory, setSearchCategory] = useState('ALL');
  const [searchApp, setSearchApp] = useState('ALL');
  const [searchDate, setSearchDate] = useState<Moment[]>([null, null]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(10);
  const [viewLogDetail, setViewLogDetail] = useState(false);
  const [dataForTable, setDataForTable] = useState([]);

  useEffect(() => {
    getFeedbacks();
  }, []);

  useEffect(() => {
    formatDataForTable(data);
  }, [data]);

  const goToPage = (pageNum, isSearching: boolean) => {
    setPage(pageNum);
    if (isSearching) {
      searchFeedbacks(pageNum);
    } else {
      getFeedbacks(pageNum);
    }
  };

  const {Option} = Select;
  const {RangePicker} = DatePicker;

  const searchFeedbacks = async (pageNum = 1) => {
    try {
      setLoading(true);
      const validSearchCategory =
        searchCategory === 'ALL' ? '' : `&category=${searchCategory}`;
      const validSearchApp = searchApp === 'ALL' ? '' : `&app=${searchApp}`;
      const validSearchDate =
        searchDate[1] !== null
          ? `&min_date=${requiredDateValForServer(
              searchDate[0],
            )}&max_date=${requiredDateValForServer(searchDate[1])}`
          : '';

      const url = `/feedbacks?limit=10&page=${pageNum}${validSearchCategory}${validSearchApp}${validSearchDate}`;
      const response = await apiInstance.get(url);
      setData(response.data.feedback_logs);
      setTotalItems(
        response.headers['x-paginate-total']
          ? parseInt(response.headers['x-paginate-total'])
          : 10,
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const getFeedbacks = async (pageNum = 1) => {
    try {
      setLoading(true);
      const url = `/feedbacks?limit=10&page=${pageNum}`;
      const response = await apiInstance.get(url);
      setTotalItems(
        response.headers['x-paginate-total']
          ? parseInt(response.headers['x-paginate-total'])
          : 10,
      );
      setData(response.data.feedback_logs);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const handleSearchFeedbacks = () => {
    if (
      searchApp === 'ALL' &&
      searchCategory === 'ALL' &&
      searchDate[1] === null
    ) {
      return;
    }
    setSearching(true);
    setPage(1);
    goToPage(1, true);
  };

  const searchModeOff = () => {
    setSearching(false);
    setSearchDate([null, null]);
    setSearchApp('ALL');
    setSearchCategory('ALL');
    setPage(1);
    goToPage(1, false);
  };

  const formatDataForTable = (data = []) => {
    const formatedData = [];
    data.forEach((item) => {
      const formatedElement = [];
      const containerColor =
        item.category === 'USER_FEEDBACK'
          ? BRAND_LAGOON
          : item.category === 'APP_ERROR'
          ? UTILITY_RED
          : BRAND_BLUE;
      const iconType =
        item.category === 'USER_FEEDBACK' ? (
          <CheckOutlined style={styles.itemIcon} />
        ) : item.category === 'APP_ERROR' ? (
          <ExclamationCircleOutlined style={styles.itemIcon} />
        ) : (
          <MessageOutlined style={styles.itemIcon} />
        );
      const displayCategory =
        item.category === 'USER_FEEDBACK'
          ? 'Comentario'
          : item.category === 'APP_ERROR'
          ? 'Error de app'
          : 'Otro';

      formatedElement.push(
        <Row align="middle">
          <Col xs={15} sm={10} lg={6}>
            <div
              style={{
                ...styles.iconContainer,
                backgroundColor: containerColor,
              }}>
              <div style={styles.iconDiv}>{iconType}</div>
            </div>
          </Col>
          <Col xs={0} xl={14} style={styles.title}>
            {displayCategory}
          </Col>
        </Row>,
      );
      formatedElement.push(item?.app ?? '');
      formatedElement.push(resizeString(item?.description ?? '', 20));
      formatedElement.push(new Date(item.date_issued).toLocaleString());
      formatedElement.push(
        <Button
          block
          onClick={() => setViewLogDetail(item)}
          style={styles.button}>
          {'VER'}
        </Button>,
      );

      formatedData.push({
        data: formatedElement,
        aditionalData: {
          active: true,
        },
      });
    });
    setDataForTable(formatedData);
  };

  return (
    <CommonLayout
      title={'Feedback'}
      filters={
        <div style={{width: '100%'}}>
          <Row>
            <Col span={24} style={styles.filterText}>
              {'Busqueda por categoria, app y/ó fecha:'}
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={6}>
              <Select
                defaultValue={searchCategory}
                style={styles.datePicker}
                onChange={(value) => setSearchCategory(value)}
                disabled={searching}
                value={searchCategory}>
                <Option value="ALL">Todas las categorias</Option>
                <Option value="USER_FEEDBACK">Comentarios</Option>
                <Option value="APP_ERROR">Errores</Option>
                <Option value="OTHER">Otros</Option>
              </Select>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <Select
                defaultValue={searchApp}
                style={styles.datePicker}
                onChange={(value) => setSearchApp(value)}
                disabled={searching}
                value={searchApp}>
                <Option value="ALL">Todas las apps</Option>
                <Option value="OWNER">OWNER</Option>
                <Option value="BELL">BELL</Option>
                <Option value="ADMIN">ADMIN</Option>
              </Select>
            </Col>
            <Col xs={24} sm={12} md={6}>
              <RangePicker
                onChange={(date) => setSearchDate([date[0], date[1]])}
                disabled={searching}
                style={styles.datePicker}
                locale={locale}
                format={'DD/MM/YYYY'}
                value={[searchDate?.[0], searchDate?.[1]]}
              />
            </Col>
            <Col xs={24} sm={12} md={6} style={styles.filterButton}>
              {searching ? (
                <Button onClick={searchModeOff}>{'REGRESAR'}</Button>
              ) : (
                <Button
                  disabled={
                    searchApp === 'ALL' &&
                    searchCategory === 'ALL' &&
                    searchDate[1] === null
                  }
                  onClick={handleSearchFeedbacks}>
                  {'BUSCAR'}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      }>
      <CustomTable
        loading={loading}
        error={{
          isThereError: error,
          emptyWhat: 'network',
          onRetry: () => goToPage(1, searching),
        }}
        filters={{
          applyFilters: null,
          setApplyFilters: null,
          filtersText: null,
        }}
        pagination={{
          searching,
          page,
          totalItems,
          goToPage,
        }}
        columns={[
          {
            columnTitle: 'Categoria',
            sizes: {xs: 6, sm: 4, md: 3, lg: 5},
          },
          {
            columnTitle: 'App',
            sizes: {xs: 10, sm: 3, lg: 4},
          },
          {columnTitle: 'Descripción', sizes: {xs: 0, sm: 8, md: 8, lg: 6}},
          {columnTitle: 'Fecha', sizes: {xs: 0, sm: 5, md: 6, lg: 5}},
          {columnTitle: 'Detalle', sizes: {xs: 8, sm: 4}},
        ]}
        items={dataForTable}
      />
      {viewLogDetail !== false && (
        <FeedbackDetail
          data={viewLogDetail}
          closeModal={() => setViewLogDetail(false)}
          reload={searchModeOff}
        />
      )}
    </CommonLayout>
  );
}

export default Feedback;
