import {CSSProperties} from 'react';
import {BASE_DARK_GRAY, BASE_WHITE} from '../../constants/themeColors';

export const FeedbackStyles: Record<string, CSSProperties> = {
  iconContainer: {
    height: '40px',
    width: '40px',
    borderRadius: '20px',
  },
  iconDiv: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemIcon: {color: BASE_WHITE},
  title: {
    fontWeight: 'bold',
  },
  button: {borderColor: '#545454'},
  datePicker: {width: '100%'},
  filterText: {
    fontSize: '14px',
    fontWeight: '500',
    color: BASE_DARK_GRAY,
  },
  filterButton: {display: 'flex', justifyContent: 'flex-end'},

  paragraph: {marginLeft: '20px', marginRight: '20px'},
};
