import React, {useState} from 'react';
import {useHistory, Redirect} from 'react-router-dom';

//local imports
import LoginForm from './components/LoginForm';
import BrandLogo from '../../navigation/topoptionbar/components/BrandLogo';
import {getAccessLevel, isAuthenticated} from '../../navigation/auth/auth';
import {RoutesManifest} from '../../navigation/Routes';

const Login = () => {
  const [viewPwordRecovery, setViewPwordRecovery] = useState(false);

  const history = useHistory();
  if (isAuthenticated()) {
    if (getAccessLevel() === 'SUPER_ADMIN') {
      return <Redirect to={RoutesManifest.hoods.path} />;
    }
    if (getAccessLevel() === 'ADMIN') {
      return <Redirect to={RoutesManifest.dashboard.path} />;
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        backgroundColor: '#081023',
        height: '100vh',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <LoginForm
        buttonAction={() => setViewPwordRecovery(!viewPwordRecovery)}
        history={history}
        recoverPword={viewPwordRecovery}
      />
      <div
        style={{
          position: 'absolute',
          top: '2vh',
          left: '4vh',
        }}>
        <BrandLogo />
      </div>
    </div>
  );
};

export default Login;
