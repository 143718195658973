import {Checkbox, Col, Input, Row, Select, TimePicker} from 'antd';
import {UilEdit} from '@iconscout/react-unicons';
import React, {useEffect, useState} from 'react';
import NoContent from '../../empty_screens/empty_content/NoContent';
import {CommonAreasStyles as styles} from '../styles';
import {Modal} from '../../../molecules/Modal';
import {
  BRAND_LAGOON,
  BRAND_TURQUOISE,
  UTILITY_BLUE,
  UTILITY_RED,
  UTILITY_YELLOW,
} from '../../../constants/themeColors';
import {getUserHood} from '../../../navigation/auth/auth';
import {DEVELOPMENT} from '../../../constants/network';
import {ICONS} from './Icons';
import {formatMins} from '../../../utils/DateTime';
import apiInstance from '../../../network/axios';
import {CircleButton} from '../../../atoms';

const EditCommonArea = (props) => {
  const {data} = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [validating, setValidating] = useState(false);
  const [opensAt, setOpensAt] = useState(null);
  const [closesAt, setClosesAt] = useState(null);
  const [availableDays, setAvailableDays] = useState([]);
  const [icon, setIcon] = useState<any>('GYM');
  const [disabled, setDisabled] = useState(false);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setName(data?.name ?? '');
    setDescription(data?.description ?? '');
    setClosesAt(data?.closes_at ?? 1);
    setOpensAt(data?.opens_at ?? 1);
    setAvailableDays(data?.days_open ?? []);
    setIcon(data?.icon ?? 'GYM');
    setDisabled(data?.disabled ?? false);
  }, [data]);

  const editCommonArea = async () => {
    try {
      if (
        name === '' ||
        description === '' ||
        opensAt === null ||
        closesAt === null ||
        availableDays.length === 0
      ) {
        setValidating(true);
        return;
      }
      setLoading(true);
      const userNeighborhoodID = getUserHood();
      const url = `/neighborhoods/${userNeighborhoodID}/common_area/${data._id}`;
      const body = {
        name,
        opens_at: opensAt,
        closes_at: closesAt,
        disabled,
        description,
        days_open: availableDays,
        icon,
        color_hex: BRAND_LAGOON,
      };
      const response = await apiInstance.put(url, body);
      if (response.data.success) {
        props.onCancel();
        props.reload();
      }
    } catch (error) {
      if (DEVELOPMENT) {
        console.error(
          'ERROR CREATING COMMIN AREA',
          error?.response?.data ?? error,
        );
      }
      setLoading(false);
      setError(true);
    }
  };
  const deleteCommonArea = async () => {
    setLoading(true);
    try {
      const userNeighborhoodID = getUserHood();
      const url = `/neighborhoods/${userNeighborhoodID}/common_area/${data._id}`;

      const response = await apiInstance.delete(url);
      if (response.data.success) {
        setEditing(false);
        props.onCancel();
        props.reload();
      } else {
        setLoading(false);
      }
    } catch (error) {
      if (DEVELOPMENT) {
        console.error('Error deleting common area', error?.response ?? error);
      }
      setLoading(false);
      setError(true);
    }
  };

  const {TextArea} = Input;
  const format = 'HH:mm';
  const {Option} = Select;
  const icons = ICONS;

  const options = [
    {value: 'MON', label: 'Lun'},
    {value: 'TUE', label: 'Mar'},
    {value: 'WED', label: 'Mie'},
    {value: 'THU', label: 'Jue'},
    {value: 'FRI', label: 'Vie'},
    {value: 'SAT', label: 'Sab'},
    {value: 'SUN', label: 'Dom'},
  ];

  return (
    <Modal
      icon={
        <div
          style={{
            width: 40,
            height: 40,
            borderRadius: 20,
            backgroundColor: props.iconColor ?? 'red',
            padding: 5,
          }}>
          <UilEdit size="30" color="#FFFFFF" />
        </div>
      }
      title={props.title}
      visible={props.visible}
      onCancel={() => {
        setError(false);
        props.onCancel();
      }}
      loading={loading}
      body={
        <div>
          {error ? (
            <NoContent
              emptyWhat={'network'}
              withRetry
              onClickMethod={() => {
                setError(false);
              }}
            />
          ) : (
            <Row>
              <Col span={24}>
                <h3 style={styles.h3}>{'Nombre'}</h3>
                <Input
                  value={name}
                  onChange={(value) => setName(value.target.value)}
                  disabled={!editing}
                />
                {validating && name === '' && (
                  <p style={styles.validation}>El nombre es requerido.</p>
                )}
                <h3 style={styles.h3}>{'Horario'}</h3>
                <Row>
                  <Col xs={24} sm={12}>
                    <div style={styles.numberInput}>
                      <TimePicker
                        style={styles.timePicker}
                        disabled={!editing}
                        format={format}
                        placeholder={formatMins(opensAt)}
                        onChange={(data: any) => {
                          if (data !== null) {
                            const minutes =
                              data._d.getHours() * 60 + data._d.getMinutes();
                            setOpensAt(minutes);
                          } else {
                            setOpensAt(data);
                          }
                        }}
                      />
                      Desde
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div style={styles.numberInput}>
                      <TimePicker
                        placeholder={formatMins(closesAt)}
                        disabled={!editing}
                        style={styles.timePicker}
                        format={format}
                        onChange={(data: any) => {
                          if (data !== null) {
                            const minutes =
                              data._d.getHours() * 60 + data._d.getMinutes();
                            setClosesAt(minutes);
                          } else {
                            setClosesAt(data);
                          }
                        }}
                      />
                      Hasta
                    </div>
                  </Col>
                </Row>
                {validating && (opensAt === null || closesAt === null) && (
                  <p style={styles.validation}>El horario es requerido.</p>
                )}

                <h3 style={styles.h3}>{'Días hábiles'}</h3>
                <Checkbox.Group
                  value={availableDays}
                  onChange={(data) => setAvailableDays(data)}
                  options={options}
                  disabled={!editing}
                />
                {validating && availableDays.length === 0 && (
                  <p style={styles.validation}>
                    Tiene que habilitarse al menos un dia.
                  </p>
                )}

                <Checkbox
                  disabled={!editing}
                  style={{marginTop: 20}}
                  onChange={(e) => setDisabled(e.target.checked)}
                  checked={disabled}>
                  Deshabilitar área
                </Checkbox>

                <h3 style={styles.h3}>{'Descripción'}</h3>
                <TextArea
                  disabled={!editing}
                  rows={4}
                  value={description}
                  onChange={(value) => setDescription(value.target.value)}
                />
                {validating && description === '' && (
                  <p style={styles.validation}>La descripción es requerida.</p>
                )}

                <h3 style={styles.h3}>{'Ícono'}</h3>
                <div>
                  <div style={styles.selectedOption}>{icons[icon]}</div>
                  <Select
                    dropdownStyle={{minWidth: 120}}
                    style={{
                      width: 100,
                      marginLeft: 5,
                    }}
                    disabled={!editing}
                    bordered={false}
                    onChange={(data) => setIcon(data)}>
                    {Object.keys(icons).map((key, index) => {
                      return (
                        <Option
                          key={index.toString()}
                          value={key}
                          style={{
                            ...styles.option,
                            backgroundColor:
                              index % 2 === 0 ? BRAND_LAGOON : BRAND_TURQUOISE,
                          }}>
                          {icons[key]}
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </Col>
            </Row>
          )}
        </div>
      }
      footer={
        error ? null : (
          <Row>
            <Col
              xs={16}
              sm={20}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
              }}>
              <CircleButton
                variant="del"
                activeColor={UTILITY_RED}
                disabled={loading}
                onClick={deleteCommonArea}
              />
            </Col>
            <Col xs={4} sm={2}>
              <CircleButton
                variant="genericEdit"
                activeColor={UTILITY_YELLOW}
                disabled={loading}
                onClick={() => setEditing(!editing)}
                clickedMode={editing}
              />
            </Col>
            <Col xs={4} sm={2}>
              <CircleButton
                variant="save"
                activeColor={UTILITY_BLUE}
                disabled={!editing || loading}
                onClick={editCommonArea}
              />
            </Col>
          </Row>
        )
      }
    />
  );
};

export default EditCommonArea;
