import {BASE_URL_ENV, DEVELOPMENT_ENV, NETWORK_TIMEOUT_ENV} from './.env';

const BASE_URL = BASE_URL_ENV || 'https://api.portos.xyz';
const NETWORK_TIMEOUT = NETWORK_TIMEOUT_ENV || 9000;
const NETWORK_ERR_MSG = 'Error de red, vuelva a intentar';
const STANDARD_ERR = 'Ha ocurrido un error, intentar nuevamente?';
const PORTOS_MSG_CONTACT =
  'Póngase en contacto con el administrador o con el soporte técnico de Portos.';

const DEVELOPMENT = DEVELOPMENT_ENV ?? true;

export {
  BASE_URL,
  NETWORK_TIMEOUT,
  NETWORK_ERR_MSG,
  STANDARD_ERR,
  DEVELOPMENT,
  PORTOS_MSG_CONTACT,
};
