import {Button, Col, Modal, Row} from 'antd';
import React, {useEffect, useRef, useState} from 'react';
import FeatureIcon from '../../../general_components/FeatureIcon/FeatureIcon';
import LoadingContent from '../../loading/LoadingContent';
import NoContent from '../../empty_screens/empty_content/NoContent';
import PaymentForm from './PaymentForm';
import apiInstance from '../../../network/axios';
import {FormInstance} from 'antd/es/form/Form';
import {TPayment} from '../../../types/manualPaymentTypes';
import moment from 'moment';

interface IPaymentCreator {
  visible: boolean;
  closeModalTrigger: () => void;
  closeRefreshAfterCreate: () => void;
}

const PaymentCreator = ({
  closeModalTrigger,
  closeRefreshAfterCreate,
  visible,
}: IPaymentCreator) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [neighborhoods, setNeighborhoods] = useState<any[]>();
  const formRef = useRef<FormInstance<TPayment>>();

  const getNeighborhoods = async () => {
    try {
      const response = await apiInstance.get('/neighborhoods');
      const result = response.data;

      if (result.length === 0) {
        setLoading(false);
        return;
      }
      setNeighborhoods(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const validatePost = async () => {
    const values = await formRef.current.validateFields();
    setLoading(true);
    try {
      const body = {
        ...values,
        subtotal: values.total,
        createTime: moment.utc(values.createTime).format(),
      };

      await apiInstance.post(`/paypal/manualPayment`, body);
      setLoading(false);
      closeRefreshAfterCreate();
    } catch (e) {
      setLoading(false);
      setError(true);
      console.error(e);
    }
  };
  const retryAfterNetErr = () => {
    setLoading(false);
    setError(false);
  };

  useEffect(() => {
    getNeighborhoods();
  }, []);

  return (
    <Modal
      centered
      visible={visible}
      onOk={validatePost}
      onCancel={closeModalTrigger}
      okText="CREAR"
      cancelText="CANCELAR"
      maskClosable={false}
      destroyOnClose={true}
      closable={!loading}
      title={
        <Row>
          <Col xs={5} sm={4} md={3}>
            <FeatureIcon con feature="accounting" />
          </Col>
          <Col xs={19} sm={20} md={21} style={{fontWeight: 'bold'}}>
            {'Crear Pago'}
          </Col>
        </Row>
      }
      footer={[
        <Button key="back" disabled={loading} onClick={closeModalTrigger}>
          {'CANCELAR'}
        </Button>,
        error ? (
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={retryAfterNetErr}>
            {'REINTENTAR'}
          </Button>
        ) : (
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={validatePost}>
            {'CREAR'}
          </Button>
        ),
      ]}>
      {loading ? (
        <LoadingContent />
      ) : error ? (
        <NoContent emptyWhat={'network'} />
      ) : (
        <Row>
          <Col span={24}>
            <PaymentForm formRef={formRef} hoodsObject={neighborhoods} />
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default PaymentCreator;
