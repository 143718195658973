import React from 'react';
import {Row, Col} from 'antd';

//local imports
import LockImage from './LockImage';
import IforgotMyPword from './IforgotMyPword';
import FormTitle from './FormTitle';
import WrappedFormContent from './WrappedFormContent';
import RightSideImage from './RightSideImage';
import PwordRecovery from './PwordRecovery';

//input form

//Components into App
const LoginForm = ({buttonAction, history, recoverPword}) => {
  if (recoverPword) {
    return <PwordRecovery goBack={buttonAction} />;
  }
  return (
    <Row style={{width: '100%'}}>
      <Col
        xs={{span: 22, offset: 1}}
        md={{span: 8, offset: 2}}
        xxl={{span: 5, offset: 2}}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '50px 5% 0 5%',
              backgroundColor: '#FFFFFF',
              borderRadius: 4,
              height: '100%',
            }}>
            <FormTitle />
            <WrappedFormContent history={history} />
            <IforgotMyPword buttonClick={buttonAction} />
          </div>
          <LockImage />
        </div>
      </Col>

      <Col xs={0} md={10}>
        <RightSideImage />
      </Col>
    </Row>
  );
};

export default LoginForm;
