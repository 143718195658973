import React from 'react';
import superlogo from '../../../res/img/SuperAdminLogo.png';
import logo from '../../../res/img/logo.png';
import {getAccessLevel} from '../../auth/auth';

const BrandLogo = () => {
  const sudo = getAccessLevel() === 'SUPER_ADMIN';

  return (
    <img
      src={sudo ? superlogo : logo}
      alt="Company-logo"
      style={{
        maxHeight: 35,
        marginBottom: '5px',
      }}
    />
  );
};

export default BrandLogo;
