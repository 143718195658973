import React from 'react';
import {Form, Input, InputNumber, Switch} from 'antd';
import {CamFormEditorStyles as styles} from '../styles';
const CamForm = (props) => {
  const editMode = props.editMode ? true : false;

  return (
    <Form ref={props.formRef}>
      <Form.Item
        labelCol={{span: 24}}
        label="Usuario"
        required={false}
        name="user"
        rules={[
          {required: true, message: 'Ingrese nombre!'},
          {whitespace: true, message: 'Ingrese nombre!'},
          {
            max: 40,
            message: 'Maximo de 40 caracteres!',
          },
        ]}
        initialValue={props.defaultValues?.username ?? ''}>
        <Input placeholder={'Usuario'} readOnly={!editMode} />
      </Form.Item>
      <Form.Item
        labelCol={{span: 24}}
        label="Contraseña"
        hasFeedback
        required={false}
        name="password"
        rules={[
          {
            required: true,
            message: 'Ingrese contraseña!',
          },
          {whitespace: true, message: 'Evite espacios! '},
          {
            min: 5,
            message: 'Contraseña debe contener al menos 5 caracteres!',
          },
        ]}
        initialValue={props.defaultValues?.password ?? ''}>
        <Input.Password placeholder={'Contraseña'} readOnly={!editMode} />
      </Form.Item>
      <Form.Item
        labelCol={{span: 24}}
        label="Verificar Contraseña"
        hasFeedback
        required={false}
        name="confirm"
        rules={[
          {
            required: true,
            message: 'Verifique contraseña!',
          },
          ({getFieldValue}) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error('The two passwords that you entered do not match!'),
              );
            },
          }),
        ]}
        initialValue={props.defaultValues?.password ?? ''}>
        <Input.Password
          placeholder={'Verificar Contraseña'}
          readOnly={!editMode}
        />
      </Form.Item>

      <Form.Item
        labelCol={{span: 24}}
        label="Video URL"
        required={false}
        name="video_url"
        rules={[
          {required: true, message: 'Ingrese URL!'},
          {
            type: 'url',
            message: 'Ingrese url',
          },
        ]}
        initialValue={props.defaultValues?.video_url ?? ''}>
        <Input placeholder={'Video URL'} readOnly={!editMode} />
      </Form.Item>

      <Form.Item
        labelCol={{span: 24}}
        label="Snap URL"
        required={false}
        name="snap_url"
        rules={[
          {required: false, message: 'Ingrese URL!'},
          {
            type: 'url',
            message: 'Ingrese url',
          },
        ]}
        initialValue={props.defaultValues?.snap_url ?? ''}>
        <Input placeholder={'Snap URL'} readOnly={!editMode} />
      </Form.Item>

      <Form.Item
        labelCol={{span: 24}}
        label="Número de la cámara"
        required={false}
        name="cam_number"
        rules={[
          {
            required: true,
            message: 'Ingrese # de la cámara!',
          },
        ]}
        initialValue={props.defaultValues?.number ?? null}>
        <InputNumber min={1} max={9999} readOnly={!editMode} />
      </Form.Item>

      <div style={styles.container}>
        <Form.Item
          name="take_snap"
          valuePropName="checked"
          style={styles.item}
          initialValue={props.defaultValues?.take_snap ?? true}>
          <Switch style={styles.switch} size="small" disabled={!editMode} />
        </Form.Item>
        <h4 style={styles.h4}>{'Tomar sanp'}</h4>
      </div>

      <div style={styles.container}>
        <Form.Item
          name="main_camera"
          valuePropName="checked"
          style={styles.item}
          initialValue={props.defaultValues?.main_camera ?? true}>
          <Switch style={styles.switch} size="small" disabled={!editMode} />
        </Form.Item>
        <h4 style={styles.h4}>{'Camara principal'}</h4>
      </div>

      <div style={styles.container}>
        <Form.Item
          name="requires_auth"
          valuePropName="checked"
          style={styles.item}
          initialValue={props.defaultValues?.requires_auth ?? true}>
          <Switch style={styles.switch} size="small" disabled={!editMode} />
        </Form.Item>
        <h4 style={styles.h4}>{'Autenticada'}</h4>
      </div>
    </Form>
  );
};

export default CamForm;
