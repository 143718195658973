import React, {useEffect, useState} from 'react';
import {Col, Row, Button, Input} from 'antd';

//local imports
import {getUserHood, getHomesName} from '../../navigation/auth/auth';
import FeatureIcon from '../../general_components/FeatureIcon/FeatureIcon';
import UilHome from '@iconscout/react-unicons/icons/uil-home';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';
import HomeCreator from './components/HomeCreator';
import HomeEditor from './components/HomeEditor';
import CommonLayout from '../../layouts/CommonLayout';
import {HomesStyles as styles} from './styles';
import apiInstance from '../../network/axios';
import CustomList from '../../molecules/CustomList';
import {SearchOutlined} from '@ant-design/icons';
import UpdateSubscriptionQuantity from './components/UpdateSubscriptionQuantity';
import {BASE_GRAY, UTILITY_BLUE} from '../../constants/themeColors';

const Homes = (props) => {
  const currentPlan = localStorage.getItem('currentPlan');
  const [createHomeModal, setCreateHomeModal] = useState(false);
  const [viewHomeModal, setViewHomeModal] = useState<any>(false);
  const [searching, setSearching] = useState(false);
  const [searchFor, setSearchFor] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState<string>('network');
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [dataForTable, setDataForTable] = useState([]);
  const [includeInactive, setIncludeInactive] = useState(true);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [homesInSubscription, setHomesInSubscription] = useState(0);

  useEffect(() => {
    retrieveHomesData();
  }, []);

  useEffect(() => {
    formatDataForTable(data);
  }, [data]);

  const handleSearchSubmit = () => {
    if (!searchFor) {
      return;
    }
    setError(false);
    setSearching(true);
    setPage(1);
    searchHomes(1);
  };
  const searchHomes = async (pageNum) => {
    try {
      setLoading(true);
      const userNeighborhoodID = getUserHood();
      const url = `/neighborhoods/${userNeighborhoodID}/homes?limit=10&page=${pageNum}&query=${searchFor}`;
      const response = await apiInstance.get(url);
      const result = response?.data?.homes ?? [];

      setData(result);
      setTotalItems(
        response.headers['x-paginate-total']
          ? parseInt(response.headers['x-paginate-total'])
          : 10,
      );
      setSearchFor('');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
      setSearchFor('');
    }
  };
  const searchModeOff = () => {
    setSearching(false);
    setSearchFor('');
    setError(true);
    goToPage(1, false);
  };
  const goToPage = (pageNum: number, isSearching: boolean) => {
    setPage(pageNum);
    setError(false);
    isSearching ? searchHomes(pageNum) : retrieveHomesData(pageNum);
  };

  const retrieveHomesData = async (pageNum = 1) => {
    try {
      setLoading(true);
      const userNeighborhoodID = getUserHood();
      const url = `/neighborhoods/${userNeighborhoodID}/homes?limit=10&page=${pageNum}`;
      const response = await apiInstance.get(url);
      const result = response?.data?.homes ?? [];
      setHomesInSubscription(
        parseInt(response?.data?.numberHomesSubscribed ?? '0'),
      );
      setTotalItems(
        response.headers['x-paginate-total']
          ? parseInt(response.headers['x-paginate-total'])
          : 10,
      );
      setLoading(false);
      setData(result);
      setSearchFor('');
    } catch (error) {
      const errorMessage = error?.response?.data?.message ?? '';
      if (errorMessage === 'Sub Not Found') {
        setErrorType('subscription');
      }
      setLoading(false);
      setError(true);
      setSearchFor('');
    }
  };

  const formatDataForTable = (data = []) => {
    const formatedData = [];
    data.forEach((item, index) => {
      const statusIcon = <FeatureIcon feature="home" randomColor={index % 5} />;
      const notActive = item.current_status === 'DEACTIVATED';
      const formatedElement = [];

      formatedElement.push(
        <Row align="middle">
          {statusIcon}
          <Col span={12} style={styles.itemIcon}>
            <p
              style={{
                padding: 0,
                margin: 0,
                fontWeight: 'bold',
              }}>
              {item.number ? getHomesName() : ' '}
            </p>
            <p style={{padding: 0, margin: 0}}>
              {item.number ? item.number : ' '}
            </p>
          </Col>
        </Row>,
      );
      formatedElement.push(
        <>
          <p style={{padding: 0, margin: 0, fontWeight: 'bold'}}>
            {item.inside_number ? 'N° Interno' : ''}
          </p>
          <p style={{padding: 0, margin: 0}}>
            {item.inside_number ? item.inside_number : ''}
          </p>
        </>,
      );
      formatedElement.push(
        <Button
          block
          onClick={() => setViewHomeModal(item)}
          style={styles.itemButton}>
          {'VER'}
        </Button>,
      );

      formatedData.push({
        data: formatedElement,
        aditionalData: {
          active: !notActive,
        },
      });
    });
    setDataForTable(formatedData);
  };

  const closeHomeModalAndRefresh = () => {
    setPage(1);
    goToPage(1, false);
    setSearchFor('');
    setSearching(false);
    retrieveHomesData();
    setCreateHomeModal(false);
    setViewHomeModal(false);
  };

  return (
    <CommonLayout
      title={getHomesName() + 's'}
      buttonAdd={
        <Col
          style={{
            display: 'flex',
            alignItems: 'end',
            flexDirection: 'column',
            justifyContent: 'center',
          }}>
          {currentPlan && currentPlan !== '' && currentPlan !== 'FREE' && (
            <Col>
              <Row gutter={10} style={styles.updateSubsContainer}>
                <p
                  style={
                    styles.updateSubsText
                  }>{`Cuentas con ${totalItems} de ${homesInSubscription} casas disponibles`}</p>
                <Button
                  style={{marginLeft: '10px'}}
                  onClick={() => setShowSubscriptionModal(true)}>
                  Modificar
                </Button>
              </Row>
            </Col>
          )}
          <Button
            style={{
              ...styles.container,
              backgroundColor:
                currentPlan &&
                currentPlan !== '' &&
                currentPlan !== 'FREE' &&
                totalItems >= homesInSubscription
                  ? BASE_GRAY
                  : UTILITY_BLUE,
            }}
            disabled={
              currentPlan &&
              currentPlan !== '' &&
              currentPlan !== 'FREE' &&
              totalItems >= homesInSubscription
            }
            onClick={() => setCreateHomeModal(true)}>
            <Row justify="center" align="middle">
              <Col xs={24} sm={0}>
                <div style={styles.iconContainer}>
                  <UilPlus size="16" color="#FFFFFF" />
                  <UilHome size="18" color="#FFFFFF" />
                </div>
              </Col>
              <Col xs={0} sm={24} md={24}>
                <div style={styles.largeButon}>
                  <div style={styles.largeButonIcon}>
                    <UilHome size="18" color="#FFFFFF" />
                  </div>
                  <div style={styles.title}>
                    {'AGREGAR ' + getHomesName().toUpperCase()}
                  </div>
                </div>
              </Col>
            </Row>
          </Button>
        </Col>
      }
      filters={
        <Row style={{width: '100%'}}>
          <Col span={12}>
            <Input
              prefix={<SearchOutlined />}
              value={searchFor}
              disabled={searching}
              onChange={(value) => setSearchFor(value.target.value)}
              style={{width: '70%'}}
              placeholder={searching ? 'Búsqueda Completada' : 'Buscar...'}
              onPressEnter={handleSearchSubmit}
            />
          </Col>
          <Col
            span={12}
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            {searching ? (
              <Button onClick={searchModeOff}>{'REGRESAR'}</Button>
            ) : (
              <Button onClick={handleSearchSubmit}>{'BUSCAR'}</Button>
            )}
          </Col>
        </Row>
      }>
      <CustomList
        loading={loading}
        error={{
          isThereError: error,
          emptyWhat: errorType,
          onRetry: () => {
            setErrorType('network');
            goToPage(1, searching);
          },
        }}
        filters={{
          applyFilters: includeInactive,
          setApplyFilters: setIncludeInactive,
          filtersText: 'INCLUIR INHABILITADOS',
        }}
        pagination={{
          searching,
          page,
          totalItems,
          goToPage,
        }}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 1,
          xl: 2,
          xxl: 2,
        }}
        emptyText={
          searching ? 'No hay coincidencias' : `Aún no hay ${getHomesName()}s`
        }
        columns={[
          {
            columnTitle: '',
            sizes: {xs: 9, lg: 10},
          },
          {
            columnTitle: '',
            sizes: {xs: 8, xl: 9},
          },
          {columnTitle: '', sizes: {xs: 7, lg: 6, xl: 5}},
        ]}
        items={
          includeInactive
            ? dataForTable
            : dataForTable.filter((item) => item.aditionalData.active)
        }
      />
      {createHomeModal && (
        <HomeCreator
          visible={createHomeModal}
          closeModalTrigger={() => setCreateHomeModal(false)}
          closeRefreshAfterCreate={closeHomeModalAndRefresh}
          history={props?.history ?? null}
          onFirstCreate={props?.onFirstCreate ?? null}
        />
      )}
      {viewHomeModal && (
        <HomeEditor
          visible={viewHomeModal !== false}
          closeModalTrigger={() => setViewHomeModal(false)}
          closeRefreshAfterCreate={closeHomeModalAndRefresh}
          homeObject={viewHomeModal}
        />
      )}
      <UpdateSubscriptionQuantity
        visible={showSubscriptionModal}
        quantity={homesInSubscription}
        onClose={() => setShowSubscriptionModal(false)}
        currentHomesQuantity={totalItems}
        onApprove={closeHomeModalAndRefresh}
      />
    </CommonLayout>
  );
};

export default Homes;
