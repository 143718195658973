/* eslint-disable react-hooks/exhaustive-deps */
import {Button, Col, Row} from 'antd';
import React, {useEffect, useState} from 'react';
import {DJSelectPlanStyles as styles} from './styles';
import {
  UilEnvelope,
  UilUsdCircle,
  UilGraphBar,
  UilExclamationTriangle,
  UilCalendarAlt,
  UilCreditCard,
  UilLocationPinAlt,
  UilSelfie,
  UilQrcodeScan,
  UilVideo,
  UilFileAlt,
  UilLockOpenAlt,
} from '@iconscout/react-unicons';
import {useHistory} from 'react-router-dom';
import {
  BASE_GREEN,
  BRAND_LAGOON,
  BRAND_TURQUOISE,
} from '../../constants/themeColors';
import {PayPalScriptProvider} from '@paypal/react-paypal-js';
import apiInstance from '../../network/axios';
import LoadingContent from '../loading/LoadingContent';
import NoContent from '../empty_screens/empty_content/NoContent';
import UpdatePlanAlert from './components/UpdatePlanAlert';
import {getRemaningDaysInSubscription} from '../../utils/DateTime';
import {ButtonWrapper, updateSubscriptionId} from '../../molecules/Paypal';
import {PLANS} from '../../constants/Routes';
import FaceRecognitionIcon from '../../res/faceRecognition.svg';

const DJSelectPlan = () => {
  const currentPlan: string = localStorage.getItem('currentPlan');
  const size = 35;
  const iconColor = BRAND_LAGOON;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorType, setErrorType] = useState('network');
  const [data, setData] = useState<any>({});
  const [planAdmin, setPlanAdmin] = useState(null);
  const [planVigilance, setPlanVigilance] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [remaningDays, setRemaningDays] = useState(0);
  const [gettingPayments, setGettingPayments] = useState(false);
  const history = useHistory();
  useEffect(() => {
    const plans = data?.plans ?? [];
    const planAd = plans.find((item) => item.name === PLANS.ADMIN);
    const planVi = plans.find((item) => item.name === PLANS.VIGILANCE);
    setPlanAdmin(planAd);
    setPlanVigilance(planVi);
  }, [data]);
  const getPlansInfo = async () => {
    try {
      const resp = await apiInstance.get('/paypal/plans');
      setData(resp.data);
      setLoading(false);
    } catch (e) {
      console.error('error getting plans', e);
      setLoading(false);
      setError(true);
    }
  };
  const getPayments = async () => {
    try {
      setGettingPayments(true);
      const url = `/paypal/payments?limit=10&page=1`;
      const resp = await apiInstance.get(url);
      setGettingPayments(false);
      const payments = resp?.data?.payments ?? [];
      if (payments.length > 0) {
        const lastDate = payments[0]?.createTime ?? '';
        setRemaningDays(getRemaningDaysInSubscription(lastDate));
      } else {
        setRemaningDays(0);
      }
      setShowAlert(true);
    } catch (e) {
      console.log('e GETTING PAYMENTS => ', e?.response?.data ?? e);
      setGettingPayments(false);
      setError(true);
    }
  };
  const onCancel = () =>
    updateSubscriptionId({
      subscriptionId: '',
      history,
      plan: PLANS.FREE,
      onError: {
        setError,
        setErrorType,
      },
    });
  useEffect(() => {
    getPlansInfo();
  }, []);
  if (loading) {
    return <LoadingContent />;
  }
  if (error) {
    return (
      <NoContent
        emptyWhat={errorType}
        withRetry
        onClickMethod={() => {
          setError(false);
          setErrorType('network');
          setLoading(true);
          if (errorType === 'network') {
            getPlansInfo();
          } else {
            history.push('/dashboard');
          }
        }}
      />
    );
  }
  return (
    <div style={styles.main}>
      <PayPalScriptProvider
        options={{
          'client-id': data?.paypalClientId ?? '',
          components: 'buttons',
          intent: 'subscription',
          vault: true,
        }}>
        <UpdatePlanAlert
          visible={showAlert}
          remaningDays={remaningDays}
          onCancel={() => setShowAlert(false)}
          onContinue={onCancel}
        />
        <Row gutter={[15, 15]}>
          <Col xs={24} md={12} xl={6}>
            <div style={styles.panel}>
              <div style={styles.benefitsContainer}>
                <p style={styles.title}>Paquete Básico</p>
                <div style={styles.benefitContainer}>
                  <UilEnvelope color={iconColor} size={size} />
                  <h4 style={styles.benefit}>Mensajes</h4>
                </div>
                <div style={styles.benefitContainer}>
                  <UilUsdCircle color={iconColor} size={size} />
                  <h4 style={styles.benefit}>Estados de cuenta</h4>
                </div>
              </div>
              <div style={styles.priceContainer}>
                <p style={styles.price}>¡Pruébalo completamente gratis!</p>
                <div style={styles.buttonContainer}>
                  <Button
                    disabled={currentPlan === PLANS.FREE || currentPlan === ''}
                    onClick={getPayments}
                    style={{
                      ...styles.button,
                      backgroundColor:
                        currentPlan === PLANS.FREE || currentPlan === ''
                          ? BASE_GREEN
                          : BRAND_TURQUOISE,
                    }}>
                    {gettingPayments ? (
                      <LoadingContent />
                    ) : currentPlan === PLANS.FREE || currentPlan === '' ? (
                      'PLAN ACTUAL'
                    ) : (
                      'ELEGIR PLAN'
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </Col>
          {planAdmin != null && (
            <Col xs={24} md={12} xl={6}>
              <div style={styles.panel}>
                <div style={styles.benefitsContainer}>
                  <p style={styles.title}>Paquete Administración</p>
                  <div style={styles.benefitContainer}>
                    <UilEnvelope color={iconColor} size={size} />
                    <h4 style={styles.benefit}>Mensajes</h4>
                  </div>
                  <div style={styles.benefitContainer}>
                    <UilUsdCircle color={iconColor} size={size} />
                    <h4 style={styles.benefit}>Estados de cuenta</h4>
                  </div>
                  <div style={styles.benefitContainer}>
                    <UilGraphBar color={iconColor} size={size} />
                    <h4 style={styles.benefit}>Encuestas</h4>
                  </div>
                  <div style={styles.benefitContainer}>
                    <UilExclamationTriangle color={iconColor} size={size} />
                    <h4 style={styles.benefit}>Reporte de eventualidades</h4>
                  </div>
                  <div style={styles.benefitContainer}>
                    <UilCalendarAlt color={iconColor} size={size} />
                    <h4 style={styles.benefit}>Eventos</h4>
                  </div>
                  <div style={styles.benefitContainer}>
                    <UilCreditCard color={iconColor} size={size} />
                    <h4 style={styles.benefit}>Pago automático con tarjeta</h4>
                  </div>
                  <div style={styles.benefitContainer}>
                    <UilLocationPinAlt color={iconColor} size={size} />
                    <h4 style={styles.benefit}>Reservación de áreas comunes</h4>
                  </div>
                </div>
                <div style={styles.priceContainer}>
                  <p style={styles.price}>
                    ¡Consíguelo por solo $9.00 MXN por casa!
                  </p>
                  {currentPlan === PLANS.ADMIN ? (
                    <div style={styles.buttonContainer}>
                      <Button
                        disabled
                        style={{
                          ...styles.button,
                          backgroundColor: BASE_GREEN,
                        }}>
                        {'PLAN ACTUAL'}
                      </Button>
                    </div>
                  ) : (
                    <ButtonWrapper
                      type="subscription"
                      planId={planAdmin?.id ?? ''}
                      quantity={data.quantity}
                      history={history}
                      plan={PLANS.ADMIN}
                      onError={{setError, setErrorType}}
                    />
                  )}
                </div>
              </div>
            </Col>
          )}
          {planVigilance != null && (
            <Col xs={24} md={12} xl={6}>
              <div style={styles.panel}>
                <div style={styles.benefitsContainer}>
                  <p style={styles.title}>Paquete Vigilancia</p>
                  <div style={styles.benefitContainer}>
                    <UilFileAlt color={iconColor} size={size} />
                    <h4 style={styles.benefit}>Log de visitas</h4>
                  </div>
                  <div style={styles.benefitContainer}>
                    <UilEnvelope color={iconColor} size={size} />
                    <h4 style={styles.benefit}>Mensajes</h4>
                  </div>
                  <div style={styles.benefitContainer}>
                    <UilUsdCircle color={iconColor} size={size} />
                    <h4 style={styles.benefit}>Estados de cuenta</h4>
                  </div>
                  <div style={styles.benefitContainer}>
                    <UilGraphBar color={iconColor} size={size} />
                    <h4 style={styles.benefit}>Encuestas</h4>
                  </div>
                  <div style={styles.benefitContainer}>
                    <UilExclamationTriangle color={iconColor} size={size} />
                    <h4 style={styles.benefit}>Reporte de eventualidades</h4>
                  </div>
                  <div style={styles.benefitContainer}>
                    <UilCalendarAlt color={iconColor} size={size} />
                    <h4 style={styles.benefit}>Eventos</h4>
                  </div>
                  <div style={styles.benefitContainer}>
                    <UilCreditCard color={iconColor} size={size} />
                    <h4 style={styles.benefit}>Pago automático con tarjeta</h4>
                  </div>
                  <div style={styles.benefitContainer}>
                    <UilLocationPinAlt color={iconColor} size={size} />
                    <h4 style={styles.benefit}>Reservación de áreas comunes</h4>
                  </div>
                </div>
                <div style={styles.priceContainer}>
                  <p style={styles.price}>
                    ¡Consíguelo por solo $19.00 MXN por casa!
                  </p>
                  {currentPlan === PLANS.VIGILANCE ? (
                    <div style={styles.buttonContainer}>
                      <Button
                        disabled
                        style={{
                          ...styles.button,
                          backgroundColor: BASE_GREEN,
                        }}>
                        {'PLAN ACTUAL'}
                      </Button>
                    </div>
                  ) : (
                    <ButtonWrapper
                      type="subscription"
                      planId={planVigilance?.id ?? ''}
                      quantity={data.quantity}
                      history={history}
                      plan={PLANS.VIGILANCE}
                      onError={{setError, setErrorType}}
                    />
                  )}
                </div>
              </div>
            </Col>
          )}
          <Col xs={24} md={12} xl={6}>
            <div style={styles.panel}>
              <div style={styles.benefitsContainer}>
                <p style={styles.title}>Paquete Seguridad</p>
                <div style={styles.benefitContainer}>
                  <UilSelfie color={iconColor} size={size} />
                  <h4 style={styles.benefit}>Videollamadas</h4>
                </div>
                <div style={styles.benefitContainer}>
                  <UilLockOpenAlt color={iconColor} size={size} />
                  <h4 style={styles.benefit}>Acceso remoto</h4>
                </div>
                <div style={styles.benefitContainer}>
                  <UilQrcodeScan color={iconColor} size={size} />
                  <h4 style={styles.benefit}>Entradas por QR</h4>
                </div>
                <div style={styles.benefitContainer}>
                  <img
                    src={FaceRecognitionIcon}
                    alt="faceRecognition"
                    style={{width: size, height: size}}
                  />

                  <h4 style={styles.benefit}>Reconocimiento facial</h4>
                </div>
                <div style={styles.benefitContainer}>
                  <UilVideo color={iconColor} size={size} />
                  <h4 style={styles.benefit}>Acceso a cámaras</h4>
                </div>
                <p>
                  ¡Además de incluir todos los beneficios del paquete
                  Vigilancia!
                </p>
              </div>
              <div style={styles.priceContainer}>
                <p style={styles.price}>
                  ¡Consíguelo por solo $150 MXN por casa!
                </p>
                {currentPlan === PLANS.SECURITY ? (
                  <div style={styles.buttonContainer}>
                    <Button
                      disabled
                      style={{
                        ...styles.button,
                        backgroundColor: BASE_GREEN,
                      }}>
                      {'PLAN ACTUAL'}
                    </Button>
                  </div>
                ) : (
                  <div
                    style={styles.buttonContainer}
                    onClick={(e) => {
                      if (currentPlan !== PLANS.SECURITY) {
                        (window as Window).location =
                          'mailto:contacto@portos.xyz';
                        e.preventDefault();
                      }
                    }}>
                    <Button style={styles.button}>CONTACTANOS</Button>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </PayPalScriptProvider>
    </div>
  );
};
export default DJSelectPlan;
