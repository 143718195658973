import React, {useRef, useState} from 'react';
import {Col, Row, Modal} from 'antd';

import {
  UTILITY_RED,
  UTILITY_YELLOW,
  UTILITY_BLUE,
} from '../../../constants/themeColors';
import LoadingContent from '../../loading/LoadingContent';
import NoContent from '../../empty_screens/empty_content/NoContent';
import FeatureIcon from '../../../general_components/FeatureIcon/FeatureIcon';
import CamFormEditor from './CamFormEditor';
import apiInstance from '../../../network/axios';
import {FormInstance} from 'antd/es/form/Form';
import {CircleButton} from '../../../atoms';

interface ICamEditor {
  visible: boolean;
  closeModalTrigger: () => void;
  closeRefreshAfterCreate: () => void;
  camObject: any;
}
const CamEditor = ({
  camObject,
  closeModalTrigger,
  closeRefreshAfterCreate,
  visible,
}: ICamEditor) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [emptyWhat, setEmptyWhat] = useState<string>('network');
  const [editMode, setEditMode] = useState<boolean>(false);

  const formRef = useRef<FormInstance>();

  const validatePut = async () => {
    const values = await formRef.current.validateFields();
    setLoading(true);
    putEditCam(values);
  };

  const putEditCam = async (form_object) => {
    try {
      const url = `/cameras/${camObject._id}`;
      const body = {
        take_snap: form_object.take_snap,
        number: form_object.cam_number,
        video_url: form_object.video_url,
        snap_url: form_object.snap_url,
        username: form_object.user,
        password: form_object.password,
        requires_auth: form_object.requires_auth,
        main_camera: form_object.main_camera,
      };

      await apiInstance.put(url, body);

      setLoading(false);
      closeRefreshAfterCreate();
    } catch (e) {
      setLoading(false);
      setError(true);

      if (e?.response?.data?.message === 'InvalidCamera') {
        setEmptyWhat('InvalidCamera');
      }
    }
  };

  const confirmDeleteCam = () => {
    if (window.confirm('¿Esta seguro que desea eliminar esta cámara?')) {
      setLoading(true);
      deleteCam();
    }
  };

  const deleteCam = async () => {
    try {
      const url = `/cameras/${camObject._id}`;
      await apiInstance.delete(url);
      setLoading(false);
      closeRefreshAfterCreate();
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const toggleEditMode = () => {
    if (editMode) {
      if (window.confirm('¿Abandonar modo de edición?')) {
        setEditMode(!editMode);
      } else {
        return;
      }
    } else {
      setEditMode(!editMode);
    }
  };

  return (
    <Modal
      centered
      visible={visible}
      onOk={validatePut}
      onCancel={closeModalTrigger}
      okText="AGREGAR"
      cancelText="CANCELAR"
      maskClosable={false}
      zIndex={2000}
      destroyOnClose={true}
      closable={!loading}
      footer={
        error ? null : (
          <Row>
            <Col span={2}>
              <CircleButton
                variant="del"
                activeColor={UTILITY_RED}
                disabled={loading}
                onClick={confirmDeleteCam}
              />
            </Col>
            <Col span={22}>
              <CircleButton
                variant="genericEdit"
                activeColor={UTILITY_YELLOW}
                disabled={loading}
                onClick={toggleEditMode}
                clickedMode={editMode}
              />
              <CircleButton
                variant="save"
                activeColor={UTILITY_BLUE}
                disabled={!editMode ? true : loading}
                onClick={validatePut}
              />
            </Col>
          </Row>
        )
      }
      title={
        <Row>
          <Col xs={5} sm={4} md={3}>
            <FeatureIcon feature="cam" />
          </Col>
          <Col xs={19} sm={20} md={21} style={{fontWeight: 'bold'}}>
            {'Cámara ' +
              (camObject ? (camObject.number ? camObject.number : ' ') : ' ')}
          </Col>
        </Row>
      }>
      {loading ? (
        <LoadingContent />
      ) : error ? (
        <NoContent emptyWhat={emptyWhat} />
      ) : (
        <Row>
          <Col span={24}>
            <CamFormEditor
              formRef={formRef}
              defaultValues={camObject ? camObject : null}
              editMode={editMode}
            />
          </Col>
        </Row>
      )}
    </Modal>
  );
};
export default CamEditor;
