import React, {useRef, useState} from 'react';
import {Col, Row, Modal, Button} from 'antd';

//local imports
import {getUserHood} from '../../../navigation/auth/auth';

import LoadingContent from '../../loading/LoadingContent';
import NoContent from '../../empty_screens/empty_content/NoContent';
import FeatureIcon from '../../../general_components/FeatureIcon/FeatureIcon';
import CamFormEditor from './CamFormEditor';
import apiInstance from '../../../network/axios';
import {FormInstance} from 'antd/es/form/Form';

interface ICamCreator {
  visible: boolean;
  closeModalTrigger: () => void;
  closeRefreshAfterCreate: () => void;
}
const CamCreator = ({
  closeModalTrigger,
  closeRefreshAfterCreate,
  visible,
}: ICamCreator) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [emptyWhat, setEmptyWhat] = useState<string>('network');

  const formRef = useRef<FormInstance>();

  const validatePost = async () => {
    const values = await formRef.current.validateFields();
    setLoading(true);
    postCreateCam(values);
  };

  const postCreateCam = async (form_object) => {
    try {
      const userHood = getUserHood();

      const url = `/neighborhoods/${userHood}/cameras`;

      const body = {
        take_snap: form_object.take_snap,
        number: form_object.cam_number,
        video_url: form_object.video_url,
        snap_url: form_object.snap_url,
        username: form_object.user,
        password: form_object.password,
        requires_auth: form_object.requires_auth,
        main_camera: form_object.main_camera,
      };

      await apiInstance.post(url, body);
      setLoading(false);
      closeRefreshAfterCreate();
    } catch (e) {
      setLoading(false);
      setError(true);

      if (e?.response?.data?.message === 'InvalidCamera') {
        setEmptyWhat('InvalidCamera');
      }
    }
  };

  const retryAfterNetErr = () => {
    setLoading(false);
    setError(false);
  };

  return (
    <Modal
      centered
      visible={visible}
      onOk={validatePost}
      onCancel={closeModalTrigger}
      okText="AGREGAR"
      cancelText="CANCELAR"
      maskClosable={false}
      zIndex={2000}
      destroyOnClose={true}
      closable={!loading}
      footer={[
        <Button key="back" disabled={loading} onClick={closeModalTrigger}>
          {'CANCELAR'}
        </Button>,
        error ? (
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={retryAfterNetErr}>
            {'REINTENTAR'}
          </Button>
        ) : (
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={validatePost}>
            {'AGREGAR'}
          </Button>
        ),
      ]}
      title={
        <Row>
          <Col xs={5} sm={4} md={3}>
            <FeatureIcon feature="cam" />
          </Col>
          <Col xs={19} sm={20} md={21} style={{fontWeight: 'bold'}}>
            {'Cámara'}
          </Col>
        </Row>
      }>
      {loading ? (
        <LoadingContent />
      ) : error ? (
        <NoContent emptyWhat={emptyWhat} />
      ) : (
        <Row>
          <Col span={24}>
            <CamFormEditor formRef={formRef} editMode={true} />
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default CamCreator;
