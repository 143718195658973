const FREE = [
  'messages',
  'accounting',
  'streets',
  'homes',
  'owners',
  'settings',
  'profile',
  'public',
];
const ADMIN = [...FREE, 'common_areas', 'polls', 'reports'];
const VIGILANCE = [...ADMIN, 'entrance', 'exit'];
const SECURITY = [...VIGILANCE, 'cameras', 'doors'];

export enum PLANS {
  SECURITY = 'SECURITY',
  ADMIN = 'ADMIN',
  VIGILANCE = 'VIGILANCE',
  FREE = 'FREE',
}

export const ROUTES = {
  SECURITY,
  ADMIN,
  VIGILANCE,
  FREE,
};
