import {Checkbox, Col, List, Pagination, Row} from 'antd';
import React, {Dispatch, SetStateAction} from 'react';
import {BASE_LIGHT_GRAY, BASE_WHITE} from '../constants/themeColors';
import NoContent from '../screens/empty_screens/empty_content/NoContent';
import {normalizeColSizes} from '../utils/cols';
import {CustomTableStyles as styles} from './styles';

interface IFilters {
  applyFilters: boolean | null;
  setApplyFilters: Dispatch<SetStateAction<boolean>> | null;
  filtersText: string | null;
}

interface IPagination {
  searching: boolean;
  page: number;
  totalItems: number;
  goToPage: (num: number, searching?: boolean) => void;
}
interface IError {
  isThereError: boolean;
  emptyWhat: string;
  onRetry: () => void;
}
interface ICustomTable {
  filters?: IFilters;
  pagination?: IPagination;
  error?: IError;
  columns?: any[];
  items?: any[];
  loading?: boolean;
  emptyText?: string;
}
const CustomTable = ({
  filters,
  pagination,
  columns,
  items,
  loading,
  error: {isThereError, emptyWhat, onRetry},
}: ICustomTable) => {
  if (isThereError) {
    return (
      <NoContent emptyWhat={emptyWhat} withRetry onClickMethod={onRetry} />
    );
  }
  return (
    <div>
      <Row align="middle" style={styles.header}>
        <Col xs={24} sm={10} md={8}>
          {filters?.filtersText && (
            <Checkbox
              checked={filters.applyFilters}
              onChange={() => filters.setApplyFilters(!filters?.applyFilters)}
              style={{paddingLeft: '10px'}}>
              {filters.filtersText}
            </Checkbox>
          )}
        </Col>
        <Col xs={24} sm={14} md={16}>
          {pagination && (
            <div style={styles.pagination}>
              <Pagination
                showSizeChanger={false}
                defaultCurrent={pagination.page}
                current={pagination.page}
                total={pagination.totalItems}
                size={'small'}
                onChange={(pageNum) =>
                  pagination.goToPage(pageNum, pagination.searching)
                }
              />
            </div>
          )}
        </Col>
      </Row>

      <Row style={styles.rowColumns} align="middle">
        {columns.map((item, index) => {
          const sizes = normalizeColSizes(item.sizes);
          return (
            <Col
              key={index.toString()}
              xs={sizes.xs}
              sm={sizes.sm}
              md={sizes.md}
              lg={sizes.lg}
              xl={sizes.xl}
              xxl={sizes.xxl}
              style={styles.clumnHeader}>
              {item.columnTitle}
            </Col>
          );
        })}
      </Row>

      <List
        locale={{emptyText: 'Sin Registros'}}
        bordered
        style={styles.list}
        dataSource={items}
        loading={loading}
        renderItem={(item: any) => {
          return (
            <List.Item
              style={{
                padding: '0 2.08%',
                minHeight: '67px',
                backgroundColor: item.aditionalData.active
                  ? BASE_WHITE
                  : BASE_LIGHT_GRAY,
              }}>
              {item.data.map((i, index) => {
                const sizes = normalizeColSizes(columns[index].sizes);
                return (
                  <Col
                    key={index.toString()}
                    xs={sizes.xs}
                    sm={sizes.sm}
                    md={sizes.md}
                    lg={sizes.lg}
                    xl={sizes.xl}
                    xxl={sizes.xxl}
                    style={styles.cell}>
                    {i}
                  </Col>
                );
              })}
            </List.Item>
          );
        }}
      />
    </div>
  );
};

export default CustomTable;
