export enum NeighborhoodConfig {
  NoConfig = '0',
  HasNeighborhood = '1',
  HasStreets = '2',
  HasHomes = '3',
  HasOwners = '4',
}
export function login(data, callBackFunc) {
  if (data.user.role === 'SUPER_ADMIN') {
    localStorage.setItem('userData', JSON.stringify(data.user));
    localStorage.setItem('token', data.token);
    localStorage.setItem('userId', data.user._id);
    localStorage.setItem('userName', data.user.name);
    localStorage.setItem('userInitials', data.user.initials);
    localStorage.setItem('userAccessLevel', data.user.role);
  } else if (data.user.role === 'ADMIN') {
    if (data.token) {
      localStorage.setItem('token', data.token);
    }
    localStorage.setItem('userData', JSON.stringify(data.user));
    localStorage.setItem('userId', data.user._id);
    localStorage.setItem('userName', data.user.name);
    localStorage.setItem('userAccessLevel', data.user.role);
    localStorage.setItem('userInitials', data.user.initials);
    localStorage.setItem('hoodId', data?.user?.neighborhood?.id ?? 'NO_ID');
    localStorage.setItem(
      'homesName',
      data?.user?.neighborhood?.house_name ?? 'NO_HOUSE_NAME',
    );
    localStorage.setItem(
      'streetsName',
      data?.user?.neighborhood?.street_name ?? 'NO_STREET_NAME',
    );
    localStorage.setItem(
      'hoodAvatar',
      data?.user?.neighborhood?.image?.path ?? 'noImage',
    );
    localStorage.setItem(
      'hoodName',
      data.user?.neighborhood?.number ?? 'Tu condominio',
    );
    localStorage.setItem(
      'activeServices',
      data.user?.neighborhood?.active_services ?? [],
    );
  }
  callBackFunc();
}

export const editLocalNeigborhood = (neighborhood) => {
  localStorage.setItem('hoodId', neighborhood?.id ?? 'NO_ID');
  localStorage.setItem(
    'homesName',
    neighborhood?.house_name ?? 'NO_HOUSE_NAME',
  );
  localStorage.setItem(
    'streetsName',
    neighborhood?.street_name ?? 'NO_STREET_NAME',
  );
  localStorage.setItem('hoodAvatar', neighborhood?.image?.path ?? 'noImage');
  localStorage.setItem('hoodName', neighborhood?.number ?? 'Tu condominio');
  localStorage.setItem('activeServices', neighborhood?.active_services ?? []);
};

export function newHood(data, callBackFunc) {
  localStorage.setItem('hoodId', data?.id ?? 'NO_ID');
  localStorage.setItem('homesName', data?.house_name ?? 'NO_HOUSE_NAME');
  localStorage.setItem('streetsName', data?.street_name ?? 'NO_STREET_NAME');
  localStorage.setItem('hoodAvatar', data?.image?.path ?? 'noImage');
  localStorage.setItem('hoodName', data?.number ?? 'Tu condominio');
  localStorage.setItem('activeServices', data?.active_services ?? []);
  callBackFunc();
}
export function logout(callBackFunc) {
  localStorage.clear();
  setTimeout(callBackFunc(), 2000);
}

export function isAuthenticated() {
  return localStorage.getItem('token') ? true : false;
}

export function getUserToken() {
  return localStorage.getItem('token') ? localStorage.getItem('token') : null;
}

export function getUserData() {
  return localStorage.getItem('userData')
    ? localStorage.getItem('userData')
    : 'NOT_AVAILABLE';
}

export function getUserHood() {
  return localStorage.getItem('hoodId')
    ? localStorage.getItem('hoodId')
    : 'NOT_AVAILABLE';
}

export function getUserId() {
  return localStorage.getItem('userId')
    ? localStorage.getItem('userId')
    : 'NOT_AVAILABLE';
}

export function getUserName() {
  return localStorage.getItem('userName')
    ? localStorage.getItem('userName')
    : 'NOT_AVAILABLE';
}

export function setUserName(user_name) {
  localStorage.setItem('userName', user_name);
}

export function getHomesName() {
  return localStorage.getItem('homesName')
    ? localStorage.getItem('homesName')
    : 'NOT_AVAILABLE';
}

export function getStreetsName() {
  return localStorage.getItem('streetsName')
    ? localStorage.getItem('streetsName')
    : 'NOT_AVAILABLE';
}

export function getUserInitials() {
  return localStorage.getItem('userInitials')
    ? localStorage.getItem('userInitials')
    : 'NOT_AVAILABLE';
}

export function getHoodAvatar() {
  return localStorage.getItem('hoodAvatar')
    ? localStorage.getItem('hoodAvatar')
    : 'NOT_AVAILABLE';
}

export function getHoodName() {
  return localStorage.getItem('hoodName')
    ? localStorage.getItem('hoodName')
    : 'NOT_AVAILABLE';
}

export function getAccessLevel() {
  return localStorage.getItem('userAccessLevel')
    ? localStorage.getItem('userAccessLevel')
    : 'NOT_AVAILABLE';
}

export function getActiveServices() {
  return localStorage.getItem('activeServices');
}

export const getLevelConfig = (): number => {
  return localStorage.getItem('levelConfig')
    ? parseInt(localStorage.getItem('levelConfig'))
    : 0;
};

export const getNeighborhoodConfig = (data: any): NeighborhoodConfig => {
  const neighborhood = data?.user?.neighborhood ?? false;
  const streets = data?.user?.neighborhood?.streets ?? [];
  const homes = data?.user?.neighborhood?.homes ?? [];
  const userCount = data?.ownerCount ?? 0;
  if (userCount > 0) {
    return NeighborhoodConfig.HasOwners;
  }
  if (homes.length > 0) {
    return NeighborhoodConfig.HasHomes;
  }
  if (streets.length > 0) {
    return NeighborhoodConfig.HasStreets;
  }
  if (neighborhood) {
    return NeighborhoodConfig.HasNeighborhood;
  }
  return NeighborhoodConfig.NoConfig;
};
