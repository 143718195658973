import {Button, Checkbox, Col, Input, Row, Select, TimePicker} from 'antd';
import {UilPlus} from '@iconscout/react-unicons';
import React, {useState} from 'react';
import NoContent from '../../empty_screens/empty_content/NoContent';
import {CommonAreasStyles as styles} from '../styles';
import {Modal} from '../../../molecules/Modal';
import {BRAND_LAGOON, BRAND_TURQUOISE} from '../../../constants/themeColors';
import {getUserHood} from '../../../navigation/auth/auth';
import {DEVELOPMENT} from '../../../constants/network';
import {ICONS} from './Icons';
import apiInstance from '../../../network/axios';

const AddCommonArea = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [validating, setValidating] = useState(false);
  const [opensAt, setOpensAt] = useState(null);
  const [closesAt, setClosesAt] = useState(null);
  const [availableDays, setAvailableDays] = useState([]);
  const [icon, setIcon] = useState<any>('GYM');
  const [disabled, setDisabled] = useState(false);

  const clearForm = () => {
    setName('');
    setDescription('');
    setClosesAt(null);
    setOpensAt(null);
    setAvailableDays([]);
    setIcon('GYM');
  };

  const createCommonArea = async () => {
    try {
      if (
        name === '' ||
        description === '' ||
        opensAt === null ||
        closesAt === null ||
        availableDays.length === 0
      ) {
        setValidating(true);
        return;
      }
      setLoading(true);
      const userNeighborhoodID = getUserHood();
      const url = `/neighborhoods/${userNeighborhoodID}/common_area`;
      const body = {
        name,
        opens_at: opensAt,
        closes_at: closesAt,
        disabled,
        description,
        days_open: availableDays,
        icon,
        color_hex: BRAND_LAGOON,
      };
      const response = await apiInstance.post(url, body);
      if (response.data.success) {
        clearForm();
        props.onCancel();
        props.reload();
      }
    } catch (error) {
      if (DEVELOPMENT) {
        console.error(
          'ERROR CREATING COMMIN AREA',
          error?.response?.data ?? error,
        );
      }
      setLoading(false);
      setError(true);
    }
  };

  const {TextArea} = Input;
  const format = 'HH:mm';
  const {Option} = Select;
  const icons = ICONS;

  const options = [
    {value: 'MON', label: 'Lun'},
    {value: 'TUE', label: 'Mar'},
    {value: 'WED', label: 'Mie'},
    {value: 'THU', label: 'Jue'},
    {value: 'FRI', label: 'Vie'},
    {value: 'SAT', label: 'Sab'},
    {value: 'SUN', label: 'Dom'},
  ];
  const onChangeDays = (data) => {
    setAvailableDays(data);
  };

  return (
    <Modal
      onOk={createCommonArea}
      icon={
        <div
          style={{
            width: 40,
            height: 40,
            borderRadius: 20,
            backgroundColor: props.iconColor ?? 'red',
            padding: 5,
          }}>
          <UilPlus size="30" color="#FFFFFF" />
        </div>
      }
      title={props.title}
      visible={props.visible}
      onCancel={() => {
        clearForm();
        props.onCancel();
      }}
      loading={loading}
      body={
        <div>
          {error ? (
            <NoContent
              emptyWhat={'network'}
              withRetry
              onClickMethod={() => {
                setError(false);
              }}
            />
          ) : (
            <Row>
              <Col span={24}>
                <h3 style={styles.h3}>{'Nombre'}</h3>
                <Input
                  value={name}
                  onChange={(value) => setName(value.target.value)}
                />
                {validating && name === '' && (
                  <p style={styles.validation}>El nombre es requerido.</p>
                )}
                <h3 style={styles.h3}>{'Horario'}</h3>
                <Row>
                  <Col xs={24} sm={12}>
                    <div style={styles.numberInput}>
                      <TimePicker
                        style={styles.timePicker}
                        format={format}
                        onChange={(data: any) => {
                          if (data !== null) {
                            const minutes =
                              data._d.getHours() * 60 + data._d.getMinutes();
                            setOpensAt(minutes);
                          } else {
                            setOpensAt(data);
                          }
                        }}
                      />
                      Desde
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <div style={styles.numberInput}>
                      <TimePicker
                        style={styles.timePicker}
                        format={format}
                        onChange={(data: any) => {
                          if (data !== null) {
                            const minutes =
                              data._d.getHours() * 60 + data._d.getMinutes();
                            setClosesAt(minutes);
                          } else {
                            setClosesAt(data);
                          }
                        }}
                      />
                      Hasta
                    </div>
                  </Col>
                </Row>
                {validating && (opensAt === null || closesAt === null) && (
                  <p style={styles.validation}>El horario es requerido.</p>
                )}

                <h3 style={styles.h3}>{'Días hábiles'}</h3>
                <Checkbox.Group onChange={onChangeDays} options={options} />
                {validating && availableDays.length === 0 && (
                  <p style={styles.validation}>
                    Tiene que habilitarse al menos un dia.
                  </p>
                )}

                <Checkbox
                  style={{marginTop: 20}}
                  onChange={(e) => setDisabled(e.target.checked)}>
                  Deshabilitar área
                </Checkbox>

                <h3 style={styles.h3}>{'Descripción'}</h3>
                <TextArea
                  disabled={loading}
                  rows={4}
                  value={description}
                  onChange={(value) => setDescription(value.target.value)}
                />
                {validating && description === '' && (
                  <p style={styles.validation}>La descripción es requerida.</p>
                )}

                <h3 style={styles.h3}>{'Ícono'}</h3>

                <div style={styles.selectedOption}>{icons[icon]}</div>
                <Select
                  dropdownStyle={{minWidth: 120}}
                  style={{
                    width: 100,
                    marginLeft: 5,
                  }}
                  bordered={false}
                  onChange={(data) => setIcon(data)}>
                  {Object.keys(icons).map((key, index) => {
                    return (
                      <Option
                        key={index.toString()}
                        value={key}
                        style={{
                          ...styles.option,
                          backgroundColor:
                            index % 2 === 0 ? BRAND_LAGOON : BRAND_TURQUOISE,
                        }}>
                        {icons[key]}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
          )}
        </div>
      }
      footer={
        error
          ? null
          : [
              <Button
                key="back"
                disabled={props.loading}
                onClick={() => {
                  clearForm();
                  props.onCancel();
                }}>
                {'CANCELAR'}
              </Button>,
              <Button
                key="submit"
                type="primary"
                loading={loading}
                onClick={createCommonArea}>
                {'CREAR'}
              </Button>,
            ]
      }
    />
  );
};

export default AddCommonArea;
