/* eslint-disable react-hooks/exhaustive-deps */
import {Button, Col, DatePicker, Input, Row} from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import React, {useEffect, useState} from 'react';
import {DEVELOPMENT} from '../../constants/network';
import FeatureIcon from '../../general_components/FeatureIcon/FeatureIcon';
import {getUserHood, getUserId} from '../../navigation/auth/auth';
import {
  getDateDisplay,
  requiredDateValForServer,
} from '../../utils/locale/chronos';
import ReportViewer from './components/ReportViewer';
import CommonLayout from '../../layouts/CommonLayout';
import CustomTable from '../../molecules/CustomTable';
import {resizeString} from '../../utils/strings';
import apiInstance from '../../network/axios';
import {ReportStyles as styles} from './styles';
import {SearchOutlined} from '@ant-design/icons';

const Reports = () => {
  const [loading, setLoading] = useState(true);
  const [searching, setSearching] = useState(false);
  const [searchForInput, setSearchForInput] = useState('');
  const [searchForDate, setSearchForDate] = useState('');
  const [page, setPage] = useState(1);
  const [reportsData, setReportsData] = useState([]);
  const [networkError, setnetworkError] = useState(false);
  const [totalItems, setTotalItems] = useState(10);
  const [showReportModal, setShowReportModal] = useState(false);
  const [modalItem, setModalItem] = useState({});
  const [modalIndex, setModalIndex] = useState(null);
  const [includeSeen, setIncludeSeen] = useState(true);
  const [dataForTable, setDataForTable] = useState([]);
  const [aux, setAux] = useState(false);
  const pageSize = 10;

  useEffect(() => {
    getReports(10, 1);
  }, []);

  useEffect(() => {
    formatDataForTable(reportsData);
  }, [reportsData, aux]);

  const onChangeSearchValue = (event) => {
    setSearchForInput(event.target.value);
  };
  const onChangeDate = (date, dateString) => {
    setSearchForDate(date);
  };

  const searchModeOff = () => {
    setSearching(false);
    setSearchForDate('');
    setSearchForInput('');
    goToPage(1, false);
  };

  const goToPage = (pageNum: number, isSearching: boolean) => {
    setLoading(true);
    setnetworkError(false);
    setPage(pageNum);
    isSearching ? searchReports(10, pageNum) : getReports(10, pageNum);
  };

  const handleSearchSubmit = () => {
    if (!searchForDate && !searchForInput) {
      return;
    }
    setLoading(true);
    setnetworkError(false);
    setSearching(true);
    setPage(1);
    setReportsData([]);
    searchReports(10, 1);
  };
  const getReports = async (limit, pageNum) => {
    try {
      const userNeighborhoodID = getUserHood();
      const url = `/neighborhoods/${userNeighborhoodID}/messages?limit=${limit}&page=${pageNum}&type=REPORT`;

      const response = await apiInstance.get(url);
      setTotalItems(parseInt(response.headers['x-paginate-total']));

      if (response.data.length === 0 && pageNum === 1) {
        setLoading(false);
        setSearchForDate('');
        return;
      }
      setLoading(false);
      setReportsData(response.data);
    } catch (error) {
      if (DEVELOPMENT) {
        console.error('ERROR GETTING REPORTS =>', error?.response ?? error);
      }
      setLoading(false);
      setnetworkError(true);
      setSearchForDate('');
    }
  };

  const searchReports = async (limit, pageNum) => {
    try {
      const userNeighborhoodID = getUserHood();
      const validSearchDate = searchForDate
        ? `&date=${requiredDateValForServer(searchForDate)}`
        : '';
      const validSearchInput = searchForInput ? `&query=${searchForInput}` : '';

      const url = `/neighborhoods/${userNeighborhoodID}/messages?type=REPORT&limit=${limit}&page=${pageNum}${validSearchDate}${validSearchInput}`;

      const response = await apiInstance.get(url);
      const total = response.headers['x-paginate-total']
        ? parseInt(response.headers['x-paginate-total'], 10)
        : pageSize;
      setTotalItems(total);
      setReportsData(response.data);
      setLoading(false);
    } catch (error) {
      if (DEVELOPMENT) {
        console.error('ERROR GETTING REPORTS =>', error?.response ?? error);
      }
      setLoading(false);
      setnetworkError(true);
      setSearchForDate('');
    }
  };

  const handleShowModal = (item, index) => {
    setModalItem(item);
    setModalIndex(index);
    setShowReportModal(true);
  };
  const hideModal = () => {
    addUserIdtoReadBy();
    setModalItem({});
    setModalIndex(null);
    setShowReportModal(false);
  };

  const addUserIdtoReadBy = () => {
    const reports = reportsData;
    if (!reports[modalIndex].readBy.includes(getUserId())) {
      reports[modalIndex].readBy.push(getUserId());
    }
    setReportsData(reports);
    setAux(!aux);
  };

  const formatDataForTable = (data = []) => {
    const userId = getUserId();
    const formatedData = [];
    data.forEach((element, index) => {
      const timeDisplay = getDateDisplay(element.date, element.time_ago);
      const formatedElement = [];
      formatedElement.push(
        <Row align="middle">
          <Col xs={24} sm={6} md={5} lg={5} xl={4} xxl={3}>
            <FeatureIcon feature="reports" />
          </Col>
          <Col
            xs={0}
            sm={18}
            md={19}
            lg={19}
            xl={20}
            xxl={21}
            style={styles.reportTitle}>
            {resizeString(element.title ?? '')}
          </Col>
        </Row>,
      );
      formatedElement.push(resizeString(element?.message ?? ''));
      formatedElement.push(timeDisplay);
      formatedElement.push(
        <Button
          block
          onClick={() => handleShowModal(element, index)}
          style={styles.reportDetailButoon}>
          {'VER'}
        </Button>,
      );
      formatedData.push({
        data: formatedElement,
        aditionalData: {
          active: !element.readBy.includes(userId),
          readBy: element.readBy,
        },
      });
    });
    setDataForTable(formatedData);
  };
  return (
    <CommonLayout
      title={'Reporte de eventualidades'}
      filters={
        <div style={{width: '100%'}}>
          <Row style={{minHeight: '16px'}} />
          <Row>
            <Col span={24} style={styles.filterText}>
              {'Busqueda por contenido y/ó fecha:'}
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={9} md={10} lg={9} xl={8}>
              <Input
                prefix={<SearchOutlined style={styles.searchIcon} />}
                disabled={searching}
                value={searchForInput}
                onChange={onChangeSearchValue}
                style={styles.searchItem}
                placeholder={searching ? 'Búsqueda Completada' : 'Buscar...'}
                onPressEnter={handleSearchSubmit}
              />
            </Col>
            <Col xs={12} sm={9} md={10} lg={9} xl={8}>
              <DatePicker
                disabled={searching}
                onChange={onChangeDate}
                format={'DD/MM/YYYY'}
                showToday={false}
                locale={locale}
                style={styles.searchItem}
                placeholder={
                  searching ? 'Búsqueda Completada' : 'Seleccionar Fecha'
                }
              />
            </Col>
            <Col xs={24} sm={6} md={4} lg={6} xl={8} style={styles.searcButton}>
              {searching ? (
                <Button onClick={searchModeOff}>{'REGRESAR'}</Button>
              ) : (
                <Button onClick={handleSearchSubmit}>{'BUSCAR'}</Button>
              )}
            </Col>
          </Row>
        </div>
      }>
      <CustomTable
        loading={loading}
        error={{
          isThereError: networkError,
          emptyWhat: 'network',
          onRetry: () => goToPage(1, searching),
        }}
        filters={{
          applyFilters: includeSeen,
          setApplyFilters: setIncludeSeen,
          filtersText: 'INCLUIR REPORTES YA VISTOS',
        }}
        pagination={{
          searching,
          page,
          totalItems,
          goToPage,
        }}
        columns={[
          {columnTitle: 'Título', sizes: {xs: 7, sm: 8, md: 8, lg: 7, xl: 8}},
          {
            columnTitle: 'Mensaje',
            sizes: {xs: 12, sm: 8, md: 10, lg: 11, xl: 10},
          },
          {columnTitle: 'Fecha', sizes: {xs: 0, sm: 5, md: 4}},
          {columnTitle: 'Detalle', sizes: {xs: 5, sm: 3, md: 2}},
        ]}
        items={
          includeSeen
            ? dataForTable
            : dataForTable.filter(
                (item) =>
                  item.aditionalData.readBy.includes(getUserId()) === false,
              )
        }
      />
      {showReportModal && (
        <ReportViewer item={modalItem} closeModal={hideModal} />
      )}
    </CommonLayout>
  );
};

export default Reports;
