import {Button, Col, Row} from 'antd';
import React, {useEffect, useMemo, useState} from 'react';
import {styles} from './styles';
import {UilHome, UilUser} from '@iconscout/react-unicons';
import {getUserHood, getUserToken} from '../../navigation/auth/auth';
import {BASE_URL, DEVELOPMENT, NETWORK_TIMEOUT} from '../../constants/network';
import axios from 'axios';
import LoadingContent from '../loading/LoadingContent';
import NoContent from '../empty_screens/empty_content/NoContent';
import numeral from 'numeral';
import {calculateMonthlyCost} from '../../utils/price';
import {BASE_GREEN, BASE_RED} from '../../constants/themeColors';
import {useHistory} from 'react-router';
import {PLANS} from '../../constants/Routes';
import BellCreator from './components/BellCreator';
import apiInstance from '../../network/axios';
import CustomTable from '../../molecules/CustomTable';
import FeatureIcon from '../../general_components/FeatureIcon/FeatureIcon';
import {resizeString} from '../../utils/strings';
import BellEditor from './components/BellEditor';
import {TBell} from './types';
import moment from 'moment';
const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [neighborhood, setNeighborhood] = useState<any>({});
  const [accountingData, setAccountingData] = useState({});
  const [totals, setTotals] = useState({income: 0, expense: 0});
  const [createUserModal, setCreateUserModal] = useState<boolean>(false);
  const [bells, setBells] = useState<any[]>([]);
  const [dataForTable, setDataForTable] = useState([]);
  const [selectedBell, setSelectedBell] = useState<TBell | null>(null);
  const history = useHistory();
  const totalOwners = useMemo(() => {
    let _totalOwners = 0;
    const homes = neighborhood?.homes ?? [];
    homes.forEach((home) => {
      _totalOwners += home.users.length;
    });
    return _totalOwners;
  }, [neighborhood]);

  const currentPlan = neighborhood?.current_plan ?? '';
  useEffect(() => {
    getNeighborhood();
  }, []);

  const getNeighborhood = async () => {
    setLoading(true);
    try {
      const userNeighborhoodID = getUserHood();
      const userToken = getUserToken();
      const url = `${BASE_URL}/neighborhoods/${userNeighborhoodID}`;
      const options = {
        headers: {
          'X-App': 'admin',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + userToken,
        },
        timeout: NETWORK_TIMEOUT,
      };
      const response = await axios.get(url, options);
      console.log('resp get NBH', response);
      if (response.data) {
        localStorage.setItem(
          'currentPlan',
          response?.data?.current_plan ?? 'FREE',
        );
        setNeighborhood(response.data);
        setLoading(false);
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      if (DEVELOPMENT) {
        console.log('Error getting neighborhood ', error?.response ?? error);
      }
      setLoading(false);
      setError(true);
    }
  };
  useEffect(() => {
    getDashboardData(firstDay, lastDay);
    getBells();
    // eslint-disable-next-line
  }, []);

  const date = new Date();
  const firstDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    1,
  ).toISOString();
  const lastDay = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
  ).toISOString();
  const getDashboardData = async (_minDate, _maxDate) => {
    try {
      const userNeighborhoodID = getUserHood();
      const userToken = getUserToken();
      const url = `${BASE_URL}/neighborhoods/${userNeighborhoodID}/accounting/totals?min_date=${_minDate}&max_date=${_maxDate}&type=balance`;
      const options = {
        headers: {
          'X-App': 'admin',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + userToken,
        },
        timeout: NETWORK_TIMEOUT,
      };
      const response = await axios.get(url, options);
      if (response.data.success) {
        console.log('Accounting data ', response.data.data);
        setAccountingData(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      if (DEVELOPMENT) {
        console.log('Error getting dashboardData', error?.response ?? error);
      }
      setLoading(false);
      setError(true);
    }
  };
  const getBells = async () => {
    try {
      setLoading(true);
      const url = `/users?roles=BELL`;
      const response = await apiInstance.get(url);
      setBells(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const formatData = (data: any) => {
    let totalIncomes = 0;
    let totalExpenses = 0;
    const labelsObjIncomes = {};
    const labelsObjExpenses = {};
    Object.keys(data).forEach((e: any) => {
      if (Object.keys(data[e]).includes('income')) {
        labelsObjIncomes[data[e].categoryName] = {
          income: data[e].income,
          color: data[e].color,
        };
      }
      if (Object.keys(data[e]).includes('expense')) {
        labelsObjExpenses[data[e].categoryName] = {
          expense: data[e].expense,
          color: data[e].color,
        };
      }
      totalIncomes += data[e]?.income ?? 0;
      totalExpenses += data[e]?.expense ?? 0;
    });
    setTotals({income: totalIncomes, expense: totalExpenses});
  };
  const formatDataForTable = (data = []) => {
    const formatedData = [];
    data.forEach((item, index) => {
      const statusIcon = <FeatureIcon feature="user" randomColor={index % 5} />;
      const formatedElement = [];

      formatedElement.push(
        <Row align="middle">
          {statusIcon}
          <Col span={18} style={styles.itemIcon}>
            {resizeString(`${item?.name ?? ' '} ${item?.last_name ?? ' '}`, 12)}
          </Col>
        </Row>,
      );
      formatedElement.push(item?.username ?? '');
      formatedElement.push(
        <Button
          block
          onClick={() => setSelectedBell(item)}
          style={styles.itemButton}>
          {'VER'}
        </Button>,
      );

      formatedData.push({
        data: formatedElement,
        aditionalData: {
          active: item.approved,
        },
      });
    });
    setDataForTable(formatedData);
  };
  useEffect(() => {
    formatDataForTable(bells);
  }, [bells]);
  useEffect(() => {
    formatData(accountingData);
  }, [accountingData]);

  if (loading) {
    return <LoadingContent />;
  }

  if (error) {
    return (
      <NoContent
        emptyWhat={'network'}
        withRetry
        onClickMethod={() => {
          setError(false);
          setLoading(true);
          getNeighborhood();
        }}
      />
    );
  }

  let plan = 'Básico';
  if (currentPlan === PLANS.ADMIN) {
    plan = 'Administración';
  }
  if (currentPlan === PLANS.VIGILANCE) {
    plan = 'Vigilancia';
  }
  if (currentPlan === PLANS.SECURITY) {
    plan = 'Seguridad';
  }
  return (
    <div style={styles.mainContainer}>
      <Row
        gutter={[16, {xs: 16, sm: 0, md: 0, lg: 0}]}
        style={{backgroundColor: 'transparent'}}>
        <Col xs={24} sm={10} md={11} style={styles.bold}>
          {'Tus datos'}
        </Col>
      </Row>
      <Row>
        <Col span={24} style={styles.rowSeparator} />
      </Row>
      {(currentPlan === PLANS.SECURITY || currentPlan === PLANS.VIGILANCE) &&
        bells.length === 0 && (
          <Row>
            <Col span={24} style={styles.warningContainer}>
              <p style={styles.p}>
                <strong>Advertencia:</strong> Aún no has registrado a un usuario
                para el registro de entradas con "Portos Bell". Lo puedes hacer
                <span
                  onClick={() => setCreateUserModal(true)}
                  style={styles.link}>
                  {' aquí.'}
                </span>
              </p>
            </Col>
          </Row>
        )}
      <Row>
        <Col span={24} style={styles.rowSeparator} />
      </Row>

      <Row gutter={[15, 15]} align="top">
        <Col span={24}>
          <Row gutter={[15, 15]}>
            <Col xs={24} md={12} lg={8}>
              <div style={styles.paper}>
                <div
                  style={{...styles.iconContainer, backgroundColor: '#A7E6F1'}}>
                  <UilHome size={30} color={'white'} />
                </div>
                <p style={styles.normal}>{'Tu vecindario tiene'}</p>
                <p style={{...styles.bold, ...styles.big}}>
                  {neighborhood?.homes?.length ?? 0}
                </p>
                <p style={styles.normal}>{'casas registradas'}</p>
                <Button
                  onClick={() => {
                    history.push('/homes');
                  }}>
                  VER
                </Button>
              </div>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <div style={styles.paper}>
                <div style={styles.iconContainer}>
                  <UilUser size={30} color={'white'} />
                </div>
                <p style={styles.normal}>{'Tu vecindario tiene'}</p>
                <p style={{...styles.bold, ...styles.big}}>{totalOwners}</p>
                <p style={styles.normal}>{'usuarios activos'}</p>
                <Button
                  onClick={() => {
                    history.push('/owners');
                  }}>
                  VER
                </Button>
              </div>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <div style={styles.paper}>
                <p style={styles.normal}>{'Tus ingresos'}</p>
                <p style={{...styles.bold, color: BASE_GREEN}}>{`+ ${numeral(
                  totals.income,
                ).format('$0,0.00')}`}</p>
                <p style={styles.normal}>{'Tus egresos'}</p>
                <p style={{...styles.bold, color: BASE_RED}}>{`- ${numeral(
                  totals.expense,
                ).format('$0,0.00')}`}</p>
                <Button
                  onClick={() => {
                    history.push('/accounting');
                  }}>
                  VER
                </Button>
              </div>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <div style={styles.paper}>
                <p style={styles.normal}>
                  {'Tu siguiente pago se aplicará el día'}
                </p>
                <p style={styles.bold}>
                  {currentPlan === PLANS.FREE
                    ? 'N/A'
                    : moment(neighborhood?.plan_payment_date ?? '')
                        .add(1, 'months')
                        .format('LL')}
                </p>
                <Button
                  onClick={() => {
                    history.push('/subscription');
                  }}>
                  VER
                </Button>
              </div>
            </Col>
            <Col xs={24} md={12} lg={8}>
              <div style={styles.paper}>
                <p style={styles.normal}>{'Tu plan actual es'}</p>
                <p style={{...styles.bold, color: '#39C3DD'}}>{plan}</p>
                <p style={styles.normal}>{'el costo de tu plan es'}</p>
                <p style={{...styles.bold, color: '#39C3DD'}}>
                  {currentPlan === PLANS.FREE
                    ? numeral(0).format('$0,0.00') + '/mes'
                    : numeral(
                        calculateMonthlyCost(
                          neighborhood?.homes ?? [],
                          currentPlan,
                        ),
                      ).format('$0,0.00') + ' MXN / mes'}
                </p>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Col span={24} style={styles.rowSeparator} />
      <Col xs={24} sm={10} md={11} style={styles.bold}>
        {'Tus Timbres'}
      </Col>
      <Col span={24} style={styles.rowSeparator} />
      <CustomTable
        loading={loading}
        error={{isThereError: error, emptyWhat: 'network', onRetry: getBells}}
        columns={[
          {columnTitle: 'Nombre', sizes: {xs: 13, sm: 8}},
          {columnTitle: 'Mail', sizes: {xs: 0, sm: 12}},
          {columnTitle: 'Acción', sizes: {xs: 11, sm: 4}},
        ]}
        items={dataForTable}
      />
      <BellCreator
        visible={createUserModal}
        onClose={() => setCreateUserModal(false)}
        onSuccess={() => {
          setCreateUserModal(false);
          getBells();
        }}
      />
      <BellEditor
        visible={selectedBell !== null}
        bell={selectedBell}
        onClose={() => setSelectedBell(null)}
        onSuccess={() => {
          setSelectedBell(null);
          getBells();
        }}
      />
    </div>
  );
};
export default Dashboard;
