import React, {useEffect, useState} from 'react';
import {Col, Row, Button} from 'antd';
import UilSignAlt from '@iconscout/react-unicons/icons/uil-sign-alt';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';

import {getUserHood, getStreetsName} from '../../navigation/auth/auth';
import FeatureIcon from '../../general_components/FeatureIcon/FeatureIcon';
import StreetCreator from './components/StreetCreator';
import StreetEditor from './components/StreetEditor';
import {resizeString} from '../../utils/strings';
import CommonLayout from '../../layouts/CommonLayout';
import {StreetsStyles as styles} from './styles';
import CustomList from '../../molecules/CustomList';
import apiInstance from '../../network/axios';

const Streets = (props) => {
  const [createStreetModal, setCreateStreetModal] = useState(false);
  const [viewStreetModal, setViewStreetModal] = useState<any>(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(10);
  const [dataForTable, setDataForTable] = useState([]);

  useEffect(() => {
    retrieveStreetsData();
  }, []);

  useEffect(() => {
    formatDataForTable(data);
  }, [data]);

  const goToPage = (pageNum) => {
    setPage(pageNum);
    setError(false);
    retrieveStreetsData(pageNum);
  };

  const retrieveStreetsData = async (pageNum = 1) => {
    try {
      setLoading(true);
      const userNeighborhoodID = getUserHood();
      const url = `/neighborhoods/${userNeighborhoodID}/streets?limit=10&page=${pageNum}`;
      const response = await apiInstance.get(url);
      const result = response.data;
      setTotalItems(
        response.headers['x-paginate-total']
          ? parseInt(response.headers['x-paginate-total'])
          : 10,
      );
      setData(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const formatDataForTable = (data = []) => {
    const formatedData = [];
    data.forEach((item, index) => {
      const statusIcon = (
        <FeatureIcon feature="street" randomColor={index % 5} />
      );
      const formatedElement = [];
      formatedElement.push(
        <Row align="middle">
          {statusIcon}
          <Col span={18} style={styles.itemIcon}>
            <p
              style={{
                padding: 0,
                margin: 0,
                fontWeight: 'bold',
              }}>
              {resizeString(item?.name ?? ' ', 15)}
            </p>
            <p style={{padding: 0, margin: 0}}> </p>
          </Col>
        </Row>,
      );
      formatedElement.push(
        <Button
          block
          onClick={() => setViewStreetModal(item)}
          style={styles.itemButton}>
          {'EDITAR'}
        </Button>,
      );

      formatedData.push({
        data: formatedElement,
        aditionalData: {
          active: true,
        },
      });
    });
    setDataForTable(formatedData);
  };

  const closeStreetModalAndRefresh = () => {
    setPage(1);
    goToPage(1);
    retrieveStreetsData();
    setCreateStreetModal(false);
    setViewStreetModal(false);
  };

  return (
    <CommonLayout
      title={getStreetsName() + 's'}
      buttonAdd={
        <Button
          style={styles.container}
          onClick={() => setCreateStreetModal(true)}>
          <Row justify="center" align="middle">
            <Col xs={24} sm={0}>
              <div style={styles.iconContainer}>
                <UilPlus size="16" color="#FFFFFF" />
                <UilSignAlt size="18" color="#FFFFFF" />
              </div>
            </Col>
            <Col xs={0} sm={24}>
              <div style={styles.largeButon}>
                <div style={styles.largeButonIcon}>
                  <UilSignAlt size="18" color="#FFFFFF" />
                </div>
                <div style={styles.title}>
                  {'AGREGAR ' + getStreetsName().toUpperCase()}
                </div>
              </div>
            </Col>
          </Row>
        </Button>
      }>
      <CustomList
        loading={loading}
        error={{
          isThereError: error,
          emptyWhat: 'network',
          onRetry: () => goToPage(1),
        }}
        filters={{
          applyFilters: null,
          setApplyFilters: null,
          filtersText: null,
        }}
        pagination={{
          searching: false,
          page,
          totalItems,
          goToPage,
        }}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 1,
          xl: 2,
          xxl: 2,
        }}
        emptyText={`Aún no hay ${getStreetsName()}s`}
        columns={[
          {
            columnTitle: '',
            sizes: {xs: 17, lg: 18, xl: 19},
          },
          {columnTitle: '', sizes: {xs: 7, lg: 6, xl: 5}},
        ]}
        items={dataForTable}
      />
      {createStreetModal && (
        <StreetCreator
          visible={createStreetModal}
          closeModalTrigger={() => setCreateStreetModal(false)}
          closeRefreshAfterCreate={closeStreetModalAndRefresh}
          onFirstCreate={props?.onFirstCreate ?? null}
        />
      )}
      {viewStreetModal && (
        <StreetEditor
          visible={viewStreetModal !== false}
          closeModalTrigger={() => setViewStreetModal(false)}
          closeRefreshAfterCreate={closeStreetModalAndRefresh}
          streetObject={viewStreetModal}
        />
      )}
    </CommonLayout>
  );
};
export default Streets;
