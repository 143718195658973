import {Button, Col, FormInstance, Modal, Row} from 'antd';
import React, {useCallback, useRef, useState} from 'react';
import {PLANS} from '../../../constants/Routes';
import FeatureIcon from '../../../general_components/FeatureIcon/FeatureIcon';
import {getUserHood} from '../../../navigation/auth/auth';
import apiInstance from '../../../network/axios';
import NoContent from '../../empty_screens/empty_content/NoContent';
import LoadingContent from '../../loading/LoadingContent';
import {TBell} from '../types';
import BellForm from './BellForm';

const BellCreator = ({
  visible,
  onClose,
  onSuccess,
}: {
  visible: boolean;
  onClose: () => void;
  onSuccess: () => void;
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorType, setErrorType] = useState<string>('network');
  const formRef = useRef<FormInstance>();

  const validateForm = useCallback(async () => {
    const values = await formRef.current.validateFields();
    const currentPlan = localStorage.getItem('currentPlan');
    const createBell = async (form_object: TBell) => {
      try {
        const userHood = getUserHood();
        const url = `/neighborhoods/${userHood}/users`;
        const body: TBell = {
          role: 'BELL',
          name: form_object.name,
          last_name: form_object.last_name,
          password: form_object.password,
          phone_number: form_object.phone_number,
          username: form_object.username,
          approved: true,
          sub_role:
            currentPlan === PLANS.VIGILANCE ? 'MANUAL' : form_object.sub_role,
        };
        await apiInstance.post(url, body);
        setLoading(false);
        onSuccess();
      } catch (error) {
        if (error.response) {
          if (
            error.response.data.message.includes(
              'E11000 duplicate key error collection:',
            )
          ) {
            setErrorType('userExist');
          }
        }
        setLoading(false);
        setIsError(true);
      }
    };
    setLoading(true);
    createBell(values);
  }, [formRef, onSuccess]);

  return (
    <Modal
      centered
      visible={visible}
      onCancel={onClose}
      okText="CREAR"
      cancelText="CANCELAR"
      maskClosable={false}
      destroyOnClose={true}
      closable={!loading}
      footer={[
        <Button key="back" disabled={loading} onClick={onClose}>
          {'CANCELAR'}
        </Button>,
        isError ? (
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={() => setIsError(false)}>
            {'REINTENTAR'}
          </Button>
        ) : (
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={validateForm}>
            {'CREAR'}
          </Button>
        ),
      ]}
      title={
        <Row>
          <Col xs={5} sm={4} md={3}>
            <FeatureIcon feature="user" />
          </Col>
          <Col xs={19} sm={20} md={21} style={{fontWeight: 'bold'}}>
            {'Crear Timbre'}
          </Col>
        </Row>
      }>
      {loading ? (
        <LoadingContent />
      ) : isError ? (
        <NoContent emptyWhat={errorType} />
      ) : (
        <Row>
          <Col span={24}>
            <BellForm editMode formRef={formRef} />
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default BellCreator;
