import {CSSProperties} from 'react';
import {
  BASE_DARK_GRAY,
  BASE_GRAY,
  BASE_WHITE,
  BRAND_TURQUOISE,
  UTILITY_RED,
} from '../../constants/themeColors';
export const SubscriptionStyles: Record<string, CSSProperties> = {
  container: {padding: '6.25% 4.166%'},
  bold: {fontSize: '20px', fontWeight: 'bold', margin: 0, padding: 0},
  paper: {
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderColor: '#70707078',
    borderWidth: 1,
    borderRadius: 5,
    marginTop: 40,
  },
  paypalContainer: {
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderColor: '#70707078',
    borderWidth: 1,
    borderRadius: 5,
    marginTop: 40,
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rowSeparator: {height: '32px'},
  dashboardListTitle: {
    fontSize: '1.3em',
    fontWeight: '500',
    padding: 0,
    margin: 0,
  },
  dashboardList: {
    display: 'flex',
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: '#D9D9D9',
    borderStyle: 'solid',
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    padding: 15,
    minHeight: 90,
  },
  addButtonContainer: {
    padding: '0px',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  item: {width: '100%'},
  configTablePaginationRow: {
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderColor: '#70707078',
    borderWidth: 1,
    borderRadius: 0,
    marginTop: 36,
    borderBottomColor: 'white',
    padding: 5,
  },
  configTablePaginationColumnCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 10px',
    fontWeight: '600',
  },
  configTablePaginationColumnEnd: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0px 10px',
    fontWeight: '600',
  },
  configPaginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0px 0px',
  },
  configTableHeader: {
    borderStyle: 'solid',
    borderColor: '#70707078',
    borderWidth: 1,
    borderRadius: 0,
    borderBottomColor: 'white',
    padding: 10,
  },
  configList: {
    minHeight: '50px',
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderColor: '#70707078',
    borderWidth: 1,
    borderRadius: 0,
    paddingLeft: 10,
    paddingRight: 10,
  },
  button: {borderColor: BASE_GRAY},
  link: {
    backgroundColor: BRAND_TURQUOISE,
    color: BASE_WHITE,
    fontWeight: 'bolder',
    borderColor: 'transparent',
    padding: '6px 30px',
    borderRadius: 5,
  },
  row: {
    borderColor: 'transparent',
    borderStyle: 'solid',
    borderWidth: 1,
    borderBottomColor: BASE_GRAY,
    padding: '20px 30px',
  },
  cardStyle: {fontWeight: 500, fontSize: 15},
  cardDeleteButtonContainer: {display: 'flex', justifyContent: 'flex-end'},
  deleteButton: {
    paddingTop: '4px',
    backgroundColor: UTILITY_RED,
  },
};

export const PaymentDetailStyles: Record<string, CSSProperties> = {
  iconContainer: {
    height: '40px',
    width: '40px',
    borderRadius: '20px',
  },
  iconDiv: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemIcon: {color: BASE_WHITE},
  title: {
    fontWeight: 'bold',
  },
  button: {borderColor: '#545454'},
  datePicker: {width: '100%'},
  filterText: {
    fontSize: '14px',
    fontWeight: '500',
    color: BASE_DARK_GRAY,
  },
  filterButton: {display: 'flex', justifyContent: 'flex-end'},

  paragraph: {marginLeft: '20px', marginRight: '20px'},
  icon: {
    width: 40,
    height: 40,
    borderRadius: 20,
    backgroundColor: '#44C3DD',
    padding: 5,
  },
  modalTitle: {
    fontSize: 20,
    color: 'gray',
    marginTop: 10,
  },
};
