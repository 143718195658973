import {CSSProperties} from 'react';

export const RecordsStyles: Record<string, CSSProperties> = {
  itemIcon: {
    fontWeight: 'bold',
    paddingLeft: 10,
  },
  button: {borderColor: '#545454'},
  datePicker: {width: '100%'},
  filterButton: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
};
