import React, {useReducer} from 'react';
//antd css import, becomes available for all components when in root
import 'antd/dist/antd.css';
//local imports
import Router from './navigation/router/Router';
import {AuthContext} from './context/AuthContext';
import {AuthContextType} from './types/contextTypes';
import {getHoodAvatar} from './navigation/auth/auth';

function App() {
  const hoodAvatar = getHoodAvatar();
  const [state, dispatch] = useReducer(
    (prevState, action) => {
      switch (action.type) {
        case 'SET_GLOBAL_STATE':
          return {
            ...prevState,
            ...action.payload,
          };
        default:
          return state;
      }
    },
    {
      levelConfig: 0,
      showMenu: false,
      showTopBar: true,
      hoodImagePath: hoodAvatar,
    },
  );
  const authContext: AuthContextType = {
    setGlobalState: (item) =>
      dispatch({type: 'SET_GLOBAL_STATE', payload: item}),
    state,
  };
  return (
    <AuthContext.Provider value={authContext}>
      <Router />
    </AuthContext.Provider>
  );
}

export default App;
