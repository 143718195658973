import React from 'react';
import {Button} from 'antd';
import * as Unicons from '@iconscout/react-unicons';
import {BASE_WHITE, DISABLED_GRAY} from '../constants/themeColors';

interface ICircleButton {
  disabled: boolean;
  onClick: () => void;
  clickedMode?: boolean;
  activeColor: string;
  variant: 'save' | 'del' | 'view' | 'edit' | 'genericEdit' | 'viewHome';
}
const CircleButton = (props: ICircleButton) => {
  const icons = {
    save: <Unicons.UilCheck size="20" color={BASE_WHITE} />,
    del: <Unicons.UilTrashAlt size="20" color={BASE_WHITE} />,
    view: props.clickedMode ? (
      <Unicons.UilEyeSlash size="20" color={BASE_WHITE} />
    ) : (
      <Unicons.UilEye size="20" color={BASE_WHITE} />
    ),
    edit: props.clickedMode ? (
      <Unicons.UilEnvelopeTimes size="20" color={BASE_WHITE} />
    ) : (
      <Unicons.UilEnvelopeEdit size="20" color={BASE_WHITE} />
    ),
    genericEdit: props.clickedMode ? (
      <Unicons.UilTimes size="20" color={BASE_WHITE} />
    ) : (
      <Unicons.UilEdit size="20" color={BASE_WHITE} />
    ),
    viewHome: props.clickedMode ? (
      <Unicons.UilArrowLeft size="20" color={BASE_WHITE} />
    ) : (
      <Unicons.UilUserLocation size="22" color={BASE_WHITE} />
    ),
  };
  return (
    <Button
      disabled={props.disabled}
      size="large"
      shape="circle"
      onClick={props.onClick}
      style={{
        paddingTop: 4,
        backgroundColor: props.disabled ? DISABLED_GRAY : props.activeColor,
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {icons[props.variant]}
      </div>
    </Button>
  );
};

export default CircleButton;
