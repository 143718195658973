import {UilPlus} from '@iconscout/react-unicons';
import {Button, Col, DatePicker, Input, Row, Select} from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import Axios from 'axios';
import numeral from 'numeral';
import React, {useState} from 'react';
import {
  BASE_URL,
  DEVELOPMENT,
  NETWORK_TIMEOUT,
} from '../../../constants/network';
import {Modal} from '../../../molecules/Modal';
import {
  getHomesName,
  getUserHood,
  getUserToken,
} from '../../../navigation/auth/auth';
import NoContent from '../../empty_screens/empty_content/NoContent';
import {styles} from './styles';

const AddEntry = (props) => {
  const homes = props?.homes ?? [];
  const [concept, setConcept] = useState(props?.concept ?? '');
  const [amount, setAmount] = useState(props?.amount ?? '');
  const [categoryId, setCategoryId] = useState(props?.category ?? '');
  const [date, setDate] = useState(null);
  const [validating, setValidating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [homeId, setHomeId] = useState(props?.homeId ?? '');

  const handleCreateEntry = () => {
    if (
      concept === '' ||
      amount === '' ||
      categoryId === '' ||
      date === null ||
      (homes.length > 0 && homeId === '')
    ) {
      setValidating(true);
      return;
    }
    createEntry();
    setLoading(true);
    setError(false);
  };
  const resetForm = () => {
    setLoading(false);
    setError(false);
    setConcept('');
    setAmount('');
    setDate(null);
    setValidating(false);
  };
  const createEntry = async () => {
    try {
      const userNeighborhoodID = getUserHood();
      const userToken = getUserToken();
      const url = `${BASE_URL}/neighborhoods/${userNeighborhoodID}/accounting/entry`;
      const options = {
        headers: {
          'X-App': 'admin',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + userToken,
        },
        timeout: NETWORK_TIMEOUT,
      };
      const body: any = {
        type: props.type,
        description: concept,
        category_id: categoryId,
        amount: numeral(amount).value(),
        date,
      };
      if (homes.length > 0 || props.withOutHomes) {
        body.home_id = homeId;
      }
      const response = await Axios.post(url, body, options);

      if (response.data.success) {
        resetForm();
        props.onCancel();
        props.reload();
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      if (DEVELOPMENT) {
        console.log('Error creating entry', error?.response ?? error);
      }
      setLoading(false);
      setError(true);
    }
  };
  const onChangeDate = (date) => {
    setDate(date);
  };
  const {Option} = Select;
  return (
    <Modal
      onOk={handleCreateEntry}
      icon={
        <div
          style={{
            width: 40,
            height: 40,
            borderRadius: 20,
            backgroundColor: props.iconColor ?? 'red',
            padding: 5,
          }}>
          <UilPlus size="30" color="#FFFFFF" />
        </div>
      }
      title={props.title}
      visible={props.visible}
      onCancel={props.onCancel}
      loading={loading}
      body={
        <div>
          {error ? (
            <NoContent
              emptyWhat={'network'}
              withRetry
              onClickMethod={() => {
                setError(false);
                setLoading(true);
                handleCreateEntry();
              }}
            />
          ) : (
            <Row>
              <Col span={24}>
                <h3 style={styles.h3}>{'Concepto'}</h3>
                <Input
                  value={concept}
                  onChange={(value) => setConcept(value.target.value)}
                />
                {validating && concept === '' && (
                  <p style={styles.validation}>El concepto es requerido.</p>
                )}
                <h3 style={styles.h3}>{'Cantidad'}</h3>
                <Input
                  prefix={'$'}
                  value={amount}
                  onChange={(value) => setAmount(value.target.value)}
                />
                {validating && (amount === '' || isNaN(parseFloat(amount))) && (
                  <p style={styles.validation}>Ingrese una cantidad valida.</p>
                )}
                <h3 style={styles.h3}>{'Fecha'}</h3>
                <DatePicker
                  onChange={onChangeDate}
                  format={'DD/MM/YYYY'}
                  showToday={false}
                  locale={locale}
                  style={styles.item}
                />
                {validating && date === null && (
                  <p style={styles.validation}>La fecha es requerida.</p>
                )}
                <h3 style={styles.h3}>{'Categoría'}</h3>
                <Select
                  style={styles.item}
                  placeholder={'Categoria'}
                  onChange={(value) => {
                    setCategoryId(value);
                  }}>
                  {props.categories.map((item) => {
                    return (
                      <Option key={item._id} value={item._id}>
                        {item.name}
                      </Option>
                    );
                  })}
                </Select>
                {validating && categoryId === '' && (
                  <p style={styles.validation}>La categoría es requerida.</p>
                )}
                {(homes.length > 0 || props.withOutHomes) && (
                  <h3 style={styles.h3}>{getHomesName()}</h3>
                )}
                {(homes.length > 0 || props.withOutHomes) && (
                  <Select
                    style={styles.item}
                    placeholder={
                      props.homeId
                        ? props.homeName
                        : `Selecione ${getHomesName()}`
                    }
                    disabled={props.homeId !== undefined}
                    onChange={(value) => {
                      setHomeId(value);
                    }}>
                    {homes.map((item) => {
                      return (
                        <Option key={item._id} value={item._id}>
                          {`${getHomesName()} ${item.name}`}
                        </Option>
                      );
                    })}
                  </Select>
                )}
                {homes.length > 0 && validating && homeId === '' && (
                  <p
                    style={
                      styles.validation
                    }>{`${getHomesName()} es requerid@.`}</p>
                )}
              </Col>
            </Row>
          )}
        </div>
      }
      footer={[
        <Button key="back" disabled={props.loading} onClick={props.onCancel}>
          {'CANCELAR'}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={handleCreateEntry}>
          {'CREAR'}
        </Button>,
      ]}
    />
  );
};

export default AddEntry;
