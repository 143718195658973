/* eslint-disable react-hooks/exhaustive-deps */
import {Button, Col, DatePicker, Row, Select} from 'antd';
import React, {useEffect, useState} from 'react';
import {getHomesName, getUserHood} from '../../navigation/auth/auth';
import {ReservationsStyles as styles} from './styles';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';
import {formatHomeNumber, resizeString} from '../../utils/strings';
import ReservationDetail from './components/ReservationDetail';
import apiInstance from '../../network/axios';
import CommonLayout from '../../layouts/CommonLayout';
import CustomTable from '../../molecules/CustomTable';
import {isExpired} from '../../utils/locale/chronos';

const Reservations = () => {
  const date = new Date();
  const firstDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    1,
  ).toISOString();
  const lastDay = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
  ).toISOString();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [searching, setSearching] = useState(false);
  const [searchingFor, setSearchingFor] = useState([]);
  const [location, setLocation] = useState('ALL');
  const [areas, setAreas] = useState([]);
  const [areasList, setAreasList] = useState({});
  const [currentReservation, setCurrentReservation] = useState(null);
  const [dataForTable, setDataForTable] = useState([]);
  const [includeExpired, setIncludeExpired] = useState(true);

  useEffect(() => {
    getInitialData();
  }, []);

  useEffect(() => {
    formatDataForTable(reservations);
  }, [reservations]);

  const getInitialData = async () => {
    await getCommonAreas();
    getReservations(firstDay, lastDay);
  };
  const getCommonAreas = async () => {
    try {
      setLoading(true);
      const userNeighborhoodID = getUserHood();
      const url = `/neighborhoods/${userNeighborhoodID}/common_areas`;
      const response = await apiInstance.get(url);
      let _areasList = {};
      response.data.common_area.forEach((element) => {
        _areasList[element._id] = {name: element.name, icon: element.icon};
      });
      setAreasList(_areasList);
      setAreas(response.data.common_area);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const getReservations = async (_minDate, _maxDate, _location = 'ALL') => {
    try {
      setLoading(true);
      const userNeighborhoodID = getUserHood();
      let url = `/neighborhoods/${userNeighborhoodID}/reservations?dateStart=${_minDate}&dateEnd=${_maxDate}`;
      if (_location !== 'ALL') {
        url += `&location=${location}`;
      }

      const response = await apiInstance.get(url);
      if (response.data.success) {
        setReservations(response.data.reservations);
        setLoading(false);
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };
  const onChangeDate = (dates) => {
    if (dates?.length > 1) {
      setSearchingFor([
        new Date(dates[0]._d).toISOString(),
        new Date(dates[1]._d).toISOString(),
      ]);
    }
  };
  const searchModeOff = () => {
    setSearching(false);
    setSearchingFor([]);
    setLoading(true);
    setLocation('ALL');
    getReservations(firstDay, lastDay);
  };
  const handleSearchSubmit = () => {
    setSearching(true);
    setLoading(true);
    getReservations(
      searchingFor[0] ?? firstDay,
      searchingFor[1] ?? lastDay,
      location,
    );
  };

  const onRetry = () => {
    setError(false);
    setLoading(true);
    getReservations(firstDay, lastDay);
  };

  const formatDataForTable = (data = []) => {
    const formatedData = [];

    data.forEach((item, index) => {
      const formatedElement = [];
      const expired = isExpired(item.date);
      formatedElement.push(moment(item.date).format('L'));
      formatedElement.push(resizeString(areasList[item.location].name));
      formatedElement.push(
        `${moment(item.date).format('LT')}-${moment(item.endTime).format(
          'LT',
        )} hrs.`,
      );
      formatedElement.push(formatHomeNumber(item?.home));
      formatedElement.push(
        `${item?.owner?.name ?? ''} ${item?.owner?.last_name ?? ''}`,
      );
      formatedElement.push(
        <Button
          block
          onClick={() => {
            setCurrentReservation({
              area: areasList[item.location],
              date: item.date,
              time: `${moment(item.date).format('LT')}-${moment(
                item.endTime,
              ).format('LT')} hrs.`,
              _id: item._id,
              home: formatHomeNumber(item?.home),
              owner: `${item?.owner?.name ?? ''} ${
                item?.owner?.last_name ?? ''
              }`,
            });
          }}>
          {'VER'}
        </Button>,
      );

      formatedData.push({
        data: formatedElement,
        aditionalData: {
          active: !expired,
        },
      });
    });
    setDataForTable(formatedData);
  };

  const {RangePicker} = DatePicker;

  return (
    <CommonLayout
      title={'Calendario de reservaciones'}
      filters={
        <div style={{width: '100%'}}>
          <Row style={styles.rowSeparator} />
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={12} md={8}>
              <Select
                disabled={searching}
                style={{width: '100%'}}
                placeholder={'Áreas'}
                value={location}
                onChange={(value) => setLocation(value)}
                options={[
                  {value: 'ALL', label: 'Todas las áreas'},
                  ...areas.map((item) => ({value: item._id, label: item.name})),
                ]}
              />
            </Col>
            <Col xs={24} sm={12} md={8}>
              <RangePicker
                disabled={searching}
                onChange={onChangeDate}
                format={'DD/MM/YYYY'}
                locale={locale}
                placeholder={[
                  searching ? 'Búsqueda Completada' : 'Seleccionar Fecha',
                  searching ? 'Búsqueda Completada' : 'Seleccionar Fecha',
                ]}
                value={[
                  moment(searchingFor.length > 1 ? searchingFor[0] : firstDay),
                  moment(searchingFor.length > 1 ? searchingFor[1] : lastDay),
                ]}
              />
            </Col>
            <Col xs={0} md={4} lg={5}></Col>
            <Col xs={12} md={4} lg={3}>
              {searching ? (
                <Button onClick={searchModeOff}>{'REGRESAR'}</Button>
              ) : (
                <Button
                  disabled={searchingFor.length === 0 && location === 'ALL'}
                  onClick={handleSearchSubmit}>
                  {'BUSCAR'}
                </Button>
              )}
            </Col>
          </Row>
        </div>
      }>
      <CustomTable
        loading={loading}
        error={{isThereError: error, emptyWhat: 'network', onRetry}}
        filters={{
          applyFilters: includeExpired,
          setApplyFilters: setIncludeExpired,
          filtersText: 'INCLUIR RESERVACIONES EXPIRADAS',
        }}
        pagination={{
          searching: null,
          page: null,
          totalItems: null,
          goToPage: null,
        }}
        columns={[
          {
            columnTitle: 'Fecha',
            sizes: {xs: 16, sm: 4},
          },
          {
            columnTitle: 'Área',
            sizes: {xs: 0, sm: 4},
          },
          {columnTitle: 'Horario', sizes: {xs: 0, sm: 4}},
          {columnTitle: getHomesName(), sizes: {xs: 0, sm: 4}},
          {columnTitle: 'Reservó', sizes: {xs: 0, sm: 4}},
          {columnTitle: 'Detalle', sizes: {xs: 8, sm: 4}},
        ]}
        items={
          includeExpired
            ? dataForTable
            : dataForTable.filter((item) => item.aditionalData.active)
        }
      />
      <ReservationDetail
        visible={currentReservation !== null}
        item={currentReservation}
        onCancel={() => setCurrentReservation(null)}
        onReload={() => getReservations(firstDay, lastDay)}
      />
    </CommonLayout>
  );
};

export default Reservations;
