import React from 'react';
import {Button} from 'antd';
import * as Unicons from '@iconscout/react-unicons';
import {BASE_GRAY} from '../../../constants/themeColors';
import {WarningOutlined} from '@ant-design/icons';

export interface INoContent {
  emptyWhat?: string;
  withRetry?: boolean;
  onClickMethod?: () => void;
}

const NoContent = ({emptyWhat, onClickMethod, withRetry}: INoContent) => {
  const iconSize = '60px';
  const disabledTheme = BASE_GRAY;

  const displayMessage =
    emptyWhat === 'records'
      ? 'Aún no hay registros'
      : emptyWhat === 'messages'
      ? 'No hay mensajes'
      : emptyWhat === 'reports'
      ? 'No hay Reportes'
      : emptyWhat === 'disabled-service'
      ? 'Servicio no disponible. Contacte con su administrador de Portos para habilitarlo.'
      : emptyWhat === 'polls'
      ? 'No hay encuestas'
      : emptyWhat === 'streets'
      ? 'Aún no hay registros'
      : emptyWhat === 'homes'
      ? 'Aún no hay registros'
      : emptyWhat === 'users'
      ? 'No hay usuarios registrados'
      : emptyWhat === 'network'
      ? 'Error de red'
      : emptyWhat === 'readers'
      ? 'Nadie ha leido este mensaje'
      : emptyWhat === 'doors'
      ? 'No hay puertas registradas'
      : emptyWhat === 'cams'
      ? 'No hay cámaras registradas'
      : emptyWhat === 'userExist'
      ? 'El usuario ya existe, favor de intentar con otro correo.'
      : emptyWhat === 'owners'
      ? 'No hay usuarios registrados'
      : emptyWhat === 'neighborhoodCreatedAlredy'
      ? 'Solo se puede crear una vez.'
      : emptyWhat === 'passwordCreated'
      ? '¡Ocurrio un error al crear la contraseña! es posible que la contraseña ya ha sido creada.'
      : emptyWhat === 'verifyEmail'
      ? '¡Ocurrio un error al verificar el correo! es posible que ya se haya validado.'
      : emptyWhat === 'feedbacks'
      ? 'Aun no hay feedbacks'
      : emptyWhat === 'deleteCategory'
      ? 'No se puede eliminar esta categoría debido a que tiene registros de ingresos/egresos relacionados.'
      : emptyWhat === 'InvalidCamera'
      ? 'No se pudo dar de alta la cámara.\nVerificar url, usuario, contraseña y si requiere de autenticación.'
      : emptyWhat === 'hoods'
      ? 'No hay vecindarios registrados'
      : emptyWhat === 'refreshSesion'
      ? 'Algo salió mal. Por favor inicie sesión de nuevo.'
      : emptyWhat === 'subscription'
      ? 'Actualizando datos de suscripción. favor de reintentar en 30 segundos'
      : emptyWhat === 'payment'
      ? 'Algo salió mal al guardar la información de su suscripción.'
      : 'Algo salió mal. Intenta de nuevo.';

  const renderIcon =
    emptyWhat === 'records' ? (
      <Unicons.UilTrafficBarrier size={iconSize} color={disabledTheme} />
    ) : emptyWhat === 'messages' ? (
      <Unicons.UilEnvelopeTimes size={iconSize} color={disabledTheme} />
    ) : emptyWhat === 'feedbacks' ? (
      <Unicons.UilFeedback size={iconSize} color={disabledTheme} />
    ) : emptyWhat === 'polls' ? (
      <Unicons.UilFileTimesAlt size={iconSize} color={disabledTheme} />
    ) : emptyWhat === 'homes' ? (
      <Unicons.UilBuilding size={iconSize} color={disabledTheme} />
    ) : emptyWhat === 'streets' ? (
      <Unicons.UilSignAlt size={iconSize} color={disabledTheme} />
    ) : emptyWhat === 'users' ? (
      <Unicons.UilUserCircle size={iconSize} color={disabledTheme} />
    ) : emptyWhat === 'network' ? (
      <Unicons.UilWifiSlash size={iconSize} color={disabledTheme} />
    ) : emptyWhat === 'readers' ? (
      <Unicons.UilUsersAlt size={iconSize} color={disabledTheme} />
    ) : emptyWhat === 'owners' ? (
      <Unicons.UilUsersAlt size={iconSize} color={disabledTheme} />
    ) : emptyWhat === 'doors' ? (
      <Unicons.UilKeyholeCircle size={iconSize} color={disabledTheme} />
    ) : emptyWhat === 'cams' ? (
      <Unicons.UilVideo size={iconSize} color={disabledTheme} />
    ) : emptyWhat === 'hoods' ? (
      <Unicons.UilBuilding size={iconSize} color={disabledTheme} />
    ) : (
      <WarningOutlined style={{color: disabledTheme, fontSize: iconSize}} />
    );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}>
      {renderIcon}
      <p style={{color: disabledTheme, fontSize: '16px', marginTop: '15px'}}>
        {displayMessage}
      </p>
      {withRetry && (
        <div>
          <Button type="primary" onClick={onClickMethod}>
            {'Reintentar'}
          </Button>
        </div>
      )}
    </div>
  );
};

export default NoContent;
