/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Col, Row, Button, DatePicker, Input} from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import UilEnvelopeAdd from '@iconscout/react-unicons/icons/uil-envelope-add';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';

//local imports
import {
  isExpired,
  requiredDateValForServer,
  getDateDisplay,
} from '../../utils/locale/chronos';
import {getUserHood, getAccessLevel} from '../../navigation/auth/auth';
import FeatureIcon from '../../general_components/FeatureIcon/FeatureIcon';
import MessageEditor from './components/MessageEditor';
import MessageViewer from './components/MessageViewer';
import CommonLayout from '../../layouts/CommonLayout';
import apiInstance from '../../network/axios';

import {MessagesStyles as styles} from './styles';
import CustomTable from '../../molecules/CustomTable';
import {resizeString} from '../../utils/strings';
import {SearchOutlined} from '@ant-design/icons';

const Messages = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [createMessageModal, setCreateMessageModal] = useState(false);
  const [viewMessageModal, setViewMessageModal] = useState(false);
  const [messageObj, setMessageObj] = useState(null);
  const [searching, setSearching] = useState(false);
  const [searchForDate, setSearchForDate] = useState('');
  const [searchingForInput, setSearchingForInput] = useState('');
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(10);
  const [messagesData, setMessagesData] = useState([]);
  const [includeSeen, setIncludeSeen] = useState(true);
  const [dataForTable, setDataForTable] = useState([]);

  const sudo = getAccessLevel() === 'SUPER_ADMIN';

  useEffect(() => {
    retrieveMsgsData();
  }, []);

  useEffect(() => {
    formatDataForTable(messagesData);
  }, [messagesData]);

  const retrieveMsgsData = async (pageNum = 1) => {
    try {
      setLoading(true);
      const userNeighborhoodID = getUserHood();
      let url;
      if (sudo) {
        url = `/neighborhoods/admin/messages?type=MESSAGE&limit=10&page=${pageNum}`;
      } else {
        url = `/neighborhoods/${userNeighborhoodID}/messages?limit=10&page=${pageNum}&type=MESSAGE`;
      }
      const response = await apiInstance.get(url);
      const result = response.data;
      setTotalItems(
        response.headers['x-paginate-total']
          ? parseInt(response.headers['x-paginate-total'])
          : 10,
      );
      setMessagesData(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const searchMsgs = async (pageNum: number) => {
    try {
      setLoading(true);
      const userNeighborhoodID = getUserHood();
      const validSearchDate = searchForDate
        ? '&date=' + requiredDateValForServer(searchForDate)
        : '';
      const validSearchInput = searchingForInput
        ? '&query=' + searchingForInput
        : '';

      let url;

      if (sudo) {
        url = `/neighborhoods/admin/messages?type=MESSAGE${validSearchDate}${validSearchInput}&limit=10&page=${pageNum}`;
      } else {
        url = `/neighborhoods/${userNeighborhoodID}/messages?type=MESSAGE${validSearchDate}${validSearchInput}&limit=10&page=${pageNum}`;
      }
      const response = await apiInstance.get(url);
      const result = response.data;

      setTotalItems(
        response.headers['x-paginate-total']
          ? parseInt(response.headers['x-paginate-total'])
          : 10,
      );

      setMessagesData(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const onChangeDate = (date) => {
    setSearchForDate(date);
  };
  const goToPage = (pageNum, isSearching) => {
    setPage(pageNum);
    setError(false);
    isSearching ? searchMsgs(pageNum) : retrieveMsgsData(pageNum);
  };
  const searchModeOff = () => {
    setSearching(false);
    goToPage(1, false);
    setPage(1);
  };

  const handleSearchSubmit = () => {
    if (searchForDate === '' && searchingForInput === '') {
      return;
    }
    setSearching(true);
    setPage(1);
    searchMsgs(1);
  };

  const parseFormat = (value) => {
    let parse;
    try {
      parse = JSON.parse(value);
    } catch (error) {
      parse = 'UNPARSEABLE';
    }

    return parse;
  };

  const formatDataForTable = (data = []) => {
    const formatedData = [];
    data.forEach((item, index) => {
      const formatedElement = [];
      const timeDisplay = getDateDisplay(item.date, item.time_ago);
      const expired = isExpired(item.expires);
      const statusIcon =
        item.creator.role === 'SUPER_ADMIN' ? (
          <FeatureIcon feature="super-admin" />
        ) : (
          <FeatureIcon feature="message" />
        );

      const parsedMessage = parseFormat(item.message);

      formatedElement.push(
        <Row align="middle">
          {statusIcon}
          <Col xs={0} md={18} style={styles.itemIcon}>
            {resizeString(item?.title ?? '', 50)}
          </Col>
        </Row>,
      );
      formatedElement.push(
        parsedMessage === 'UNPARSEABLE'
          ? parsedMessage
          : parsedMessage.ops[0].insert.length > 70
          ? parsedMessage.ops[0].insert.slice(0, 70).replace(/(\n||\t)/g, '') +
            '...'
          : parsedMessage.ops[0].insert.replace(/(\n||\t)/g, ''),
      );
      formatedElement.push(timeDisplay);
      formatedElement.push(
        <Button
          block
          onClick={() => {
            setMessageObj(item);
            setViewMessageModal(true);
          }}
          style={{borderColor: '#545454'}}>
          {'VER'}
        </Button>,
      );

      formatedData.push({
        data: formatedElement,
        aditionalData: {
          active: !expired,
        },
      });
    });
    setDataForTable(formatedData);
  };

  return (
    <CommonLayout
      title={'Mensajes'}
      buttonAdd={
        <Button
          style={styles.button}
          onClick={() => setCreateMessageModal(true)}>
          <Row justify="center" align="middle">
            <Col xs={24} sm={0}>
              <div style={styles.icon}>
                <UilPlus size="16" color="#FFFFFF" />
                <UilEnvelopeAdd size="18" color="#FFFFFF" />
              </div>
            </Col>
            <Col xs={0} sm={24}>
              <div style={styles.buttonTitle}>
                <div style={styles.bigButtonIcon}>
                  <UilEnvelopeAdd size="18" color="#FFFFFF" />
                </div>
                <div style={styles.buttonText}>{'CREAR MENSAJE'}</div>
              </div>
            </Col>
          </Row>
        </Button>
      }
      filters={
        <div style={{width: '100%'}}>
          <Row>
            <Col span={24} style={styles.searchText}>
              {'Busqueda por contenido y/ó fecha:'}
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={9} md={10} lg={9} xl={8}>
              <Input
                prefix={<SearchOutlined />}
                disabled={searching}
                value={searchingForInput}
                onChange={(event) => setSearchingForInput(event.target.value)}
                style={styles.searchInput}
                placeholder={searching ? 'Búsqueda Completada' : 'Buscar...'}
                onPressEnter={() => {
                  setError(false);
                  setMessagesData([]);
                  setPage(1);
                  searchMsgs(1);
                }}
              />
            </Col>
            <Col xs={12} sm={9} md={10} lg={9} xl={8}>
              <DatePicker
                disabled={searching}
                onChange={onChangeDate}
                format={'DD/MM/YYYY'}
                showToday={false}
                locale={locale}
                style={styles.searchInput}
                placeholder={
                  searching ? 'Búsqueda Completada' : 'Seleccionar Fecha'
                }
              />
            </Col>
            <Col
              xs={24}
              sm={6}
              md={4}
              lg={6}
              xl={8}
              style={styles.searchingButtonContainer}>
              {searching ? (
                <Button onClick={searchModeOff}>{'REGRESAR'}</Button>
              ) : (
                <Button onClick={handleSearchSubmit}>{'BUSCAR'}</Button>
              )}
            </Col>
          </Row>
        </div>
      }>
      <CustomTable
        loading={loading}
        error={{
          isThereError: error,
          emptyWhat: 'network',
          onRetry: () => goToPage(1, searching),
        }}
        filters={{
          applyFilters: includeSeen,
          setApplyFilters: setIncludeSeen,
          filtersText: 'INCLUIR EXPIRADOS',
        }}
        pagination={{
          searching,
          page,
          totalItems,
          goToPage,
        }}
        columns={[
          {columnTitle: 'Título', sizes: {xs: 7, sm: 8, lg: 7, xl: 8}},
          {
            columnTitle: 'Mensaje',
            sizes: {xs: 12, sm: 8, md: 10, lg: 11, xl: 10},
          },
          {columnTitle: 'Fecha', sizes: {xs: 0, sm: 5, md: 4}},
          {columnTitle: 'Detalle', sizes: {xs: 5, sm: 3, md: 2}},
        ]}
        items={
          includeSeen
            ? dataForTable
            : dataForTable.filter((item) => item.aditionalData.active)
        }
      />

      {createMessageModal && (
        <MessageEditor
          visible={createMessageModal}
          closeModalTrigger={() => setCreateMessageModal(false)}
          closeRefreshAfterCreate={() => {
            setSearchForDate('');
            setPage(1);
            setSearching(false);
            setError(false);
            retrieveMsgsData();
            setCreateMessageModal(false);
          }}
          executivePrivilege={sudo}
        />
      )}
      {messageObj !== null && (
        <MessageViewer
          visible={viewMessageModal}
          closeModalTrigger={() => setViewMessageModal(false)}
          closeRefreshAfterCreate={() => {
            setPage(1);
            retrieveMsgsData();
            setViewMessageModal(false);
          }}
          messageObject={messageObj}
          executivePrivilege={sudo}
        />
      )}
    </CommonLayout>
  );
};

export default Messages;
