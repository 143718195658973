import {CSSProperties} from 'react';
import {UTILITY_BLUE} from '../../constants/themeColors';

export const DoorsStyles: Record<string, CSSProperties> = {
  button: {
    backgroundColor: UTILITY_BLUE,
    color: '#FFFFFF',
    fontWeight: 'bold',
    borderColor: '#FFFFFF',
    padding: '0px',
    borderRadius: '5px',
  },
  container: {
    padding: '0px 10px',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    padding: '0px 10px',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonTitle: {
    paddingRight: '10px',
  },
  itemButton: {borderColor: '#545454'},
  itemIcon: {
    fontWeight: 'bold',
    paddingLeft: 10,
  },
};

export const DoorFormEditorStyles: Record<string, CSSProperties> = {
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: '30px',
  },
  item: {display: 'flex', margin: 0},
  switch: {margin: 0},
  h4: {
    padding: '0px 0px 0px 20px',
    margin: '0px',
  },
};
