import moment from 'moment';
import 'moment/locale/es';

export function convertToMomentObj(item_date) {
  return item_date ? moment(item_date) : undefined;
}

export function getDateDisplay(item_date: any, in_day_diff: any) {
  return item_date
    ? moment(item_date).isSame(moment(), 'day')
      ? in_day_diff
      : moment(item_date).format('DD/MM/YYYY')
    : ' ';
}

export function isExpired(item_date) {
  return item_date ? moment(item_date).isBefore(moment()) : false;
}

export function requiredDateValForServer(moment_obj) {
  if (moment_obj) {
    return moment(moment_obj).format('YYYY-MM-DD') + 'T06:01:00';
  } else {
    return moment().format('YYYY-MM-DD') + 'T06:01:00';
  }
}
