/* eslint-disable react-hooks/exhaustive-deps */
import React, {useState, useEffect} from 'react';
import {Col, Row, Modal, Button} from 'antd';

import {
  UTILITY_BLUE,
  UTILITY_GREEN,
  UTILITY_RED,
} from '../../../constants/themeColors';
import LoadingContent from '../../loading/LoadingContent';
import NoContent from '../../empty_screens/empty_content/NoContent';
import apiInstance from '../../../network/axios';
import {FeedbackStyles as styles} from '../styles';
import {
  CheckOutlined,
  ExclamationCircleOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import {CircleButton} from '../../../atoms';

function FeedbackDetail(props) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [user, setUser] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [deletedLog, setDeletedLog] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const containerColor =
    props.data.category === 'USER_FEEDBACK'
      ? UTILITY_GREEN
      : props.data.category === 'APP_ERROR'
      ? UTILITY_RED
      : UTILITY_BLUE;
  const iconType =
    props.data.category === 'USER_FEEDBACK' ? (
      <CheckOutlined style={styles.itemIcon} />
    ) : props.data.category === 'APP_ERROR' ? (
      <ExclamationCircleOutlined style={styles.itemIcon} />
    ) : (
      <MessageOutlined style={styles.itemIcon} />
    );
  const displayCategory =
    props.data.category === 'USER_FEEDBACK'
      ? 'Comentario'
      : props.data.category === 'APP_ERROR'
      ? 'Error de app'
      : 'Otro';
  const date = new Date(props.data.date_issued).toString();

  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      const url = `/users/${props.data.user}`;
      const response = await apiInstance.get(url);
      setUser(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const deleteLog = async () => {
    try {
      setLoadingDelete(true);
      const url = `/feedbacks/${props.data._id}`;
      const response = await apiInstance.delete(url);
      if (response.data.success) {
        setDeletedLog(true);
      } else {
        setDeleteError(true);
      }
      setLoadingDelete(false);
    } catch (error) {
      setDeleteError(true);
      setLoadingDelete(false);
    }
  };

  const retryDelete = () => {
    setDeleteError(false);
    setLoadingDelete(true);
    setDeletedLog(false);
    deleteLog();
  };

  const onRetry = () => {
    setError(false);
    setLoading(true);
    getUser();
  };
  return (
    <Modal
      cancelText={'CLOSE'}
      onCancel={props.closeModal}
      centered
      visible={true}
      width={'600px'}
      destroyOnClose={true}
      closable={true}
      maskClosable={false}
      title={
        <Row align="middle">
          <Col xs={4} sm={2}>
            <div
              style={{
                ...styles.iconContainer,
                backgroundColor: containerColor,
              }}>
              <div style={styles.iconDiv}>{iconType}</div>
            </div>
          </Col>
          <Col
            xs={20}
            sm={22}
            style={{
              fontWeight: 'bold',
              marginTop: '10px',
            }}>
            {displayCategory}
          </Col>
        </Row>
      }
      footer={
        <Row>
          <Col span={22}></Col>
          <Col span={2}>
            <CircleButton
              variant="del"
              activeColor={UTILITY_RED}
              disabled={loading}
              onClick={() => setShowConfirmModal(true)}
            />
          </Col>
        </Row>
      }>
      {loading ? (
        <LoadingContent />
      ) : error ? (
        <NoContent
          emptyWhat={'network'}
          withRetry
          onClickMethod={() => onRetry()}
        />
      ) : (
        <Row>
          <Col span={24}>
            <h3>{'Descripción'}</h3>
            <p style={styles.paragraph}>{props.data.description}</p>
            <h3>{'Fecha'}</h3>
            <p style={styles.paragraph}>{date}</p>
            <h3>{'App'}</h3>
            <p style={styles.paragraph}>{props.data.app}</p>
            <h3>{'Versión de la app'}</h3>
            <p style={styles.paragraph}>{props.data.version}</p>
            <h3>{'Dispositivo'}</h3>
            <p style={styles.paragraph}>{props.data.device}</p>
            <h3>{'Vecindario'}</h3>
            <p style={styles.paragraph}>{user.neighborhood.number}</p>
            <h3>{'Usuario'}</h3>
            <p style={styles.paragraph}>{`${user.name} ${user.last_name}`}</p>
            {props?.data.traceback && (
              <>
                <h3>{'Traceback'}</h3>
                <textarea
                  style={{
                    ...styles.paragraph,
                    width: '100%',
                    minHeight: 80,
                    borderColor: 'transparent',
                  }}
                  readOnly={true}
                  value={props.data.traceback}
                />
              </>
            )}
          </Col>
        </Row>
      )}
      {showConfirmModal && (
        <Modal
          onCancel={() => {
            setShowConfirmModal(false);
            if (deletedLog) {
              props.closeModal();
              props.reload();
            }
          }}
          centered
          visible={true}
          width={'500px'}
          destroyOnClose={true}
          closable={!loadingDelete}
          maskClosable={false}
          footer={
            <Row>
              <Col span={6}></Col>
              <Col span={8}>
                {!deletedLog && (
                  <Button
                    onClick={() => setShowConfirmModal(false)}
                    disabled={loadingDelete}>
                    {'Cancelar'}
                  </Button>
                )}
              </Col>
              <Col span={10}>
                {!deletedLog ? (
                  <Button
                    loading={loadingDelete}
                    key="del"
                    onClick={() => deleteLog()}
                    style={{
                      backgroundColor: UTILITY_RED,
                      width: '90%',
                    }}>
                    {'¡Sí, continuar!'}
                  </Button>
                ) : deleteError ? (
                  <Button
                    loading={loadingDelete}
                    key="del"
                    onClick={() => retryDelete()}
                    style={{
                      backgroundColor: UTILITY_RED,
                      width: '90%',
                    }}>
                    {'Reintentar'}
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    loading={loadingDelete}
                    key="del"
                    onClick={() => {
                      setShowConfirmModal(false);
                      props.closeModal();
                      props.reload();
                    }}
                    style={{
                      backgroundColor: UTILITY_GREEN,
                      width: '90%',
                    }}>
                    {'Regresar'}
                  </Button>
                )}
              </Col>
            </Row>
          }>
          <h3>
            {deletedLog
              ? '¡Listo se ha eliminado correctamente!'
              : loadingDelete
              ? 'Eliminando...'
              : deleteError
              ? 'Ocurrio un error a eliminar el registro'
              : '¿Desea eliminar el registro?'}
          </h3>
        </Modal>
      )}
    </Modal>
  );
}

export default FeedbackDetail;
