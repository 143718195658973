import {Button, Col, Input, Row} from 'antd';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {
  BASE_URL,
  DEVELOPMENT,
  NETWORK_TIMEOUT,
} from '../../../constants/network';
import {UTILITY_GREEN} from '../../../constants/themeColors';
import FeatureIcon from '../../../general_components/FeatureIcon/FeatureIcon';
import {Modal} from '../../../molecules/Modal';
import {
  getHomesName,
  getUserHood,
  getUserToken,
} from '../../../navigation/auth/auth';
import NoContent from '../../empty_screens/empty_content/NoContent';
import LoadingContent from '../../loading/LoadingContent';
import AddEntry from './AddEntry';
import {styles} from './styles';

const ViewHomeStatus = (props) => {
  const [paymentModal, setPaymentModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [delays, setDelays] = useState(0);
  const [maintenance, setMaintenance] = useState(0);
  const [deadline, setDeadline] = useState(15);

  useEffect(() => {
    getConfigData();
  }, []);

  const getConfigData = async () => {
    try {
      const userNeighborhoodID = getUserHood();
      const userToken = getUserToken();
      const url = `${BASE_URL}/neighborhoods/${userNeighborhoodID}/config`;
      const options = {
        headers: {
          'X-App': 'admin',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + userToken,
        },
        timeout: NETWORK_TIMEOUT,
      };
      const response = await axios.get(url, options);

      if (response.data.success) {
        setDeadline(response.data.hoodConfig.maintenanceDue);
        setMaintenance(response.data.hoodConfig.maintenanceFee);
        setDelays(response.data.hoodConfig.lateFee);
        setLoading(false);
      } else {
        setDeadline(15);
        setMaintenance(0);
        setDelays(0);
        setLoading(false);
      }
    } catch (error) {
      if (DEVELOPMENT) {
        console.log('Error getting configData', error?.response ?? error);
      }
      setLoading(false);
      setError(true);
    }
  };
  const today = new Date();

  const totalToPay =
    today.getDate() <= deadline ? maintenance : maintenance + delays;

  return (
    <Modal
      icon={<FeatureIcon feature={'homeAccounting'} entryType="expense" />}
      title={`${getHomesName()} ${props?.data?.number ?? ''} - ${
        props?.data?.inside_number ?? 'S/N'
      }`}
      visible={props.visible}
      onCancel={props.onCancel}
      loading={loading}
      body={
        loading ? (
          <LoadingContent />
        ) : error ? (
          <NoContent
            emptyWhat={'network'}
            withRetry
            onClickMethod={() => {
              setError(false);
              setLoading(true);
              getConfigData();
            }}
          />
        ) : (
          <div>
            <Row>
              <Col span={24}>
                <h3 style={styles.h3}>{'Saldo pendiente'}</h3>
                <Input prefix={'$'} value={maintenance} />
                <h3 style={styles.h3}>{'Retardos'}</h3>
                <Input
                  prefix={'$'}
                  value={today.getDate() <= deadline ? 0 : delays}
                />
              </Col>
            </Row>
            <AddEntry
              concept="Mantenimiento"
              amount={totalToPay}
              visible={paymentModal}
              iconColor={UTILITY_GREEN}
              title={'Añadir ingreso'}
              onCancel={() => setPaymentModal(false)}
              categories={props.categories}
              type={'income'}
              reload={() => {
                props.onCancel();
                props.reload();
              }}
              homeId={props?.data?._id ?? ''}
              homeName={`${getHomesName()} ${props?.data?.number ?? ''} - ${
                props?.data?.inside_number ?? 'S/N'
              }`}
              withOutHomes
            />
          </div>
        )
      }
      footer={[
        <Button key="back" disabled={props.loading} onClick={props.onCancel}>
          {'CERRAR'}
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => setPaymentModal(true)}>
          {'AÑADIR PAGO'}
        </Button>,
      ]}
    />
  );
};
export default ViewHomeStatus;
