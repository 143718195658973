import React, {useState, useEffect} from 'react';
import {Col, Row, List, Button, DatePicker, Select, Input} from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import {BASE_URL, DEVELOPMENT, NETWORK_TIMEOUT} from '../../constants/network';
import {getUserHood, getUserToken} from '../../navigation/auth/auth';
import Axios from 'axios';
import LoadingContent from '../loading/LoadingContent';
import NoContent from '../empty_screens/empty_content/NoContent';
import {styles} from './styles';
import AddButton from '../../atoms/AddButton';
import AddModal from './components/AddModal';
import FeatureIcon from '../../general_components/FeatureIcon/FeatureIcon';
import EditCategory from './components/EditCategory';
import {UTILITY_BLUE, UTILITY_YELLOW} from '../../constants/themeColors';
import numeral from 'numeral';
import {CircleButton} from '../../atoms';

const AccountingConfig = () => {
  const [maintenance, setMaintenance] = useState(0);
  const [delays, setDelays] = useState(0);
  const [timeToDisabled, setTimeToDisabled] = useState(-1);
  const [deadline, setDeadline] = useState(1);
  const [editing, setEditing] = useState(false);
  const [validating, setValidating] = useState(false);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [categories, setCategories] = useState([]);
  const [current, setCurrent] = useState({});
  const [addModalVisible, setAddModalVisible] = useState(false);

  const onChangeDate = (date) => {
    setDeadline(date);
  };

  useEffect(() => {
    getConfigData();
    getCategories();
  }, []);

  const getCategories = async () => {
    try {
      const userNeighborhoodID = getUserHood();
      const userToken = getUserToken();
      const url = `${BASE_URL}/neighborhoods/${userNeighborhoodID}/accounting/categories`;
      const options = {
        headers: {
          'X-App': 'admin',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + userToken,
        },
        timeout: NETWORK_TIMEOUT,
      };
      const response = await Axios.get(url, options);

      if (response.data.success) {
        setCategories(response.data.categories);
        setLoading(false);
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      if (DEVELOPMENT) {
        console.log('Error getting categories', error?.response ?? error);
      }
      setLoading(false);
      setError(true);
    }
  };
  const getConfigData = async () => {
    try {
      const userNeighborhoodID = getUserHood();
      const userToken = getUserToken();
      const url = `${BASE_URL}/neighborhoods/${userNeighborhoodID}/config`;
      const options = {
        headers: {
          'X-App': 'admin',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + userToken,
        },
        timeout: NETWORK_TIMEOUT,
      };
      const response = await Axios.get(url, options);

      if (response.data.success) {
        setDeadline(response.data.hoodConfig.maintenanceDue);
        setTimeToDisabled(response.data.hoodConfig.disableDebtors);
        setMaintenance(response.data.hoodConfig.maintenanceFee);
        setDelays(response.data.hoodConfig.lateFee);
        setLoading(false);
      } else {
        setDeadline(15);
        setTimeToDisabled(90);
        setMaintenance(0);
        setDelays(0);
        setLoading(false);
      }
    } catch (error) {
      if (DEVELOPMENT) {
        console.log('Error getting configData', error?.response ?? error);
      }
      setLoading(false);
      setError(true);
    }
  };
  const handleUpdate = () => {
    if (isNaN(maintenance) || isNaN(delays)) {
      setValidating(true);
      return;
    }
    setLoading(true);
    updateConfigData();
  };
  const updateConfigData = async () => {
    try {
      const userNeighborhoodID = getUserHood();
      const userToken = getUserToken();
      const url = `${BASE_URL}/neighborhoods/${userNeighborhoodID}/config`;
      const options = {
        headers: {
          'X-App': 'admin',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + userToken,
        },
        timeout: NETWORK_TIMEOUT,
      };
      const body = {
        disableDebtors: timeToDisabled,
        lateFee: numeral(delays).value(),
        maintenanceFee: numeral(maintenance).value(),
        maintenanceDue:
          typeof deadline === 'number'
            ? deadline
            : new Date(deadline).getDate(),
      };
      const response = await Axios.put(url, body, options);

      if (response.data.success) {
        setEditing(false);
        getConfigData();
        setValidating(false);
        setLoading(false);
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      if (DEVELOPMENT) {
        console.log('Error updating configData', error?.response ?? error);
      }
      setLoading(false);
      setError(true);
    }
  };

  if (loading) {
    return <LoadingContent />;
  }

  if (error) {
    return (
      <NoContent
        emptyWhat={'network'}
        withRetry
        onClickMethod={() => {
          setError(false);
          setLoading(true);
          getCategories();
          getConfigData();
        }}
      />
    );
  }

  const {Option} = Select;
  return (
    <div style={styles.mainContainer}>
      <Row>
        <Col style={styles.bold}>{'Configuración'}</Col>
      </Row>
      <Row style={styles.rowSeparator} />
      <div style={styles.config}>
        <Col>
          <h3>{'Cuotas'}</h3>
          <Row>
            <Col span={24} style={styles.rowSeparator} />
          </Row>
          <Row gutter={[16, 32]}>
            <Col xs={12} md={8}>
              {'Mantenimiento'}
              <Input
                prefix={'$'}
                disabled={!editing}
                value={maintenance}
                onChange={(value: any) => setMaintenance(value.target.value)}
              />
              {validating && isNaN(maintenance) && (
                <p style={styles.validation}>Ingrese una cantidad valida.</p>
              )}
            </Col>
            <Col xs={12} md={8}>
              {'Retardos'}
              <Input
                prefix="$"
                disabled={!editing}
                value={delays}
                onChange={(value: any) => setDelays(value.target.value)}
              />
              {validating && isNaN(delays) && (
                <p style={styles.validation}>Ingrese una cantidad valida.</p>
              )}
            </Col>
            <Col xs={24} sm={12} md={8}>
              {'Fecha límite de pago'}
              <DatePicker
                disabled={!editing}
                onChange={onChangeDate}
                format={'DD/MM/YYYY'}
                showToday={false}
                locale={locale}
                style={styles.item}
                placeholder={`Los dias ${deadline} de cada mes.`}
              />
              {validating && deadline.toString() === '' && (
                <p style={styles.validation}>Ingrese una fecha.</p>
              )}
            </Col>
            <Col xs={24} sm={12} md={8}>
              {'Deshabilitar morosos'}
              <Select
                disabled={!editing}
                defaultValue={-1}
                style={styles.item}
                onChange={(value) => setTimeToDisabled(value)}>
                <Option value={-1}>No deshabilitar</Option>
                <Option value={30}>30 días de adeudo</Option>
                <Option value={60}>60 días de adeudo</Option>
                <Option value={90}>90 días de adeudo</Option>
              </Select>
            </Col>
          </Row>
          <Row>
            <Col span={24} style={{height: '32px'}} />
          </Row>
        </Col>
        <Row>
          <Col xs={18} sm={20}></Col>
          <Col
            xs={6}
            sm={4}
            style={{display: 'flex', justifyContent: 'end', gap: 10}}>
            <CircleButton
              variant="genericEdit"
              activeColor={UTILITY_YELLOW}
              disabled={loading}
              onClick={() => setEditing(!editing)}
              clickedMode={editing}
            />
            <CircleButton
              variant="save"
              activeColor={UTILITY_BLUE}
              disabled={!editing}
              onClick={handleUpdate}
            />
          </Col>
        </Row>
      </div>
      <Row>
        <Col span={24} style={{height: '32px'}} />
      </Row>
      <Row>
        <Col span={12} style={{height: '32px'}}>
          <h2 style={styles.h2}>{'Categorías'}</h2>
        </Col>
        <Col span={12} style={styles.addButtonContainer}>
          <AddButton
            title={'AÑADIR CATEGORÍA'}
            onClick={() => setAddModalVisible(true)}
            small
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{height: '20px'}} />
      </Row>
      <List
        locale={{emptyText: 'Sin Registros'}}
        style={{
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
        }}
        grid={{gutter: 8, xs: 1, sm: 2, md: 2, lg: 2, xl: 3, xxl: 4}}
        dataSource={categories}
        renderItem={(item) => {
          let _name = '';
          if (item.name) {
            _name =
              item.name.length > 15
                ? item.name.substring(0, 15) + '...'
                : item.name;
          }
          return (
            <List.Item style={styles.categoryList}>
              <Row>
                <Col span={6}>
                  <FeatureIcon feature="accounting" />
                </Col>
                <Col span={12} style={{fontSize: 18}}>
                  {_name ?? ''}
                </Col>

                <Col span={6}>
                  <Button
                    block
                    onClick={() => {
                      setCurrent(item);
                    }}
                    style={styles.button}>
                    {'VER'}
                  </Button>
                </Col>
              </Row>
            </List.Item>
          );
        }}
      />

      <AddModal
        visible={addModalVisible}
        title={'Nueva categoria'}
        onCancel={() => setAddModalVisible(false)}
        iconColor={'#44C3DD'}
        reload={() => {
          setError(false);
          getCategories();
        }}
      />
      <EditCategory
        visible={Object.entries(current).length !== 0}
        title={'Editar categoria'}
        onCancel={() => setCurrent({})}
        iconColor={'#44C3DD'}
        data={current}
        reload={() => {
          setError(false);
          getCategories();
        }}
      />
    </div>
  );
};

export default AccountingConfig;
