import React, {useRef, useState} from 'react';
import {Form, Input, Row, Col, Button} from 'antd';

//local imports
import {setUserName} from '../../../navigation/auth/auth';
import {UTILITY_YELLOW, UTILITY_BLUE} from '../../../constants/themeColors';

import {FormInstance} from 'antd/es/form/Form';
import {CircleButton} from '../../../atoms';
import apiInstance from '../../../network/axios';
import NoContent from '../../empty_screens/empty_content/NoContent';

interface IAdminEditor {
  defaultValues: any;
}
const AdminEditor = ({defaultValues}: IAdminEditor) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [passwordChange, setPasswordChange] = useState<boolean>();

  const formRef = useRef<FormInstance>();

  const handleSubmit = async () => {
    const values = await formRef.current.validateFields();
    setLoading(true);
    putAdminUser(values);
  };

  const togglePwordChange = () => {
    setPasswordChange(!passwordChange);
  };

  const toggleEditMode = () => {
    if (editMode) {
      if (window.confirm('¿Abandonar modo de edición?')) {
        setPasswordChange(false);
        setEditMode(!editMode);
      } else {
        return;
      }
    } else {
      setEditMode(!editMode);
    }
  };

  const putAdminUser = async (form_object) => {
    try {
      const url = `/users/${defaultValues._id}`;

      let body: any = {
        name: form_object.name,
        last_name: form_object.last_name,
        phone_number: form_object.phone,
        username: form_object.email,
      };

      if (form_object.password) {
        body.password = form_object.password;
      }

      const response = await apiInstance.put(url, body);
      const result = response.data;
      setUserName(result.name);
      setPasswordChange(false);
      setEditMode(false);
      setLoading(false);
    } catch (e) {
      setPasswordChange(false);
      setEditMode(false);
      setLoading(false);
      setError(true);
    }
  };

  if (error) {
    return <NoContent emptyWhat={'network'} />;
  }

  return (
    <Form
      ref={formRef}
      style={{
        backgroundColor: '#FFFFFF',
        border: '1px solid #D9D9D9',
        borderRadius: '5px',
      }}>
      <Row>
        <Col
          span={24}
          style={{
            minHeight: '33px',
            padding: '2.088% 2.088%',
            fontWeight: 'bold',
            borderWidth: 0,
            borderBottomWidth: 1,
            borderStyle: 'solid',
            borderColor: '#D9D9D9',
          }}>
          {'Información'}
        </Col>
      </Row>

      <Row style={{paddingTop: '4.166%'}}>
        <Col xs={24} lg={12} style={{padding: '0% 4.166%'}}>
          <Form.Item
            label="Nombre"
            required={false}
            name="name"
            labelCol={{span: 24}}
            rules={[
              {required: true, message: 'Ingrese nombre!'},
              {whitespace: true, message: 'Ingrese nombre!'},
            ]}
            initialValue={defaultValues?.name ?? ''}>
            <Input
              placeholder={'Nombre'}
              readOnly={!editMode}
              disabled={loading}
            />
          </Form.Item>

          <Form.Item
            label="Correo Electrónico"
            required={false}
            name="email"
            labelCol={{span: 24}}
            rules={[
              {
                type: 'email',
                message: "Ingrese formato valido. Ej. 'usuario@portos.xyz'",
              },
              {
                required: true,
                message: 'Ingrese correo electrónico!',
              },
            ]}
            initialValue={defaultValues?.username ?? ''}>
            <Input
              placeholder={'Correo Electrónico'}
              readOnly={!editMode}
              disabled={loading}
            />
          </Form.Item>
        </Col>

        <Col xs={24} lg={12} style={{padding: '0% 4.166%'}}>
          <Form.Item
            label="Apellido"
            required={false}
            name="last_name"
            labelCol={{span: 24}}
            rules={[
              {required: true, message: 'Ingrese apellido!'},
              {whitespace: true, message: 'Ingrese apellido!'},
            ]}
            initialValue={defaultValues?.last_name ?? ''}>
            <Input
              placeholder={'Apellido'}
              readOnly={!editMode}
              disabled={loading}
            />
          </Form.Item>

          <Form.Item
            label="Teléfono"
            required={false}
            name="phone"
            labelCol={{span: 24}}
            rules={[
              {required: true, message: 'Ingrese teléfono!'},
              {whitespace: true, message: 'Ingrese teléfono!'},
            ]}
            initialValue={defaultValues?.phone_number ?? ''}>
            <Input
              placeholder={'Teléfono'}
              readOnly={!editMode}
              disabled={loading}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row style={{padding: '0%'}}>
        <Col xs={24} lg={12} style={{padding: '0% 4.166%'}}>
          <Button
            style={{
              color: passwordChange ? 'red' : null,
              padding: 0,
              marginBottom: '4.166%',
            }}
            type="link"
            onClick={togglePwordChange}
            disabled={!editMode}>
            {'Cambiar contraseña'}
          </Button>
        </Col>
      </Row>

      {passwordChange && (
        <Row style={{padding: '0%'}}>
          <Col xs={24} lg={12} style={{padding: '0% 4.166%'}}>
            <Form.Item
              labelCol={{span: 24}}
              label="Contraseña"
              hasFeedback
              required={false}
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Ingrese contraseña!',
                },
                {whitespace: true, message: 'Evite espacios! '},
                {
                  min: 5,
                  message: 'Contraseña debe contener al menos 5 caracteres!',
                },
              ]}>
              <Input.Password placeholder={'Contraseña'} />
            </Form.Item>
          </Col>

          <Col xs={24} lg={12} style={{padding: '0% 4.166%'}}>
            <Form.Item
              labelCol={{span: 24}}
              required={false}
              label="Verificar Contraseña"
              hasFeedback
              name="confirm"
              rules={[
                {
                  required: true,
                  message: 'Verifique contraseña!',
                },
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        'The two passwords that you entered do not match!',
                      ),
                    );
                  },
                }),
              ]}>
              <Input.Password
                placeholder={'Verificar Contraseña'}
                readOnly={!editMode}
              />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row
        style={{
          padding: '2.088% 4.166% 2% 4.166%',
          border: '0 solid #D9D9D9',
          borderTopWidth: 1,
          display: 'flex',
          justifyContent: 'end',
        }}>
        <CircleButton
          variant="genericEdit"
          activeColor={UTILITY_YELLOW}
          disabled={loading}
          onClick={toggleEditMode}
          clickedMode={editMode}
        />
        <CircleButton
          variant="save"
          activeColor={UTILITY_BLUE}
          disabled={!editMode}
          onClick={handleSubmit}
        />
      </Row>
    </Form>
  );
};

export default AdminEditor;
