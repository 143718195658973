import {Button, Col, DatePicker, List, Pagination, Row} from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import axios from 'axios';
import moment from 'moment';
import React, {useEffect, useState} from 'react';
import {BASE_URL, DEVELOPMENT, NETWORK_TIMEOUT} from '../../constants/network';
import {BASE_GRAY, UTILITY_BLUE} from '../../constants/themeColors';
import FeatureIcon from '../../general_components/FeatureIcon/FeatureIcon';
import {
  getHomesName,
  getUserHood,
  getUserToken,
} from '../../navigation/auth/auth';
import NoContent from '../empty_screens/empty_content/NoContent';
import LoadingContent from '../loading/LoadingContent';
import AddModal from './components/AddModal';
import ViewHomeStatus from './components/ViewHomeStatus';
import {styles} from './styles';

const AccountingHomes = () => {
  const [searching, setSearching] = useState(false);
  const [searchingFor, setSearchingFor] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [debtors, setDebtors] = useState([]);
  const [totalAvailableItems, setTotalAvailableItems] = useState(10);
  const [payerPage, setPayerPage] = useState(1);
  const [payers, setPayers] = useState([]);
  const [totalPayers, setTotalPayers] = useState(10);
  const [currentHomeSelected, setCurrentHomeSelected] = useState(null);
  const [categories, setCategories] = useState([]);

  const date = new Date();
  const firstDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    1,
  ).toISOString();
  const lastDay = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
  ).toISOString();

  useEffect(() => {
    getAll();
    // eslint-disable-next-line
  }, []);
  const getAll = async () => {
    await getCategories();
    await getDebtors();
    await getDebtors(1, false);
  };
  const onChangeDate = (dates) => {
    if (dates?.length > 1) {
      setSearchingFor([
        new Date(dates[0]._d).toISOString(),
        new Date(dates[1]._d).toISOString(),
      ]);
    }
  };

  const searchModeOff = () => {
    setSearching(false);
    setSearchingFor([]);
    setLoading(true);
    getDebtors(1, true, false);
    getDebtors(1, false, false);
    setPage(1);
    setPayerPage(1);
  };

  const handleSearchSubmit = () => {
    setSearching(true);
    setLoading(true);
    getDebtors(1, true, true);
    getDebtors(1, false, true);
  };
  const goToPage = (page, currentDebtors = true) => {
    if (currentDebtors) {
      setPage(page);
      getDebtors(page, true, searching);
    } else {
      setPayerPage(page);
      getDebtors(page, false, searching);
    }
  };

  const getDebtors = async (
    pageNum = 1,
    currentDebtors = true,
    isSearching = false,
  ) => {
    setLoading(true);
    try {
      const userNeighborhoodID = getUserHood();
      const userToken = getUserToken();
      const url = `${BASE_URL}/neighborhoods/${userNeighborhoodID}/accounting/homes?min_date=${
        isSearching === false ? firstDay : searchingFor[0]
      }&max_date=${
        isSearching === false ? lastDay : searchingFor[1]
      }&limit=10&page=${pageNum}${currentDebtors ? '&getDebtors=true' : ''}`;
      const options = {
        headers: {
          'X-App': 'admin',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + userToken,
        },
        timeout: NETWORK_TIMEOUT,
      };
      const response = await axios.get(url, options);
      if (response.data) {
        if (currentDebtors) {
          setDebtors(response.data);
          setTotalAvailableItems(
            parseInt(response.headers['x-paginate-total']),
          );
        } else {
          setPayers(response.data);
          setTotalPayers(parseInt(response.headers['x-paginate-total']));
        }
      }
      setLoading(false);
    } catch (error) {
      if (DEVELOPMENT) {
        console.log('Error getting debtors', error?.response ?? error);
      }
      setLoading(false);
      setError(true);
    }
  };
  const getCategories = async () => {
    setLoading(true);
    try {
      const userNeighborhoodID = getUserHood();
      const userToken = getUserToken();
      const url = `${BASE_URL}/neighborhoods/${userNeighborhoodID}/accounting/categories`;
      const options = {
        headers: {
          'X-App': 'admin',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + userToken,
        },
        timeout: NETWORK_TIMEOUT,
      };
      const response = await axios.get(url, options);
      if (response.data.success) {
        setCategories(response.data.categories);
        setLoading(false);
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      if (DEVELOPMENT) {
        console.log('Error getting categories', error?.response ?? error);
      }
      setLoading(false);
      setError(true);
    }
  };

  if (loading) {
    return <LoadingContent />;
  }

  if (error) {
    return (
      <NoContent
        emptyWhat={'network'}
        withRetry
        onClickMethod={() => {
          setError(false);
          setLoading(true);
          getDebtors();
        }}
      />
    );
  }
  if (categories.length === 0) {
    return (
      <AddModal
        visible={categories.length === 0}
        title={'Añadir categoria'}
        cancelable={false}
        iconColor={UTILITY_BLUE}
        reload={() => {
          getAll();
        }}
      />
    );
  }

  const {RangePicker} = DatePicker;
  return (
    <div style={styles.mainContainer}>
      <Row
        gutter={[16, {xs: 8, sm: 0, md: 0, lg: 0}]}
        style={{backgroundColor: 'transparent'}}>
        <Col xs={24} sm={10} md={11} style={styles.bold}>
          {`${getHomesName()}s`}
        </Col>
        <Col xs={24} sm={8}>
          <RangePicker
            disabled={searching}
            onChange={onChangeDate}
            format={'DD/MM/YYYY'}
            locale={locale}
            style={styles.item}
            value={[
              moment(searchingFor.length > 1 ? searchingFor[0] : firstDay),
              moment(searchingFor.length > 1 ? searchingFor[1] : lastDay),
            ]}
            placeholder={[
              searching ? 'Búsqueda Completada' : 'Seleccionar Fecha',
              searching ? 'Búsqueda Completada' : 'Seleccionar Fecha',
            ]}
          />
        </Col>
        <Col
          xs={{span: 12, offset: 12}}
          sm={{span: 6, offset: 0}}
          md={{span: 5, offset: 0}}
          style={styles.configTablePaginationColumnEnd}>
          {searching ? (
            <Button onClick={searchModeOff}>{'REGRESAR'}</Button>
          ) : (
            <Button
              disabled={searchingFor.length === 0}
              onClick={handleSearchSubmit}>
              {'BUSCAR'}
            </Button>
          )}
        </Col>
      </Row>
      <Row style={styles.rowSeparator} />

      <Row gutter={16}>
        <Col xs={24} lg={12}>
          <div style={styles.dashboardList}>
            <Row>
              <Col span={14} style={{marginBottom: 16}}>
                <p style={styles.dashboardListTitle}>{'Faltan por pagar'}</p>
              </Col>
              <Col span={10}>
                <div style={styles.configPaginationContainer}>
                  <Pagination
                    defaultCurrent={page}
                    total={totalAvailableItems}
                    size={'small'}
                    onChange={(pageNum) => goToPage(pageNum)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <List
                  locale={{emptyText: 'Sin Registros'}}
                  // style={styles.configList}
                  dataSource={debtors}
                  renderItem={(item) => {
                    return (
                      <List.Item
                        style={{
                          minHeight: '60px',
                          borderColor: BASE_GRAY,
                          borderStyle: 'solid',
                          borderWidth: 1,
                          marginBottom: 8,
                          padding: 8,
                        }}>
                        <Col xs={6} md={5}>
                          <FeatureIcon
                            feature={'homeAccounting'}
                            entryType="expense"
                          />
                        </Col>
                        <Col xs={12} md={13}>
                          {`${getHomesName()} ${item.number} - ${
                            item?.inside_number ?? 'S/N'
                          }`}
                        </Col>

                        <Col span={6}>
                          <Button
                            block
                            onClick={() => {
                              setCurrentHomeSelected(item);
                            }}
                            style={styles.button}>
                            {'VER'}
                          </Button>
                        </Col>
                      </List.Item>
                    );
                  }}
                />
              </Col>
            </Row>
          </div>
        </Col>
        <Col xs={24} lg={12}>
          <div style={styles.dashboardList}>
            <Row>
              <Col span={14} style={{marginBottom: 16}}>
                <p style={styles.dashboardListTitle}>{'Han pagado'}</p>
              </Col>
              <Col span={10}>
                <div style={styles.configPaginationContainer}>
                  <Pagination
                    defaultCurrent={payerPage}
                    total={totalPayers}
                    size={'small'}
                    onChange={(pageNum) => goToPage(pageNum, false)}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <List
                  locale={{emptyText: 'Sin Registros'}}
                  // style={styles.configList}
                  dataSource={payers}
                  renderItem={(item) => {
                    return (
                      <List.Item
                        style={{
                          minHeight: '60px',
                          borderColor: BASE_GRAY,
                          borderStyle: 'solid',
                          borderWidth: 1,
                          marginBottom: 8,
                          padding: 8,
                        }}>
                        <Col xs={6} md={5}>
                          <FeatureIcon
                            feature={'homeAccounting'}
                            entryType="income"
                          />
                        </Col>
                        <Col xs={13} md={19}>
                          {`${getHomesName()} ${item.number} - ${
                            item?.inside_number ?? 'S/N'
                          }`}
                        </Col>
                      </List.Item>
                    );
                  }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <ViewHomeStatus
        visible={currentHomeSelected !== null}
        data={currentHomeSelected}
        onCancel={() => setCurrentHomeSelected(null)}
        reload={() => getAll()}
        categories={categories}
      />
    </div>
  );
};
export default AccountingHomes;
