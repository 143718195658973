import {CSSProperties} from 'react';
import {BASE_GRAY} from '../constants/themeColors';

export const CustomTableStyles: Record<string, CSSProperties> = {
  header: {
    minHeight: '33px',
    borderWidth: 1,
    borderColor: '#D9D9D9',
    borderStyle: 'solid',
    borderBottomWidth: 0,
    backgroundColor: '#FFFFFF',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0px 10px',
  },
  rowColumns: {
    padding: '0 2.08%',
    height: '33px',
    borderWidth: 1,
    borderColor: '#D9D9D9',
    borderStyle: 'solid',
    borderBottomWidth: 0,
  },
  clumnHeader: {fontWeight: 'bold'},
  list: {
    backgroundColor: '#FFFFFF',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
  },
  cell: {padding: '10px 10px 10px 0px'},
};

export const CustomListStyles: Record<string, CSSProperties> = {
  header: {
    minHeight: '33px',
    borderWidth: 1,
    borderColor: '#D9D9D9',
    borderStyle: 'solid',
    backgroundColor: '#FFFFFF',
    borderRadius: 4,
  },
  pagination: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0px 10px',
  },
  rowColumns: {
    padding: '0 2.08%',
    height: '33px',
    borderWidth: 1,
    borderColor: '#D9D9D9',
    borderStyle: 'solid',
    borderBottomWidth: 0,
  },
  list: {
    marginTop: '15px',
    borderWidth: 0,
  },
  cell: {padding: '10px 10px 10px 0px'},
  item: {
    display: 'flex',
    padding: '0px 10px',
    minHeight: '67px',
    borderColor: BASE_GRAY,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '5px',
  },
};
