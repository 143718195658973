import {Button} from 'antd';
import React from 'react';
import {Link} from 'react-router-dom';
import {getLevelConfig} from '../../navigation/auth/auth';
import * as Unicons from '@iconscout/react-unicons';
import {DJIntroStyles as styles} from './styles';
import {CheckOutlined} from '@ant-design/icons';

const LinkItem = ({text, number, done, go, route}) => {
  return (
    <Link
      style={{display: 'flex', cursor: go ? 'pointer' : 'no-drop'}}
      to={go ? route : '#'}>
      <div style={styles.linkContainer}>
        <div
          style={{
            ...styles.linkNumber,
            borderColor: go ? '#60D7E6' : '#707070',
            color: go ? '#60D7E6' : '#707070',
          }}>
          {number}
        </div>
        <div
          style={{
            ...styles.line,
            color: go ? '#60D7E6' : '#707070',
            textDecoration: go ? 'underline' : 'none',
          }}>
          {text}
        </div>
        <div>
          {done && (
            <CheckOutlined
              style={{
                ...styles.line,
                color: go ? '#60D7E6' : '#707070',
              }}
            />
          )}
          {go && (
            <Unicons.UilArrowCircleRight
              size="35"
              color={go ? '#60D7E6' : '#707070'}
            />
          )}
        </div>
      </div>
    </Link>
  );
};

const DJIntro = () => {
  const levelConfig = getLevelConfig();
  if (levelConfig === 0) {
    return (
      <div style={styles.container}>
        <Unicons.UilCheckCircle size="150" color={'#0000005E'} />
        <h1 style={styles.title}>
          Estás cada vez más cerca de llevar tu condominio a otro nivel.
        </h1>
        <Link
          style={styles.buttonContainer}
          to="/digitalJourney/createNeighborhood">
          <Button style={styles.button}>CREAR CONDOMINIO</Button>
        </Link>
      </div>
    );
  }
  return (
    <div style={styles.main}>
      <div style={styles.statusContainer}>
        <div
          style={{
            ...styles.progress,
            width: `${25 * levelConfig}%`,
          }}
        />
      </div>
      <LinkItem
        route="null"
        text="Crea tu condominio"
        number="1"
        done={levelConfig >= 1}
        go={false}
      />
      <LinkItem
        route="/digitalJourney/createStreets"
        text="Crea calles / secciones"
        number="2"
        done={levelConfig >= 2}
        go={levelConfig === 1}
      />
      <LinkItem
        route="/digitalJourney/createHomes"
        text="Crea casas / departamentos"
        number="3"
        done={levelConfig >= 3}
        go={levelConfig === 2}
      />
      <LinkItem
        route="/digitalJourney/createOwners"
        text="Da de alta a los residentes"
        number="4"
        done={getLevelConfig() >= 4}
        go={levelConfig === 3}
      />
    </div>
  );
};

export default DJIntro;
