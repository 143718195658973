/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Col, Row, Modal} from 'antd';

//local imports
import {getHomesName} from '../../../navigation/auth/auth';
import LoadingContent from '../../loading/LoadingContent';
import NoContent from '../../empty_screens/empty_content/NoContent';
import FeatureIcon from '../../../general_components/FeatureIcon/FeatureIcon';
import {ImageGrid} from '../../../molecules/Index';
import apiInstance from '../../../network/axios';

interface ICallDetailModal {
  visible: boolean;
  closeModalTrigger: () => void;
  callId: string;
}
const CallDetailModal = ({
  callId,
  closeModalTrigger,
  visible,
}: ICallDetailModal) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [callType, setCallType] = useState<'Entrada' | 'Salida'>('Entrada');
  const [recorDetail, setRecorDetail] = useState<any>({});

  useEffect(() => {
    retrieveRecordData();
  }, []);

  const dateOfRecord = (date_string) => {
    if (!date_string || date_string === '') {
      return '';
    }
    const monthNames = [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ];
    const dateObject = new Date(date_string);
    return (
      dateObject.getDate().toString() +
      ' / ' +
      monthNames[dateObject.getMonth()] +
      ' / ' +
      dateObject.getFullYear()
    );
  };

  const hourOfRecord = (date_string) => {
    if (!date_string || date_string === '') {
      return '';
    }

    const dateObject = new Date(date_string);
    const hours =
      dateObject.getHours() < 10
        ? '0' + dateObject.getHours().toString()
        : dateObject.getHours().toString();
    const minutes =
      dateObject.getMinutes() < 10
        ? '0' + dateObject.getMinutes().toString()
        : dateObject.getMinutes().toString();

    return hours + ':' + minutes + ' hrs';
  };

  const retrieveRecordData = async () => {
    try {
      const url = `/calls/${callId}`;
      const response = await apiInstance.get(url);
      const result = response.data;
      setCallType(result.motif === 'EXIT' ? 'Salida' : 'Entrada');
      setRecorDetail(result);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  return (
    <Modal
      centered
      visible={visible}
      onOk={closeModalTrigger}
      onCancel={closeModalTrigger}
      okText="CERRAR"
      cancelText="CANCELAR"
      maskClosable={false}
      footer={null}
      zIndex={2000}
      width={'600px'}
      destroyOnClose={true}
      title={
        loading ? null : error ? null : (
          <Row>
            <Col xs={5} sm={4} md={3}>
              {recorDetail.status === 'FINISHED' ? (
                <FeatureIcon feature="received-call" />
              ) : recorDetail.status === 'LOST' ? (
                <FeatureIcon feature="missed-call" />
              ) : recorDetail.call_type === 'IDCARD' ? (
                <FeatureIcon feature="id-card" />
              ) : recorDetail.call_type === 'REGISTRATION' ? (
                <FeatureIcon feature="hood" />
              ) : recorDetail.call_type === 'FACE_ID' ? (
                <FeatureIcon
                  feature="face-id"
                  denied={recorDetail.status === 'DENIED'}
                />
              ) : recorDetail.call_type === 'QQR' ? (
                <FeatureIcon feature="qqr-code" />
              ) : recorDetail.call_type === 'SELFIE' ? (
                <FeatureIcon feature="selfie" />
              ) : (
                <FeatureIcon feature="qr-code" />
              )}
            </Col>
            <Col xs={19} sm={20} md={21} style={{fontWeight: 'bold'}}>
              {recorDetail.status === 'FINISHED'
                ? `${callType} por llamada`
                : recorDetail.status === 'LOST'
                ? 'Llamada perdida'
                : recorDetail.call_type === 'IDCARD'
                ? `${callType} por ID`
                : recorDetail.call_type === 'REGISTRATION'
                ? `${callType} manual`
                : recorDetail.call_type === 'FACE_ID'
                ? `${callType} por Face ID ${
                    recorDetail.status === 'DENIED' ? '(Rechazada)' : ''
                  }`
                : recorDetail.call_type === 'QQR'
                ? `${callType} por Quick QR`
                : recorDetail.call_type === 'SELFIE'
                ? `${callType} por foto`
                : `${callType} por QR`}
            </Col>
          </Row>
        )
      }>
      {loading ? (
        <LoadingContent />
      ) : error ? (
        <NoContent emptyWhat={'network'} />
      ) : (
        <Row>
          <Col xs={24} sm={12} md={12}>
            <p style={{fontWeight: 'bold', padding: 0, margin: 0}}>
              {getHomesName()}
            </p>
            <p>
              {(recorDetail.home.number ? recorDetail.home.number : '') +
                (recorDetail.home.inside_number
                  ? ' - ' + recorDetail.home.inside_number
                  : '')}
            </p>
            <p style={{fontWeight: 'bold', padding: 0, margin: 0}}>
              {'Puerta'}
            </p>
            <p>{'No Disponible'}</p>
            <p style={{fontWeight: 'bold', padding: 0, margin: 0}}>{'Fecha'}</p>
            <p>{dateOfRecord(recorDetail.date)}</p>
            <p style={{fontWeight: 'bold', padding: 0, margin: 0}}>{'Hora'}</p>
            <p>{hourOfRecord(recorDetail.date)}</p>
            {recorDetail.status === 'FINISHED' && (
              <div>
                <p style={{fontWeight: 'bold', padding: 0, margin: 0}}>
                  {'Duración'}
                </p>
                <p>{recorDetail.duration}</p>
              </div>
            )}
          </Col>
          <Col xs={24} sm={12} md={0}>
            {recorDetail.images.length > 0 && (
              <ImageGrid data={recorDetail.images} />
            )}
          </Col>
          <Col xs={0} sm={0} md={12}>
            {recorDetail.images.length > 0 && (
              <ImageGrid data={recorDetail.images} />
            )}
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default CallDetailModal;
