/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {ROUTES} from '../../constants/Routes';
import {AuthContext} from '../../context/AuthContext';
import {isAuthenticated, getAccessLevel, getLevelConfig} from './auth';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export default function MixedShieldedRoute({children, routeName, ...rest}) {
  const levelConfig = getLevelConfig();
  const context: any = useContext(AuthContext);
  const availableRoutes = ROUTES[localStorage.getItem('availableRoutes')] || [
    'public',
  ];
  useEffect(() => {
    context.setGlobalState({showMenu: true});
  }, []);
  const root = getAccessLevel() === 'SUPER_ADMIN';
  const admin = getAccessLevel() === 'ADMIN';
  const authenticated = isAuthenticated();

  return (
    <Route
      {...rest}
      render={({location}) =>
        authenticated ? (
          root ? (
            children
          ) : admin &&
            levelConfig === 4 &&
            availableRoutes.includes(routeName) ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: '/accessDenied',
                state: {from: location},
              }}
            />
          )
        ) : (
          <Redirect
            to={{
              pathname:
                levelConfig === 4 || root ? '/' : '/digitalJourney/intro',
              state: {from: location},
            }}
          />
        )
      }
    />
  );
}
