const BASE_LIGHT_GRAY = '#E6E6E6';
const BASE_ORANGE = '#FBB03B';
const BASE_RED = '#FF0000';
const BASE_GREEN = '#39B54A';
const BASE_BLUE = '#29ABE2';
const BASE_PURPLE = '#784ABA';
const BASE_BLACK = '#000000';
const BASE_GRAY = '#CCCCCC';
const BASE_DARK_GRAY = '#545454';
const BRAND_LAGOON = '#51D3BD';
const BASE_WHITE = '#FFFFFF';
const BRAND_TURQUOISE = '#2EC3DF';
const BRAND_BLUE = '#081023';
const UTILITY_GREEN = '#4CD964';
const UTILITY_YELLOW = '#F7B31B';
const UTILITY_BLUE = '#167EFb';
const UTILITY_RED = '#EA0404';
const DISABLED_GRAY = '#F7F7F7';
const DEACTIVATED_ITEM = '#D9D9D9';
const MEDIUM_GRAY = '#AEAEAF';
const ANOTHER_GRAY = '#707070';

const dashBoardColors = [
  '#57d2bd',
  '#39c3dd',
  '#2bb1c3',
  '#191d3a',
  '#9aadd6',
  '#3b5691',
  '#666666',
  '#ee6055',
  '#ffd97d',
  '#7c77b9',
];

export {
  BASE_LIGHT_GRAY,
  BASE_ORANGE,
  BASE_RED,
  BASE_WHITE,
  BASE_GREEN,
  BASE_BLUE,
  BASE_PURPLE,
  BASE_BLACK,
  BASE_DARK_GRAY,
  BASE_GRAY,
  BRAND_TURQUOISE,
  BRAND_BLUE,
  BRAND_LAGOON,
  UTILITY_BLUE,
  UTILITY_GREEN,
  UTILITY_RED,
  UTILITY_YELLOW,
  DISABLED_GRAY,
  DEACTIVATED_ITEM,
  dashBoardColors,
  MEDIUM_GRAY,
  ANOTHER_GRAY,
};
