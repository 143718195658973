import React, {useState, useRef, useContext, useEffect} from 'react';
import {Col, Input, Row} from 'antd';
import * as Unicons from '@iconscout/react-unicons';
import {Link} from 'react-router-dom';
import * as Compress from 'compress.js';

//constants
import {
  BASE_WHITE,
  UTILITY_BLUE,
  UTILITY_YELLOW,
} from '../../constants/themeColors';
//styles
import {CreateNeighborhoodStyles as styles} from './styles';

//utils
import {
  editLocalNeigborhood,
  getUserHood,
  getUserToken,
} from '../../navigation/auth/auth';
// network
import {uploadFileToBlob} from '../../network/azureBlob';
import apiInstance from '../../network/axios';
//components
import {CircleButton} from '../../atoms';
import {useSasToken} from '../../hooks/useSasToken';
import LoadingContent from '../loading/LoadingContent';
import NoContent from '../empty_screens/empty_content/NoContent';
import Logo from '../../res/img/trans_128.png';
import {AuthContext} from '../../context/AuthContext';
import {AuthContextType} from '../../types/contextTypes';

const Neighborhood = () => {
  const {url: sasUrl, token: sasToken} = useSasToken();
  const inputRef: any = useRef();
  const [neighborhoodData, setNeighborhoodData] = useState(
    JSON.parse(localStorage.getItem('myNeighborhood')),
  );
  const userToken = getUserToken();
  const [name, setName] = useState(neighborhoodData?.number ?? '');
  const [address, setAddress] = useState(neighborhoodData?.address ?? '');
  const [description, setDescription] = useState(
    neighborhoodData?.description ?? '',
  );
  const [streetsName, setStreetsName] = useState(
    neighborhoodData?.street_name ?? '',
  );
  const [homesName, setHomesName] = useState(
    neighborhoodData?.house_name ?? '',
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const {
    setGlobalState,
    state: {hoodImagePath},
  } = useContext(AuthContext) as AuthContextType;

  const [image, setImage] = useState('');
  const [imageFile, setImageFile] = useState(null);
  const [validating, setValidating] = useState(false);
  const [hasImageChanged, setHasImageChanged] = useState(false);
  const [editing, setEditing] = useState(false);
  const resizeImageFn = async (file) => {
    const compress = new Compress();
    const resizedImage = await compress.compress([file], {
      size: 2,
      maxWidth: 1024,
      maxHeight: 1024,
      resize: true,
      quality: 0.95,
    });
    const img = resizedImage[0];
    const dataURLtoFile = (dataurl, filename) => {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type: mime});
    };
    const hoodId = getUserHood();
    const _file = dataURLtoFile(
      `data:image/png;base64,${img.data}`,
      `${hoodId}_image.png`,
    );
    return _file;
  };

  const sendImgToServer = async (id: string, imagePath: string) => {
    setLoading(true);
    try {
      const url = `/neighborhoods/${id}/image`;

      const body = {
        imagePath,
      };
      const response = await apiInstance.put(url, body);
      localStorage.setItem('myNeighborhood', JSON.stringify(response.data));
      setNeighborhoodData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('ERROR UPLOADIN IMAGE ', error?.response ?? error);
      setLoading(false);
      setError(true);
    }
  };

  const updateNeighborhood = async () => {
    try {
      setLoading(true);
      if (
        name === '' ||
        address === '' ||
        description === '' ||
        streetsName === '' ||
        homesName === ''
      ) {
        setValidating(true);
        setLoading(false);
        return;
      }

      const url = `/neighborhoods/${neighborhoodData._id}`;
      const body = {
        description,
        number: name,
        address: address,
        house_name: homesName,
        street_name: streetsName,
      };

      const resp = await apiInstance.put(url, body);

      editLocalNeigborhood(resp.data);
      localStorage.setItem('myNeighborhood', JSON.stringify(resp.data));
      setNeighborhoodData(resp.data);
      if (hasImageChanged) {
        const file = await resizeImageFn(imageFile);
        const imagePath = await uploadFileToBlob(file, sasUrl, sasToken);
        if (imagePath) {
          setGlobalState({hoodImagePath: imagePath});
          localStorage.setItem('hoodAvatar', imagePath);
        }
        await sendImgToServer(neighborhoodData._id, imagePath);
      }
      setEditing(false);
      setLoading(false);
    } catch (error) {
      console.error('ERROR UPDATING HOOD ', error?.response ?? error);
      setLoading(false);
      setError(true);
    }
  };

  useEffect(() => {
    const imageUrl = hoodImagePath.includes(
      'portosuploads.blob.core.windows.net',
    )
      ? `${hoodImagePath}?${sasToken}`
      : `${hoodImagePath}&token=${userToken}`;
    setImage(imageUrl);
  }, [sasToken, hoodImagePath, userToken]);

  if (loading) {
    return <LoadingContent />;
  }
  if (error) {
    return (
      <NoContent
        emptyWhat={'network'}
        withRetry
        onClickMethod={() => {
          setError(false);
          setLoading(true);
          updateNeighborhood();
        }}
      />
    );
  }
  const currentPlan = localStorage.getItem('currentPlan');
  const plan = {
    FREE: 'Paquete Básico',
    ADMIN: 'Paquete Administración',
    SECURITY: 'Paquete Seguridad',
  };
  return (
    <div style={styles.container}>
      <Row gutter={[50, 15]}>
        <Col xs={24} sm={12} style={{...styles.bold, padding: 10}}>
          {'Mi condominio'}
        </Col>
      </Row>
      <Row style={styles.rowSeparator} />
      <div style={styles.paper}>
        <Row>
          <h3>{'Información'}</h3>
          <Row>
            <Col span={24} style={styles.rowSeparator} />
          </Row>
          <Row gutter={[100, 40]}>
            <Col xs={24} md={12}>
              {'Nombre del vecindario'}
              <Input
                disabled={loading || !editing}
                value={name}
                onChange={(value) => setName(value.target.value)}
              />
              {validating && name === '' && (
                <p style={styles.validation}>
                  Ingrese el nombre del vecindario.
                </p>
              )}
            </Col>
            <Col xs={24} md={12}>
              {'Dirección del vecindario'}
              <Input
                disabled={loading || !editing}
                value={address}
                onChange={(value) => setAddress(value.target.value)}
              />
              {validating && address === '' && (
                <p style={styles.validation}>Ingrese la dirección.</p>
              )}
            </Col>
            <Col xs={24} md={12}>
              {'Descripción del vecindario'}
              <Input
                disabled={loading || !editing}
                value={description}
                onChange={(value) => setDescription(value.target.value)}
              />
              {validating && description === '' && (
                <p style={styles.validation}>Ingrese una descripción.</p>
              )}
            </Col>
            <Col xs={24} md={12}>
              {'Nombre de secciones (ej. Calle, Torre, etc)'}
              <Input
                disabled={loading || !editing}
                value={streetsName}
                onChange={(value) => setStreetsName(value.target.value)}
              />
              {validating && streetsName === '' && (
                <p style={styles.validation}>
                  Ingrese el nombre de las secciones.
                </p>
              )}
            </Col>
            <Col xs={24} md={12}>
              {'Nombre de vivienda (ej. Casa, Depa, etc)'}
              <Input
                disabled={loading || !editing}
                value={homesName}
                onChange={(value) => setHomesName(value.target.value)}
              />
              {validating && homesName === '' && (
                <p style={styles.validation}>
                  Ingrese el nombre de las viviendas.
                </p>
              )}
            </Col>
            <Col xs={24} md={12}>
              <div style={{display: 'flex', marginBottom: 5}}>
                {'Plan de Portos'}
                <Link style={{marginLeft: 10}} to="/updatePlan">
                  {'cambiar'}
                </Link>
              </div>
              <Input disabled value={plan[currentPlan]} />
            </Col>

            <Col xs={24} md={12}>
              {'Foto de perfil'}

              <br />
              <div style={styles.imageContainer}>
                <img
                  src={image !== null && image !== undefined ? image : Logo}
                  style={styles.image}
                  alt={'Foto de Perfil'}
                />
                <div
                  onClick={() =>
                    !loading && editing && inputRef.current.click()
                  }
                  style={{
                    ...styles.uploadImage,
                    cursor: loading || !editing ? 'no-drop' : 'pointer',
                  }}>
                  <Unicons.UilPen size="30" color={BASE_WHITE} />
                  <input
                    ref={inputRef}
                    style={styles.fileInput}
                    onChange={(event) => {
                      if (event?.target?.files.length > 0) {
                        setImageFile(event?.target?.files[0]);
                        setImage(URL.createObjectURL(event?.target?.files[0]));
                        setHasImageChanged(true);
                      }
                    }}
                    type="file"
                    pattern="([^\s]+(\.(?i)(jpg|png))$)"></input>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24} style={styles.rowSeparator} />
          </Row>
        </Row>

        <Row>
          <Col xs={16} sm={19} md={19} lg={19} xl={21}></Col>
          <Col
            xs={8}
            sm={5}
            xl={3}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <CircleButton
              variant="genericEdit"
              activeColor={UTILITY_YELLOW}
              disabled={loading}
              onClick={() => setEditing(!editing)}
              clickedMode={editing}
            />
            <CircleButton
              variant="save"
              activeColor={UTILITY_BLUE}
              disabled={!editing || loading}
              onClick={updateNeighborhood}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Neighborhood;
