import {Button, Col, Row} from 'antd';
import moment from 'moment';
import React, {useState} from 'react';
import {Modal} from '../../../molecules/Modal';
import NoContent from '../../empty_screens/empty_content/NoContent';
import LoadingContent from '../../loading/LoadingContent';
import {ICONS} from './Icons';
import {ReservationDetailStyles as styles} from '../styles';
import apiInstance from '../../../network/axios';

const ReservationDetail = ({item, visible, onCancel, onReload}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const deleteReservation = async () => {
    setLoading(true);
    try {
      const url = `/events/${item._id}`;
      await apiInstance.delete(url);
      onReload();
      setLoading(false);
      onCancel();
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };
  const handleDeleteReservation = () => {
    if (window.confirm('¿Esta seguro que desea cancelar esta reservación?')) {
      deleteReservation();
    }
  };
  return (
    <Modal
      icon={
        <div style={styles.iconContainer}>
          {ICONS[item?.area?.icon ?? 'GYM']}
        </div>
      }
      title={item?.area?.name ?? ''}
      visible={visible}
      onCancel={() => {
        onCancel();
      }}
      loading={loading}
      body={
        <div>
          {error ? (
            <NoContent
              emptyWhat={'network'}
              withRetry
              onClickMethod={() => {
                setError(false);
                setLoading(true);
              }}
            />
          ) : (
            <Row>
              <Col span={24}>
                <h3 style={styles.h3}>{'Fecha'}</h3>
                <h4 style={styles.h4}>
                  {moment(item?.date).format('LL') ?? ''}
                </h4>
                <h3 style={styles.h3}>{'Horario'}</h3>
                <h4 style={styles.h4}>{item?.time ?? ''}</h4>
                <h3 style={styles.h3}>{'Condominio'}</h3>
                <h4 style={styles.h4}>{item?.home ?? ''}</h4>
                <h3 style={styles.h3}>{'Condómino'}</h3>
                <h4 style={styles.h4}>{item?.owner ?? ''}</h4>
              </Col>
            </Row>
          )}
        </div>
      }
      footer={
        error ? null : (
          <Row>
            <Col span={18} offset={3}>
              <Button
                disabled={loading}
                key="del"
                block
                size="large"
                onClick={() => handleDeleteReservation()}
                style={styles.cancelButton}>
                {loading ? <LoadingContent /> : 'CANCELAR RESERVA'}
              </Button>
            </Col>
          </Row>
        )
      }
    />
  );
};

export default ReservationDetail;
