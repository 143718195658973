import {UilPlus} from '@iconscout/react-unicons';
import {Col, Input, Row} from 'antd';
import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {
  BASE_URL,
  DEVELOPMENT,
  NETWORK_TIMEOUT,
} from '../../../constants/network';
import {
  UTILITY_BLUE,
  UTILITY_RED,
  UTILITY_YELLOW,
} from '../../../constants/themeColors';
import {Modal} from '../../../molecules/Modal';
import {getUserHood, getUserToken} from '../../../navigation/auth/auth';
import NoContent from '../../empty_screens/empty_content/NoContent';
import {styles} from './styles';
import {CircleButton} from '../../../atoms';

const EditCategory = (props) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [validating, setValidating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [errorToDelete, setErrorToDelete] = useState(false);
  useEffect(() => {
    setName(props.data.name);
    setDescription(props.data.description);
  }, [props.data]);

  const handleUpdateCategory = () => {
    if (name === '' || description === '') {
      setValidating(true);
      return;
    }
    setError(false);
    setLoading(true);
    updateCategory();
  };
  const updateCategory = async () => {
    setLoading(true);
    try {
      const userNeighborhoodID = getUserHood();
      const userToken = getUserToken();
      const url = `${BASE_URL}/neighborhoods/${userNeighborhoodID}/accounting/categories`;
      const options = {
        headers: {
          'X-App': 'admin',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + userToken,
        },
        timeout: NETWORK_TIMEOUT,
      };
      const body = {
        name,
        description,
        category_id: props.data._id,
      };
      const response = await axios.put(url, body, options);
      if (response.data.success) {
        setLoading(false);
        setEditMode(false);
        props.onCancel();
        props.reload();
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      if (DEVELOPMENT) {
        console.log('Error updating category', error?.response ?? error);
      }
      setLoading(false);
      setError(true);
    }
  };

  const deleteCategory = async () => {
    setLoadingDelete(true);
    try {
      const userNeighborhoodID = getUserHood();
      const userToken = getUserToken();
      const url = `${BASE_URL}/neighborhoods/${userNeighborhoodID}/accounting/category`;
      const options = {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'X-App': 'admin',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + userToken,
        },
        data: {
          category_id: props.data._id,
        },
        timeout: NETWORK_TIMEOUT,
      };
      const response = await axios.delete(url, options);
      if (response.data.success) {
        setLoadingDelete(false);
        setEditMode(false);
        props.onCancel();
        props.reload();
      } else {
        setLoading(false);
        setLoadingDelete(false);
        setErrorToDelete(true);
      }
    } catch (error) {
      if (DEVELOPMENT) {
        console.log('Error deleting category', error?.response ?? error);
      }
      setLoading(false);
      setLoadingDelete(false);
      setErrorToDelete(true);
    }
  };
  const handleDeleteCategory = () => {
    if (window.confirm('¿Esta seguro que desea eliminar este elemento?')) {
      setLoadingDelete(true);
      deleteCategory();
    }
  };

  const {TextArea} = Input;
  return (
    <Modal
      icon={
        <div
          style={{
            width: 40,
            height: 40,
            borderRadius: 20,
            backgroundColor: props.iconColor ?? 'red',
            padding: 5,
          }}>
          <UilPlus size="30" color="#FFFFFF" />
        </div>
      }
      title={props.title}
      visible={props.visible}
      onOk={handleUpdateCategory}
      onCancel={() => {
        props.onCancel();
        setError(false);
        setLoadingDelete(false);
        setEditMode(false);
        setErrorToDelete(false);
      }}
      loading={loading || loadingDelete}
      okButton={'GUARDAR'}
      body={
        <div>
          {error || errorToDelete ? (
            <NoContent
              emptyWhat={errorToDelete ? 'deleteCategory' : 'network'}
              withRetry
              onClickMethod={() => {
                setError(false);
                setLoading(true);
                if (errorToDelete) {
                  deleteCategory();
                } else {
                  handleUpdateCategory();
                }
                setErrorToDelete(false);
              }}
            />
          ) : (
            <Row>
              <Col span={24}>
                <h3 style={styles.h3}>{'Nombre'}</h3>
                <Input
                  disabled={!editMode || loading}
                  value={name}
                  onChange={(value) => setName(value.target.value)}
                />
                {validating && name === '' && (
                  <p style={styles.validation}>El nombre es requerido.</p>
                )}
                <h3 style={styles.h3}>{'Descripción'}</h3>
                <TextArea
                  disabled={!editMode || loading}
                  rows={4}
                  value={description}
                  onChange={(value) => setDescription(value.target.value)}
                />
                {validating && description === '' && (
                  <p style={styles.validation}>La descripción es requerida.</p>
                )}
              </Col>
            </Row>
          )}
        </div>
      }
      footer={
        error ? null : (
          <Row>
            <Col span={2}>
              <CircleButton
                variant="del"
                activeColor={UTILITY_RED}
                disabled={loading}
                onClick={handleDeleteCategory}
              />
            </Col>
            <Col span={22}>
              <CircleButton
                variant="genericEdit"
                activeColor={UTILITY_YELLOW}
                disabled={loading}
                onClick={() => setEditMode(!editMode)}
                clickedMode={editMode}
              />
              <CircleButton
                variant="save"
                activeColor={UTILITY_BLUE}
                disabled={!editMode || loading}
                onClick={handleUpdateCategory}
              />
            </Col>
          </Row>
        )
      }
    />
  );
};

export default EditCategory;
