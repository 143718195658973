/* eslint-disable react-hooks/exhaustive-deps */
import React, {Dispatch, SetStateAction} from 'react';
import {PayPalButtons, usePayPalScriptReducer} from '@paypal/react-paypal-js';
import {useEffect} from 'react';
import apiInstance from '../network/axios';
import {onAdminLogin} from '../screens/login/components/WrappedFormContent';

interface IButtonWrapper {
  type: string;
  planId: string;
  quantity: string;
  history: any;
  plan: string;
  disabled?: boolean;
  onError: {
    setError: Dispatch<SetStateAction<boolean>>;
    setErrorType: Dispatch<SetStateAction<string>>;
  };
  onApprove?: () => void;
}
interface IUpdateSubscription {
  subscriptionId: string;
  history: any;
  plan: string;
  onError: {
    setError: Dispatch<SetStateAction<boolean>>;
    setErrorType?: Dispatch<SetStateAction<string>>;
  };
  onApprove?: () => void;
}

export const ButtonWrapper = ({
  type,
  planId,
  quantity,
  history,
  plan,
  onError,
  onApprove,
  disabled,
}: IButtonWrapper) => {
  const [{options}, dispatch] = usePayPalScriptReducer();
  useEffect(() => {
    dispatch({
      type: 'resetOptions',
      value: {
        ...options,
        intent: 'subscription',
      },
    });
  }, [type, planId, quantity]);

  return (
    <PayPalButtons
      disabled={disabled}
      createSubscription={(_, actions) => {
        return actions.subscription
          .create({
            plan_id: planId,
            quantity,
          })
          .then((orderId) => {
            return orderId;
          });
      }}
      onApprove={async (data) => {
        await updateSubscriptionId({
          subscriptionId: data.subscriptionID,
          history,
          plan,
          onError,
          onApprove,
        });
      }}
      style={{
        label: 'subscribe',
      }}
    />
  );
};

export const updateSubscriptionId = async ({
  subscriptionId,
  history,
  plan,
  onError,
  onApprove,
}: IUpdateSubscription) => {
  try {
    await apiInstance.put(`/paypal/subscription`, {
      subscriptionId,
      current_plan: plan,
    });
    if (onApprove) {
      onApprove();
    }
    refreshNeighborhood(history, onError, onApprove ? false : true);
  } catch (e) {
    onError.setErrorType('payment');
    onError.setError(true);
    console.error('error updating subscription id', e?.response?.data ?? e);
  }
};
export const refreshNeighborhood = async (
  history,
  onError,
  needRedirect = true,
) => {
  try {
    const url = '/paypal/confirmed';
    const resp = await apiInstance.get(url);
    onAdminLogin(resp.data, history, needRedirect);
  } catch (e) {
    if (onError) {
      onError.setErrorType('refreshSesion');
      onError.setError(true);
    }
    console.error(e);
  }
};
