import {CSSProperties} from 'react';
import {
  ANOTHER_GRAY,
  BASE_WHITE,
  BRAND_LAGOON,
  UTILITY_RED,
} from '../../constants/themeColors';

export const CommonAreasStyles: Record<string, CSSProperties> = {
  main: {
    padding: '6.25% 4.166%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    minHeight: '70vh',
    color: '#A1A1A1',
  },
  mainContainer: {padding: '6.25% 4.166%'},
  rowSeparator: {height: '32px'},
  buttonContainer: {
    backgroundColor: '#39C3DD',
    overflow: 'hidden',
    borderRadius: 5,
  },
  configTableHeader: {
    borderStyle: 'solid',
    borderColor: '#70707078',
    borderWidth: 1,
    borderRadius: 0,
    borderBottomColor: 'white',
    padding: 6,
    paddingLeft: 10,
    paddingRight: 10,
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },
  listTitle: {
    fontSize: '1.3em',
    fontWeight: '600',
    padding: 0,
    margin: 0,
  },
  list: {
    minHeight: '50px',
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderColor: '#70707078',
    borderWidth: 1,
    borderRadius: 0,
    paddingLeft: 10,
    paddingRight: 10,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
  iconContainer: {
    width: 50,
    height: 50,
    borderRadius: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    backgroundColor: 'transparent',
    color: BASE_WHITE,
    fontWeight: 'bolder',
    borderColor: 'transparent',
  },
  detailButton: {borderColor: '#545454'},
  title: {textAlign: 'center', fontWeight: 'bold', fontSize: 30},
  message: {textAlign: 'center', fontWeight: 'bold', fontSize: 28},
  message2: {textAlign: 'center', fontWeight: '500', fontSize: 18},
  validation: {color: 'red'},
  h3: {color: '#707070', marginTop: '20px'},
  item: {width: '100%'},
  numberInput: {width: '95%'},
  timePicker: {width: '100%'},
  icon: {width: '70%', height: '70%'},
  option: {
    width: 70,
    height: 70,
    padding: 5,
    margin: 10,
    marginTop: 5,
    marginBottom: 5,
    borderRadius: 5,
    borderColor: ANOTHER_GRAY,
    borderStyle: 'solid',
    borderWidth: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedOption: {
    width: 70,
    height: 70,
    backgroundColor: BRAND_LAGOON,
    borderRadius: 10,
    borderColor: ANOTHER_GRAY,
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: -32,
    position: 'relative',
    zIndex: +10,
  },
  bold: {fontSize: '20px', fontWeight: 700, margin: 0, padding: 0},
};

export const ReservationsStyles: Record<string, CSSProperties> = {
  mainContainer: {padding: '6.25% 4.166%'},
  bold: {fontSize: '20px', fontWeight: 'bold', margin: 0, padding: 0},
  dashboardListTitle: {
    fontSize: '1.3em',
    fontWeight: '500',
  },
  configTablePaginationColumnCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 10px',
    fontWeight: '600',
  },
  dashboardList: {
    display: 'flex',
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: '#D9D9D9',
    borderStyle: 'solid',
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    padding: 15,
    minHeight: 90,
  },
  rowSeparator: {height: '32px'},
  configTableHeader: {
    borderStyle: 'solid',
    borderColor: '#70707078',
    borderWidth: 1,
    borderBottomColor: 'white',
    padding: 6,
    paddingLeft: 10,
    paddingRight: 10,
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    display: 'flex',
    justifyContent: 'space-evently',
  },
  configList: {
    minHeight: '50px',
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderColor: '#70707078',
    borderWidth: 1,
    borderRadius: 0,
    paddingLeft: 10,
    paddingRight: 10,
    borderBottomRightRadius: 5,
    borderBottomLeftRadius: 5,
  },
};

export const ReservationDetailStyles: Record<string, CSSProperties> = {
  iconContainer: {
    width: 50,
    height: 50,
    borderRadius: 25,
    backgroundColor: BRAND_LAGOON,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  h3: {color: ANOTHER_GRAY, marginTop: '20px'},
  h4: {color: ANOTHER_GRAY, marginLeft: 15},
  cancelButton: {
    paddingTop: '4px',
    backgroundColor: UTILITY_RED,
    color: BASE_WHITE,
  },
};
