import React, {useState} from 'react';
import {Col, Row, Modal, Input} from 'antd';

import {
  UTILITY_RED,
  UTILITY_YELLOW,
  UTILITY_BLUE,
} from '../../../constants/themeColors';
import {getStreetsName} from '../../../navigation/auth/auth';
import LoadingContent from '../../loading/LoadingContent';
import NoContent from '../../empty_screens/empty_content/NoContent';
import FeatureIcon from '../../../general_components/FeatureIcon/FeatureIcon';
import apiInstance from '../../../network/axios';
import {CircleButton} from '../../../atoms';

interface IStreetEditor {
  visible: boolean;
  closeModalTrigger: () => void;
  closeRefreshAfterCreate: () => void;
  streetObject: {
    id: string;
    name: string;
  };
}

const StreetEditor = ({
  closeModalTrigger,
  closeRefreshAfterCreate,
  streetObject,
  visible,
}: IStreetEditor) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [name, setName] = useState<string>(streetObject.name);
  const [editMode, setEditMode] = useState<boolean>(false);

  const onChangeStreetName = (event) => {
    setName(event.target.value);
  };

  const validateEdit = () => {
    if (!name) {
      alert('Ingrese Nombre para continuar');
      return;
    }
    setLoading(true);
    putEditStreet();
  };

  const putEditStreet = async () => {
    try {
      const url = `/streets/${streetObject.id}`;
      const body = {
        name: name,
      };

      await apiInstance.put(url, body);
      setLoading(false);
      closeRefreshAfterCreate();
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const confirmDeleteStreet = () => {
    if (window.confirm('¿Esta seguro que desea eliminar este elemento?')) {
      setLoading(true);
      deleteStreet();
    }
  };

  const deleteStreet = async () => {
    try {
      const url = `/streets/${streetObject.id}`;
      await apiInstance.delete(url);

      setLoading(false);
      closeRefreshAfterCreate();
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const toggleEditMode = () => {
    if (editMode) {
      if (window.confirm('¿Abandonar modo de edición?')) {
        setName(streetObject.name);
        setEditMode(!editMode);
      } else {
        return;
      }
    } else {
      setEditMode(!editMode);
    }
  };

  return (
    <Modal
      centered
      visible={visible}
      onOk={validateEdit}
      onCancel={closeModalTrigger}
      okText="AGREGAR"
      cancelText="CANCELAR"
      maskClosable={false}
      zIndex={2000}
      destroyOnClose={true}
      closable={!loading}
      footer={
        error ? null : (
          <Row>
            <Col span={2}>
              <CircleButton
                variant="del"
                activeColor={UTILITY_RED}
                disabled={loading}
                onClick={confirmDeleteStreet}
              />
            </Col>
            <Col span={22}>
              <CircleButton
                variant="genericEdit"
                activeColor={UTILITY_YELLOW}
                disabled={loading}
                onClick={toggleEditMode}
                clickedMode={editMode}
              />

              <CircleButton
                variant="save"
                activeColor={UTILITY_BLUE}
                disabled={!editMode ? true : loading}
                onClick={validateEdit}
              />
            </Col>
          </Row>
        )
      }
      title={
        <Row>
          <Col xs={5} sm={4} md={3}>
            <FeatureIcon feature="street" />
          </Col>
          <Col xs={19} sm={20} md={21} style={{fontWeight: 'bold'}}>
            {editMode ? 'Editar ' + getStreetsName() : name}
          </Col>
        </Row>
      }>
      {loading ? (
        <LoadingContent />
      ) : error ? (
        <NoContent emptyWhat={'network'} />
      ) : (
        <Row>
          <Col span={24}>
            <h3>{'Nombre'}</h3>
            <Input
              readOnly={!editMode}
              value={name}
              placeholder="Nombre"
              style={{marginBottom: '20px'}}
              onChange={onChangeStreetName}
            />
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default StreetEditor;
