import React from 'react';

const FormTitle = () => {
  return (
    <div>
      <h2 style={{fontWeight: 'bold'}}>{'¡Bienvenido!'}</h2>
      <h4 style={{marginBottom: '5%'}}>{'Panel de Administración'}</h4>
    </div>
  );
};

export default FormTitle;
