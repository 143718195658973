import {CSSProperties} from 'react';
import {BASE_WHITE, UTILITY_YELLOW} from '../../constants/themeColors';

export const CreateNeighborhoodStyles: Record<string, CSSProperties> = {
  container: {padding: '6.25% 4.166%'},
  bold: {fontSize: '20px', fontWeight: 'bold', margin: 0, padding: 0},
  paper: {
    backgroundColor: 'white',
    padding: 40,
    borderStyle: 'solid',
    borderColor: '#70707078',
    borderWidth: 1,
    borderRadius: 5,
  },
  mainContainer: {padding: '6.25% 4.166%'},
  rowSeparator: {height: '32px'},
  item: {width: '100%'},
  validation: {color: 'red'},
  imageContainer: {
    width: 122,
    height: 120,
    display: 'flex',
    position: 'relative',
    borderRadius: 60,
    border: 'solid #00000021 1px',
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
  },
  uploadImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    height: 60,
    width: 120,
    top: 60,
    zIndex: +1,
    backgroundColor: '#00000080',
    borderBottomRightRadius: 60,
    borderBottomLeftRadius: 60,
    overflow: 'hiden',
    cursor: 'pointer',
  },
  fileInput: {display: 'none'},
  editButton: {
    paddingTop: '4px',
    backgroundColor: UTILITY_YELLOW,
  },
  saveButton: {
    paddingTop: '4px',
  },
};

export const DJIntroStyles: Record<string, CSSProperties> = {
  main: {padding: '6.25% 4.166%'},
  linkContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '20px',
  },
  linkNumber: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '50px',
    height: '50px',
    borderRadius: '25px',
    borderStyle: 'solid',
    borderWidth: '5px',
    fontWeight: 'bolder',
    fontSize: '22px',
  },
  line: {fontWeight: 'bolder', fontSize: '22px', marginLeft: '15px'},
  container: {
    padding: '6.25% 4.166%',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    color: '#A1A1A1',
    fontSize: '20px',
    fontWeight: 'bold',
    margin: '40px 0',
    textAlign: 'center',
  },
  buttonContainer: {
    backgroundColor: '#39C3DD',
    overflow: 'hidden',
    borderRadius: 5,
  },
  button: {
    backgroundColor: 'transparent',
    color: BASE_WHITE,
    fontWeight: 'bolder',
    borderColor: 'transparent',
  },
  statusContainer: {
    width: '80%',
    backgroundColor: 'white',
    height: '30px',
    borderColor: '70',
    boxShadow: '0px 3px 6px #00000029',
    overflow: 'hiden',
    borderRadius: '5px',
  },
  progress: {borderRadius: '5px', backgroundColor: '#60D7E6', height: '100%'},
};

export const DJStepHeaderStyles: Record<string, CSSProperties> = {
  header: {padding: '1% 4.166% 0 4.166%'},
};

export const RegisterIndicatorStyles: Record<string, CSSProperties> = {
  main: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'flex-end',
  },
  col: {
    backgroundColor: BASE_WHITE,
    borderRadius: 15,
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    color: '#60D7E6',
    marginBottom: 5,
    fontSize: 14,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  step: {
    color: '#60D7E6',
    marginBottom: 5,
    fontSize: 20,
    fontWeight: 'bolder',
    textAlign: 'center',
  },
  arrow: {
    cursor: 'pointer',
    borderStyle: 'solid',
    borderWidth: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    margin: 5,
  },
};

export const DJCreateOwnerPassStyles: Record<string, CSSProperties> = {
  main: {
    backgroundColor: '#081023',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    justifyContent: 'center',
    color: BASE_WHITE,
    padding: '10vh 5vw',
  },
  logo: {
    position: 'absolute',
    top: '2vh',
    left: '4vh',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  left: {
    maxWidth: '95%',
    textAlign: 'center',
    display: 'flex',
    flex: 1,
    minHeight: 350,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  validation: {color: 'red'},
  imageDone: {width: 100},
  title: {
    color: BASE_WHITE,
    fontSize: 32,
    marginBottom: 30,
    marginTop: 50,
  },
  message: {textAlign: 'center', fontSize: 25, marginBottom: 50},
  inputContainer: {width: '90%', marginTop: 20},
  input: {
    backgroundColor: '#FFFFFF00',
    color: BASE_WHITE,
    paddingLeft: 15,
    fontSize: 18,
  },
  button: {
    backgroundColor: '#60D7E6',
    marginTop: 20,
    color: BASE_WHITE,
    fontWeight: 'bold',
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 400,
  },
  store: {height: 80, cursor: 'pointer'},
  building: {maxWidth: '80%'},
};

export const DJVerifyEmailStyles: Record<string, CSSProperties> = {
  main: {
    backgroundColor: '#081023',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    justifyContent: 'center',
    color: BASE_WHITE,
    padding: '10vh 5vw',
  },
  logo: {
    position: 'absolute',
    top: '2vh',
    left: '4vh',
  },
  left: {
    maxWidth: '95%',
    textAlign: 'center',
    display: 'flex',
    flex: 1,
    minHeight: 350,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  imageDone: {width: 100},
  title: {
    color: BASE_WHITE,
    fontSize: 32,
    marginBottom: 30,
    marginTop: 50,
  },
  message: {textAlign: 'center', fontSize: 25, marginBottom: 50},
  button: {
    backgroundColor: '#4AD3BC',
    marginTop: 20,
    color: BASE_WHITE,
    fontWeight: 'bold',
    minWidth: 200,
  },
};

export const DJAccessDeniedStyles: Record<string, CSSProperties> = {
  main: {
    padding: '6.25% 4.166%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    minHeight: '70vh',
    color: '#A1A1A1',
  },

  buttonContainer: {
    backgroundColor: '#39C3DD',
    overflow: 'hidden',
    borderRadius: 5,
  },
  button: {
    backgroundColor: 'transparent',
    color: BASE_WHITE,
    fontWeight: 'bolder',
    borderColor: 'transparent',
  },
  title: {textAlign: 'center', fontWeight: 'bold', fontSize: 30},
  message: {textAlign: 'center', fontWeight: 'bold', fontSize: 28},
  message2: {textAlign: 'center', fontWeight: '500', fontSize: 18},
};
export const DJSelectPlanStyles: Record<string, CSSProperties> = {
  main: {
    padding: '3.25% 4.166%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  panel: {
    minHeight: '80vh',
    backgroundColor: BASE_WHITE,
    boxShadow: '0px 3px 6px #00000029',
    padding: 30,
    paddingTop: 30,
    paddingBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 30,
    width: '100%',
  },
  buttonContainer: {
    backgroundColor: '#39C3DD',
    overflow: 'hidden',
    borderRadius: 5,
  },
  button: {
    backgroundColor: 'transparent',
    color: BASE_WHITE,
    fontWeight: 'bolder',
    borderColor: 'transparent',
  },
  title: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
    color: '#06090E',
  },
  message: {textAlign: 'center', fontWeight: 'bold', fontSize: 28},
  message2: {textAlign: 'center', fontWeight: '500', fontSize: 18},
  benefitsContainer: {
    width: '100%',
  },
  benefitContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 20,
  },
  benefit: {
    marginLeft: 10,
    marginBottom: 0,
  },
  priceContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  price: {
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 20,
    fontWeight: 'bold',
  },
};
