import axios from 'axios';
import {BASE_URL, NETWORK_TIMEOUT} from '../constants/network';
import {getUserToken} from '../navigation/auth/auth';

const apiInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'X-App': 'admin',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  timeout: NETWORK_TIMEOUT,
});

apiInstance.interceptors.request.use(
  async (config) => {
    const token = getUserToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    } else {
      delete apiInstance.defaults.headers.common.Authorization;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

export default apiInstance;
