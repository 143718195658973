import {useEffect, useState} from 'react';
import apiInstance from '../network/axios';
import {getUserHood} from '../navigation/auth/auth';

type TSas = {
  url: string;
  token: string;
};

export const useSasToken = () => {
  const [data, setData] = useState<TSas | null>(null);
  const getSas = async () => {
    try {
      const neighborhoodId = getUserHood();
      const respkey = await apiInstance.get(
        `/neighborhoods/${neighborhoodId}/getBlobKey`,
      );

      const sas: string = respkey?.data?.containerSas ?? '';
      const [sasUrl, sasToken] = sas.split('?');
      const expirationDate = respkey?.data?.expiryDate ?? '';
      const time = new Date(expirationDate).getTime();
      localStorage.setItem('sasExpirationDate', String(time));
      localStorage.setItem('sasUrl', sasUrl || '');
      localStorage.setItem('sasToken', sasToken || '');
      setData({
        url: sasUrl || '',
        token: sasToken || '',
      });
    } catch (e) {
      console.error(
        'Error geting sas token in request',
        e?.response?.data ?? e,
      );
    }
  };

  useEffect(() => {
    const checkExpirationDate = async () => {
      try {
        const expirationDate = localStorage.getItem('sasExpirationDate');
        const time = expirationDate ? Number(expirationDate) : null;
        const now = new Date().getTime();
        if (time && now < time) {
          const url = localStorage.getItem('sasUrl');
          const token = localStorage.getItem('sasToken');
          setData({
            url: url as string,
            token: token as string,
          });
        } else {
          await getSas();
        }
      } catch (e) {
        console.error('Error geting sas token', e?.response?.data ?? e);
      }
    };
    checkExpirationDate();
  }, []);

  return {...data};
};
