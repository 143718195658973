import {Col, Input, Row, Select, Tooltip} from 'antd';
import React, {useState, useRef} from 'react';
import {BASE_WHITE, UTILITY_BLUE} from '../../constants/themeColors';
import * as Unicons from '@iconscout/react-unicons';
import {CreateNeighborhoodStyles as styles} from './styles';
import Logo from '../../res/img/trans_128.png';
import {BASE_URL, NETWORK_TIMEOUT} from '../../constants/network';
import {
  getLevelConfig,
  getUserHood,
  getUserToken,
  newHood,
} from '../../navigation/auth/auth';
import axios from 'axios';
import LoadingContent from '../loading/LoadingContent';
import NoContent from '../empty_screens/empty_content/NoContent';
import {useHistory} from 'react-router';
import * as Compress from 'compress.js';
import RegisterIndicator from './components/RegisterIndicator';
import {CircleButton} from '../../atoms';

const DJCreateNeighborhood = () => {
  const history = useHistory();
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [description, setDescription] = useState('');
  const [streetsName, setStreetsName] = useState('');
  const [homesName, setHomesName] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [image, setImage] = useState(undefined);
  const [done, setDone] = useState(false);

  const [validating, setValidating] = useState(false);

  const resizeImageFn = async (file) => {
    const compress = new Compress();
    const resizedImage = await compress.compress([file], {
      size: 2,
      maxWidth: 1024,
      maxHeight: 1024,
      resize: true,
      quality: 0.95,
    });
    const img = resizedImage[0];
    const dataURLtoFile = (dataurl, filename) => {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type: mime});
    };
    const hoodId = getUserHood();
    const _file = dataURLtoFile(
      `data:image/png;base64,${img.data}`,
      `${hoodId}_image.png`,
    );
    return _file;
  };

  const sendImgToServer = async (id, file) => {
    try {
      const userToken = getUserToken();

      const url = BASE_URL + `/neighborhoods/${id}/image`;

      let formData = new FormData();
      formData.append('image', file);

      const options = {
        headers: {
          'X-App': 'admin',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + userToken,
        },
        timeout: NETWORK_TIMEOUT,
      };

      const response = await axios.put(url, formData, options);
      const result = response.data;
      console.log('put result image: ', result);
      setLoading(false);
    } catch (error) {
      console.log('ERROR UPLOADIN IMAGE ', error?.response ?? error);
      setLoading(false);
      setError(true);
    }
  };

  const createNeighborhood = async () => {
    try {
      setLoading(true);
      if (
        name === '' ||
        address === '' ||
        description === '' ||
        streetsName === '' ||
        homesName === ''
      ) {
        setValidating(true);
        setLoading(false);
        return;
      }

      const userToken = getUserToken();
      const url = BASE_URL + '/neighborhoods/register_neighborhood';
      const body = {
        description,
        number: name,
        address: address,
        house_name: homesName,
        street_name: streetsName,
      };
      const options = {
        headers: {
          'X-App': 'admin',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + userToken,
        },
        timeout: NETWORK_TIMEOUT,
      };
      const resp = await axios.post(url, body, options);
      console.log('RESP POST HOOD', resp);
      if (resp.status === 200) {
        if (image !== undefined) {
          const file = await resizeImageFn(image);
          await sendImgToServer(resp.data.id, file);
        }
        localStorage.setItem('levelConfig', '1');
        setLoading(false);
        newHood(resp.data, () => {
          history.push('/digitalJourney/intro');
        });
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      console.log('ERROR CREATING HOOD ', error?.response?.data ?? error);
      const message = error?.response?.data?.message ?? '';
      if (message === 'AlreadyRegistered') {
        setDone(true);
      }
      setLoading(false);
      setError(true);
    }
  };

  const inputRef = useRef<any>();
  const {Option} = Select;
  if (loading) {
    return <LoadingContent />;
  }
  if (error) {
    return (
      <NoContent
        emptyWhat={done ? 'neighborhoodCreatedAlredy' : 'network'}
        withRetry
        onClickMethod={() => {
          setError(false);
          setLoading(true);
          createNeighborhood();
        }}
      />
    );
  }
  return (
    <div style={styles.container}>
      <Row gutter={[50, 15]}>
        <Col xs={24} sm={12} style={{...styles.bold, padding: 10}}>
          {'Nuevo condominio'}
        </Col>
        <Col xs={24} sm={12}>
          <RegisterIndicator
            disabledPrev
            disabledNext={getLevelConfig() === 0}
            onClickNext={() => {
              history.push('/digitalJourney/createStreets');
            }}
            level={1}
          />
        </Col>
      </Row>
      <Row style={styles.rowSeparator} />
      <div style={styles.paper}>
        <Row>
          <h3>{'Información'}</h3>
          <Row>
            <Col span={24} style={styles.rowSeparator} />
          </Row>
          <Row gutter={[100, 40]}>
            <Col xs={24} md={12}>
              {'Nombre del vecindario'}
              <Input
                disabled={loading}
                value={name}
                onChange={(value) => setName(value.target.value)}
              />
              {validating && name === '' && (
                <p style={styles.validation}>
                  Ingrese el nombre del vecindario.
                </p>
              )}
            </Col>
            <Col xs={24} md={12}>
              {'Dirección del vecindario'}
              <Input
                disabled={loading}
                value={address}
                onChange={(value) => setAddress(value.target.value)}
              />
              {validating && address === '' && (
                <p style={styles.validation}>Ingrese la dirección.</p>
              )}
            </Col>
            <Col xs={24} md={12}>
              {'Descripción del vecindario'}
              <Input
                disabled={loading}
                value={description}
                onChange={(value) => setDescription(value.target.value)}
              />
              {validating && description === '' && (
                <p style={styles.validation}>Ingrese una descripción.</p>
              )}
            </Col>
            <Col xs={24} md={12}>
              {'Nombre de secciones (ej. Calle, Torre, etc)'}
              <Input
                disabled={loading}
                value={streetsName}
                onChange={(value) => setStreetsName(value.target.value)}
              />
              {validating && streetsName === '' && (
                <p style={styles.validation}>
                  Ingrese el nombre de las secciones.
                </p>
              )}
            </Col>
            <Col xs={24} md={12}>
              {'Nombre de vivienda (ej. Casa, Depa, etc)'}
              <Input
                disabled={loading}
                value={homesName}
                onChange={(value) => setHomesName(value.target.value)}
              />
              {validating && homesName === '' && (
                <p style={styles.validation}>
                  Ingrese el nombre de las viviendas.
                </p>
              )}
            </Col>
            <Col xs={24} md={12}>
              <div style={{display: 'flex', marginBottom: 5}}>
                {'Plan de Portos'}
                <div
                  style={{
                    backgroundColor: '#5FD8E6',
                    display: 'flex',
                    marginLeft: 10,
                    borderRadius: 10,
                  }}>
                  <Tooltip
                    placement="top"
                    title={
                      <div>
                        <p>Podrás actualizar tu plan mas adelante.</p>
                      </div>
                    }>
                    <Unicons.UilQuestionCircle size="20" color={BASE_WHITE} />
                  </Tooltip>
                </div>
              </div>
              <Select disabled={true} defaultValue={0} style={styles.item}>
                <Option value={0}>Paquete Básico</Option>
                <Option value={1}>Paquete Administración</Option>
                <Option value={2}>Paquete Seguridad</Option>
              </Select>
            </Col>
            {/* {selectedPlan === 2 && (
              <Col xs={24} sm={24} md={12}>
                {'Opciones de entrada'}
                <br />
                <CheckboxGroup
                  options={options}
                  defaultValue={services}
                  onChange={onChangeOptions}
                />
              </Col>
            )} */}

            <Col xs={24} md={12}>
              {'Foto de perfil'}

              <br />
              <div style={styles.imageContainer}>
                <img
                  src={
                    image !== null && image !== undefined
                      ? URL.createObjectURL(image)
                      : Logo
                  }
                  style={styles.image}
                  alt={'Foto de Perfil'}
                />
                <div
                  onClick={() => !loading && inputRef.current.click()}
                  style={styles.uploadImage}>
                  <Unicons.UilPen size="30" color={BASE_WHITE} />
                  <input
                    ref={inputRef}
                    style={styles.fileInput}
                    onChange={(event) => {
                      setImage(event.target.files[0]);
                    }}
                    type="file"
                    placeholder=""
                    pattern="([^\s]+(\.(?i)(jpg|png))$)"></input>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24} style={styles.rowSeparator} />
          </Row>
        </Row>

        <Row>
          <Col xs={24} sm={22}></Col>
          <Col xs={4} sm={2}>
            <CircleButton
              variant="save"
              activeColor={UTILITY_BLUE}
              disabled={loading}
              onClick={createNeighborhood}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DJCreateNeighborhood;
