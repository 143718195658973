import {Col, Row} from 'antd';
import React, {useState} from 'react';
import {useHistory} from 'react-router';
import {refreshNeighborhood} from '../../molecules/Paypal';
import {getLevelConfig} from '../../navigation/auth/auth';
import Owners from '../owners/Owners';
import RegisterIndicator from './components/RegisterIndicator';
import {DJStepHeaderStyles as styles} from './styles';

const DJCreateOwners = () => {
  const history = useHistory();
  const [levelConfig, setLevelConfig] = useState(getLevelConfig());
  return (
    <div>
      <Row style={styles.header}>
        <Col xs={24} sm={12} />
        <Col xs={24} sm={12}>
          <RegisterIndicator
            level={4}
            disabledNext={levelConfig <= 3}
            onClickPrev={() => {
              history.push('/digitalJourney/createHomes');
            }}
            onClickNext={() => {
              if (levelConfig > 3) {
                refreshNeighborhood(history, null, true);
              }
            }}
          />
        </Col>
      </Row>
      <Owners history={history} onFirstCreate={() => setLevelConfig(4)} />;
    </div>
  );
};

export default DJCreateOwners;
