/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Col, Row, Modal, Input, DatePicker, List, Select} from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

//local imports
import {convertToMomentObj} from '../../../utils/locale/chronos';
import {
  UTILITY_GREEN,
  UTILITY_RED,
  UTILITY_YELLOW,
  UTILITY_BLUE,
} from '../../../constants/themeColors';
import LoadingContent from '../../loading/LoadingContent';
import NoContent from '../../empty_screens/empty_content/NoContent';
import FeatureIcon from '../../../general_components/FeatureIcon/FeatureIcon';
import apiInstance from '../../../network/axios';
import {CircleButton} from '../../../atoms';

interface IMessageViewer {
  visible: boolean;
  closeModalTrigger: () => void;
  closeRefreshAfterCreate: () => void;
  executivePrivilege: boolean;
  messageObject: {
    title?: string;
    message?: string;
    expires?: string;
    creator?: {
      role?: 'SUPER_ADMIN' | 'ADMIN' | 'OWNER' | 'BELL';
    };
    id?: string;
    neighborhood?: string[];
  };
}

const MessageViewer = ({
  executivePrivilege,
  closeModalTrigger,
  closeRefreshAfterCreate,
  visible,
  messageObject,
}: IMessageViewer) => {
  const [loading, setLoading] = useState<boolean>(executivePrivilege);
  const [error, setError] = useState<boolean>(false);
  const [expirationDate, setExpirationDate] = useState<any | null>();
  const [title, setTitle] = useState<string>('');
  const [neighborhoodIds, setNeighborhoodIds] = useState<string[]>([]);
  const [hoods, setHoods] = useState<any[]>([]);
  const [deltaValue, setDeltaValue] = useState<any>();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [messageDetail, setMessageDetail] = useState<any>({});
  const [byWho, setByWho] = useState<string>('');
  const [showReadBy, setShowReadBy] = useState<boolean>(false);

  let randomColor = 0;

  useEffect(() => {
    checkViewMode();
  }, [messageObject]);

  const checkViewMode = () => {
    setTitle(messageObject?.title ?? '');
    setDeltaValue(JSON.parse(messageObject?.message ?? ''));
    setExpirationDate(convertToMomentObj(messageObject?.expires));
    setByWho(
      messageObject?.creator?.role === 'SUPER_ADMIN'
        ? 'Por Portos'
        : 'Por Administrador',
    );
    setNeighborhoodIds(messageObject?.neighborhood ?? []);
    setLoading(true);
    retrieveMessageData();
  };

  const onSelectHood = (value: string) => {
    setNeighborhoodIds([...value]);
  };

  const retrieveMessageData = async () => {
    try {
      const url = `/messages/${messageObject?.id ?? ''}`;
      const response = await apiInstance.get(url);
      const result = response.data;

      if (executivePrivilege) {
        setMessageDetail(result);
        retrieveHoodsData();
      } else {
        setMessageDetail(result);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const retrieveHoodsData = async () => {
    try {
      const url = '/neighborhoods';
      const response = await apiInstance.get(url);
      const result = response.data;

      setLoading(false);
      setHoods([...result]);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const handleEditorChange = (_, __, ___, editor) => {
    setDeltaValue(editor.getContents());
  };

  const onChangeDate = (date: any) => {
    setExpirationDate(date);
  };

  const onChangeTitle = (event) => {
    setTitle(event.target.value);
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  const validateEdit = () => {
    if (!executivePrivilege) {
      if (byWho === 'Por Portos') {
        alert('No es posible editar este mensaje');
        return;
      }
    }

    if (!title) {
      alert('Ingrese título para continuar');
      return;
    }
    if (!deltaValue) {
      alert('Ingrese mensaje para continuar');
      return;
    }

    if (executivePrivilege) {
      if (neighborhoodIds.length === 0) {
        alert('Ingrese vecindarios para continuar');
        return;
      }
    }
    setLoading(true);
    putEditMessage();
  };

  const putEditMessage = async () => {
    try {
      const url = `/messages/${messageObject.id}`;

      let body = {
        title: title,
        expires: null,
        message: JSON.stringify(deltaValue, null, 2),
        neighborhood: undefined,
      };
      if (expirationDate) {
        body.expires = expirationDate.toISOString();
      }

      if (executivePrivilege) {
        body.neighborhood = [...neighborhoodIds];
      }

      await apiInstance.put(url, body);
      setLoading(false);
      closeRefreshAfterCreate();
      setEditMode(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const confirmDeleteMsg = () => {
    if (window.confirm('¿Esta seguro que desea eliminar este mensaje?')) {
      setLoading(true);
      deleteMsg();
    }
  };

  const deleteMsg = async () => {
    try {
      const url = `/messages/${messageObject.id}`;
      await apiInstance.delete(url);
      setLoading(false);
      closeRefreshAfterCreate();
      setEditMode(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const toggleReadBy = () => {
    setShowReadBy(!showReadBy);
  };

  const whichRandomColor = () => {
    randomColor++;
    if (randomColor >= 5) randomColor = 0;
  };

  return (
    <Modal
      centered
      visible={visible}
      onOk={validateEdit}
      onCancel={() => {
        closeModalTrigger();
        setShowReadBy(false);
      }}
      okText={!editMode ? 'EDITAR' : 'ACEPTAR'}
      cancelText="CANCELAR"
      maskClosable={false}
      zIndex={2000}
      width={'600px'}
      destroyOnClose={true}
      closable={!loading}
      footer={
        error ? null : executivePrivilege ? (
          <Row>
            <Col span={2}>
              <CircleButton
                variant="del"
                activeColor={UTILITY_RED}
                disabled={loading}
                onClick={confirmDeleteMsg}
              />
            </Col>
            <Col span={22}>
              <CircleButton
                variant="view"
                activeColor={UTILITY_GREEN}
                disabled={loading}
                onClick={toggleReadBy}
                clickedMode={showReadBy}
              />
              <CircleButton
                variant="edit"
                activeColor={UTILITY_YELLOW}
                disabled={loading}
                onClick={toggleEditMode}
                clickedMode={editMode}
              />
              <CircleButton
                variant="save"
                activeColor={UTILITY_BLUE}
                disabled={!editMode ? true : loading}
                onClick={validateEdit}
              />
            </Col>
          </Row>
        ) : (
          <Row>
            <Col span={2}>
              {byWho === 'Por Portos' ? null : (
                <CircleButton
                  variant="del"
                  activeColor={UTILITY_RED}
                  disabled={loading}
                  onClick={confirmDeleteMsg}
                />
              )}
            </Col>
            <Col span={22}>
              <CircleButton
                variant="view"
                activeColor={UTILITY_GREEN}
                disabled={loading}
                onClick={toggleReadBy}
                clickedMode={showReadBy}
              />
              {byWho === 'Por Portos' ? null : (
                <CircleButton
                  variant="edit"
                  activeColor={UTILITY_YELLOW}
                  disabled={loading}
                  onClick={toggleEditMode}
                  clickedMode={editMode}
                />
              )}
              {byWho === 'Por Portos' ? null : (
                <CircleButton
                  variant="save"
                  activeColor={UTILITY_BLUE}
                  disabled={!editMode ? true : loading}
                  onClick={validateEdit}
                />
              )}
            </Col>
          </Row>
        )
      }
      title={
        <Row>
          <Col xs={5} sm={4} md={3}>
            {byWho === 'Por Portos' ? (
              <FeatureIcon feature="super-admin" />
            ) : (
              <FeatureIcon feature="message" />
            )}
          </Col>
          <Col xs={19} sm={20} md={21} style={{paddingRight: '20px'}}>
            <Row>
              <Col span={24} style={{fontWeight: 'bold'}}>
                {editMode ? 'Editar Mensaje' : title ? title : 'Mensaje'}
              </Col>
              <Col span={24} style={{fontSize: '11px'}}>
                {byWho}
              </Col>
            </Row>
          </Col>
        </Row>
      }>
      {loading ? (
        <LoadingContent />
      ) : error ? (
        <NoContent emptyWhat={'network'} />
      ) : showReadBy ? (
        <Row>
          <Col span={24}>
            {messageDetail.readBy.length > 0 ? (
              <List
                locale={{emptyText: 'Sin Registros'}}
                bordered
                style={{
                  backgroundColor: '#FFFFFF',
                  borderWidth: 0,
                }}
                dataSource={messageDetail.readBy}
                renderItem={(item: any) => {
                  const statusIcon = (
                    <FeatureIcon feature="user" randomColor={randomColor} />
                  );

                  whichRandomColor();

                  return (
                    <List.Item
                      style={{
                        padding: '0 2.08%',
                        minHeight: '67px',
                        borderWidth: 0,
                      }}>
                      <Col
                        xs={4}
                        sm={3}
                        md={2}
                        style={{
                          padding: '0px 10px 0px 0px',
                        }}>
                        {statusIcon}
                      </Col>
                      <Col
                        xs={20}
                        sm={21}
                        md={22}
                        style={{
                          fontWeight: 'bold',
                          paddingRight: '10px',
                          paddingLeft: '10px',
                        }}>
                        {(item.name ? item.name : '') +
                          (item.last_name ? ' ' + item.last_name : '')}
                      </Col>
                    </List.Item>
                  );
                }}
              />
            ) : (
              <NoContent emptyWhat={'readers'} />
            )}
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <h3>{'Título'}</h3>
            <Input
              value={title}
              placeholder="Título"
              style={{marginBottom: '20px'}}
              onChange={onChangeTitle}
              readOnly={!editMode}
            />
            <h3>{'Mensaje'}</h3>
            <ReactQuill
              theme="snow"
              onChange={handleEditorChange}
              style={{marginBottom: '20px'}}
              defaultValue={deltaValue}
              readOnly={!editMode}
            />
            <h3>{'Fecha de Expiración'}</h3>
            <DatePicker
              onChange={onChangeDate}
              format={'DD-MM-YYYY'}
              showToday={false}
              locale={locale}
              style={{width: '100%', marginBottom: '40px'}}
              placeholder="Fecha de Expiración"
              popupStyle={{zIndex: 3000}}
              defaultValue={expirationDate}
              disabled={!editMode}
            />
            {executivePrivilege && <h3>{'Vecindarios'}</h3>}

            {executivePrivilege && (
              <Select
                mode="multiple"
                disabled={true}
                style={{width: '100%'}}
                dropdownStyle={{zIndex: 3000}}
                onChange={onSelectHood}
                defaultValue={neighborhoodIds[0] ?? undefined}
                placeholder={hoods.length <= 0 ? 'Sin registros' : null}>
                {hoods.length > 0 &&
                  hoods.map((item) => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.number ? item.number : 'Sin Nombre'}
                    </Select.Option>
                  ))}
              </Select>
            )}
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default MessageViewer;
