import React from 'react';

//local imports

export default function NotFound404() {
  return (
    <div
      style={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}>
      <svg
        viewBox="0 10 256 230"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        fill="#CCCCCC"
        style={{
          minWidth: '150px',
          maxWidth: '200px',
          minHeight: '150px',
          maxHeight: '200px',
          height: 'auto',
          width: 'auto',
          contain: 'content',
          padding: '0px 20px',
        }}>
        <path
          d="M129.3,130.6l-4.4,4.4v6.2h8.8V135L129.3,130.6z M129.3,130.6l-4.4,4.4v6.2h8.8V135L129.3,130.6z
	 M160.1,111.7l-8.8-8.8l-5.6-5.6l-3.2-3.2l-8.8-8.8l-4.4-4.4l-4.4,4.4l-8.8,8.8l-3.2,3.2l-5.6,5.6l-8.8,8.8l-8.8,8.8v38.3h17.6v17.6
	h61.5v-55.9L160.1,111.7z M160.1,167.5h-43.9v-36.2l8.8-8.8l4.4-4.4l4.4,4.4l8.8,8.8V150h-17.6v8.8h26.3v-31l-8.8-8.8l-8.8-8.8
	l-4.4-4.4l-4.4,4.4l-8.8,8.8l-8.8,8.8V150h-8.8v-25.9l8.8-8.8l8.8-8.8l8.8-8.8l4.4-4.4l4.4,4.4l8.8,8.8l8.8,8.8l8.8,8.8V167.5z
	 M133.7,135l-4.4-4.4l-4.4,4.4v6.2h8.8L133.7,135z"
        />

        <path d="M75.6,137.1v15.5H62v22.3H45.3v-22.3H0v-13.4l30.8-58.9h17.4l-28.9,56.7h25.9v-21.9H62v21.9H75.6z" />
        <path d="M256,137.1v15.5h-13.6v22.3h-16.8v-22.3h-45.3v-13.4l30.8-58.9h17.4l-28.9,56.7h25.9v-21.9h16.8v21.9H256z" />
      </svg>

      <div
        style={{
          maxWidth: '440px',
          width: '80%',
          height: '10%',
          minHeight: '100px',
          minWidth: '275px',
          textAlign: 'center',
          color: '#CCCCCC',
        }}>
        <p
          style={{
            fontSize: '1em',
            fontWeight: 'bold',
            padding: '0px 5px',
            margin: 0,
          }}>
          {'Ups... a veces Portos no puede abrir todas las puertas.'}
        </p>
        <p style={{fontSize: '1em', fontWeight: 'bold', padding: '0px 5px'}}>
          {'Esta página no existe o ha sido movida.'}
        </p>
      </div>
    </div>
  );
}
