import React from 'react';
import UilLock from '@iconscout/react-unicons/icons/uil-lock';

const LockImage = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: '-7%',
        left: '5%',
        height: '50px',
        width: '50px',
      }}>
      <div
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          backgroundColor: '#51D3BD',
          borderRadius: '5px',
        }}>
        <UilLock size="30" color="#FFFFFF" />
      </div>
    </div>
  );
};

export default LockImage;
