import {CSSProperties} from 'react';
import {BASE_DARK_GRAY, BRAND_LAGOON} from '../../constants/themeColors';

export const MessagesStyles: Record<string, CSSProperties> = {
  button: {
    backgroundColor: BRAND_LAGOON,
    color: '#FFFFFF',
    fontWeight: 'bold',
    borderColor: '#FFFFFF',
    padding: '0px',
    borderRadius: '5px',
  },
  icon: {
    padding: '0px 10px',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonTitle: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  bigButtonIcon: {
    padding: '0px 10px',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    paddingRight: '10px',
  },

  searchText: {
    fontSize: '14px',
    fontWeight: '500',
    height: '33px',
    color: BASE_DARK_GRAY,
  },
  searchIcon: {color: 'rgba(0,0,0,.25)'},
  searchInput: {width: '90%'},
  searchingButtonContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  itemIcon: {
    fontWeight: 'bold',
    paddingLeft: 10,
  },
};
