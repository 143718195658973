import React, {useEffect, useState} from 'react';
import {Col, Row, Button, Input} from 'antd';
import UilBuilding from '@iconscout/react-unicons/icons/uil-building';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';

import FeatureIcon from '../../general_components/FeatureIcon/FeatureIcon';
import HoodCreator from './components/HoodCreator';
import HoodEditor from './components/HoodEditor';
import {resizeString} from '../../utils/strings';
import apiInstance from '../../network/axios';
import CommonLayout from '../../layouts/CommonLayout';
import {HoodsStyles as styles} from './styles';
import CustomList from '../../molecules/CustomList';
import {SearchOutlined} from '@ant-design/icons';

const Hoods = () => {
  const [createHoodModal, setCreateHoodModal] = useState(false);
  const [viewHoodModal, setViewHoodModal] = useState<any>(false);
  const [searching, setSearching] = useState(false);
  const [searchFor, setSearchFor] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(10);
  const [dataForTable, setDataForTable] = useState([]);
  const [includeInactive, setIncludeInactive] = useState(true);

  useEffect(() => {
    retrieveHoodsData();
  }, []);

  useEffect(() => {
    formatDataForTable(data);
  }, [data]);

  const handleSearchSubmit = () => {
    if (!searchFor) {
      return;
    }
    setError(false);
    setSearching(true);
    setPage(1);
    searchHoods(1);
  };
  const searchHoods = async (pageNum) => {
    try {
      setLoading(true);
      const url = `/neighborhoods?limit=10&page=${pageNum}&query=${searchFor}`;
      const response = await apiInstance.get(url);
      const result = response.data;
      setTotalItems(
        response.headers['x-paginate-total']
          ? parseInt(response.headers['x-paginate-total'])
          : 10,
      );

      setData(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
      setSearchFor('');
    }
  };
  const searchModeOff = () => {
    setSearching(false);
    setSearchFor('');
    setError(true);
    goToPage(1, false);
  };
  const goToPage = (pageNum: number, isSearching: boolean) => {
    setPage(pageNum);
    setError(false);
    isSearching ? searchHoods(pageNum) : retrieveHoodsData(pageNum);
  };

  const retrieveHoodsData = async (pageNum = 1) => {
    try {
      setLoading(true);
      const url = `/neighborhoods?limit=10&page=${pageNum}`;
      const response = await apiInstance.get(url);
      const result = response.data;
      setTotalItems(
        response.headers['x-paginate-total']
          ? parseInt(response.headers['x-paginate-total'])
          : 10,
      );
      setLoading(false);
      setData(result);
      setSearchFor('');
    } catch (error) {
      setLoading(false);
      setError(true);
      setSearchFor('');
    }
  };

  const formatDataForTable = (data = []) => {
    const formatedData = [];
    data.forEach((item, index) => {
      const statusIcon = <FeatureIcon feature="hood" randomColor={index % 5} />;
      const formatedElement = [];

      formatedElement.push(
        <Row align="middle">
          {statusIcon}
          <Col span={12} style={styles.itemIcon}>
            <p
              style={{
                padding: 0,
                margin: 0,
                fontWeight: 'bold',
              }}>
              {resizeString(item?.number ?? '', 10)}
            </p>
            <p style={{padding: 0, margin: 0}}> </p>
          </Col>
        </Row>,
      );
      formatedElement.push(
        <>
          <p style={{padding: 0, margin: 0, fontWeight: 'bold'}}>
            {'Descripción'}
          </p>
          <p style={{padding: 0, margin: 0}}>
            {resizeString(item?.description ?? '')}
          </p>
        </>,
      );
      formatedElement.push(
        <Button
          block
          onClick={() => setViewHoodModal(item)}
          style={styles.itemButton}>
          {'VER'}
        </Button>,
      );

      formatedData.push({
        data: formatedElement,
        aditionalData: {
          active: item.activated,
        },
      });
    });
    setDataForTable(formatedData);
  };

  const closeHoodModalAndRefresh = () => {
    setPage(1);
    goToPage(1, false);
    setSearchFor('');
    setSearching(false);
    retrieveHoodsData();
    setCreateHoodModal(false);
    setViewHoodModal(false);
  };

  return (
    <CommonLayout
      title={'Vecindarios'}
      buttonAdd={
        <Button
          style={styles.container}
          onClick={() => setCreateHoodModal(true)}>
          <Row justify="center" align="middle">
            <Col xs={24} sm={0}>
              <div style={styles.iconContainer}>
                <UilPlus size="16" color="#FFFFFF" />
                <UilBuilding size="18" color="#FFFFFF" />
              </div>
            </Col>
            <Col xs={0} sm={24} md={24}>
              <div style={styles.largeButon}>
                <div style={styles.largeButonIcon}>
                  <UilBuilding size="18" color="#FFFFFF" />
                </div>
                <div style={styles.title}>{'AGREGAR VECINDARIO'}</div>
              </div>
            </Col>
          </Row>
        </Button>
      }
      filters={
        <Row style={{width: '100%'}}>
          <Col span={12}>
            <Input
              prefix={<SearchOutlined />}
              value={searchFor}
              disabled={searching}
              onChange={(value) => setSearchFor(value.target.value)}
              style={{width: '70%'}}
              placeholder={searching ? 'Búsqueda Completada' : 'Buscar...'}
              onPressEnter={handleSearchSubmit}
            />
          </Col>
          <Col
            span={12}
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            {searching ? (
              <Button onClick={searchModeOff}>{'REGRESAR'}</Button>
            ) : (
              <Button onClick={handleSearchSubmit}>{'BUSCAR'}</Button>
            )}
          </Col>
        </Row>
      }>
      <CustomList
        loading={loading}
        error={{
          isThereError: error,
          emptyWhat: 'network',
          onRetry: () => goToPage(1, searching),
        }}
        filters={{
          applyFilters: includeInactive,
          setApplyFilters: setIncludeInactive,
          filtersText: 'INCLUIR INHABILITADOS',
        }}
        pagination={{
          searching,
          page,
          totalItems,
          goToPage,
        }}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 1,
          xl: 2,
          xxl: 2,
        }}
        emptyText={
          searching ? 'No hay coincidencias' : `Aún no hay vecindarios`
        }
        columns={[
          {columnTitle: '', sizes: {xs: 9, lg: 10}},
          {columnTitle: '', sizes: {xs: 8, xl: 9}},
          {columnTitle: '', sizes: {xs: 7, lg: 6, xl: 5}},
        ]}
        items={
          includeInactive
            ? dataForTable
            : dataForTable.filter((item) => item.aditionalData.active)
        }
      />
      {createHoodModal && (
        <HoodCreator
          visible={createHoodModal}
          closeModalTrigger={() => setCreateHoodModal(false)}
          closeRefreshAfterCreate={closeHoodModalAndRefresh}
        />
      )}
      {viewHoodModal && (
        <HoodEditor
          visible={viewHoodModal !== false}
          closeModalTrigger={() => setViewHoodModal(false)}
          closeRefreshAfterCreate={closeHoodModalAndRefresh}
          hoodObject={viewHoodModal}
        />
      )}
    </CommonLayout>
  );
};

export default Hoods;
