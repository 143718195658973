import React from 'react';

const UnderConstruction = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#081023',
      }}>
      <img
        src="https://portos.xyz/images/logo.png"
        alt="Company-logo"
        style={{
          maxWidth: '85%',
          height: 'auto',
          marginBottom: '5px',
        }}
      />
      <p style={{marginTop: '15px', color: '#FFF', fontSize: '20px'}}>
        {'Under Construction'}
      </p>
    </div>
  );
};

export default UnderConstruction;
