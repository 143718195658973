import React, {RefObject} from 'react';
import {DatePicker, Form, FormInstance, InputNumber, Select} from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import {TPayment} from '../../../types/manualPaymentTypes';

interface IPaymentForm {
  formRef: RefObject<FormInstance<TPayment>>;
  hoodsObject: any[];
}

const PaymentForm = ({formRef, hoodsObject}: IPaymentForm) => {
  return (
    <Form ref={formRef}>
      <Form.Item
        labelCol={{span: 24}}
        label="Fecha"
        required={false}
        name={'createTime'}
        rules={[{required: true, message: 'Ingrese una cantidad!'}]}>
        <DatePicker
          format={'DD/MM/YYYY'}
          showToday={false}
          locale={locale}
          style={{width: '100%'}}
          placeholder="Fecha"
        />
      </Form.Item>

      <Form.Item
        labelCol={{span: 24}}
        label="Total"
        required={false}
        name={'total'}
        rules={[{required: true, message: 'Ingrese una cantidad!'}]}>
        <InputNumber style={{width: '100%'}} controls={false} prefix="MXN $" />
      </Form.Item>

      <Form.Item
        labelCol={{span: 24}}
        label="Neighborhood"
        name="neighborhoodId"
        required
        rules={[{required: true, message: 'Seleccione un vecindario!'}]}>
        <Select
          style={{width: '100%'}}
          dropdownStyle={{zIndex: 3000}}
          defaultValue={undefined}
          placeholder={hoodsObject.length <= 0 ? 'Sin registros' : null}>
          {hoodsObject.length > 0 &&
            hoodsObject.map((item) => (
              <Select.Option value={item.id} key={item.id}>
                {item?.number ?? 'Sin Nombre'}
              </Select.Option>
            ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

export default PaymentForm;
