import {Col, Modal, Row} from 'antd';
import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {
  BASE_URL,
  DEVELOPMENT,
  NETWORK_TIMEOUT,
} from '../../../constants/network';
import FeatureIcon from '../../../general_components/FeatureIcon/FeatureIcon';
import {getUserToken} from '../../../navigation/auth/auth';
import NoContent from '../../empty_screens/empty_content/NoContent';
import LoadingContent from '../../loading/LoadingContent';
import {ImageGrid} from '../../../molecules/Index';
import moment from 'moment';

const ReportViewer = (props) => {
  const [loading, setLoading] = useState(true);
  const [initialGet, setInitialGet] = useState(true);
  const [report, setReport] = useState<any>({});
  const [networkError, setNetworkError] = useState(false);

  useEffect(() => {
    if (initialGet) {
      getReport();
    }
  });

  const getReport = async () => {
    setInitialGet(false);
    try {
      const userToken = getUserToken();
      const url = `${BASE_URL}/messages/${props.item.id}`;

      const options = {
        headers: {
          'X-App': 'admin',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + userToken,
        },
        timeout: NETWORK_TIMEOUT,
      };

      const response = await axios.get(url, options);
      if (DEVELOPMENT) {
        console.log('URL => ', url);
        console.log('RESPONSE:> ', response);
      }
      setReport(response.data);

      setLoading(false);
    } catch (error) {
      if (DEVELOPMENT) {
        console.log('ERROR=>', error);
      }
      setLoading(false);
      setNetworkError(true);
    }
  };

  return (
    <Modal
      centered
      visible={true}
      onCancel={props.closeModal}
      maskClosable={false}
      zIndex={2000}
      width={'600px'}
      destroyOnClose={true}
      closable={loading ? false : true}
      footer={null}
      title={
        <Row>
          <Col xs={5} sm={4} md={3}>
            <FeatureIcon feature="reports" />
          </Col>
          <Col xs={19} sm={20} md={21} style={{paddingRight: '20px'}}>
            <Row>
              <Col span={24} style={{fontWeight: 'bold'}}>
                {props.item.title}
              </Col>
              <Col span={24} style={{fontSize: '11px'}}>
                {`Por ${props.item.creator.name} ${props.item.creator.last_name}`}
              </Col>
            </Row>
          </Col>
        </Row>
      }>
      {loading ? (
        <LoadingContent />
      ) : networkError ? (
        <NoContent emptyWhat={'network'} />
      ) : (
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={12}>
            <h3>{'Título'}</h3>
            <p style={{marginLeft: '1em'}}>{report.title}</p>
            <h3>{'Mensaje'}</h3>
            <p style={{marginLeft: '1em'}}>{report.message}</p>
            <h3>{'Fecha'}</h3>
            <p style={{marginLeft: '1em'}}>
              {moment(report.date).format('lll')}
            </p>
          </Col>
          <Col xs={24} sm={12}>
            {report.images.length > 0 && (
              <div>
                <h3>{'Imagenes'}</h3>
                <ImageGrid data={report.images} />
              </div>
            )}
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default ReportViewer;
