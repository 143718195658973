import React from 'react';
import {Form, Input, Switch} from 'antd';

import {HoodFormEditorStyles as styles} from '../styles';

interface IHoodForm {
  editMode: boolean;
  formRef: any;
  defaultValues?: any;
}

const HoodForm = ({editMode, formRef, defaultValues}: IHoodForm) => {
  return (
    <Form ref={formRef} labelWrap>
      <Form.Item
        labelCol={{span: 24}}
        label="Nombre del Vecindario"
        required={false}
        name="hood_name"
        rules={[
          {required: true, message: 'Ingrese nombre!'},
          {whitespace: true, message: 'Ingrese nombre!'},
          {
            max: 45,
            message: 'Maximo de 45 caracteres!',
          },
        ]}
        initialValue={defaultValues?.number ?? null}>
        <Input placeholder={'Nombre del Vecindario'} readOnly={!editMode} />
      </Form.Item>
      <Form.Item
        labelCol={{span: 24}}
        label="Dirección del Vecindario"
        required={false}
        name="address"
        rules={[
          {required: true, message: 'Ingrese la dirección!'},
          {whitespace: true, message: 'Ingrese la dirección'},
          {
            max: 100,
            message: 'Maximo de 100 caracteres!',
          },
        ]}
        initialValue={defaultValues?.address ?? ''}>
        <Input placeholder={'Dirección del Vecindario'} readOnly={!editMode} />
      </Form.Item>

      <Form.Item
        labelCol={{span: 24}}
        label="Descripción del Vecindario"
        required={false}
        name="hood_description"
        rules={[
          {required: true, message: 'Ingrese Descripción!'},
          {whitespace: true, message: 'Ingrese Descripción!'},
          {
            max: 60,
            message: 'Maximo de 60 caracteres!',
          },
        ]}
        initialValue={defaultValues?.description ?? ''}>
        <Input
          placeholder={'Descripción del Vecindario'}
          readOnly={!editMode}
        />
      </Form.Item>

      <Form.Item
        labelCol={{span: 24}}
        label="Nombre de Secciones (Calle, torre, etc)"
        required={false}
        name="street_name"
        rules={[
          {required: true, message: 'Ingrese nombre!'},
          {whitespace: true, message: 'Ingrese nombre!'},
          {
            max: 35,
            message: 'Maximo de 35 caracteres!',
          },
        ]}
        initialValue={defaultValues?.street_name ?? ''}>
        <Input
          placeholder={'Nombre de Secciones (Calle, torre, etc)'}
          readOnly={!editMode}
        />
      </Form.Item>

      <Form.Item
        labelCol={{span: 24}}
        label="Nombre de Viviendas (Casa, departamento, etc.)"
        required={false}
        name="house_name"
        rules={[
          {required: true, message: 'Ingrese nombre!'},
          {whitespace: true, message: 'Ingrese nombre!'},
          {
            max: 35,
            message: 'Maximo de 35 caracteres!',
          },
        ]}
        initialValue={defaultValues?.house_name ?? ''}>
        <Input
          placeholder={'Nombre de Viviendas (Casa, departamento, etc.)'}
          readOnly={!editMode}
        />
      </Form.Item>

      <div style={styles.container}>
        <Form.Item
          name={'activated'}
          valuePropName="checked"
          style={styles.item}
          initialValue={defaultValues?.activated ?? false}>
          <Switch style={styles.switch} size="small" disabled={!editMode} />
        </Form.Item>
        <h4 style={styles.h4}>{'Habilitado'}</h4>
      </div>

      <div style={styles.container}>
        <Form.Item
          name={'id_service_enabled'}
          valuePropName="checked"
          style={styles.item}
          initialValue={
            defaultValues?.active_services?.includes('ID_DETECTION') ?? false
          }>
          <Switch style={styles.switch} size="small" disabled={!editMode} />
        </Form.Item>
        <h4 style={styles.h4}>{'Entrada por ID'}</h4>
      </div>

      <div style={styles.container}>
        <Form.Item
          name={'call_service_enabled'}
          valuePropName="checked"
          style={styles.item}
          initialValue={
            defaultValues?.active_services?.includes('CALLS') ?? false
          }>
          <Switch style={styles.switch} size="small" disabled={!editMode} />
        </Form.Item>
        <h4 style={styles.h4}>{'Servicio de Videollamada'}</h4>
      </div>

      <div style={styles.container}>
        <Form.Item
          name={'directory_service_enabled'}
          valuePropName="checked"
          style={styles.item}
          initialValue={
            defaultValues?.active_services?.includes('STREET_DIR') ?? false
          }>
          <Switch style={styles.switch} size="small" disabled={!editMode} />
        </Form.Item>
        <h4 style={styles.h4}>{'Servicio de Directorio'}</h4>
      </div>

      <div style={styles.container}>
        <Form.Item
          name={'selfie_service_enabled'}
          valuePropName="checked"
          style={styles.item}
          initialValue={
            defaultValues?.active_services?.includes('SELFIE_ID') ?? false
          }>
          <Switch style={styles.switch} size="small" disabled={!editMode} />
        </Form.Item>
        <h4 style={styles.h4}>{'Entrada por SELFIE'}</h4>
      </div>

      <div style={styles.container}>
        <Form.Item
          name={'exit_service_enabled'}
          valuePropName="checked"
          style={styles.item}
          initialValue={
            defaultValues?.active_services?.includes('EXIT_LOG') ?? false
          }>
          <Switch style={styles.switch} size="small" disabled={!editMode} />
        </Form.Item>
        <h4 style={styles.h4}>{'Registro de Salidas'}</h4>
      </div>

      <div style={styles.container}>
        <Form.Item
          style={styles.item}
          name={'face_id_enabled'}
          valuePropName="checked"
          initialValue={
            defaultValues?.active_services?.includes('FACE_ID') ?? false
          }>
          <Switch style={styles.switch} size="small" disabled={!editMode} />
        </Form.Item>
        <h4 style={styles.h4}>{'ID Facial'}</h4>
      </div>
    </Form>
  );
};

export default HoodForm;
