import React, {useEffect, useRef, useState} from 'react';
import {Col, Row, Modal, Button} from 'antd';

import LoadingContent from '../../loading/LoadingContent';
import NoContent from '../../empty_screens/empty_content/NoContent';
import FeatureIcon from '../../../general_components/FeatureIcon/FeatureIcon';
import UserFormEditor from './UserFormEditor';
import apiInstance from '../../../network/axios';
import {FormInstance} from 'antd/es/form/Form';

interface IUserCreator {
  visible: boolean;
  closeModalTrigger: () => void;
  closeRefreshAfterCreate: () => void;
}

const UserCreator = ({
  closeModalTrigger,
  closeRefreshAfterCreate,
  visible,
}: IUserCreator) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [errorType, setErrorType] = useState<string>('network');
  const [hoods, setHoods] = useState<any[]>([]);
  const formRef = useRef<FormInstance>();

  useEffect(() => {
    retrieveHoodsData();
  }, []);

  const validatePost = async () => {
    const values = await formRef.current.validateFields();
    setLoading(true);
    postCreateUser(values);
  };

  const retrieveHoodsData = async () => {
    try {
      const url = '/neighborhoods';
      const response = await apiInstance.get(url);
      const result = response.data;
      setHoods(result);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const postCreateUser = async (form_object) => {
    try {
      let url = `/neighborhoods/${form_object.hood_id}/users`;
      const body = {
        role: form_object.role,
        name: form_object.name,
        last_name: form_object.last_name,
        password: form_object.password,
        phone_number: form_object.phone,
        username: form_object.email,
        approved: form_object.active,
      };
      if (form_object.sub_role) {
        body['sub_role'] = form_object.sub_role;
      }
      if (form_object.role === 'SUPER_ADMIN') {
        url = '/users';
      }
      await apiInstance.post(url, body);
      setLoading(false);
      closeRefreshAfterCreate();
    } catch (e) {
      if (e.response) {
        if (
          e.response.data.message.includes(
            'E11000 duplicate key error collection:',
          )
        ) {
          setErrorType('userExist');
        }
      }

      setLoading(false);
      setError(true);
    }
  };

  const retryAfterNetErr = () => {
    setLoading(false);
    setError(false);
    setErrorType('network');
  };

  return (
    <Modal
      centered
      visible={visible}
      onOk={validatePost}
      onCancel={closeModalTrigger}
      okText="CREAR"
      cancelText="CANCELAR"
      maskClosable={false}
      destroyOnClose={true}
      closable={!loading}
      footer={[
        <Button key="back" disabled={loading} onClick={closeModalTrigger}>
          {'CANCELAR'}
        </Button>,
        error ? (
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={retryAfterNetErr}>
            {'REINTENTAR'}
          </Button>
        ) : (
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={validatePost}>
            {'CREAR'}
          </Button>
        ),
      ]}
      title={
        <Row>
          <Col xs={5} sm={4} md={3}>
            <FeatureIcon feature="user" />
          </Col>
          <Col xs={19} sm={20} md={21} style={{fontWeight: 'bold'}}>
            {'Crear Usuario'}
          </Col>
        </Row>
      }>
      {loading ? (
        <LoadingContent />
      ) : error ? (
        <NoContent emptyWhat={errorType} />
      ) : (
        <Row>
          <Col span={24}>
            <UserFormEditor
              hoodsObject={hoods}
              formRef={formRef}
              editMode={true}
            />
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default UserCreator;
