import React, {useRef, useState} from 'react';
import {Form, Input, Button} from 'antd';

//local imports
import {NETWORK_ERR_MSG} from '../../../constants/network';
import {getNeighborhoodConfig, login} from '../../../navigation/auth/auth';
import {LockOutlined, UserOutlined} from '@ant-design/icons';
import {FormInstance} from 'antd/es/form/Form';
import apiInstance from '../../../network/axios';

export const onAdminLogin = (
  result: any,
  history: any,
  needRedirect = true,
) => {
  const neighborhood = result?.user?.neighborhood ?? {};
  const plan = neighborhood?.current_plan ?? 'FREE';

  login(result, () => {
    const levelConfig = getNeighborhoodConfig(result);
    localStorage.setItem('levelConfig', levelConfig);
    if (levelConfig === '4') {
      localStorage.setItem(
        'myNeighborhood',
        JSON.stringify(result?.user?.neighborhood ?? {}),
      );
      localStorage.setItem('availableRoutes', plan);
      localStorage.setItem(
        'dataForCreateCard',
        JSON.stringify({
          address: result?.user?.neighborhood.address ?? '',
          name: `${result?.user?.name ?? ''} ${result?.user?.last_name ?? ''}`,
        }),
      );
      localStorage.setItem('currentPlan', plan);
      if (needRedirect) {
        history.push('/dashboard');
      }
    } else {
      history.push('/digitalJourney/intro');
    }
  });
};

const FormContent = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [username, setUsername] = useState<string>('');
  const [userpass, setUserpass] = useState<string>('');

  const formRef = useRef<FormInstance>();

  const handleChangeName = (event: any) => {
    setUsername(event.target.value);
    formRef.current!.setFieldsValue({
      username: event.target.value,
    });
  };
  const handleChangePword = (event: any) => {
    setUserpass(event.target.value);
    formRef.current!.setFieldsValue({
      userpass: event.target.value,
    });
  };

  const requestLogin = async () => {
    try {
      setLoading(true);
      const body = {
        username,
        password: userpass,
      };

      const url = '/token';
      const response = await apiInstance.post(url, body);
      const result = response.data;

      //user has token proceding
      const resultHasToken = result.token ? true : false;
      if (resultHasToken) {
        if (result.user.role === 'ADMIN') {
          onAdminLogin(result, props.history);
        } else if (result.user.role === 'SUPER_ADMIN') {
          login(result, () => {
            props.history.push('/hoods');
          });
        }
      }
    } catch (error) {
      let message = NETWORK_ERR_MSG;
      if (error.response) {
        if (error.response.data) {
          if (
            error.response.data.message === 'UserNotFound' ||
            error.response.data.message === 'InvalidUser'
          )
            message = 'Usuario o contraseña incorrecta!';
          if (error.response.data.message === 'UserDisable')
            message =
              'Usuario inhabilitado, favor de comunicarse con la administración.';
          if (error.response.data.message === 'NeighborhoodDeactivated')
            message =
              'Servicio inhabilitado, favor de comunicarse con la administración.';
        }
      }
      setLoading(false);
      alert(message);
    }
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (username === '' && userpass === '') {
      alert('Ingrese su usuario y contraseña.');
      return;
    }

    if (username === '') {
      alert('Ingrese su usuario.');
      return;
    }

    if (userpass === '') {
      alert('Ingrese su contraseña.');
      return;
    }

    requestLogin();
  };
  return (
    <div>
      <Form className="login-form" ref={formRef}>
        <Form.Item
          name="username"
          rules={[{required: true, message: 'Introduzca su usuario!'}]}>
          <Input
            prefix={<UserOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
            size="large"
            placeholder="Usuario"
            onChange={handleChangeName}
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: 'Introduzca su contraseña!',
            },
          ]}>
          <Input.Password
            prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}} />}
            size="large"
            placeholder="Contraseña"
            onChange={handleChangePword}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            loading={loading}
            block
            onClick={handleSubmit}
            style={{
              fontWeight: 'bold',
              backgroundColor: '#51D3BD',
              borderColor: '#51D3BD',
            }}>
            {'Iniciar Sesión'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default FormContent;
