import {PLANS} from '../constants/Routes';

export const calculateMonthlyCost = (homes, plan) => {
  let pricePerHouse = 0;
  if (plan === PLANS.ADMIN) {
    pricePerHouse = 9;
  } else if (plan === PLANS.VIGILANCE) {
    pricePerHouse = 19;
  } else if (plan === PLANS.SECURITY) {
    pricePerHouse = 150;
  } else {
    pricePerHouse = 0;
  }
  return pricePerHouse * homes.length;
};
