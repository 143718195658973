import {CSSProperties} from 'react';
import {UTILITY_BLUE} from '../../constants/themeColors';

export const UsersStyles: Record<string, CSSProperties> = {
  container: {
    backgroundColor: UTILITY_BLUE,
    color: '#FFFFFF',
    fontWeight: 'bold',
    borderColor: '#FFFFFF',
    padding: '0px',
    borderRadius: '5px',
  },
  iconContainer: {
    padding: '0px 10px',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  largeButon: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  largeButonIcon: {
    padding: '0px 10px',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    paddingRight: '10px',
  },
  itemButton: {borderColor: '#545454'},
  itemIcon: {
    fontWeight: 'bold',
    paddingLeft: 10,
  },
};
