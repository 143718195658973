import React, {useState, useEffect} from 'react';
import Axios from 'axios';
import {BASE_URL, DEVELOPMENT, NETWORK_TIMEOUT} from '../../constants/network';
import {getUserHood, getUserToken} from '../../navigation/auth/auth';
import LoadingContent from '../loading/LoadingContent';
import NoContent from '../empty_screens/empty_content/NoContent';
import {Col, Row, List, Button, DatePicker, Select, Pagination} from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import AddButton from '../../atoms/AddButton';
import AddModal from './components/AddModal';
import AddEntry from './components/AddEntry';
import {
  BASE_BLUE,
  BASE_GREEN,
  BASE_RED,
  UTILITY_BLUE,
  UTILITY_GREEN,
  UTILITY_RED,
} from '../../constants/themeColors';
import EditEntry from './components/EditEntry';
import {styles} from './styles';
import moment from 'moment';
import numeral from 'numeral';

const IncomeExpenses = () => {
  const date = new Date();
  const firstDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    1,
  ).toISOString();
  const lastDay = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
  ).toISOString();

  const [searchingFor, setSearchingFor] = useState([]);
  const [loading, setLoading] = useState(true);
  const [entries, setEntries] = useState([]);
  const [error, setError] = useState(false);
  const [categories, setCategories] = useState([]);
  const [searching, setSearching] = useState(false);
  const [categoryToSearch, setCategoryToSearch] = useState('ALL');
  const [addIncome, setAddIncome] = useState(false);
  const [addExpense, setAddExpense] = useState(false);
  const [addCaregory, setAddCaregory] = useState(false);
  const [currentEntry, setCurrentEntry] = useState(null);
  const [totalAvailableItems, settotalAvailableItems] = useState(10);
  const [page, setPage] = useState(1);

  const getEntries = async (pageNum: any = 1, category = 'ALL') => {
    setLoading(true);
    try {
      const userNeighborhoodID = getUserHood();
      const userToken = getUserToken();
      const url = `${BASE_URL}/neighborhoods/${userNeighborhoodID}/accounting/entries?min_date=${
        searchingFor.length === 0 ? firstDay : searchingFor[0]
      }&max_date=${
        searchingFor.length === 0 ? lastDay : searchingFor[1]
      }&limit=10&page=${pageNum}${
        category !== 'ALL' ? '&category_id=' + categoryToSearch : ''
      }`;
      const options = {
        headers: {
          'X-App': 'admin',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + userToken,
        },
        timeout: NETWORK_TIMEOUT,
      };
      const response = await Axios.get(url, options);
      if (response.data.success) {
        settotalAvailableItems(parseInt(response.headers['x-paginate-total']));
        setEntries(response.data.entries);
        setLoading(false);
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      if (DEVELOPMENT) {
        console.log('Error getting Entries', error?.response ?? error);
      }
      setLoading(false);
      setError(true);
    }
  };

  const getCategories = async () => {
    try {
      const userNeighborhoodID = getUserHood();
      const userToken = getUserToken();
      const url = `${BASE_URL}/neighborhoods/${userNeighborhoodID}/accounting/categories`;
      const options = {
        headers: {
          'X-App': 'admin',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + userToken,
        },
        timeout: NETWORK_TIMEOUT,
      };
      const response = await Axios.get(url, options);
      if (response.data.success) {
        setCategories(response.data.categories);
        setLoading(false);
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      if (DEVELOPMENT) {
        console.log('Error getting categories', error?.response ?? error);
      }
      setLoading(false);
      setError(true);
    }
  };
  useEffect(() => {
    getEntries();
    getCategories();
    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <LoadingContent />;
  }

  if (error) {
    return (
      <NoContent
        emptyWhat={'network'}
        withRetry
        onClickMethod={() => {
          setError(false);
          setLoading(true);
          getEntries();
          getCategories();
        }}
      />
    );
  }
  if (categories.length === 0) {
    return (
      <AddModal
        visible={categories.length === 0}
        title={'Añadir categoria'}
        cancelable={false}
        iconColor={UTILITY_BLUE}
        reload={() => {
          getEntries();
          getCategories();
        }}
      />
    );
  }
  const onChangeDate = (dates) => {
    if (dates?.length > 1) {
      setSearchingFor([
        new Date(dates[0]._d).toISOString(),
        new Date(dates[1]._d).toISOString(),
      ]);
    }
  };
  const searchModeOff = () => {
    setSearching(false);
    setSearchingFor([]);
    setCategoryToSearch('ALL');
    setLoading(true);
    setPage(1);
    getEntries();
  };
  const handleSearchSubmit = () => {
    setSearching(true);
    setLoading(true);
    getEntries(1, categoryToSearch);
  };

  const goToPage = (page) => {
    setPage(page);
    getEntries(page);
  };
  const {Option} = Select;
  const {RangePicker} = DatePicker;
  return (
    <div style={styles.mainContainer}>
      <Row>
        <h1>Ingresos/Egresos</h1>
      </Row>
      <Row>
        <Col span={24} style={styles.rowSeparator} />
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={12} md={8}>
          <Select
            disabled={searching}
            style={styles.item}
            placeholder={'Categorias'}
            value={categoryToSearch}
            onChange={(value) => setCategoryToSearch(value)}>
            <Option value={'ALL'}>{'Todas las categorías'}</Option>
            {categories.map((item) => {
              return (
                <Option key={item._id} value={item._id}>
                  {item.name}
                </Option>
              );
            })}
          </Select>
        </Col>
        <Col xs={24} sm={12} md={8}>
          <RangePicker
            disabled={searching}
            onChange={onChangeDate}
            format={'DD/MM/YYYY'}
            locale={locale}
            style={styles.item}
            placeholder={[
              searching ? 'Búsqueda Completada' : 'Seleccionar Fecha',
              searching ? 'Búsqueda Completada' : 'Seleccionar Fecha',
            ]}
            value={[
              moment(searchingFor.length > 1 ? searchingFor[0] : firstDay),
              moment(searchingFor.length > 1 ? searchingFor[1] : lastDay),
            ]}
          />
        </Col>
        <Col xs={0} md={4} lg={5}></Col>
        <Col xs={12} md={4} lg={3}>
          {searching ? (
            <Button onClick={searchModeOff}>{'REGRESAR'}</Button>
          ) : (
            <Button
              disabled={searchingFor.length === 0 && categoryToSearch === 'ALL'}
              onClick={handleSearchSubmit}>
              {'BUSCAR'}
            </Button>
          )}
        </Col>
      </Row>
      <Col span={24} style={styles.rowSeparator} />
      <Row gutter={[16, 16]}>
        <Col xs={12} sm={8} md={6}>
          <AddButton
            title={'Añadir ingreso'}
            onClick={() => setAddIncome(true)}
            color={BASE_GREEN}
          />
        </Col>
        <Col xs={12} sm={8} md={6}>
          <AddButton
            title={'Añadir egreso'}
            onClick={() => setAddExpense(true)}
            color={BASE_RED}
          />
        </Col>
        <Col xs={12} sm={8} md={6}>
          <AddButton
            title={'Añadir categoría'}
            onClick={() => setAddCaregory(true)}
            color={BASE_BLUE}
          />
        </Col>
      </Row>

      <Row style={styles.configTablePaginationRow}>
        <Col
          xs={{span: 14, offset: 0}}
          sm={{span: 12, offset: 1}}
          md={{span: 12, offset: 6}}
          lg={{span: 12, offset: 6}}>
          <div style={styles.configTablePaginationColumnCenter}>
            {`Del ${moment(
              searchingFor.length === 0 ? firstDay : searchingFor[0],
            ).format('DD MMM YY')} al ${moment(
              searchingFor.length === 0 ? lastDay : searchingFor[1],
            ).format('DD MMM YY')}`}
          </div>
        </Col>
        <Col xs={10} sm={11} md={6}>
          <div style={styles.configPaginationContainer}>
            <Pagination
              defaultCurrent={page}
              total={totalAvailableItems}
              size={'small'}
              onChange={(pageNum) => goToPage(pageNum)}
            />
          </div>
        </Col>
      </Row>
      <Row style={styles.configTableHeader}>
        <Col xs={8} sm={6} md={4} style={styles.dashboardListTitle}>
          {'Cantidad'}
        </Col>
        <Col xs={10} sm={8} md={6} style={styles.dashboardListTitle}>
          {'Concepto'}
        </Col>
        <Col xs={0} sm={6} md={7} style={styles.dashboardListTitle}>
          {'Categoría'}
        </Col>
        <Col xs={0} sm={0} md={4} style={styles.dashboardListTitle}>
          {'Fecha'}
        </Col>
        <Col xs={6} sm={4} md={3} style={styles.dashboardListTitle}>
          {'Detalle'}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <List
            locale={{emptyText: 'Sin Registros'}}
            style={styles.configList}
            dataSource={entries}
            renderItem={(item) => {
              let _description = '';
              if (item.description) {
                _description =
                  item.description.length > 20
                    ? item.description.substring(0, 20) + '...'
                    : item.description;
              }
              return (
                <List.Item
                  style={{
                    minHeight: '60px',
                  }}>
                  <Col xs={8} sm={6} md={4}>
                    <Row gutter={10}>
                      <Col
                        style={{
                          marginLeft: 10,
                          width: 20,
                          height: 20,
                          borderRadius: 10,
                          backgroundColor:
                            item?.type === 'income' ? BASE_GREEN : BASE_RED,
                        }}></Col>
                      <Col span={18}>
                        {numeral(parseFloat(item?.amount ?? '0')).format(
                          '$0,0.00',
                        )}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={10} sm={8} md={6}>
                    {_description ?? ''}
                  </Col>
                  <Col xs={0} sm={6} md={7}>
                    {item?.category?.name ?? 'Categoría'}
                  </Col>
                  <Col xs={0} sm={0} md={4}>
                    {new Date(item.date).toLocaleDateString()}
                  </Col>
                  <Col xs={6} sm={4} md={3}>
                    <Button
                      block
                      onClick={() => {
                        setCurrentEntry(item);
                      }}
                      style={styles.button}>
                      {'VER'}
                    </Button>
                  </Col>
                </List.Item>
              );
            }}
          />
        </Col>
      </Row>
      <AddEntry
        visible={addIncome}
        iconColor={UTILITY_GREEN}
        title={'Añadir ingreso'}
        onCancel={() => setAddIncome(false)}
        categories={categories}
        type={'income'}
        reload={() => getEntries()}
      />
      <AddEntry
        visible={addExpense}
        iconColor={UTILITY_RED}
        title={'Añadir egreso'}
        onCancel={() => setAddExpense(false)}
        categories={categories}
        type={'expense'}
        reload={() => getEntries()}
      />
      <EditEntry
        visible={currentEntry !== null}
        data={currentEntry}
        title={'Detalle'}
        onCancel={() => setCurrentEntry(null)}
        categories={categories}
        reload={() => getEntries()}
      />
      <AddModal
        visible={addCaregory}
        title={'Añadir categoria'}
        onCancel={() => setAddCaregory(false)}
        iconColor={UTILITY_BLUE}
        reload={() => {
          getEntries();
        }}
      />
    </div>
  );
};

export default IncomeExpenses;
