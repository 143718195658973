import React, {useState} from 'react';
import {Col, Modal, Row} from 'antd';
import moment from 'moment';
import numeral from 'numeral';
import FeatureIcon from '../../../general_components/FeatureIcon/FeatureIcon';
import {TPayment} from '../../../types/manualPaymentTypes';
import {CircleButton} from '../../../atoms';
import {UTILITY_RED} from '../../../constants/themeColors';
import apiInstance from '../../../network/axios';

interface IPaymentEditor {
  visible: boolean;
  closeModalTrigger: () => void;
  closeRefreshAfterCreate: () => void;
  payment: TPayment;
}

const PaymentEditor = ({
  closeModalTrigger,
  visible,
  payment,
  closeRefreshAfterCreate,
}: IPaymentEditor) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleDelete = () => {
    if (window.confirm('¿Esta seguro que desea eliminar este elemento?')) {
      deletePayment();
    }
  };

  const deletePayment = async (pageNum: number = 1) => {
    setLoading(true);
    try {
      await apiInstance.delete(`/paypal/payments/${payment.id}`);
      setLoading(false);
      closeRefreshAfterCreate();
    } catch (e) {
      setLoading(false);
      setError(true);
      console.error(e);
    }
  };

  return (
    <Modal
      centered
      visible={visible}
      onCancel={closeModalTrigger}
      maskClosable
      destroyOnClose={true}
      title={
        <Row align="middle">
          <Col xs={5} sm={4} md={3}>
            <FeatureIcon con feature="accounting" />
          </Col>
          <Col xs={19} sm={20} md={21} style={{fontWeight: 'bold'}}>
            {`Pago de ${payment?.neighborhood?.number ?? 'Sin Nombre'}`}
          </Col>
        </Row>
      }
      footer={
        !error && (
          <CircleButton
            variant="del"
            activeColor={UTILITY_RED}
            disabled={loading}
            onClick={handleDelete}
          />
        )
      }>
      <Row>
        <Col span={24}>
          <h3>{'Fecha'}</h3>
          <p style={{marginLeft: '20px', marginRight: '20px'}}>
            {moment(payment.createTime).format('ll')}
          </p>
          <h3>{'Subtotal'}</h3>
          <p style={{marginLeft: '20px', marginRight: '20px'}}>
            {numeral(payment?.subtotal ?? 0).format('$0,0.00')}
          </p>
          <h3>{'Total'}</h3>
          <p style={{marginLeft: '20px', marginRight: '20px'}}>
            {numeral(payment?.total ?? 0).format('$0,0.00')}
          </p>
          <h3>{'Resumen'}</h3>
          <p style={{marginLeft: '20px', marginRight: '20px'}}>
            {payment.summary}
          </p>
        </Col>
      </Row>
    </Modal>
  );
};

export default PaymentEditor;
