import React, {useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {Form, Row, Col, Button, Input} from 'antd';

import {BRAND_LAGOON} from '../../../constants/themeColors';
import {FormInstance} from 'antd/es/form/Form';
import apiInstance from '../../../network/axios';
interface IResetPwdForm {
  userEmail: string;
  retrievalToken: string;
}

const ResetPwdForm = ({retrievalToken, userEmail}: IResetPwdForm) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('Restablecer contraseña');
  const [message, setMessage] = useState<string>(
    'Introduzca una nueva contraseña',
  );
  const [dirty, setDirty] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const formRef = useRef<FormInstance>();

  const handleConfirmBlur = (e) => {
    const {value} = e.target;
    setDirty(dirty || !!value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const values = await formRef.current.validateFields();
    const requester = userEmail;
    const token = retrievalToken;
    const newPwd = values.password;

    setLoading(true);
    requestPwdReset(requester, token, newPwd);
  };

  const requestPwdReset = async (email, token, new_pwd) => {
    try {
      const url = `/remember/${email}/${token}`;
      const body = {
        password: new_pwd,
      };

      await apiInstance.post(url, body);

      setSuccess(true);
      setMessage('¡Restablecimiento de contraseña exitoso!');
      setTitle('Contraseña restablecida');
      setLoading(false);
    } catch (error) {
      setMessage('¡Ha ocurrido un error');
      setTitle('Restablecimiento fallido');
      setLoading(false);
      setError(true);
    }
  };

  return (
    <Form
      ref={formRef}
      style={{
        width: '470px',
        backgroundColor: '#FFFFFF',
        borderRadius: '5px',
        padding: '25px 50px',
      }}>
      <Row>
        <Col
          span={24}
          style={{fontSize: '18px', fontWeight: 'bold', margin: '7px 0px'}}>
          {title}
        </Col>
      </Row>

      <Row>
        <Col
          span={24}
          style={{
            fontSize: '12px',
            fontWeight: '600',
            marginBottom: '30px',
          }}>
          {message}
        </Col>
      </Row>

      {error && (
        <Row>
          <Col
            span={24}
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
            }}>
            {'Lo sentimos, ha ocurrido un error al restablecer su contraseña, ' +
              'vuelva a intentar pulsando en el enlace enviado a su correo electrónico ó ' +
              'solicite nuevamente el restablecimiento de su contraseña desde la página de inicio.'}
          </Col>
        </Row>
      )}

      {!error && (
        <Form.Item
          labelCol={{span: 24}}
          label="Nueva contraseña"
          hasFeedback
          required={false}
          name="password"
          rules={[
            {
              required: true,
              message: 'Ingrese contraseña!',
            },
            {whitespace: true, message: 'Evite espacios! '},
            {
              min: 5,
              message: 'Contraseña debe contener al menos 5 caracteres!',
            },
          ]}>
          <Input.Password
            placeholder={'Nueva contraseña'}
            disabled={loading || success}
          />
        </Form.Item>
      )}

      {!error && (
        <Form.Item
          labelCol={{span: 24}}
          label="Verificar nueva contraseña"
          name={'confirm'}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Verifique contraseña!',
            },
            ({getFieldValue}) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!'),
                );
              },
            }),
          ]}
          required={false}>
          <Input.Password
            onBlur={handleConfirmBlur}
            placeholder={'Verificar nueva contraseña'}
            disabled={loading || success}
          />
        </Form.Item>
      )}

      {error || success ? (
        <div style={{marginTop: '30px'}}>
          <Link
            style={{color: BRAND_LAGOON, fontWeight: 'bold'}}
            to={'/'}
            replace>
            {'Ir a Portos'}
          </Link>
        </div>
      ) : (
        <Form.Item style={{marginTop: '40px'}}>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            className="login-form-button"
            loading={loading}
            block
            onClick={handleSubmit}
            style={{
              fontWeight: 'bold',
              backgroundColor: '#51D3BD',
              borderColor: '#51D3BD',
            }}>
            {'Confirmar'}
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};

export default ResetPwdForm;
