import React, {useState, useEffect} from 'react';
import {Doughnut} from 'react-chartjs-2';
import 'chart.js/auto';
import moment from 'moment';
import {Col, Row, List, Button, DatePicker, Pagination} from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import {BASE_URL, DEVELOPMENT, NETWORK_TIMEOUT} from '../../constants/network';
import {
  getHomesName,
  getUserHood,
  getUserToken,
} from '../../navigation/auth/auth';
import Axios from 'axios';
import LoadingContent from '../loading/LoadingContent';
import NoContent from '../empty_screens/empty_content/NoContent';
import {styles} from './styles';
import numeral from 'numeral';
import FeatureIcon from '../../general_components/FeatureIcon/FeatureIcon';
import {
  BASE_BLUE,
  BASE_GRAY,
  UTILITY_BLUE,
  UTILITY_GREEN,
} from '../../constants/themeColors';
import AddButton from '../../atoms/AddButton';
import AddEntry from './components/AddEntry';
import axios from 'axios';
import ViewHomeStatus from './components/ViewHomeStatus';
import AddModal from './components/AddModal';
import {formatHomeNumber} from '../../utils/strings';

const Accounting = () => {
  const [searching, setSearching] = useState(false);
  const [searchingFor, setSearchingFor] = useState([]);
  const [page, setPage] = useState(1);
  const [totalAvailableItems, setTotalAvailableItems] = useState(10);
  const [dashboardData, setDashboardData] = useState<any>([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [debtors, setDebtors] = useState([]);
  const [estimatedBlance, setEstimatedBlance] = useState(0);
  const [outstandingBalance, setOutstandingBalance] = useState(0);
  const [addIncome, setAddIncome] = useState(false);
  const [categories, setCategories] = useState([]);
  const [homes, setHomes] = useState([]);
  const [currentHomeSelected, setCurrentHomeSelected] = useState(null);

  useEffect(() => {
    getAll();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const _estimatedBalance =
      dashboardData?.maintenanceFee ?? 0 * dashboardData?.totalHomes ?? 0;
    const _outstandingBalance =
      dashboardData?.maintenanceFee ?? 0 * totalAvailableItems;
    setEstimatedBlance(_estimatedBalance);
    setOutstandingBalance(_estimatedBalance - _outstandingBalance);
    // eslint-disable-next-line
  }, [totalAvailableItems]);

  const date = new Date();
  const firstDay = new Date(
    date.getFullYear(),
    date.getMonth(),
    1,
  ).toISOString();
  const lastDay = new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
  ).toISOString();

  const onChangeDate = (dates) => {
    if (dates?.length > 1) {
      setSearchingFor([
        new Date(dates[0]._d).toISOString(),
        new Date(dates[1]._d).toISOString(),
      ]);
    }
  };

  const searchModeOff = () => {
    setSearching(false);
    setSearchingFor([]);
    setLoading(true);
    getDashboardData(firstDay, lastDay);
    getDebtors();
  };

  const handleSearchSubmit = () => {
    setSearching(true);
    setLoading(true);
    getDashboardData(searchingFor[0], searchingFor[1]);
    getDebtors();
  };
  const goToPage = (page) => {
    setPage(page);
    getDebtors(page);
  };

  const getDashboardData = async (_minDate, _maxDate) => {
    setLoading(true);
    try {
      const userNeighborhoodID = getUserHood();
      const userToken = getUserToken();
      const url = `${BASE_URL}/neighborhoods/${userNeighborhoodID}/accounting/totals?min_date=${_minDate}&max_date=${_maxDate}`;
      const options = {
        headers: {
          'X-App': 'admin',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + userToken,
        },
        timeout: NETWORK_TIMEOUT,
      };
      const response = await Axios.get(url, options);

      if (response.data.success) {
        setDashboardData(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      if (DEVELOPMENT) {
        console.log('Error getting dashboardData', error?.response ?? error);
      }
      setLoading(false);
      setError(true);
    }
  };
  const getDebtors = async (pageNum = 1) => {
    setLoading(true);
    try {
      const userNeighborhoodID = getUserHood();
      const userToken = getUserToken();
      const url = `${BASE_URL}/neighborhoods/${userNeighborhoodID}/accounting/homes?min_date=${
        searchingFor.length === 0 ? firstDay : searchingFor[0]
      }&max_date=${
        searchingFor.length === 0 ? lastDay : searchingFor[1]
      }&limit=10&page=${pageNum}&getDebtors=true`;
      const options = {
        headers: {
          'X-App': 'admin',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + userToken,
        },
        timeout: NETWORK_TIMEOUT,
      };
      const response = await Axios.get(url, options);
      if (response.data) {
        setDebtors(response.data);
        setTotalAvailableItems(parseInt(response.headers['x-paginate-total']));
        setLoading(false);
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      if (DEVELOPMENT) {
        console.log('Error getting debtors', error?.response ?? error);
      }
      setLoading(false);
      setError(true);
    }
  };
  const getCategories = async () => {
    setLoading(true);
    try {
      const userNeighborhoodID = getUserHood();
      const userToken = getUserToken();
      const url = `${BASE_URL}/neighborhoods/${userNeighborhoodID}/accounting/categories`;
      const options = {
        headers: {
          'X-App': 'admin',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + userToken,
        },
        timeout: NETWORK_TIMEOUT,
      };
      const response = await Axios.get(url, options);
      if (response.data.success) {
        setCategories(response.data.categories);
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (error) {
      if (DEVELOPMENT) {
        console.log('Error getting categories', error?.response ?? error);
      }
      setLoading(false);
      setError(true);
    }
  };
  const getHomes = async () => {
    setLoading(true);
    try {
      const userNeighborhoodID = getUserHood();
      const userToken = getUserToken();

      const url = `${BASE_URL}/neighborhoods/${userNeighborhoodID}/homes`;

      const options = {
        headers: {
          'X-App': 'admin',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Authorization: 'Bearer ' + userToken,
        },
        timeout: NETWORK_TIMEOUT,
      };

      const response = await axios.get(url, options);
      const _homes = [];
      response.data.homes.forEach((e) => {
        const item = {
          _id: e._id,
          name: formatHomeNumber(e),
        };
        _homes.push(item);
      });
      setHomes(_homes);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const getAll = async () => {
    await getCategories();
    await getDashboardData(firstDay, lastDay);
    getDebtors();
    getHomes();
  };

  if (loading) {
    return <LoadingContent />;
  }

  if (error) {
    return (
      <NoContent
        emptyWhat={'network'}
        withRetry
        onClickMethod={() => {
          setError(false);
          setLoading(true);
          getDashboardData(firstDay, lastDay);
          getDebtors();
        }}
      />
    );
  }
  if (categories.length === 0) {
    return (
      <AddModal
        visible={categories.length === 0}
        title={'Añadir categoria'}
        cancelable={false}
        iconColor={UTILITY_BLUE}
        reload={() => {
          getAll();
        }}
      />
    );
  }
  const {RangePicker} = DatePicker;
  const totalEntries = {
    datasets: [
      {
        label: '# of Votes',
        data: [
          estimatedBlance - outstandingBalance === 0
            ? 0.0001
            : estimatedBlance - outstandingBalance,
          outstandingBalance,
        ],
        backgroundColor: ['#00000029', '#466BAF'],
        borderColor: ['#95E4EE', '#466BAF'],
        borderWidth: 1,
      },
    ],
  };
  const totalHomes = {
    datasets: [
      {
        label: '# of Votes',
        data: [
          dashboardData.totalHomes -
            (dashboardData.totalHomes - totalAvailableItems),
          dashboardData.totalHomes - totalAvailableItems,
        ],
        backgroundColor: ['#00000029', '#466BAF'],
        borderColor: ['#95E4EE', '#466BAF'],
        borderWidth: 1,
      },
    ],
    labels: ['Faltan por pagar', 'Han pagado'],
  };
  const options = {
    responsive: true,
    cutout: '80%',
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <div style={styles.mainContainer}>
      <Row
        gutter={[16, {xs: 16, sm: 0, md: 0, lg: 0}]}
        style={{backgroundColor: 'transparent'}}>
        <Col xs={24} sm={10} md={11} style={styles.bold}>
          {'Contabilidad'}
        </Col>
        <Col xs={24} sm={8}>
          <RangePicker
            disabled={searching}
            onChange={onChangeDate}
            format={'DD/MM/YYYY'}
            locale={locale}
            style={styles.item}
            value={[
              moment(searchingFor.length > 1 ? searchingFor[0] : firstDay),
              moment(searchingFor.length > 1 ? searchingFor[1] : lastDay),
            ]}
            placeholder={[
              searching ? 'Búsqueda Completada' : 'Seleccionar Fecha',
              searching ? 'Búsqueda Completada' : 'Seleccionar Fecha',
            ]}
          />
        </Col>
        <Col
          xs={{span: 12, offset: 12}}
          sm={{span: 6, offset: 0}}
          md={{span: 5, offset: 0}}
          style={styles.configTablePaginationColumnEnd}>
          {searching ? (
            <Button onClick={searchModeOff}>{'REGRESAR'}</Button>
          ) : (
            <Button
              disabled={searchingFor.length === 0}
              onClick={handleSearchSubmit}>
              {'BUSCAR'}
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={24} style={styles.rowSeparator} />
      </Row>

      <Row gutter={[15, 15]} align="top">
        <Col xs={24} lg={14}>
          <Row gutter={[15, 15]}>
            <Col xs={24} md={12}>
              <div style={styles.paper}>
                <p style={styles.normal}>{'Saldo estimado'}</p>
                <p style={styles.bold}>
                  {numeral(
                    dashboardData.maintenanceFee * dashboardData.totalHomes,
                  ).format('$0,0.00')}
                </p>
                <div style={styles.chartContainer}>
                  <Doughnut data={totalEntries} />
                </div>
                <p style={styles.normal}>{'Saldo Pendiente'}</p>
                <p style={styles.bold}>
                  {numeral(
                    dashboardData.maintenanceFee * totalAvailableItems,
                  ).format('$0,0.00')}
                </p>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div style={styles.paper}>
                <p style={styles.normal}>{'Al día de hoy han pagado'}</p>
                <div style={styles.chartContainer}>
                  <div style={styles.totalHomesContainer}>
                    <div style={styles.totalHomes}>
                      {dashboardData.totalHomes -
                        totalAvailableItems +
                        '/' +
                        dashboardData.totalHomes}
                    </div>
                  </div>
                  <Doughnut data={totalHomes} options={options} />
                </div>
                <p style={styles.normal}>{'condóminos'}</p>
              </div>
            </Col>
          </Row>
          <Row gutter={[15, 15]} style={{marginTop: 15, marginBottom: 15}}>
            <Col span={12}>
              <div style={styles.smallPaper}>
                <p style={styles.bold}>{'Ingresos'}</p>
                <div style={styles.tintGreenCenter}>
                  {numeral(dashboardData?.incomeTotal ?? 0).format('$0,0.00')}
                </div>
              </div>
            </Col>
            <Col span={12}>
              <div style={styles.smallPaper}>
                <p style={styles.bold}>{'Egresos'}</p>
                <div style={styles.tintRedCenter}>
                  {`-${numeral(dashboardData?.expensesTotal ?? 0).format(
                    '$0,0.00',
                  )}`}
                </div>
              </div>
            </Col>
          </Row>
        </Col>

        <Col xs={24} lg={10}>
          <div style={styles.dashboardList}>
            <Row>
              <Col span={14}>
                <p style={styles.dashboardListTitle}>{'Faltan por pagar'}</p>
              </Col>
              <Col span={10}>
                <div style={styles.configPaginationContainer}>
                  <Pagination
                    defaultCurrent={page}
                    total={totalAvailableItems}
                    size={'small'}
                    onChange={(pageNum) => goToPage(pageNum)}
                  />
                </div>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col span={24} style={{marginTop: 10, marginBottom: 10}}>
                <div style={styles.configPaginationContainer}>
                  <AddButton
                    title={'AÑADIR PAGO'}
                    onClick={() => setAddIncome(true)}
                    color={BASE_BLUE}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <List
                  locale={{emptyText: 'Sin Registros'}}
                  // style={styles.configList}
                  dataSource={debtors}
                  renderItem={(item) => {
                    return (
                      <List.Item
                        style={{
                          minHeight: '60px',
                          borderColor: BASE_GRAY,
                          borderStyle: 'solid',
                          borderWidth: 1,
                          marginBottom: 8,
                          padding: 8,
                        }}>
                        <Col xs={6} md={5}>
                          <FeatureIcon
                            feature={'homeAccounting'}
                            entryType="expense"
                          />
                        </Col>
                        <Col xs={12} md={13}>
                          {`${getHomesName()} ${item.number} - ${
                            item?.inside_number ?? 'S/N'
                          }`}
                        </Col>

                        <Col span={6}>
                          <Button
                            block
                            onClick={() => {
                              setCurrentHomeSelected(item);
                            }}
                            style={styles.button}>
                            {'VER'}
                          </Button>
                        </Col>
                      </List.Item>
                    );
                  }}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{height: '15px'}} />
      </Row>
      <AddEntry
        concept="Mantenimiento"
        amount={dashboardData.maintenanceFee}
        visible={addIncome}
        iconColor={UTILITY_GREEN}
        title={'Añadir ingreso'}
        onCancel={() => setAddIncome(false)}
        categories={categories}
        type={'income'}
        reload={() => getAll()}
        homes={homes}
      />
      <ViewHomeStatus
        visible={currentHomeSelected !== null}
        data={currentHomeSelected}
        onCancel={() => setCurrentHomeSelected(null)}
        reload={() => getAll()}
        categories={categories}
        homes={homes}
      />
    </div>
  );
};

export default Accounting;
