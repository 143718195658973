import {Col, Row} from 'antd';
import React from 'react';
import {getLevelConfig} from '../../../navigation/auth/auth';
import {RegisterIndicatorStyles as styles} from '../styles';
import {UilArrowLeft, UilArrowRight} from '@iconscout/react-unicons';
import {
  ANOTHER_GRAY,
  BASE_GREEN,
  DEACTIVATED_ITEM,
} from '../../../constants/themeColors';

const RegisterIndicator = (props: any) => {
  const {disabledNext, disabledPrev, onClickNext, onClickPrev, level} = props;
  return (
    <Row style={styles.main}>
      <Col xs={24} lg={12} style={styles.col}>
        <div
          style={{
            ...styles.arrow,
            borderColor: !disabledPrev ? ANOTHER_GRAY : DEACTIVATED_ITEM,
            cursor: !disabledPrev ? 'pointer' : 'no-drop',
          }}
          onClick={() => {
            if (!disabledPrev) {
              onClickPrev();
            }
          }}>
          <UilArrowLeft
            color={!disabledPrev ? ANOTHER_GRAY : DEACTIVATED_ITEM}
            size={40}
          />
        </div>
        <div>
          <p style={styles.title}>Mi registro</p>
          <p style={styles.step}>{`Paso ${
            level || getLevelConfig() + 1
          } de 4`}</p>
        </div>
        <div
          style={{
            ...styles.arrow,
            borderColor: !disabledNext ? BASE_GREEN : DEACTIVATED_ITEM,
            cursor: !disabledNext ? 'pointer' : 'no-drop',
          }}
          onClick={() => {
            if (!disabledNext) {
              onClickNext();
            }
          }}>
          <UilArrowRight
            color={!disabledNext ? BASE_GREEN : DEACTIVATED_ITEM}
            size={40}
          />
        </div>
      </Col>
    </Row>
  );
};
export default RegisterIndicator;
