import React, {useState} from 'react';
import {Form, Input, Switch, Cascader} from 'antd';

//local imports
import {getHomesName} from '../../../navigation/auth/auth';
import apiInstance from '../../../network/axios';
import {resizeString} from '../../../utils/strings';
import {OwnerFormEditorStyles as styles} from '../styles';

interface IOwnerForm {
  streetsObject: any[];
  editMode: boolean;
  formRef: any;
  defaultValues?: any;
}

const OwnerForm = ({
  defaultValues,
  editMode,
  formRef,
  streetsObject,
}: IOwnerForm) => {
  const [options, setOptions] = useState<any[]>(
    streetsObject.map((item) => ({
      value: item.id,
      label: resizeString(item?.name ?? '', 27),
      isLeaf: false,
    })),
  );

  const loadData = async (selectedOptions: any[]) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    const homesInStreet = await retrieveHomesData(targetOption.value);

    targetOption.children = [...homesInStreet];
    setOptions([...options]);
    targetOption.loading = false;
  };
  const retrieveHomesData = async (street_id) => {
    try {
      const response = await apiInstance.get(`/streets/${street_id}/homes`);
      const result = response.data;

      if (result.length > 0) {
        return result.map((item: any) => {
          return {
            value: item.id,
            label: `${item?.number ?? ' '} - ${item?.inside_number ?? ' '}`,
          };
        });
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  };

  const label = defaultValues?.home?.number
    ? `${defaultValues?.home?.number ?? ''} - ${
        defaultValues?.home?.inside_number ?? ''
      }`
    : '';

  return (
    <Form ref={formRef}>
      <Form.Item
        labelCol={{span: 24}}
        label="Nombre"
        required={false}
        name="name"
        rules={[
          {required: true, message: 'Ingrese nombre!'},
          {whitespace: true, message: 'Ingrese nombre!'},
        ]}
        initialValue={defaultValues?.name ?? ''}>
        <Input placeholder={'Nombre'} readOnly={!editMode} />
      </Form.Item>

      <Form.Item
        labelCol={{span: 24}}
        label="Apellido"
        required={false}
        name="last_name"
        rules={[
          {required: true, message: 'Ingrese apellido!'},
          {whitespace: true, message: 'Ingrese apellido!'},
        ]}
        initialValue={defaultValues?.last_name ?? ''}>
        <Input placeholder={'Apellido'} readOnly={!editMode} />
      </Form.Item>

      <Form.Item
        labelCol={{span: 24}}
        label="Correo Electrónico"
        required={false}
        name="email"
        rules={[
          {
            type: 'email',
            message: "Ingrese formato valido. Ej. 'usuario@portos.xyz'",
          },
          {
            required: true,
            message: 'Ingrese correo electrónico!',
          },
        ]}
        initialValue={defaultValues?.username ?? ''}>
        <Input placeholder={'Correo Electrónico'} readOnly={!editMode} />
      </Form.Item>

      <Form.Item
        labelCol={{span: 24}}
        label="Teléfono"
        required={false}
        name={'phone'}
        rules={[
          {required: true, message: 'Ingrese teléfono!'},
          {whitespace: true, message: 'Ingrese teléfono!'},
        ]}
        initialValue={defaultValues?.phone_number ?? ''}>
        <Input placeholder={'Teléfono'} readOnly={!editMode} />
      </Form.Item>

      <Form.Item
        labelCol={{span: 24}}
        label={getHomesName()}
        required={false}
        name={'home_id'}
        rules={[
          {
            type: 'array',
            len: 2,
            required: !defaultValues,
            message: 'Selecciona ' + getHomesName() + '!',
          },
        ]}>
        <Cascader
          options={options}
          loadData={loadData}
          placeholder={defaultValues ? label : `Selecciona ${getHomesName()}`}
          changeOnSelect
          disabled={!editMode}
        />
      </Form.Item>
      <div style={styles.container}>
        <Form.Item
          name={'active'}
          valuePropName="checked"
          style={styles.item}
          initialValue={defaultValues?.approved ?? true}>
          <Switch style={styles.switch} size="small" disabled={!editMode} />
        </Form.Item>
        <h4 style={styles.h4}>{'Habilitado'}</h4>
      </div>
    </Form>
  );
};

export default OwnerForm;
