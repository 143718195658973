import {CSSProperties} from 'react';

export const CommonLayoutStyles: Record<string, CSSProperties> = {
  main: {padding: '6.25% 4.166%'},
  title: {fontSize: '20px', fontWeight: 'bold', height: '33px'},
  buttonAddContainer: {
    padding: '0px',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  separator: {minHeight: '16px'},
};
