import React, {useEffect, useState} from 'react';
import {Col, Row, Modal, Input, DatePicker, Button, Select} from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

//local imports
import {getUserHood} from '../../../navigation/auth/auth';
import LoadingContent from '../../loading/LoadingContent';
import NoContent from '../../empty_screens/empty_content/NoContent';
import FeatureIcon from '../../../general_components/FeatureIcon/FeatureIcon';
import apiInstance from '../../../network/axios';

interface IMessageEditor {
  visible: boolean;
  closeModalTrigger: () => void;
  closeRefreshAfterCreate: () => void;
  executivePrivilege: boolean;
}

const MessageEditor = ({
  executivePrivilege,
  closeModalTrigger,
  closeRefreshAfterCreate,
  visible,
}: IMessageEditor) => {
  const [loading, setLoading] = useState<boolean>(executivePrivilege);
  const [error, setError] = useState<boolean>(false);
  const [expirationDate, setExpirationDate] = useState<Date | null>();
  const [title, setTitle] = useState<string>('');
  const [neighborhoodIds, setNeighborhoodIds] = useState<string[]>([]);
  const [hoods, setHoods] = useState<any[]>([]);
  const [deltaValue, setDeltaValue] = useState<any>();

  useEffect(() => {
    if (executivePrivilege) {
      retrieveHoodsData();
    }
  }, [executivePrivilege]);

  const retrieveHoodsData = async () => {
    try {
      const response = await apiInstance.get('/neighborhoods');
      const result = response.data;

      if (result.length === 0) {
        setLoading(false);
        return;
      }
      setHoods([...result]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const onChangeDate = (date) => {
    setExpirationDate(date);
  };

  const onChangeTitle = (event) => {
    setTitle(event.target.value);
  };

  const validatePost = () => {
    if (!title) {
      alert('Ingrese título para continuar');
      return;
    }
    if (!deltaValue) {
      alert('Ingrese mensaje para continuar');
      return;
    }

    if (executivePrivilege) {
      if (neighborhoodIds.length === 0) {
        alert('Ingrese vecindarios para continuar');
        return;
      }
    }
    setLoading(true);
    postCreateMessage();
  };

  const onSelectHood = (value) => {
    setNeighborhoodIds([...value]);
  };

  const postCreateMessage = async () => {
    try {
      const userNeighborhoodID = getUserHood();
      let url = `/neighborhoods/${userNeighborhoodID}/messages`;

      let body = {};

      if (!expirationDate) {
        body = {
          title,
          message: JSON.stringify(deltaValue, null, 2),
        };
      } else {
        body = {
          title,
          expires: expirationDate.toISOString(),
          message: JSON.stringify(deltaValue, null, 2),
        };
      }

      if (executivePrivilege) {
        url = '/neighborhoods/admin/messages';
        body = {
          ...body,
          ...{neighborhood: [...neighborhoodIds]},
        };
      }
      body['type'] = 'MESSAGE';

      await apiInstance.post(url, body);

      setLoading(false);
      closeRefreshAfterCreate();
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const retryAfterNetErr = () => {
    setLoading(false);
    setError(true);
    setExpirationDate(null);
  };

  return (
    <Modal
      centered
      visible={visible}
      onOk={validatePost}
      onCancel={closeModalTrigger}
      okText="CREAR"
      cancelText="CANCELAR"
      maskClosable={false}
      zIndex={2000}
      destroyOnClose={true}
      closable={loading ? false : true}
      footer={[
        <Button key="back" disabled={loading} onClick={closeModalTrigger}>
          {'CANCELAR'}
        </Button>,
        error ? (
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={retryAfterNetErr}>
            {'REINTENTAR'}
          </Button>
        ) : (
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={validatePost}>
            {'CREAR'}
          </Button>
        ),
      ]}
      title={
        <Row>
          <Col xs={5} sm={4} md={3}>
            <FeatureIcon feature="message" />
          </Col>
          <Col xs={19} sm={20} md={21} style={{fontWeight: 'bold'}}>
            {'Nuevo Mensaje'}
          </Col>
        </Row>
      }>
      {loading ? (
        <LoadingContent />
      ) : error ? (
        <NoContent emptyWhat={'network'} />
      ) : (
        <Row>
          <Col span={24}>
            <h3>{'Título'}</h3>
            <Input
              value={title}
              placeholder="Título"
              style={{marginBottom: '20px'}}
              onChange={onChangeTitle}
            />
            <h3>{'Mensaje'}</h3>
            <ReactQuill
              theme="snow"
              onChange={(_, __, ___, editor) => {
                setDeltaValue(editor.getContents());
              }}
              style={{marginBottom: '20px'}}
              placeholder="Mensaje"
              defaultValue={null}
            />
            <h3>{'Fecha de Expiración'}</h3>
            <DatePicker
              onChange={onChangeDate}
              format={'DD-MM-YYYY'}
              showToday={false}
              locale={locale}
              style={{width: '100%', marginBottom: '40px'}}
              placeholder="Fecha de Expiración"
              popupStyle={{zIndex: 3000}}
            />

            {executivePrivilege && <h3>{'Vecindarios a enviar mensaje'}</h3>}

            {executivePrivilege && (
              <Select
                mode="multiple"
                disabled={hoods.length <= 0}
                style={{width: '100%'}}
                dropdownStyle={{zIndex: 3000}}
                onChange={onSelectHood}
                placeholder={
                  hoods.length <= 0
                    ? 'Agregue vecindarios para habilitar'
                    : null
                }>
                {hoods.map((item) => (
                  <Select.Option value={item.id} key={item.id}>
                    {item.number ? item.number : 'Sin Nombre'}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default MessageEditor;
