import React from 'react';
import {LoadingOutlined} from '@ant-design/icons';

const LoadingContent = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}>
      <LoadingOutlined
        style={{color: '#2EC3DF', fontSize: '30px', fontWeight: 'bold'}}
      />
    </div>
  );
};

export default LoadingContent;
