/* eslint-disable react-hooks/exhaustive-deps */
import {Button, Col, Input, Row} from 'antd';
import axios from 'axios';
import React, {useContext, useEffect, useState} from 'react';
import {BASE_URL, DEVELOPMENT, NETWORK_TIMEOUT} from '../../constants/network';
import BrandLogo from '../../navigation/topoptionbar/components/BrandLogo';
import Asset from '../../res/img/createPasswordAsset.png';
import NoContent from '../empty_screens/empty_content/NoContent';
import LoadingContent from '../loading/LoadingContent';
import {DJCreateOwnerPassStyles as styles} from './styles';
import Appstore from '../../res/img/appStore.png';
import PlayStore from '../../res/img/googlePlay.png';
import Done from '../../res/img/passwordCreated.png';
import {AuthContext} from '../../context/AuthContext';

const DJCreateOwnerPass = (props) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validating, setValidating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [done, setDone] = useState(false);
  const context: any = useContext(AuthContext);
  useEffect(() => {
    localStorage.clear();
    context.setGlobalState({showTopBar: false});
  }, []);

  const createPass = async () => {
    try {
      if (password === '' && password !== confirmPassword) {
        setValidating(true);
        return;
      }
      setLoading(true);
      const {email, token} = props.match.params;
      const url = BASE_URL + '/confirm_register/' + email + '/' + token;
      const body = {
        password,
      };

      const options = {
        headers: {
          'X-App': 'admin',
          'Content-Type': 'application/json',
        },
        timeout: NETWORK_TIMEOUT,
      };

      const resp = await axios.post(url, body, options);
      // console.log('RESP CREATE PASSWORD', resp);
      if (resp.status === 200) {
        setLoading(false);
        setDone(true);
      } else {
        setLoading(false);
        setError(true);
      }
    } catch (e) {
      if (DEVELOPMENT) {
        console.log('ERROR CREATING PASSWORD', e);
      }
      setLoading(false);
      setError(true);
    }
  };

  if (loading) {
    return <LoadingContent />;
  }
  if (done) {
    return (
      <div style={styles.main}>
        <div style={styles.logo}>
          <BrandLogo />
        </div>
        <Row gutter={[50, 15]}>
          <Col span={24} style={styles.form}>
            <div style={styles.left}>
              <img src={Done} alt={'Todo listo'} style={styles.imageDone} />
              <h1 style={styles.title}>¡Todo listo!</h1>
              <p style={styles.message}>
                Tu cuenta ha sido creada con éxito. Para comenzar a disfrutar de
                Portos es necesario que descargues la aplicación en tu teléfono.
              </p>
              <Row gutter={[80, 15]}>
                <Col
                  xs={24}
                  sm={12}
                  onClick={() =>
                    window.open(
                      'https://apps.apple.com/mx/app/portos/id1480726251',
                      '_blank',
                    )
                  }>
                  <img
                    src={Appstore}
                    alt={'Descargar para IOS'}
                    style={styles.store}
                  />
                </Col>
                <Col
                  xs={24}
                  sm={12}
                  onClick={() =>
                    window.open(
                      'https://play.google.com/store/apps/details?id=xyz.portos.owner',
                      '_blank',
                    )
                  }>
                  <img
                    src={PlayStore}
                    alt={'Descargar para Android'}
                    style={styles.store}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
  if (error) {
    return (
      <NoContent
        emptyWhat={'passwordCreated'}
        withRetry
        onClickMethod={() => {
          setError(false);
        }}
      />
    );
  }
  return (
    <div style={styles.main}>
      <div style={styles.logo}>
        <BrandLogo />
      </div>
      <Row gutter={[50, 15]}>
        <Col xs={24} sm={14} lg={12} style={styles.form}>
          <div style={styles.left}>
            <h1 style={styles.title}>¡Bienvenido a Portos!</h1>
            <p style={styles.message}>
              Tu condominio ya cuenta con Portos. Para tener acceso a nuestras
              funciones necesitas crear una contraseña para tu usuario.
            </p>
            <div style={styles.inputContainer}>
              <Input
                onChange={(event) => setPassword(event.target.value)}
                placeholder={'Contraseña'}
                type="password"
                style={styles.input}
              />
              {validating && password.length < 8 && (
                <p style={styles.validation}>
                  La contraseña debe tener al menos 8 caracteres.
                </p>
              )}
            </div>
            <div style={styles.inputContainer}>
              <Input
                onChange={(event) => setConfirmPassword(event.target.value)}
                placeholder={'Confirmar contraseña'}
                type="password"
                style={styles.input}
              />
              {validating &&
                password !== '' &&
                password !== confirmPassword && (
                  <p style={styles.validation}>Las contraseñas no coinciden.</p>
                )}
            </div>

            <Button
              style={styles.button}
              onClick={() => {
                createPass();
              }}>
              {'CREAR CONTRASEÑA'}
            </Button>
          </div>
        </Col>
        <Col xs={24} sm={10} lg={12} style={styles.right}>
          <img src={Asset} alt="Portos" style={styles.building} />
        </Col>
      </Row>
    </div>
  );
};

export default DJCreateOwnerPass;
