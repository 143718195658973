import React, {useState} from 'react';
import {Form, Row, Col, Button, Input} from 'antd';

//local imports
import {BRAND_LAGOON, UTILITY_RED} from '../../../constants/themeColors';
import {formItemLayout} from '../../../utils/cols';
import apiInstance from '../../../network/axios';

const PwordRecoveryForm = (props: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [message, setMessage] = useState<string>(
    'Introduce tu dirección de correo electrónico.',
  );
  const [success, setSuccess] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const doTheRetry = (e: any) => {
    e.preventDefault();
    setLoading(false);
    setMessage('Introduce tu dirección de correo electrónico.');
    setSuccess(false);
    setError(false);
  };

  const handleSubmit = (e) => {
    if (email === '') {
      return;
    }
    requestPassword(email);
  };

  const requestPassword = async (email) => {
    setLoading(true);
    try {
      const url = '/remember/' + email;

      await apiInstance.get(url);
      setMessage(
        'Hemos enviado las instrucciones de restablecimiento de contraseña a tu dirección de correo electrónico.',
      );
      setSuccess(true);
      setLoading(false);
    } catch (error) {
      const errorMessage = error?.response?.data?.message ?? '';
      if (errorMessage === 'UserNotFound') {
        setMessage('Este correo no esta registrado.');
        return;
      }
      setError(true);
      setLoading(false);
      setMessage('Error de red. Vuelva a intentar.');
      setSuccess(false);
    }
  };
  return (
    <Form
      {...formItemLayout}
      style={{
        width: '470px',
        backgroundColor: '#FFFFFF',
        borderRadius: '5px',
        padding: '20px 50px',
      }}>
      <Row>
        <Col span={24}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            <Button
              icon="left"
              type="link"
              onClick={props.goBack}
              style={{color: '#000', fontSize: '12px'}}>
              {'Volver'}
            </Button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col
          span={24}
          style={{fontSize: '18px', fontWeight: 'bold', margin: '5px 0px'}}>
          {'Recuperar contraseña'}
        </Col>
      </Row>

      <Row>
        <Col
          span={24}
          style={{
            fontSize: '12px',
            fontWeight: '600',
            marginBottom: '15px',
          }}>
          {message}
        </Col>
      </Row>

      <Form.Item
        rules={[
          {
            type: 'email',
            message: "Ingrese formato valido. Ej. 'usuario@portos.xyz'",
          },
          {
            required: true,
            message: 'Ingrese correo electrónico!',
          },
        ]}>
        <Input
          size="large"
          placeholder={'Correo Electrónico'}
          disabled={loading ? true : success ? true : error}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          size="large"
          className="login-form-button"
          block
          onClick={error ? doTheRetry : success ? props.goBack : handleSubmit}
          style={{
            fontWeight: 'bold',
            backgroundColor: error ? UTILITY_RED : BRAND_LAGOON,
            borderColor: '#51D3BD',
            marginTop: '60px',
            marginBottom: '20px',
          }}>
          {error ? 'Reintentar' : success ? 'Aceptar' : 'Enviar'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default PwordRecoveryForm;
