import React, {useCallback, useContext, useState} from 'react';
import {Menu, Layout} from 'antd';
import {withRouter} from 'react-router-dom';

//local imports
import {
  getHomesName,
  getStreetsName,
  isAuthenticated,
  getHoodName,
  getAccessLevel,
  getUserToken,
} from '../auth/auth';
import logo from '../../res/img/trans_128.png';
import {BRAND_BLUE, BRAND_TURQUOISE} from '../../constants/themeColors';
import * as Unicons from '@iconscout/react-unicons';
import {
  BarChartOutlined,
  DashboardOutlined,
  DownOutlined,
  LikeOutlined,
  LogoutOutlined,
  MailOutlined,
  MenuOutlined,
  SettingFilled,
  UserOutlined,
  DollarOutlined,
} from '@ant-design/icons';
import {RoutesManifest} from '../Routes';
import {useSasToken} from '../../hooks/useSasToken';
import {AuthContext} from '../../context/AuthContext';
import {AuthContextType} from '../../types/contextTypes';

const {SubMenu} = Menu;
const {Sider} = Layout;

const LeftHandNavigator = (props: any) => {
  const absoluteMenu = {
    position: 'absolute',
    maxHeight: '100%',
    backgroundColor: '#FFFFFF',
    overflowY: 'auto',
    zIndex: 1000,
  };
  const defaultMenu = {
    maxHeight: '100%',
    backgroundColor: '#FFFFFF',
    overflowY: 'auto',
  };
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [menuStyle, setMenuStyle] = useState<any>(defaultMenu);
  const {token: sasToken} = useSasToken();

  const {
    state: {hoodImagePath},
  } = useContext(AuthContext) as AuthContextType;

  const onCollapse = useCallback((collapsed: boolean) => {
    setCollapsed(collapsed);
  }, []);

  const mobileMenuFitter = (window_width: boolean) => {
    if (window_width) {
      setMenuStyle(absoluteMenu);
    } else {
      setMenuStyle(defaultMenu);
    }
  };

  const routesAsObject = () => {
    const routes = {};
    Object.keys(RoutesManifest).forEach((route) => {
      const name = RoutesManifest[route].name;
      const path = RoutesManifest[route].path;
      routes[name] = path;
    });
    return routes;
  };

  const handleClick = (e: any) => {
    const r = routesAsObject();
    props.history.push(r[e.key]);
    if (r[e.key] === '/') localStorage.clear();
  };

  const getCurrentKey = () => {
    const currentItem = Object.keys(RoutesManifest).find(
      (item) => RoutesManifest[item].path === window.location.pathname,
    );
    return currentItem;
  };

  const authed = isAuthenticated();
  if (!authed) return null;

  const homesName = getHomesName();
  const streetsName = getStreetsName();
  const userToken = getUserToken();

  const imagePath = hoodImagePath.includes(
    'portosuploads.blob.core.windows.net',
  )
    ? `${hoodImagePath}?${sasToken}`
    : `${hoodImagePath}&token=${userToken}`;

  const hoodName =
    getHoodName() === 'NOT_AVAILABLE'
      ? 'Panel de Administración'
      : getHoodName();
  const fontsize = '14px';
  const fontweight = 'bold';
  const currentKey = getCurrentKey();
  const accessLevel = getAccessLevel();

  return (
    <Sider
      breakpoint="lg"
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
      collapsedWidth="0"
      onBreakpoint={mobileMenuFitter}
      zeroWidthTriggerStyle={{
        position: 'fixed',
        top: '13px',
        right: 0,
        bottom: 0,
        left: '10px',
        zIndex: 99,
        backgroundColor: accessLevel === 'SUPER_ADMIN' ? '#FFFFFF' : null,
        color: accessLevel === 'SUPER_ADMIN' ? BRAND_TURQUOISE : '#FFFFFF',
      }}
      style={menuStyle}
      width={'250px'}>
      <div
        style={{
          width: '100%',
          minHeight: collapsed ? '45px' : '140px',
          backgroundColor: '#FFFFFF',
        }}>
        <div
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            height: '100%',
          }}>
          <div
            style={{
              display: 'flex',
              flex: 3,
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <div
              style={{
                marginTop: 20,
                width: 100,
                height: 100,
              }}>
              <img
                src={
                  accessLevel === 'SUPER_ADMIN'
                    ? logo
                    : sasToken
                    ? imagePath
                    : logo
                }
                alt="hood-avatar"
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'block',
                  borderRadius: '50%',
                  backgroundColor:
                    accessLevel === 'SUPER_ADMIN' ? BRAND_BLUE : 'transparent',
                  objectFit: 'cover',
                }}
              />
            </div>
          </div>

          {!collapsed && (
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <p
                style={{
                  fontSize: '150%',
                  fontWeight: 'bold',
                  color: '#000000',
                  overflowWrap: 'break-word',
                  textAlign: 'center',
                  padding: '5% 5%',
                  margin: 0,
                }}>
                {hoodName}
              </p>
            </div>
          )}
        </div>
      </div>

      <div
        style={{
          height: '2px',
          backgroundColor: '#E6E6E6',
          margin: '0px 25px 40px 25px',
        }}
      />

      <Menu
        theme="light"
        onClick={handleClick}
        defaultSelectedKeys={['dashboard']}
        defaultOpenKeys={['records', 'inbox']}
        selectedKeys={[RoutesManifest[currentKey].name]}
        mode="inline">
        {accessLevel === 'SUPER_ADMIN' && (
          <Menu.Item key={RoutesManifest.hoods.name}>
            <MenuOutlined />
            <span style={{fontSize: fontsize, fontWeight: fontweight}}>
              {'Vecindarios'}
            </span>
          </Menu.Item>
        )}
        {accessLevel === 'ADMIN' && (
          <Menu.Item key={RoutesManifest.dashboard.name}>
            <DashboardOutlined />
            <span style={{fontSize: fontsize, fontWeight: fontweight}}>
              {'Dashboard'}
            </span>
          </Menu.Item>
        )}

        {accessLevel === 'SUPER_ADMIN' && (
          <Menu.Item key={RoutesManifest.users.name}>
            <UserOutlined />
            <span style={{fontSize: fontsize, fontWeight: fontweight}}>
              {'Usuarios'}
            </span>
          </Menu.Item>
        )}

        {accessLevel === 'ADMIN' && (
          <SubMenu
            key="records"
            title={
              <span>
                <MenuOutlined />
                <span style={{fontSize: fontsize, fontWeight: fontweight}}>
                  {'Registros'}
                </span>
              </span>
            }>
            <Menu.Item key={RoutesManifest.entrance.name}>
              {'Entradas'}
            </Menu.Item>
            <Menu.Item key={RoutesManifest.exit.name}>{'Salidas'}</Menu.Item>
          </SubMenu>
        )}

        <SubMenu
          key="inbox"
          title={
            <span>
              <MailOutlined />
              <span style={{fontSize: fontsize, fontWeight: fontweight}}>
                {'Enviados'}
              </span>
            </span>
          }>
          <Menu.Item key={RoutesManifest.messages.name}>{'Mensajes'}</Menu.Item>
          <Menu.Item key={RoutesManifest.polls.name}>{'Encuestas'}</Menu.Item>
        </SubMenu>

        {accessLevel === 'ADMIN' && (
          <SubMenu
            key="received"
            title={
              <span>
                <DownOutlined />
                <span style={{fontSize: fontsize, fontWeight: fontweight}}>
                  {'Recibidos'}
                </span>
              </span>
            }>
            <Menu.Item key={RoutesManifest.reports.name}>
              {'Reportes'}
            </Menu.Item>
          </SubMenu>
        )}
        {accessLevel === 'ADMIN' && (
          <SubMenu
            key="common"
            title={
              <span>
                <Unicons.UilTrees
                  size={20}
                  color={'#242425'}
                  style={{marginBottom: -5, marginLeft: -3, marginRight: 3}}
                />
                <span style={{fontSize: fontsize, fontWeight: fontweight}}>
                  {'Areas Comunes'}
                </span>
              </span>
            }>
            <Menu.Item key={RoutesManifest.commonAreas.name}>
              {'Mis áreas'}
            </Menu.Item>
            <Menu.Item key={RoutesManifest.commonAreasReservations.name}>
              {'Reservaciones'}
            </Menu.Item>
          </SubMenu>
        )}

        {accessLevel === 'ADMIN' && (
          <SubMenu
            key="admin"
            title={
              <span>
                <BarChartOutlined />
                <span style={{fontSize: fontsize, fontWeight: fontweight}}>
                  {'Administración'}
                </span>
              </span>
            }>
            <Menu.Item key={RoutesManifest.streets.name}>
              {streetsName + 's'}
            </Menu.Item>
            <Menu.Item key={RoutesManifest.homes.name}>
              {homesName + 's'}
            </Menu.Item>
            <Menu.Item key={RoutesManifest.owners.name}>{'Usuarios'}</Menu.Item>
          </SubMenu>
        )}

        {accessLevel === 'ADMIN' && (
          <SubMenu
            key="accounting-menu"
            title={
              <span>
                <Unicons.UilDollarSign
                  size={20}
                  color={'#242425'}
                  style={{marginBottom: -5, marginLeft: -3, marginRight: 3}}
                />
                <span style={{fontSize: fontsize, fontWeight: fontweight}}>
                  {'Contabilidad'}
                </span>
              </span>
            }>
            <Menu.Item key={RoutesManifest.accountingDashboard.name}>
              {'Dashboard'}
            </Menu.Item>
            <Menu.Item
              key={
                RoutesManifest.accountingHomes.name
              }>{`${getHomesName()}s`}</Menu.Item>
            <Menu.Item key={RoutesManifest.accountingConfig.name}>
              {'Configuración'}
            </Menu.Item>
            <Menu.Item key={RoutesManifest.accountingIncomeExpenses.name}>
              {'Ingresos/Egresos'}
            </Menu.Item>
            <Menu.Item key={RoutesManifest.accountingBalance.name}>
              {'Balance general'}
            </Menu.Item>
          </SubMenu>
        )}

        <SubMenu
          key="settings"
          title={
            <span>
              <SettingFilled />
              <span style={{fontSize: fontsize, fontWeight: fontweight}}>
                {'Ajustes'}
              </span>
            </span>
          }>
          <Menu.Item key={RoutesManifest.profile.name}>{'Perfil'}</Menu.Item>
          {accessLevel === 'ADMIN' && (
            <Menu.Item key={RoutesManifest.viewNeighborhood.name}>
              {'Condominio'}
            </Menu.Item>
          )}
          {accessLevel === 'ADMIN' && (
            <Menu.Item key={RoutesManifest.subscription.name}>
              {'Mi suscripción'}
            </Menu.Item>
          )}
          {accessLevel === 'ADMIN' && (
            <SubMenu key="security" title="Seguridad">
              <Menu.Item key={RoutesManifest.doors.name}>{'Puertas'}</Menu.Item>
              <Menu.Item key={RoutesManifest.cameras.name}>
                {'Cámaras'}
              </Menu.Item>
            </SubMenu>
          )}
        </SubMenu>

        {accessLevel === 'SUPER_ADMIN' && (
          <Menu.Item key={RoutesManifest.feedback.name}>
            <LikeOutlined />
            <span style={{fontSize: fontsize, fontWeight: fontweight}}>
              {'Feedback'}
            </span>
          </Menu.Item>
        )}
        {accessLevel === 'SUPER_ADMIN' && (
          <Menu.Item key={RoutesManifest.payments.name}>
            <DollarOutlined />
            <span style={{fontSize: fontsize, fontWeight: fontweight}}>
              {'Pagos'}
            </span>
          </Menu.Item>
        )}

        <Menu.Item key={RoutesManifest.login.name}>
          <LogoutOutlined />
          <span style={{fontSize: fontsize, fontWeight: fontweight}}>
            {'Salir'}
          </span>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default withRouter(LeftHandNavigator);
