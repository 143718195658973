import {CSSProperties} from 'react';
import {
  BRAND_LAGOON,
  UTILITY_BLUE,
  UTILITY_YELLOW,
} from '../../constants/themeColors';

export const styles: Record<string, CSSProperties> = {
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    borderWidth: 1,
    borderColor: '#D9D9D9',
    borderStyle: 'solid',
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    padding: 15,
    minHeight: 300,
  },
  bold: {fontSize: '20px', fontWeight: 'bold', margin: 0, padding: 0},
  normal: {
    fontSize: '18px',
    textAlign: 'center',
    fontWeight: '500',
    padding: 0,
    margin: 0,
  },
  mainContainer: {padding: '6.25% 4.166%'},
  rowSeparator: {height: '32px'},
  iconContainer: {
    width: 50,
    height: 50,
    borderRadius: 25,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: BRAND_LAGOON,
  },
  big: {
    fontSize: 55,
  },
  warningContainer: {
    height: '65px',
    borderColor: UTILITY_YELLOW,
    backgroundColor: `${UTILITY_YELLOW}33`,
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 20,
  },
  link: {
    color: UTILITY_BLUE,
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  p: {margin: 0},
  itemIcon: {
    fontWeight: 'bold',
    paddingLeft: 10,
  },
};
