import {CSSProperties} from 'react';
import {BASE_GRAY, BASE_GREEN, BASE_RED} from '../../constants/themeColors';

export const styles: Record<string, CSSProperties> = {
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    borderWidth: 1,
    borderColor: '#D9D9D9',
    borderStyle: 'solid',
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    padding: 15,
    minHeight: 380,
  },
  smallPaper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    borderWidth: 1,
    borderColor: '#D9D9D9',
    borderStyle: 'solid',
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    padding: 15,
    minHeight: 90,
  },
  bold: {fontSize: '20px', fontWeight: 'bold', margin: 0, padding: 0},
  normal: {
    fontSize: '18px',
    textAlign: 'center',
    fontWeight: '500',
    padding: 0,
    margin: 0,
  },
  dashboardListTitle: {
    fontSize: '1.3em',
    fontWeight: '500',
    padding: 0,
    margin: 0,
  },
  boldCenter: {fontSize: '20px', fontWeight: 'bold', textAlign: 'center'},
  tintGreenCenter: {
    fontSize: '25px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: BASE_GREEN,
  },
  tintRedCenter: {
    fontSize: '25px',
    fontWeight: 'bold',
    textAlign: 'center',
    color: BASE_RED,
  },
  chartContainer: {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dashboardList: {
    display: 'flex',
    flexDirection: 'column',
    borderWidth: 1,
    borderColor: '#D9D9D9',
    borderStyle: 'solid',
    backgroundColor: '#FFFFFF',
    borderRadius: 5,
    padding: 15,
    minHeight: 90,
  },
  totalHomesContainer: {
    position: 'absolute',
  },
  totalHomes: {
    display: 'flex',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    color: '#707070',
    fontSize: 23,
    fontWeight: '600',
    paddingTop: 5,
  },
  addButtonContainer: {
    padding: '0px',
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  config: {
    backgroundColor: 'white',
    padding: 20,
    borderStyle: 'solid',
    borderColor: '#70707078',
    borderWidth: 1,
    borderRadius: 5,
  },
  mainContainer: {padding: '6.25% 4.166%'},
  rowSeparator: {height: '32px'},
  item: {width: '100%'},
  configTablePaginationRow: {
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderColor: '#70707078',
    borderWidth: 1,
    borderRadius: 0,
    marginTop: 36,
    borderBottomColor: 'white',
    padding: 5,
  },
  configTablePaginationColumnCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0px 10px',
    fontWeight: '600',
  },
  configTablePaginationColumnEnd: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0px 10px',
    fontWeight: '600',
  },
  configPaginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0px 0px',
  },
  configTableHeader: {
    borderStyle: 'solid',
    borderColor: '#70707078',
    borderWidth: 1,
    borderRadius: 0,
    borderBottomColor: 'white',
    padding: 10,
  },
  configList: {
    minHeight: '50px',
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderColor: '#70707078',
    borderWidth: 1,
    borderRadius: 0,
    paddingLeft: 10,
    paddingRight: 10,
  },
  button: {borderColor: BASE_GRAY},
  validation: {color: 'red'},
  h2: {color: '#707070'},
  categoryList: {
    minHeight: '70px',
    backgroundColor: 'white',
    padding: 15,
    borderStyle: 'solid',
    borderColor: '#70707078',
    borderWidth: 1,
    borderRadius: 5,
  },
  emptyChart: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
};
