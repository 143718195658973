import React, {useEffect, useRef, useState} from 'react';
import {Col, Row, Modal} from 'antd';

import {
  UTILITY_RED,
  UTILITY_YELLOW,
  UTILITY_BLUE,
} from '../../../constants/themeColors';
import LoadingContent from '../../loading/LoadingContent';
import NoContent from '../../empty_screens/empty_content/NoContent';
import FeatureIcon from '../../../general_components/FeatureIcon/FeatureIcon';
import UserFormEditor from './UserFormEditor';
import apiInstance from '../../../network/axios';
import {FormInstance} from 'antd/es/form/Form';
import {CircleButton} from '../../../atoms';

interface IUserEditor {
  visible: boolean;
  closeModalTrigger: () => void;
  closeRefreshAfterCreate: () => void;
  userObject?: any;
}

const UserEditor = ({
  closeModalTrigger,
  closeRefreshAfterCreate,
  userObject,
  visible,
}: IUserEditor) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const [hoods, setHoods] = useState<any[]>([]);

  const formRef = useRef<FormInstance>();

  useEffect(() => {
    retrieveHoodsData();
  }, []);

  const validatePut = async () => {
    const values = await formRef.current.validateFields();
    setLoading(true);
    putEditUser(values);
  };

  const retrieveHoodsData = async () => {
    try {
      const url = '/neighborhoods';
      const response = await apiInstance.get(url);
      const result = response.data;

      setHoods(result);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const putEditUser = async (form_object) => {
    try {
      const url = `/users/${userObject.id}`;

      let body: any = {
        name: form_object.name,
        last_name: form_object.last_name,
        phone_number: form_object.phone,
        username: form_object.email,
        approved: form_object.active,
        neighborhood: form_object.hood_id,
      };
      if (form_object.password) {
        body.password = form_object.password;
      }
      if (form_object.sub_role) {
        body['sub_role'] = form_object.sub_role;
      }
      await apiInstance.put(url, body);

      setLoading(false);
      closeRefreshAfterCreate();
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const confirmDeleteUser = () => {
    if (window.confirm('¿Esta seguro que desea eliminar este elemento?')) {
      setLoading(true);
      deleteUser();
    }
  };

  const deleteUser = async () => {
    try {
      const url = `/users/${userObject.id}`;
      await apiInstance.delete(url);
      setLoading(false);
      closeRefreshAfterCreate();
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const toggleEditMode = () => {
    if (editMode) {
      if (window.confirm('¿Abandonar modo de edición?')) {
        setEditMode(!editMode);
      } else {
        return;
      }
    } else {
      setEditMode(!editMode);
    }
  };

  return (
    <Modal
      centered
      visible={visible}
      onOk={validatePut}
      onCancel={closeModalTrigger}
      okText="CREAR"
      cancelText="CANCELAR"
      maskClosable={false}
      zIndex={2000}
      destroyOnClose={true}
      closable={!loading}
      footer={
        error ? null : (
          <Row>
            <Col span={2}>
              <CircleButton
                variant="del"
                activeColor={UTILITY_RED}
                disabled={loading}
                onClick={confirmDeleteUser}
              />
            </Col>
            <Col span={22}>
              <CircleButton
                variant="genericEdit"
                activeColor={UTILITY_YELLOW}
                disabled={loading}
                onClick={toggleEditMode}
                clickedMode={editMode}
              />
              <CircleButton
                variant="save"
                activeColor={UTILITY_BLUE}
                disabled={!editMode ? true : loading}
                onClick={validatePut}
              />
            </Col>
          </Row>
        )
      }
      title={
        <Row>
          <Col xs={5} sm={4} md={3}>
            <FeatureIcon feature="user" />
          </Col>
          <Col xs={19} sm={20} md={21} style={{fontWeight: 'bold'}}>
            {userObject?.name ?? 'Usuario'}
            {` ${userObject?.last_name ?? ''}`}
          </Col>
        </Row>
      }>
      {loading ? (
        <LoadingContent />
      ) : error ? (
        <NoContent emptyWhat={'network'} />
      ) : (
        <Row>
          <Col span={24}>
            <UserFormEditor
              hoodsObject={hoods}
              formRef={formRef}
              defaultValues={userObject ?? null}
              editMode={editMode}
            />
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default UserEditor;
