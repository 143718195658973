import React, {useRef, useState} from 'react';
import {Col, Row, Modal} from 'antd';

import {
  UTILITY_RED,
  UTILITY_YELLOW,
  UTILITY_BLUE,
} from '../../../constants/themeColors';
import LoadingContent from '../../loading/LoadingContent';
import NoContent from '../../empty_screens/empty_content/NoContent';
import FeatureIcon from '../../../general_components/FeatureIcon/FeatureIcon';
import DoorFormEditor from './DoorFormEditor';
import apiInstance from '../../../network/axios';
import {FormInstance} from 'antd/es/form/Form';
import {CircleButton} from '../../../atoms';

interface IDoorEditor {
  visible: boolean;
  closeModalTrigger: () => void;
  closeRefreshAfterCreate: () => void;
  doorObject: any;
}

const DoorEditor = ({
  closeModalTrigger,
  closeRefreshAfterCreate,
  doorObject,
  visible,
}: IDoorEditor) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [editMode, setEditMode] = useState<boolean>(false);
  const formRef = useRef<FormInstance>();

  const validatePut = async () => {
    const values = await formRef.current.validateFields();
    setLoading(true);
    putEditDoor(values);
  };

  const putEditDoor = async (form_object) => {
    try {
      const url = `/doors/${doorObject._id}`;
      const doorHost = `${form_object.host_1}.${form_object.host_2}.${form_object.host_3}.${form_object.host_4}`;

      const body = {
        number: form_object.door_number,
        user: form_object.user,
        description: form_object.description,
        password: form_object.password,
        closing_time: form_object.seconds,
        host: doorHost,
        port: form_object.port,
        normally_closed: form_object.normally_closed,
      };
      await apiInstance.put(url, body);
      setLoading(false);
      closeRefreshAfterCreate();
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const confirmDeleteDoor = () => {
    if (window.confirm('¿Esta seguro que desea eliminar esta puerta?')) {
      setLoading(true);
      deleteDoor();
    }
  };

  const deleteDoor = async () => {
    try {
      const url = `/doors/${doorObject._id}`;
      await apiInstance.delete(url);
      setLoading(false);
      closeRefreshAfterCreate();
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const toggleEditMode = () => {
    if (editMode) {
      if (window.confirm('¿Abandonar modo de edición?')) {
        setEditMode(!editMode);
      } else {
        return;
      }
    } else {
      setEditMode(!editMode);
    }
  };

  return (
    <Modal
      centered
      visible={visible}
      onOk={validatePut}
      onCancel={closeModalTrigger}
      okText="AGREGAR"
      cancelText="CANCELAR"
      maskClosable={false}
      zIndex={2000}
      destroyOnClose={true}
      closable={!loading}
      footer={
        error ? null : (
          <Row>
            <Col span={2}>
              <CircleButton
                variant="del"
                activeColor={UTILITY_RED}
                disabled={loading}
                onClick={confirmDeleteDoor}
              />
            </Col>
            <Col span={22}>
              <CircleButton
                variant="genericEdit"
                activeColor={UTILITY_YELLOW}
                disabled={loading}
                onClick={toggleEditMode}
                clickedMode={editMode}
              />
              <CircleButton
                variant="save"
                activeColor={UTILITY_BLUE}
                disabled={!editMode ? true : loading}
                onClick={validatePut}
              />
            </Col>
          </Row>
        )
      }
      title={
        <Row>
          <Col xs={5} sm={4} md={3}>
            <FeatureIcon feature="door" />
          </Col>
          <Col xs={19} sm={20} md={21} style={{fontWeight: 'bold'}}>
            {`Puerta ${doorObject?.number ?? ''}`}
          </Col>
        </Row>
      }>
      {loading ? (
        <LoadingContent />
      ) : error ? (
        <NoContent emptyWhat={'network'} />
      ) : (
        <Row>
          <Col span={24}>
            <DoorFormEditor
              formRef={formRef}
              defaultValues={doorObject ?? null}
              editMode={editMode}
            />
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default DoorEditor;
