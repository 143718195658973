import {CSSProperties} from 'react';
import {BASE_DARK_GRAY} from '../../constants/themeColors';

export const ReportStyles: Record<string, CSSProperties> = {
  reportTitle: {
    padding: '10px 20px 10px 3px',
    fontWeight: 'bold',
  },
  reportDetailButoon: {borderColor: '#545454'},
  filterText: {
    fontSize: '14px',
    fontWeight: '500',
    height: '33px',
    color: BASE_DARK_GRAY,
  },
  searchIcon: {color: 'rgba(0,0,0,.25)'},
  searchItem: {width: '90%'},
  searcButton: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
};
