import React, {useContext} from 'react';
import {Switch, Route} from 'react-router-dom';

//local imports

//auth
import ShieldedRoute from '../auth/ShieldedRoute';
import SuperShieldedRoute from '../auth/SuperShieldedRoute';
import MixedShieldedRoute from '../auth/MixedShieldedRoute';
import LeftHandNavigator from '../lefthandnav/LeftHandNavigator';
import TopOptionsBar from '../topoptionbar/TopOptionsBar';

//admin-screens
import Login from '../../screens/login/Login';
import Home from '../../screens/home/Home';
import Records from '../../screens/records/Records';
import ExitRecords from '../../screens/records/ExitRecords';
import Messages from '../../screens/messages/Messages';
import NotFound404 from '../../screens/not_found404/NotFound404';
import Polls from '../../screens/polls/Polls';
import Streets from '../../screens/streets/Streets';
import Homes from '../../screens/homes/Homes';
import Owners from '../../screens/owners/Owners';
import UserProfile from '../../screens/user_profile/UserProfile';
import Doors from '../../screens/doors/Doors';
import SecurityCams from '../../screens/security_cams/SecurityCams';
import Calendar from '../../screens/calendar/Calendar';
import Subscribe from '../../screens/subscribe/Subscribe';
import Notifications from '../../screens/notifications/Notifications';
import Settings from '../../screens/settings/Settings';
import PwdRecovery from '../../screens/pwd_recovery/PwdRecovery';

//super-admin screens
import Hoods from '../../screens/hoods/Hoods';
import Users from '../../screens/users/Users';

import {Layout} from 'antd';
import Feedback from '../../screens/feedback/Feedback';
import Payments from '../../screens/payments/Payments';
import Reports from '../../screens/reports/Reports';
import Accounting from '../../screens/accounting/Accounting';
import AccountingConfig from '../../screens/accounting/AccountingConfig';
import IncomeExpenses from '../../screens/accounting/IncomeExpenses';
import AccountingHomes from '../../screens/accounting/AccountingHomes';
import Balance from '../../screens/accounting/Balance';
import DJdRoute from '../auth/DJRoute';
import DJDashboard from '../../screens/digitalJourney/DJDashboard';
import DJCreateNeighborhood from '../../screens/digitalJourney/DJCreateNeighborhood';
import DJIntro from '../../screens/digitalJourney/DJIntro';
import DJAccessDenied from '../../screens/digitalJourney/DJAccessDenied';
import DJCreateStreets from '../../screens/digitalJourney/DJCreateStreets';
import DJCreateHomes from '../../screens/digitalJourney/DJCreateHomes';
import DJCreateOwners from '../../screens/digitalJourney/DJCreateOwners';
import {AuthContext} from '../../context/AuthContext';
import DJCreateOwnerPass from '../../screens/digitalJourney/DJCreateOwnerPass';
import DJVerifyEmail from '../../screens/digitalJourney/DJVerifyEmail';
import DJSelectPlan from '../../screens/digitalJourney/DJSelectPlan';
import Neighborhood from '../../screens/neighborhood/Neighborhood';
import Subscription from '../../screens/subscription/Subscription';
import Dashboard from '../../screens/dashboard/Dashboard';
import CommonAreas from '../../screens/common_areas/CommonAreas';
import Reservations from '../../screens/common_areas/Reservations';

import {RoutesManifest} from '../Routes';

const {Content} = Layout;

const Router = () => {
  const context: any = useContext(AuthContext);
  const r = RoutesManifest;
  return (
    <Layout style={{height: '100vh', width: '100vw'}}>
      {context.state.showTopBar && <TopOptionsBar />}

      <Layout
        style={{
          height: '100%',
          width: '100%',
          overflowY: 'scroll',
        }}>
        {context.state.showMenu && <LeftHandNavigator />}

        <Content style={{height: '100%'}}>
          <Switch>
            <ShieldedRoute routeName="cameras" exact path={r.cameras.path}>
              <SecurityCams />
            </ShieldedRoute>
            <ShieldedRoute routeName="reports" exact path={r.reports.path}>
              <Reports />
            </ShieldedRoute>
            <ShieldedRoute routeName="public" exact path={r.calendar.path}>
              <Calendar />
            </ShieldedRoute>
            <ShieldedRoute routeName="doors" exact path={r.doors.path}>
              <Doors />
            </ShieldedRoute>
            <ShieldedRoute routeName="public" exact path={r.home.path}>
              <Home />
            </ShieldedRoute>
            <SuperShieldedRoute exact path={r.hoods.path}>
              <Hoods />
            </SuperShieldedRoute>
            <SuperShieldedRoute exact path={r.feedback.path}>
              <Feedback />
            </SuperShieldedRoute>
            <SuperShieldedRoute exact path={r.payments.path}>
              <Payments />
            </SuperShieldedRoute>
            <SuperShieldedRoute exact path={r.users.path}>
              <Users />
            </SuperShieldedRoute>
            <ShieldedRoute routeName="entrance" exact path={r.entrance.path}>
              <Records />
            </ShieldedRoute>
            <ShieldedRoute routeName="exit" exact path={r.exit.path}>
              <ExitRecords />
            </ShieldedRoute>
            <ShieldedRoute routeName="streets" exact path={r.streets.path}>
              <Streets />
            </ShieldedRoute>
            <ShieldedRoute routeName="homes" exact path={r.homes.path}>
              <Homes />
            </ShieldedRoute>
            <ShieldedRoute routeName="owners" exact path={r.owners.path}>
              <Owners />
            </ShieldedRoute>
            <ShieldedRoute routeName="public" exact path={r.notifications.path}>
              <Notifications />
            </ShieldedRoute>
            <ShieldedRoute routeName="settings" exact path={r.settings.path}>
              <Settings />
            </ShieldedRoute>
            <ShieldedRoute routeName="public" exact path={r.subscribe.path}>
              <Subscribe />
            </ShieldedRoute>

            <MixedShieldedRoute
              routeName="messages"
              exact
              path={r.messages.path}>
              <Messages />
            </MixedShieldedRoute>

            <MixedShieldedRoute routeName="polls" exact path={r.polls.path}>
              <Polls />
            </MixedShieldedRoute>

            <Route exact path={r.recovery.path}>
              <PwdRecovery />
            </Route>

            <MixedShieldedRoute routeName="profile" exact path={r.profile.path}>
              <UserProfile />
            </MixedShieldedRoute>

            <ShieldedRoute
              routeName="accounting"
              exact
              path={r.accountingDashboard.path}>
              <Accounting />
            </ShieldedRoute>

            <ShieldedRoute
              routeName="accounting"
              exact
              path={r.accountingHomes.path}>
              <AccountingHomes />
            </ShieldedRoute>

            <ShieldedRoute
              routeName="public"
              exact
              path={r.accountingBalance.path}>
              <Balance />
            </ShieldedRoute>

            <ShieldedRoute
              routeName="accounting"
              exact
              path={r.accountingConfig.path}>
              <AccountingConfig />
            </ShieldedRoute>

            <ShieldedRoute
              routeName="accounting"
              exact
              path={r.accountingIncomeExpenses.path}>
              <IncomeExpenses />
            </ShieldedRoute>

            <DJdRoute exact path={r.djDasboard.path}>
              <DJDashboard />
            </DJdRoute>

            <DJdRoute exact path={r.djIntro.path}>
              <DJIntro />
            </DJdRoute>
            <DJdRoute exact path={r.djCreateNeighborhood.path}>
              <DJCreateNeighborhood />
            </DJdRoute>
            <DJdRoute exact path={r.djStreets.path}>
              <DJCreateStreets />
            </DJdRoute>
            <DJdRoute exact path={r.djHomes.path}>
              <DJCreateHomes />
            </DJdRoute>
            <DJdRoute exact path={r.djOwners.path}>
              <DJCreateOwners />
            </DJdRoute>

            <Route
              exact
              path={r.createPassword.path}
              component={DJCreateOwnerPass}
            />
            <Route exact path={r.verifyEmail.path} component={DJVerifyEmail} />

            <ShieldedRoute routeName="public" exact path={r.accessDenied.path}>
              <DJAccessDenied />
            </ShieldedRoute>
            <ShieldedRoute routeName="public" exact path={r.updatePlan.path}>
              <DJSelectPlan />
            </ShieldedRoute>

            <ShieldedRoute
              routeName="public"
              exact
              path={r.viewNeighborhood.path}>
              <Neighborhood />
            </ShieldedRoute>

            <ShieldedRoute routeName="public" exact path={r.subscription.path}>
              <Subscription />
            </ShieldedRoute>
            <ShieldedRoute
              routeName="common_areas"
              exact
              path={r.commonAreas.path}>
              <CommonAreas />
            </ShieldedRoute>
            <ShieldedRoute
              routeName="common_areas"
              exact
              path={r.commonAreasReservations.path}>
              <Reservations />
            </ShieldedRoute>

            <ShieldedRoute routeName="public" exact path={r.dashboard.path}>
              <Dashboard />
            </ShieldedRoute>

            <Route exact path={r.login.path}>
              <Login />
            </Route>

            <Route path="*">
              <NotFound404 />
            </Route>
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Router;
