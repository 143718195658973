/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {UilFilePlus} from '@iconscout/react-unicons';
import {
  BASE_BLUE,
  BRAND_LAGOON,
  BRAND_TURQUOISE,
  MEDIUM_GRAY,
} from '../../constants/themeColors';
import {CommonAreasStyles as styles} from './styles';
import {Button} from 'antd';
import AddCommonArea from './components/AddCommonArea';
import {getUserHood} from '../../navigation/auth/auth';
import AddButton from '../../atoms/AddButton';
import {ICONS} from './components/Icons';
import EditCommonArea from './components/EditCommonArea';
import {formatMins} from '../../utils/DateTime';
import CommonLayout from '../../layouts/CommonLayout';
import CustomTable from '../../molecules/CustomTable';
import apiInstance from '../../network/axios';

const CommonAreas = () => {
  const [areas, setAreas] = useState([]);
  const [newCommonArea, setNewCommonArea] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [dataForTable, setDataForTable] = useState([]);

  useEffect(() => {
    getCommonAreas();
  }, []);

  useEffect(() => {
    formatDataForTable(areas);
  }, [areas]);

  const getCommonAreas = async () => {
    try {
      setLoading(true);
      const userNeighborhoodID = getUserHood();
      const url = `/neighborhoods/${userNeighborhoodID}/common_areas`;
      const response = await apiInstance.get(url);
      setAreas(response?.data?.common_area ?? []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };
  const formatDays = (days) => {
    const options = {
      MON: 'Lun',
      TUE: 'Mar',
      WED: 'Mie',
      THU: 'Jue',
      FRI: 'Vie',
      SAT: 'Sab',
      SUN: 'Dom',
    };

    let formatedDays = '';
    days.forEach((day) => {
      formatedDays += `${options[day]}, `;
    });
    return formatedDays;
  };
  const formatDataForTable = (data = []) => {
    const icons = ICONS;
    const formatedData = [];
    data.forEach((item, index) => {
      const formatedElement = [];

      formatedElement.push(
        <div
          style={{
            ...styles.iconContainer,
            backgroundColor: index % 2 === 0 ? BRAND_LAGOON : BRAND_TURQUOISE,
          }}>
          {icons[item.icon]}
        </div>,
      );
      formatedElement.push(item?.name ?? 'name');
      formatedElement.push(formatDays(item.days_open));
      formatedElement.push(
        `${formatMins(item.opens_at)} - ${formatMins(item.closes_at)} hrs.`,
      );
      formatedElement.push(
        <Button
          block
          onClick={() => setCurrentItem(item)}
          style={styles.detailButton}>
          {'VER'}
        </Button>,
      );

      formatedData.push({
        data: formatedElement,
        aditionalData: {
          active: true,
        },
      });
    });
    setDataForTable(formatedData);
  };

  const onRetry = () => {
    setError(false);
    setLoading(true);
    getCommonAreas();
  };

  if (areas.length === 0 && !loading) {
    return (
      <div style={styles.main}>
        <AddCommonArea
          reload={getCommonAreas}
          title="Añadir área"
          visible={newCommonArea}
          onCancel={() => setNewCommonArea(false)}
          iconColor={BRAND_TURQUOISE}
        />
        <UilFilePlus size="150" color={MEDIUM_GRAY} />
        <p style={styles.message}>¡Aún no tienes áreas comunes!</p>
        <p style={styles.message2}>
          Estás cada vez más cerca de llevar tu condominio a otro nivel.
        </p>
        <div style={styles.buttonContainer}>
          <Button style={styles.button} onClick={() => setNewCommonArea(true)}>
            CREAR ÁREA COMÚN
          </Button>
        </div>
      </div>
    );
  }

  return (
    <CommonLayout
      title={'Áreas comunes'}
      buttonAdd={
        <AddButton
          title={'AÑADIR ÁREA COMUN'}
          onClick={() => setNewCommonArea(true)}
          color={BASE_BLUE}
        />
      }>
      <CustomTable
        loading={loading}
        error={{isThereError: error, emptyWhat: 'network', onRetry}}
        filters={{
          applyFilters: null,
          setApplyFilters: null,
          filtersText: null,
        }}
        pagination={{
          searching: null,
          page: null,
          totalItems: null,
          goToPage: null,
        }}
        columns={[
          {
            columnTitle: 'Ícono',
            sizes: {xs: 8, sm: 6, md: 4},
          },
          {
            columnTitle: 'Área',
            sizes: {xs: 8, sm: 6, md: 4},
          },
          {columnTitle: 'Días', sizes: {xs: 0, sm: 8, md: 9}},
          {columnTitle: 'Horario', sizes: {xs: 0, sm: 0, md: 4}},
          {columnTitle: 'Detalle', sizes: {xs: 8, sm: 4, md: 3}},
        ]}
        items={dataForTable}
      />

      <AddCommonArea
        reload={getCommonAreas}
        title="Añadir área"
        visible={newCommonArea}
        onCancel={() => setNewCommonArea(false)}
        iconColor={BRAND_TURQUOISE}
      />
      <EditCommonArea
        data={currentItem}
        reload={getCommonAreas}
        title="Detalle de área"
        visible={currentItem !== null}
        onCancel={() => setCurrentItem(null)}
        iconColor={BRAND_TURQUOISE}
      />
    </CommonLayout>
  );
};

export default CommonAreas;
