/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Col, Row, Modal, Input, DatePicker, Switch, Button, Select} from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';

//local imports
import {getUserHood} from '../../../navigation/auth/auth';
import LoadingContent from '../../loading/LoadingContent';
import NoContent from '../../empty_screens/empty_content/NoContent';
import FeatureIcon from '../../../general_components/FeatureIcon/FeatureIcon';
import {PollEditorStyles as styles} from '../styles';
import apiInstance from '../../../network/axios';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';

interface IPollEditor {
  visible: boolean;
  closeModalTrigger: () => void;
  closeRefreshAfterCreate: () => void;
  executivePrivilege: boolean;
}

const PollEditor = ({
  closeModalTrigger,
  closeRefreshAfterCreate,
  executivePrivilege,
  visible,
}: IPollEditor) => {
  const [loading, setLoading] = useState<boolean>(executivePrivilege);
  const [error, setError] = useState<boolean>(false);
  const [expirationDate, setExpirationDate] = useState<Date | null>();
  const [title, setTitle] = useState<string>('');
  const [neighborhoodIds, setNeighborhoodIds] = useState<string[]>([]);
  const [hoods, setHoods] = useState<any[]>([]);
  const [question, setQuestion] = useState<string>('');
  const [isPollAnonymus, setIsPollAnonymus] = useState<boolean>();
  const [openResults, setOpenResults] = useState<boolean>(false);
  const [allowOpenAnswer, setAllowOpenAnswer] = useState<boolean>(false);
  const [answers, setAnswers] = useState<any[]>([]);

  useEffect(() => {
    if (executivePrivilege) {
      retrieveHoodsData();
    }
  }, []);

  const retrieveHoodsData = async () => {
    try {
      const url = '/neighborhoods';
      const response = await apiInstance.get(url);
      const result = response.data;

      setLoading(false);
      setHoods(result);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const onChangeQuestion = (event) => {
    setQuestion(event.target.value);
  };

  const onChangeDate = (date) => {
    setExpirationDate(date);
  };

  const onChangeTitle = (event) => {
    setTitle(event.target.value);
  };

  const onCheckAnonymousPoll = (event: boolean) => {
    setIsPollAnonymus(event);
  };

  const onCheckOpenResults = (event: boolean) => {
    setOpenResults(event);
  };

  const onCheckAllowOpenAnswers = (event: boolean) => {
    setAllowOpenAnswer(event);
  };

  const postCreatePoll = async () => {
    try {
      const userNeighborhoodID = getUserHood();

      let url = `/neighborhoods/${userNeighborhoodID}/messages`;

      let body = {
        type: 'POLL',
        hasOpenResults: openResults,
        isAnonymous: isPollAnonymus,
        hasOtherAnswer: allowOpenAnswer,
        choices: answers,
        title,
        message: question,
        expires: expirationDate ?? undefined,
        neighborhood: undefined,
      };

      if (executivePrivilege) {
        url = '/neighborhoods/admin/messages';
        body.neighborhood = neighborhoodIds;
      }

      await apiInstance.post(url, body);
      setLoading(false);
      closeRefreshAfterCreate();
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const validatePollValues = () => {
    if (!title) {
      alert('Ingrese título para continuar');
      return;
    }
    if (!question) {
      alert('Ingrese mensaje para continuar');
      return;
    }
    if (executivePrivilege) {
      if (neighborhoodIds.length === 0) {
        alert('Ingrese vecindarios para continuar');
        return;
      }
    }

    if (answers.length === 0) {
      alert('Ingrese respuestas para continuar');
      return;
    }
    setLoading(true);
    postCreatePoll();
  };

  const onSelectHood = (value) => {
    setNeighborhoodIds(value);
  };

  const retryAfterNetErr = () => {
    setExpirationDate(null);
    setError(false);
    setLoading(false);
  };

  return (
    <Modal
      centered
      visible={visible}
      onOk={validatePollValues}
      onCancel={closeModalTrigger}
      okText="CREAR"
      cancelText="CANCELAR"
      maskClosable={false}
      zIndex={2000}
      width={'600px'}
      destroyOnClose={true}
      closable={!loading}
      footer={[
        <Button key="back" disabled={loading} onClick={closeModalTrigger}>
          {'CANCELAR'}
        </Button>,
        error ? (
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={retryAfterNetErr}>
            {'REINTENTAR'}
          </Button>
        ) : (
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={validatePollValues}>
            {'CREAR'}
          </Button>
        ),
      ]}
      title={
        <Row>
          <Col xs={5} sm={4} md={3}>
            <FeatureIcon feature="polls" />
          </Col>
          <Col xs={19} sm={20} md={21} style={styles.title}>
            {'Nueva Encuesta'}
          </Col>
        </Row>
      }>
      {loading ? (
        <LoadingContent />
      ) : error ? (
        <NoContent emptyWhat={'network'} />
      ) : (
        <>
          <Row>
            <Col span={24}>
              <h3>{'Título'}</h3>
              <Input
                value={title}
                placeholder="Título"
                style={styles.input}
                onChange={onChangeTitle}
              />
              <h3>{'Cuestión'}</h3>
              <Input.TextArea
                value={question}
                onChange={onChangeQuestion}
                style={styles.input}
                placeholder="Pregunta para los habitantes..."
                rows={4}
              />
              <h3>{'Fecha de Expiración'}</h3>
              <DatePicker
                onChange={onChangeDate}
                format={'DD-MM-YYYY'}
                showToday={false}
                locale={locale}
                style={styles.picker}
                placeholder="Fecha de Expiración"
                popupStyle={{zIndex: 3000}}
              />

              {executivePrivilege && <h3>{'Vecindarios a enviar encuesta'}</h3>}

              {executivePrivilege && (
                <Select
                  mode="multiple"
                  disabled={hoods.length <= 0}
                  style={styles.picker}
                  dropdownStyle={{zIndex: 3000}}
                  onChange={onSelectHood}
                  placeholder={
                    hoods.length <= 0
                      ? 'Agregue vecindarios para habilitar'
                      : null
                  }>
                  {hoods.length > 0 &&
                    hoods.map((item) => (
                      <Select.Option value={item.id} key={item.id}>
                        {item.number ? item.number : 'Sin Nombre'}
                      </Select.Option>
                    ))}
                </Select>
              )}
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <h3>{'Opciones'}</h3>
              <div style={styles.switchContainer}>
                <Switch
                  size="small"
                  defaultChecked
                  onChange={onCheckAnonymousPoll}
                  style={styles.switch}
                />
                <h4 style={styles.switchText}>{'Encuesta Anónima'}</h4>
              </div>

              <div style={styles.switchContainer}>
                <Switch
                  size="small"
                  onChange={onCheckOpenResults}
                  style={styles.switch}
                />
                <h4 style={styles.switchText}>
                  {'Resultados visibles para otros usuarios'}
                </h4>
              </div>

              <div style={styles.switchContainer}>
                <Switch
                  size="small"
                  onChange={onCheckAllowOpenAnswers}
                  style={styles.switch}
                />
                <h4 style={styles.switchText}>
                  {'Permitir respuesta abierta'}
                </h4>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <div>
                <h3>{'Respuestas'}</h3>
                {answers.map((answer, i) => (
                  <div key={i.toString()}>
                    <Input
                      style={styles.inputChoice}
                      placeholder="Respuesta"
                      value={answer}
                      onChange={(e) => {
                        const nextAnswers = [...answers];
                        nextAnswers[i] = e.target.value;
                        setAnswers(nextAnswers);
                      }}
                    />
                    <MinusCircleOutlined
                      onClick={() => {
                        const nextAnswers = [...answers];
                        nextAnswers.splice(i, 1);
                        setAnswers(nextAnswers);
                      }}
                    />
                  </div>
                ))}
                <Button
                  style={styles.button}
                  type="dashed"
                  onClick={() => setAnswers([...answers, ''])}
                  block
                  icon={<PlusOutlined />}>
                  {'Agregar Respuesta'}
                </Button>
              </div>
            </Col>
          </Row>
        </>
      )}
    </Modal>
  );
};

export default PollEditor;
