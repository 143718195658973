/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Col, Row, Button, Input} from 'antd';
import UilUser from '@iconscout/react-unicons/icons/uil-user';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';

//local imports
import {getHomesName} from '../../navigation/auth/auth';
import FeatureIcon from '../../general_components/FeatureIcon/FeatureIcon';
import OwnerCreator from './components/OwnerCreator';
import OwnerEditor from './components/OwnerEditor';
import CommonLayout from '../../layouts/CommonLayout';
import {OwnersStyles as styles} from './styles';
import apiInstance from '../../network/axios';
import CustomTable from '../../molecules/CustomTable';
import {formatHomeNumber, resizeString} from '../../utils/strings';
import {SearchOutlined} from '@ant-design/icons';

const Owners = (props) => {
  const [createOwnerModal, setCreateOwnerModal] = useState(false);
  const [viewOwnerModal, setViewOwnerModal] = useState<any>(false);
  const [searching, setSearching] = useState(false);
  const [searchFor, setSearchFor] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(10);
  const [dataForTable, setDataForTable] = useState([]);
  const [includeInactive, setIncludeInactive] = useState(true);

  useEffect(() => {
    retrieveOwnersData();
  }, []);

  useEffect(() => {
    formatDataForTable(data);
  }, [data]);

  const searchOwners = async (pageNum: number) => {
    try {
      setLoading(true);
      const query = searchFor;
      const url = `/users?limit=10&page=${pageNum}&query=${query}`;
      const response = await apiInstance.get(url);
      const result = response.data.filter((item) => item.role === 'OWNER');
      setTotalItems(
        response.headers['x-paginate-total']
          ? parseInt(response.headers['x-paginate-total'])
          : 10,
      );
      setLoading(false);
      setData(result);
    } catch (error) {
      setLoading(false);
      setError(true);
      setSearchFor('');
    }
  };

  const retrieveOwnersData = async (pageNum = 1) => {
    try {
      setLoading(true);
      const url = `/users?roles=OWNER&limit=10&page=${pageNum}`;
      const response = await apiInstance.get(url);
      const result = response.data.filter((item) => item.role === 'OWNER');
      setTotalItems(
        response.headers['x-paginate-total']
          ? parseInt(response.headers['x-paginate-total'])
          : 10,
      );
      setLoading(false);
      setData(result);
      setSearchFor('');
    } catch (error) {
      setLoading(false);
      setError(true);
      setSearchFor('');
    }
  };

  const handleSearchSubmit = () => {
    if (!searchFor) {
      return;
    }
    setPage(1);
    setError(false);
    setSearching(true);
    searchOwners(1);
  };

  const goToPage = (pageNum: number, isSearching: boolean) => {
    setError(false);
    setPage(pageNum);
    isSearching ? searchOwners(pageNum) : retrieveOwnersData(pageNum);
  };

  const searchModeOff = () => {
    setSearching(false);
    goToPage(1, false);
  };

  const formatDataForTable = (data = []) => {
    const formatedData = [];
    data.forEach((item, index) => {
      const statusIcon = (
        <FeatureIcon feature="user" randomColor={Math.floor(index % 5)} />
      );
      const formatedElement = [];

      formatedElement.push(
        <Row align="middle">
          {statusIcon}
          <Col xs={0} md={18} style={styles.itemIcon}>
            {`${item?.name ?? ''} ${item?.last_name ?? ''}`}
          </Col>
        </Row>,
      );
      formatedElement.push(formatHomeNumber(item?.home));
      formatedElement.push(item?.phone_number ?? 'NO PHONE');
      formatedElement.push(resizeString(item?.username ?? 'NO MAIL', 15));
      formatedElement.push(
        <Button
          block
          onClick={() => setViewOwnerModal(item)}
          style={styles.itemButton}>
          {'VER'}
        </Button>,
      );

      formatedData.push({
        data: formatedElement,
        aditionalData: {
          active: item.approved,
        },
      });
    });
    setDataForTable(formatedData);
  };

  const closeOwnerModalAndRefresh = () => {
    setPage(1);
    setError(false);
    setCreateOwnerModal(false);
    setViewOwnerModal(false);
    setSearchFor('');
    setSearching(false);
    goToPage(1, false);
  };

  return (
    <CommonLayout
      title={'Usuarios'}
      buttonAdd={
        <Button
          style={styles.container}
          onClick={() => setCreateOwnerModal(true)}>
          <Row justify="center" align="middle">
            <Col xs={24} sm={0}>
              <div style={styles.buton}>
                <UilPlus size="16" color="#FFFFFF" />
                <UilUser size="18" color="#FFFFFF" />
              </div>
            </Col>
            <Col xs={0} sm={24}>
              <div style={styles.iconButtonContainer}>
                <div style={styles.iconButton}>
                  <UilUser size="18" color="#FFFFFF" />
                </div>
                <div style={styles.buttonTitle}>{'CREAR USUARIO'}</div>
              </div>
            </Col>
          </Row>
        </Button>
      }
      filters={
        <Row style={{width: '100%'}}>
          <Col span={12}>
            <Input
              prefix={<SearchOutlined />}
              value={searchFor}
              disabled={searching}
              onChange={(value) => setSearchFor(value.target.value)}
              style={{width: '100%'}}
              placeholder={searching ? 'Búsqueda Completada' : 'Buscar...'}
              onPressEnter={handleSearchSubmit}
            />
          </Col>
          <Col
            span={12}
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            {searching ? (
              <Button onClick={searchModeOff}>{'REGRESAR'}</Button>
            ) : (
              <Button onClick={handleSearchSubmit}>{'BUSCAR'}</Button>
            )}
          </Col>
        </Row>
      }>
      <CustomTable
        loading={loading}
        error={{
          isThereError: error,
          emptyWhat: 'network',
          onRetry: () => goToPage(1, searching),
        }}
        filters={{
          applyFilters: includeInactive,
          setApplyFilters: setIncludeInactive,
          filtersText: 'INCLUIR INHABILITADOS',
        }}
        pagination={{
          searching,
          page,
          totalItems,
          goToPage,
        }}
        columns={[
          {
            columnTitle: 'Nombre',
            sizes: {xs: 8, sm: 4, md: 8, lg: 7},
          },
          {
            columnTitle: getHomesName(),
            sizes: {xs: 8, sm: 5, md: 3},
          },
          {columnTitle: 'Teléfono', sizes: {xs: 0, sm: 5, md: 5, xl: 5}},
          {columnTitle: 'Mail', sizes: {xs: 0, sm: 7, md: 5, lg: 6}},
          {columnTitle: 'Acción', sizes: {xs: 8, sm: 3}},
        ]}
        items={
          includeInactive
            ? dataForTable
            : dataForTable.filter((item) => item.aditionalData.active)
        }
      />

      {createOwnerModal && (
        <OwnerCreator
          visible={createOwnerModal}
          closeModalTrigger={() => setCreateOwnerModal(false)}
          closeRefreshAfterCreate={closeOwnerModalAndRefresh}
          onFirstCreate={props?.onFirstCreate ?? null}
        />
      )}
      {viewOwnerModal && (
        <OwnerEditor
          visible={viewOwnerModal !== false}
          closeModalTrigger={() => setViewOwnerModal(false)}
          closeRefreshAfterCreate={closeOwnerModalAndRefresh}
          ownerObject={viewOwnerModal}
        />
      )}
    </CommonLayout>
  );
};

export default Owners;
