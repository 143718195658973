import React from 'react';
import {Button} from 'antd';

const IforgotMyPword = ({buttonClick}) => {
  return (
    <div>
      <Button type="link" onClick={buttonClick} style={{marginBottom: '20px'}}>
        {'¿Olvidaste tu contraseña?'}
      </Button>
    </div>
  );
};

export default IforgotMyPword;
