import {Card, List} from 'antd';
import React from 'react';
import {getUserToken} from '../navigation/auth/auth';
import {useSasToken} from '../hooks/useSasToken';

type TImage = {
  url: string;
  path: string;
};

const ImageGrid = ({data}: {data: TImage[]}) => {
  const userToken = getUserToken();
  const {token} = useSasToken();

  data.sort((a, b) => {
    if (a.path < b.path) {
      return -1;
    }
    if (a.path > b.path) {
      return 1;
    }
    return 0;
  });

  const getTag = (path: string): string => {
    const categories = [
      {
        tag: 'Selfie',
        key: 'public/selfies',
      },
      {
        tag: 'Identificación',
        key: 'public/ids',
      },
      {
        tag: 'Identificación',
        key: 'id-',
      },
      {
        tag: 'Placa',
        key: 'plate-',
      },
      {
        tag: 'Vehículo',
        key: 'vehicle-',
      },
      {
        tag: 'Persona',
        key: 'person-',
      },
      {
        tag: 'Otra',
        key: 'other-',
      },
    ];
    const tag = categories.find((item) => path.includes(item.key));
    return tag?.tag ?? 'Otra';
  };
  return (
    <List
      grid={{
        gutter: 16,
        xs: 1,
        sm: 1,
        md: 2,
        lg: 2,
        xl: 2,
        xxl: 2,
      }}
      dataSource={data}
      renderItem={(item, index) => {
        // Legacy support
        const url = item.url.includes('portosuploads.blob.core.windows.net')
          ? `${item.path}?${token}`
          : `${item.url}&token=${userToken}`;
        return (
          <List.Item>
            <Card
              hoverable
              style={{
                borderRadius: 10,
                padding: 0,
                maxHeight: '350px',
                overflow: 'hidden',
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
              bodyStyle={{borderRadius: 10, padding: 0}}
              onClick={() => window.open(url, '_blank')}>
              <img
                style={{
                  borderRadius: 10,
                  maxWidth: '100%',
                }}
                alt={'photo-' + index}
                src={url}
              />
              <p style={{margin: 3, textAlign: 'center'}}>
                {getTag(item.path)}
              </p>
            </Card>
          </List.Item>
        );
      }}
    />
  );
};

export default ImageGrid;
