import {Col, Row} from 'antd';
import React from 'react';
import {CommonLayoutStyles as styles} from './styles';

const CommonLayout = ({children, buttonAdd = null, filters = null, title}) => {
  return (
    <div style={styles.main}>
      <Col>
        <Row>
          <Col span={12} style={styles.title}>
            {title ?? 'Title'}
          </Col>

          <Col span={12} style={styles.buttonAddContainer}>
            {buttonAdd}
          </Col>
        </Row>
        <Row style={styles.separator} />
        <Row>{filters}</Row>

        <Row style={styles.separator} />
        <Row style={styles.separator} />
      </Col>
      {children}
    </div>
  );
};

export default CommonLayout;
