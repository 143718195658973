import React, {useState} from 'react';
import {Form, Input, Select, Switch, Button} from 'antd';

interface IUserForm {
  editMode: boolean;
  formRef: any;
  defaultValues?: any;
  hoodsObject: any[];
}
const UserForm = ({
  editMode,
  formRef,
  defaultValues,
  hoodsObject,
}: IUserForm) => {
  const [passwordChange, setPasswordChange] = useState<boolean>();
  const [subRole, setSubRole] = useState<string>(defaultValues?.sub_role ?? '');
  const [role, setRole] = useState<string>(defaultValues?.role ?? '');

  const togglePwordChange = () => {
    setPasswordChange(!pwordChange);
  };

  const roleConvertDisplay = (role) => {
    return role
      ? role === 'SUPER_ADMIN'
        ? 'Super Administrador'
        : role === 'ADMIN'
        ? 'Administrador'
        : role === 'BELL'
        ? 'Timbre'
        : 'NO ROLE'
      : 'NO ROLE';
  };

  const pwordChange = editMode ? passwordChange : false;

  return (
    <Form ref={formRef}>
      <Form.Item
        labelCol={{span: 24}}
        label="Nombre"
        required={false}
        name={'name'}
        rules={[
          {required: true, message: 'Ingrese nombre!'},
          {whitespace: true, message: 'Ingrese nombre!'},
        ]}
        initialValue={defaultValues?.name ?? ''}>
        <Input placeholder={'Nombre'} readOnly={!editMode} />
      </Form.Item>

      <Form.Item
        labelCol={{span: 24}}
        label="Apellido"
        required={false}
        name="last_name"
        rules={[
          {required: true, message: 'Ingrese apellido!'},
          {whitespace: true, message: 'Ingrese apellido!'},
        ]}
        initialValue={defaultValues?.last_name ?? ''}>
        <Input placeholder={'Apellido'} readOnly={!editMode} />
      </Form.Item>

      <Form.Item
        labelCol={{span: 24}}
        label="Correo Electrónico"
        required={false}
        name="email"
        rules={[
          {
            type: 'email',
            message: "Ingrese formato valido. Ej. 'usuario@portos.xyz'",
          },
          {
            required: true,
            message: 'Ingrese correo electrónico!',
          },
        ]}
        initialValue={defaultValues?.username ?? ''}>
        <Input placeholder={'Correo Electrónico'} readOnly={!editMode} />
      </Form.Item>

      <Form.Item
        labelCol={{span: 24}}
        label="Teléfono"
        required={false}
        name="phone"
        rules={[
          {required: true, message: 'Ingrese teléfono!'},
          {whitespace: true, message: 'Ingrese teléfono!'},
        ]}
        initialValue={defaultValues?.phone_number ?? ''}>
        <Input placeholder={'Teléfono'} readOnly={!editMode} />
      </Form.Item>

      {defaultValues ? null : (
        <Form.Item
          labelCol={{span: 24}}
          label="Contraseña"
          hasFeedback
          required={false}
          name="password"
          rules={[
            {
              required: true,
              message: 'Ingrese contraseña!',
            },
            {whitespace: true, message: 'Evite espacios! '},
            {
              min: 5,
              message: 'Contraseña debe contener al menos 5 caracteres!',
            },
          ]}>
          <Input.Password placeholder={'Contraseña'} />
        </Form.Item>
      )}
      {defaultValues ? null : (
        <Form.Item
          labelCol={{span: 24}}
          required={false}
          label="Verificar Contraseña"
          hasFeedback
          name="confirm"
          rules={[
            {
              required: true,
              message: 'Verifique contraseña!',
            },
            ({getFieldValue}) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!'),
                );
              },
            }),
          ]}>
          <Input.Password
            placeholder={'Verificar Contraseña'}
            readOnly={!editMode}
          />
        </Form.Item>
      )}

      {defaultValues && (
        <div>
          <p style={{fontWeight: '600'}}>{'Rol'}</p>
          <p style={{paddingLeft: '12px'}}>
            {roleConvertDisplay(defaultValues.role)}
          </p>
          {defaultValues.role === 'BELL' && (
            <Form.Item
              labelCol={{span: 24}}
              label={'Tipo de timbre'}
              required={false}
              name="sub_role"
              rules={[{required: true, message: 'Selecciona un tipo'}]}
              initialValue={defaultValues?.sub_role ?? null}>
              <Select
                value={subRole}
                disabled={!editMode}
                style={{width: '100%'}}
                dropdownStyle={{zIndex: 3000}}
                onChange={(value) => {
                  setSubRole(value);
                }}>
                <Select.Option value="ENTRANCE">Entrada</Select.Option>
                <Select.Option value="EXIT">Salida</Select.Option>
                <Select.Option value="MANUAL">Manual</Select.Option>
              </Select>
            </Form.Item>
          )}
        </div>
      )}

      {!defaultValues && (
        <Form.Item
          labelCol={{span: 24}}
          label={'Rol del usuario'}
          required={false}
          name="role"
          rules={[{required: true, message: 'Selecciona un rol'}]}
          initialValue={defaultValues?.role ?? null}>
          <Select
            value={role}
            disabled={!editMode}
            style={{width: '100%'}}
            dropdownStyle={{zIndex: 3000}}
            onChange={(value) => setRole(value)}>
            <Select.Option value="SUPER_ADMIN">
              Super Administrador
            </Select.Option>
            <Select.Option value="ADMIN">Administrador</Select.Option>
            <Select.Option value="BELL">Timbre</Select.Option>
          </Select>
        </Form.Item>
      )}
      {!defaultValues && role === 'BELL' && (
        <Form.Item
          labelCol={{span: 24}}
          label={'Tipo de timbre'}
          required={false}
          name="sub_role"
          rules={[{required: true, message: 'Selecciona un tipo'}]}
          initialValue={defaultValues?.sub_role ?? null}>
          <Select
            value={subRole}
            disabled={!editMode}
            style={{width: '100%'}}
            dropdownStyle={{zIndex: 3000}}
            onChange={(value) => {
              setSubRole(value);
            }}>
            <Select.Option value="ENTRANCE" defaultValue>
              Entrada
            </Select.Option>
            <Select.Option value="EXIT">Salida</Select.Option>
            <Select.Option value="MANUAL">Manual</Select.Option>
          </Select>
        </Form.Item>
      )}
      {(role === 'ADMIN' || role === 'BELL') && (
        <Form.Item
          labelCol={{span: 24}}
          label={'Vecindario'}
          required={false}
          name="hood_id"
          rules={[{required: true, message: 'Selecciona un Vecindario!'}]}
          initialValue={defaultValues?.neighborhood?.id ?? null}>
          <Select
            disabled={hoodsObject.length <= 0 || !editMode}
            style={{width: '100%'}}
            dropdownStyle={{zIndex: 3000}}>
            {hoodsObject.length > 0 &&
              hoodsObject.map((item) => (
                <Select.Option value={item.id} key={item.id}>
                  {item.number ? item.number : 'Sin Nombre'}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          marginTop: '30px',
        }}>
        <Form.Item
          labelCol={{span: 24}}
          valuePropName="checked"
          name={'active'}
          style={{display: 'flex', margin: 0}}
          initialValue={defaultValues?.approved ?? false}>
          <Switch size="small" disabled={!editMode} style={{margin: 0}} />
        </Form.Item>
        <h4
          style={{
            padding: '0px 0px 0px 20px',
            margin: '0px',
            display: 'flex',
            height: 'auto',
          }}>
          {'Habilitado'}
        </h4>
      </div>

      {defaultValues && (
        <Button
          style={{
            color: pwordChange ? 'red' : null,
            padding: 0,
            marginBottom: '4.166%',
          }}
          type="link"
          onClick={togglePwordChange}
          disabled={!editMode}>
          {'Cambiar contraseña'}
        </Button>
      )}

      {pwordChange && (
        <Form.Item
          labelCol={{span: 24}}
          label="Contraseña"
          hasFeedback
          required={false}
          name={'password'}
          rules={[
            {
              required: true,
              message: 'Ingrese contraseña! ',
            },
            {whitespace: true, message: 'Evite espacios! '},
            {
              min: 5,
              message: 'Contraseña debe contener al menos 5 caracteres! ',
            },
          ]}>
          <Input.Password placeholder={'Contraseña'} readOnly={!editMode} />
        </Form.Item>
      )}

      {pwordChange && (
        <Form.Item
          labelCol={{span: 24}}
          required={false}
          label="Verificar Contraseña"
          hasFeedback
          name="confirm"
          rules={[
            {
              required: true,
              message: 'Verifique contraseña!',
            },
            ({getFieldValue}) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('The two passwords that you entered do not match!'),
                );
              },
            }),
          ]}>
          <Input.Password
            placeholder={'Verificar Contraseña'}
            readOnly={!editMode}
          />
        </Form.Item>
      )}
    </Form>
  );
};

export default UserForm;
