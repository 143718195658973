/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Col, Row, Button, Input} from 'antd';
import UilUser from '@iconscout/react-unicons/icons/uil-user';
import UilPlus from '@iconscout/react-unicons/icons/uil-plus';

import FeatureIcon from '../../general_components/FeatureIcon/FeatureIcon';
import UserCreator from './components/UserCreator';
import UserEditor from './components/UserEditor';
import CommonLayout from '../../layouts/CommonLayout';
import {UsersStyles as styles} from './styles';
import apiInstance from '../../network/axios';
import CustomTable from '../../molecules/CustomTable';
import {resizeString} from '../../utils/strings';
import {SearchOutlined} from '@ant-design/icons';

const Users = () => {
  const [createUserModal, setCreateUserModal] = useState(false);
  const [viewUserModal, setViewUserModal] = useState<any>(false);
  const [searching, setSearching] = useState(false);
  const [searchFor, setSearchFor] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalItems, setTotalItems] = useState(10);
  const [dataForTable, setDataForTable] = useState([]);
  const [includeInactive, setIncludeInactive] = useState(true);

  useEffect(() => {
    retrieveUsersData();
  }, []);

  useEffect(() => {
    formatDataForTable(data);
  }, [data]);

  const handleSearchSubmit = () => {
    if (!searchFor) {
      return;
    }
    setError(false);
    setSearching(true);
    setPage(1);
    searchUsers(1);
  };
  const searchUsers = async (pageNum: number) => {
    try {
      setLoading(true);
      const url = `/users?roles=SUPER_ADMIN&roles=ADMIN&roles=BELL&limit=10&page=${pageNum}&query=${searchFor}`;
      const response = await apiInstance.get(url);
      const result = response.data;
      setTotalItems(
        response.headers['x-paginate-total']
          ? parseInt(response.headers['x-paginate-total'])
          : 10,
      );
      setData(result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(true);
      setSearchFor('');
    }
  };
  const searchModeOff = () => {
    setSearching(false);
    setSearchFor('');
    setError(true);
    goToPage(1, false);
  };
  const goToPage = (pageNum: number, isSearching: boolean) => {
    setPage(pageNum);
    setError(false);

    isSearching ? searchUsers(pageNum) : retrieveUsersData(pageNum);
  };

  const retrieveUsersData = async (pageNum = 1) => {
    try {
      setLoading(true);
      const url = `/users?roles=SUPER_ADMIN&roles=ADMIN&roles=BELL&limit=10&page=${pageNum}`;
      const response = await apiInstance.get(url);
      const result = response.data;
      setTotalItems(
        response.headers['x-paginate-total']
          ? parseInt(response.headers['x-paginate-total'])
          : 10,
      );
      setLoading(false);
      setData(result);
      setSearchFor('');
    } catch (error) {
      setLoading(false);
      setError(true);
      setSearchFor('');
    }
  };
  const roleConvertDisplay = (role) => {
    return role
      ? role === 'SUPER_ADMIN'
        ? 'Super Administrador'
        : role === 'ADMIN'
        ? 'Administrador'
        : role === 'BELL'
        ? 'Timbre'
        : 'NO ROLE'
      : 'NO ROLE';
  };
  const formatDataForTable = (data = []) => {
    const formatedData = [];
    data.forEach((item, index) => {
      const statusIcon = <FeatureIcon feature="user" randomColor={index % 5} />;
      const formatedElement = [];

      formatedElement.push(
        <Row align="middle">
          {statusIcon}
          <Col span={18} style={styles.itemIcon}>
            {resizeString(`${item?.name ?? ' '} ${item?.last_name ?? ' '}`, 12)}
          </Col>
        </Row>,
      );
      formatedElement.push(roleConvertDisplay(item.role));
      formatedElement.push(
        item.role === 'SUPER_ADMIN'
          ? 'N/A'
          : item?.neighborhood?.number ?? 'NO_HOOD_NAME',
      );
      formatedElement.push(resizeString(item?.username ?? '', 10));
      formatedElement.push(
        <Button
          block
          onClick={() => setViewUserModal(item)}
          style={styles.itemButton}>
          {'VER'}
        </Button>,
      );

      formatedData.push({
        data: formatedElement,
        aditionalData: {
          active: item.approved,
        },
      });
    });
    setDataForTable(formatedData);
  };

  const closeUserModalAndRefresh = () => {
    goToPage(1, false);
    setSearchFor('');
    setSearching(false);
    setCreateUserModal(false);
    setViewUserModal(false);
  };
  return (
    <CommonLayout
      title={'Usuarios'}
      buttonAdd={
        <Button
          style={styles.container}
          onClick={() => setCreateUserModal(true)}>
          <Row justify="center" align="middle">
            <Col xs={24} sm={0}>
              <div style={styles.iconContainer}>
                <UilPlus size="16" color="#FFFFFF" />
                <UilUser size="18" color="#FFFFFF" />
              </div>
            </Col>
            <Col xs={0} sm={24} md={24}>
              <div style={styles.largeButon}>
                <div style={styles.largeButonIcon}>
                  <UilUser size="18" color="#FFFFFF" />
                </div>
                <div style={styles.title}>{'CREAR USUARIO'}</div>
              </div>
            </Col>
          </Row>
        </Button>
      }
      filters={
        <Row style={{width: '100%'}}>
          <Col span={12}>
            <Input
              prefix={<SearchOutlined />}
              value={searchFor}
              disabled={searching}
              onChange={(value) => setSearchFor(value.target.value)}
              style={{width: '70%'}}
              placeholder={searching ? 'Búsqueda Completada' : 'Buscar...'}
              onPressEnter={handleSearchSubmit}
            />
          </Col>
          <Col
            span={12}
            style={{
              display: 'flex',
              flex: 1,
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}>
            {searching ? (
              <Button onClick={searchModeOff}>{'REGRESAR'}</Button>
            ) : (
              <Button onClick={handleSearchSubmit}>{'BUSCAR'}</Button>
            )}
          </Col>
        </Row>
      }>
      <CustomTable
        loading={loading}
        error={{
          isThereError: error,
          emptyWhat: 'network',
          onRetry: () => goToPage(1, searching),
        }}
        filters={{
          applyFilters: includeInactive,
          setApplyFilters: setIncludeInactive,
          filtersText: 'INCLUIR INHABILITADOS',
        }}
        pagination={{
          searching,
          page,
          totalItems,
          goToPage,
        }}
        columns={[
          {columnTitle: 'Nombre', sizes: {xs: 13, sm: 9, md: 7}},
          {columnTitle: 'Rol', sizes: {xs: 6, md: 5}},
          {columnTitle: 'Vecindario', sizes: {xs: 0, sm: 6, md: 5}},
          {columnTitle: 'Mail', sizes: {xs: 0, md: 5}},
          {columnTitle: 'Acción', sizes: {xs: 5, sm: 3, md: 2}},
        ]}
        items={
          includeInactive
            ? dataForTable
            : dataForTable.filter((item) => item.aditionalData.active)
        }
      />
      {createUserModal && (
        <UserCreator
          visible={createUserModal}
          closeModalTrigger={() => setCreateUserModal(false)}
          closeRefreshAfterCreate={closeUserModalAndRefresh}
        />
      )}
      {viewUserModal && (
        <UserEditor
          visible={viewUserModal !== false}
          closeModalTrigger={() => setViewUserModal(false)}
          closeRefreshAfterCreate={closeUserModalAndRefresh}
          userObject={viewUserModal}
        />
      )}
    </CommonLayout>
  );
};

export default Users;
