import {CSSProperties} from 'react';
import {UTILITY_YELLOW} from '../../constants/themeColors';

export const CreateNeighborhoodStyles: Record<string, CSSProperties> = {
  container: {padding: '6.25% 4.166%'},
  bold: {fontSize: '20px', fontWeight: 'bold', margin: 0, padding: 0},
  paper: {
    backgroundColor: 'white',
    padding: 40,
    borderStyle: 'solid',
    borderColor: '#70707078',
    borderWidth: 1,
    borderRadius: 5,
  },
  mainContainer: {padding: '6.25% 4.166%'},
  rowSeparator: {height: '32px'},
  item: {width: '100%'},
  validation: {color: 'red'},
  imageContainer: {
    width: 122,
    height: 120,
    display: 'flex',
    position: 'relative',
    borderRadius: 60,
    border: 'solid #00000021 1px',
  },
  image: {
    width: '100%',
    height: '100%',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  uploadImage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    height: 60,
    width: 120,
    top: 60,
    zIndex: +1,
    backgroundColor: '#00000080',
    borderBottomRightRadius: 60,
    borderBottomLeftRadius: 60,
    overflow: 'hiden',
    cursor: 'pointer',
  },
  fileInput: {display: 'none'},
  editButton: {
    paddingTop: '4px',
    backgroundColor: UTILITY_YELLOW,
  },
  saveButton: {
    paddingTop: '4px',
  },
};
