import React from 'react';
import {PollGrapherStyles as styles} from '../styles';

const PollGrapher = ({choices}: {choices: any[]}) => {
  if (choices.length === 0) {
    return null;
  }
  const totalVotes = choices.map((item) => item.count).reduce((a, b) => a + b);
  const mostVoted = Math.max(...choices.map((item) => item.count));

  const graphs = choices.map((item, index) => (
    <div key={index} style={styles.main}>
      <div style={styles.conatiner}>
        <p style={{padding: 0, margin: 0}}>
          {totalVotes
            ? ((item.count / totalVotes) * 100).toFixed(2) + '% - ' + item.text
            : '0.00% - ' + item.text}
        </p>
        <div style={styles.absolutContainer}>
          <div style={styles.anotherContainer}>
            <div
              style={{
                ...styles.bar,
                flex: item.count,
                backgroundColor:
                  item.count === mostVoted ? '#51D3BD44' : '#CCCCCC66',
              }}
            />
            <div
              style={{
                ...styles.transparentBar,
                flex: totalVotes - item.count,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  ));
  return (
    <div>
      {graphs}
      <div>
        {totalVotes
          ? totalVotes === 1
            ? totalVotes + ' voto'
            : totalVotes + ' votos'
          : '0 votos'}
      </div>
    </div>
  );
};

export default PollGrapher;
