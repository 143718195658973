/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {ROUTES} from '../../constants/Routes';
import {AuthContext} from '../../context/AuthContext';
import {isAuthenticated, getAccessLevel, getLevelConfig} from './auth';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
export default function ShieldedRoute({children, routeName, ...rest}) {
  const levelConfig = getLevelConfig();
  const availableRoutes = ROUTES[localStorage.getItem('availableRoutes')] || [
    'public',
  ];
  const context: any = useContext(AuthContext);
  useEffect(() => {
    context.setGlobalState({showMenu: true});
  }, []);
  return (
    <Route
      {...rest}
      render={({location}) => {
        if (
          isAuthenticated() &&
          getAccessLevel() === 'ADMIN' &&
          levelConfig === 4
        ) {
          if (availableRoutes.includes(routeName)) {
            return children;
          } else {
            return (
              <Redirect
                to={{
                  pathname: '/accessDenied',
                  state: {from: location},
                }}
              />
            );
          }
        } else {
          return (
            <Redirect
              to={{
                pathname: levelConfig === 4 ? '/' : '/digitalJourney/intro',
                state: {from: location},
              }}
            />
          );
        }
      }}
    />
  );
}
