import {Col, Row, Modal as Modalx} from 'antd';
import React from 'react';

export const Modal = (props) => {
  return (
    <Modalx
      centered
      visible={props.visible}
      onOk={props.onOk}
      onCancel={props.onCancel}
      okText="CREAR"
      cancelText="CANCELAR"
      maskClosable={false}
      width={'600px'}
      destroyOnClose={true}
      closable={!props.loading}
      footer={props.footer}
      title={
        <Row>
          <Col xs={5} sm={4} md={3}>
            {props.icon}
          </Col>
          <Col
            xs={19}
            sm={20}
            md={21}
            style={{
              fontSize: 20,
              color: 'gray',
              marginTop: 10,
            }}>
            {props.title}
          </Col>
        </Row>
      }>
      {props.body}
    </Modalx>
  );
};
