import React, {useState} from 'react';
import {Col, Row, Modal, Input, Button} from 'antd';

import {
  getUserHood,
  getStreetsName,
  getLevelConfig,
} from '../../../navigation/auth/auth';
import LoadingContent from '../../loading/LoadingContent';
import NoContent from '../../empty_screens/empty_content/NoContent';
import FeatureIcon from '../../../general_components/FeatureIcon/FeatureIcon';
import apiInstance from '../../../network/axios';
interface IStreetCreator {
  visible: boolean;
  closeModalTrigger: () => void;
  closeRefreshAfterCreate: () => void;
  onFirstCreate?: () => void;
}
const StreetCreator = ({
  closeModalTrigger,
  closeRefreshAfterCreate,
  visible,
  onFirstCreate,
}: IStreetCreator) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [name, setName] = useState<string>('');

  const onChangeStreetName = (event) => {
    setName(event.target.value);
  };

  const validatePost = () => {
    if (!name) {
      alert('Ingrese Nombre para continuar');
      return;
    }
    setLoading(true);
    postCreateStreet();
  };

  const postCreateStreet = async () => {
    try {
      const userNeighborhoodID = getUserHood();
      const url = `/neighborhoods/${userNeighborhoodID}/streets`;

      const body = {
        name: name,
      };
      await apiInstance.post(url, body);

      if (getLevelConfig() < 4) {
        localStorage.setItem('levelConfig', '2');
        if (onFirstCreate) {
          onFirstCreate();
        }
      }
      setLoading(false);
      closeRefreshAfterCreate();
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const retryAfterNetErr = () => {
    setLoading(false);
    setError(false);
  };

  return (
    <Modal
      centered
      visible={visible}
      onOk={validatePost}
      onCancel={closeModalTrigger}
      okText="AGREGAR"
      cancelText="CANCELAR"
      maskClosable={false}
      zIndex={2000}
      destroyOnClose={true}
      closable={!loading}
      footer={[
        <Button key="back" disabled={loading} onClick={closeModalTrigger}>
          {'CANCELAR'}
        </Button>,
        error ? (
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={retryAfterNetErr}>
            {'REINTENTAR'}
          </Button>
        ) : (
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={validatePost}>
            {'AGREGAR'}
          </Button>
        ),
      ]}
      title={
        <Row>
          <Col xs={5} sm={4} md={3}>
            <FeatureIcon feature="street" />
          </Col>
          <Col xs={19} sm={20} md={21} style={{fontWeight: 'bold'}}>
            {'Agregar ' + getStreetsName()}
          </Col>
        </Row>
      }>
      {loading ? (
        <LoadingContent />
      ) : error ? (
        <NoContent emptyWhat={'network'} />
      ) : (
        <Row>
          <Col span={24}>
            <h3>{'Nombre'}</h3>
            <Input
              value={name}
              placeholder="Nombre"
              style={{marginBottom: '20px'}}
              onChange={onChangeStreetName}
            />
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default StreetCreator;
