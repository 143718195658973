import React, {useContext} from 'react';
import {withRouter} from 'react-router-dom';
import {Layout, Button, Row, Col} from 'antd';
import {LogoutOutlined, UserOutlined} from '@ant-design/icons';

//local imports
import {isAuthenticated, getUserName, getAccessLevel} from '../auth/auth';
import BrandLogo from './components/BrandLogo';
import {
  BRAND_TURQUOISE,
  BRAND_BLUE,
  BASE_GREEN,
} from '../../constants/themeColors';
import {AuthContext} from '../../context/AuthContext';

const {Header} = Layout;

const TopOptionsBar = (props: any) => {
  const context: any = useContext(AuthContext);

  if (!isAuthenticated()) return null;
  const headerColor =
    getAccessLevel() === 'SUPER_ADMIN' ? BRAND_TURQUOISE : BRAND_BLUE;
  const nameDisplayColor =
    getAccessLevel() === 'SUPER_ADMIN' ? BASE_GREEN : BRAND_TURQUOISE;

  return (
    <Header style={{paddingRight: '3.5%', backgroundColor: headerColor}}>
      <Row>
        <Col
          xs={12}
          sm={10}
          md={13}
          lg={15}
          xl={17}
          style={{padding: '0 0 0 15px'}}>
          <BrandLogo />
        </Col>

        <Col xs={0} sm={8} md={8} lg={5} xl={4}>
          <Button
            type="primary"
            block
            style={{
              overflow: 'hidden',
              backgroundColor: nameDisplayColor,
              borderColor: nameDisplayColor,
              fontWeight: '500',
              fontSize: '15px',
            }}>
            <UserOutlined size={30} color="#FFFFFF" />
            {getUserName()}
          </Button>
        </Col>
        <Col span={3}>
          {!context.state.showMenu && getAccessLevel() === 'ADMIN' && (
            <Button
              onClick={() => {
                localStorage.clear();
                props.history.push('/');
              }}
              type="ghost"
              ghost
              style={{
                overflow: 'hidden',
                backgroundColor: nameDisplayColor,
                borderColor: nameDisplayColor,
                fontWeight: '500',
                fontSize: '15px',
                marginLeft: 30,
              }}>
              <LogoutOutlined size={30} color="#FFFFFF" />
              {'Salir'}
            </Button>
          )}
        </Col>
      </Row>
    </Header>
  );
};

export default withRouter(TopOptionsBar);
