import {CSSProperties} from 'react';
import {
  BASE_BLUE,
  BASE_DARK_GRAY,
  BASE_LIGHT_GRAY,
  BASE_WHITE,
  BRAND_LAGOON,
} from '../../constants/themeColors';
export const PollsStyles: Record<string, CSSProperties> = {
  buttonAdd: {
    backgroundColor: BASE_BLUE,
    color: '#FFFFFF',
    fontWeight: 'bold',
    borderColor: '#FFFFFF',
    padding: '0px',
    borderRadius: '5px',
  },
  addButtonContainer: {
    padding: '0px 10px',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    padding: '0px 10px',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonTitle: {
    paddingRight: '10px',
  },
  filtersText: {
    fontSize: '14px',
    fontWeight: '500',
    height: '33px',
    color: BASE_DARK_GRAY,
  },
  filtersInput: {width: '90%'},
  fiterButton: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  itemIcon: {
    fontWeight: 'bold',
    paddingLeft: 10,
  },
};

export const PollEditorStyles: Record<string, CSSProperties> = {
  title: {fontWeight: 'bold'},
  input: {marginBottom: '20px'},
  picker: {width: '100%', marginBottom: '20px'},
  switchContainer: {display: 'flex', flexDirection: 'row'},
  switch: {marginTop: '3px'},
  switchText: {
    padding: '0px 0px 0px 20px',
    marginBottom: '15px',
  },
  button: {
    marginTop: '10px',
    backgroundColor: BRAND_LAGOON,
    color: BASE_WHITE,
    fontWeight: 'bold',
  },
  inputChoice: {width: '60%', marginRight: 8, marginBottom: '20px'},
};

export const PollViewerStyles: Record<string, CSSProperties> = {
  button: {paddingTop: '4px'},
  titleContainer: {paddingRight: '20px'},
  title: {fontWeight: 'bold'},
  byWho: {fontSize: '11px'},
  countContainer: {
    backgroundColor: BASE_LIGHT_GRAY,
    borderRadius: '15px',
    padding: '5px 15px',
    marginBottom: 10,
  },
  list: {
    backgroundColor: BASE_WHITE,
    borderWidth: 0,
  },
  listItem: {
    padding: '0 2.08%',
    minHeight: '67px',
    borderWidth: 0,
  },
  statusIcon: {
    padding: '0px 10px 0px 0px',
  },
  name: {
    fontWeight: 'bold',
    paddingRight: '10px',
    paddingLeft: '10px',
  },
  separator: {height: '20px'},
  question: {marginBottom: '20px'},
  picker: {width: '100%', marginBottom: '20px'},
  switchContainer: {display: 'flex', flexDirection: 'row'},
  switch: {marginTop: '3px'},
  switchText: {
    padding: '0px 0px 0px 20px',
    marginBottom: '15px',
  },
  results: {marginBottom: '20px'},
};

export const PollGrapherStyles: Record<string, CSSProperties> = {
  main: {position: 'relative', marginBottom: '20px'},
  conatiner: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    borderStyle: 'solid',
    borderColor: '#CCCCCC',
    borderRadius: '15px',
    padding: '2.5%',
    minHeight: '25px',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  absolutContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    borderRadius: '15px',
    borderStyle: 'solid',
    borderColor: '#CCCCCC',
    overflow: 'hidden',
  },
  anotherContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    borderColor: '#E6E6E6',
    borderRadius: '15px',
    height: '100%',
  },
  bar: {display: 'flex', flexDirection: 'row'},
  transparentBar: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'transparent',
  },
};
