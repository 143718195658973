import React from 'react';
import {UilTimesSquare} from '@iconscout/react-unicons';
import {Link} from 'react-router-dom';
import {Button} from 'antd';
import {DJAccessDeniedStyles as styles} from './styles';
import {MEDIUM_GRAY} from '../../constants/themeColors';

const DJAccessDenied = () => {
  return (
    <div style={styles.main}>
      <UilTimesSquare size="150" color={MEDIUM_GRAY} />
      <p style={styles.title}>¡Oh, no!.</p>
      <p style={styles.message}>
        Esta función no está incluida en tu plan actual.
      </p>
      <p style={styles.message2}>
        Mejora tu plan y lleva tu condominio a otro nivel.
      </p>
      <Link style={styles.buttonContainer} to="/updatePlan">
        <Button style={styles.button}>MEJORAR MI PLAN</Button>
      </Link>
    </div>
  );
};

export default DJAccessDenied;
