/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {Col, Row, Modal, Input, Select, Switch, List} from 'antd';

//local imports
import {
  getUserHood,
  getHomesName,
  getStreetsName,
} from '../../../navigation/auth/auth';
import {
  UTILITY_GREEN,
  UTILITY_RED,
  UTILITY_YELLOW,
  UTILITY_BLUE,
} from '../../../constants/themeColors';
import LoadingContent from '../../loading/LoadingContent';
import NoContent from '../../empty_screens/empty_content/NoContent';
import FeatureIcon from '../../../general_components/FeatureIcon/FeatureIcon';
import apiInstance from '../../../network/axios';
import {CircleButton} from '../../../atoms';

interface IHomeEditor {
  visible: boolean;
  closeModalTrigger: () => void;
  closeRefreshAfterCreate: () => void;
  homeObject: any;
}
const HomeEditor = ({
  closeModalTrigger,
  closeRefreshAfterCreate,
  homeObject,
  visible,
}: IHomeEditor) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [homeNum, setHomeNum] = useState(homeObject?.number ?? '');
  const [homeInsideNum, setHomeInsideNum] = useState(
    homeObject?.inside_number ?? '',
  );
  const [streets, setStreets] = useState([]);
  const [streetId, setStreetId] = useState(homeObject?.street?._id ?? '');
  const [activatedHome, setActivatedHome] = useState(
    homeObject?.current_status ?? 'ACTIVATED',
  );
  const [homeDetail, setHomeDetail] = useState<any>({});
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showOwner, setShowOwner] = useState<boolean>(false);
  let randomColor = 0;
  console.log({streetId, homeObject});

  useEffect(() => {
    retrieveHomeData();
  }, []);

  const onChangeHomeNum = (event) => {
    setHomeNum(event.target.value);
  };

  const onChangeHomeInteriorNum = (event) => {
    setHomeInsideNum(event.target.value);
  };

  const onChangeStreet = (value) => {
    setStreetId(value);
  };

  const onCheckStatus = (event) => {
    if (event) {
      setActivatedHome('ACTIVATED');
    } else {
      setActivatedHome('DEACTIVATED');
    }
  };

  const validateEdit = () => {
    if (!homeNum) {
      alert('Ingrese Número para continuar');
      return;
    }
    if (!streetId) {
      alert(
        'Seleccione una ' + getStreetsName().toLowerCase() + ' para continuar',
      );
      return;
    }
    setLoading(true);
    putEditHome();
  };

  const retrieveHomeData = async () => {
    try {
      const url = `/homes/${homeObject?.id}`;
      const response = await apiInstance.get(url);
      const result = response.data;
      setHomeDetail(result);

      retrieveStreetsData();
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const retrieveStreetsData = async () => {
    try {
      const userNeighborhoodID = getUserHood();
      const url = `/neighborhoods/${userNeighborhoodID}`;
      const response = await apiInstance.get(url);
      const result = response.data.streets;
      setStreets(result);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const putEditHome = async () => {
    try {
      const url = `/homes/${homeObject?.id ?? ''}`;
      const body = {
        street: streetId,
        current_status: activatedHome,
        number: homeNum,
        inside_number: homeInsideNum,
      };
      await apiInstance.put(url, body);
      setLoading(false);
      closeRefreshAfterCreate();
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const confirmDeleteHome = () => {
    if (window.confirm('¿Esta seguro que desea eliminar este elemento?')) {
      setLoading(true);
      deleteHome();
    }
  };

  const deleteHome = async () => {
    try {
      const url = `/homes/${homeObject?.id ?? ''}`;
      await apiInstance.delete(url);

      setLoading(false);
      closeRefreshAfterCreate();
    } catch (e) {
      setLoading(false);
      setError(true);
    }
  };

  const toggleReadBy = () => {
    setShowOwner(!showOwner);
  };

  const toggleEditMode = () => {
    if (editMode) {
      if (window.confirm('¿Abandonar modo de edición?')) {
        setEditMode(!editMode);
      } else {
        return;
      }
    } else {
      setEditMode(!editMode);
    }
  };

  const whichRandomColor = () => {
    randomColor++;
    if (randomColor >= 5) randomColor = 0;
  };

  return (
    <Modal
      centered
      visible={visible}
      onOk={validateEdit}
      onCancel={closeModalTrigger}
      okText="AGREGAR"
      cancelText="CANCELAR"
      maskClosable={false}
      zIndex={2000}
      destroyOnClose={true}
      closable={!loading}
      footer={
        error ? null : (
          <Row>
            <Col span={2}>
              <CircleButton
                variant="del"
                activeColor={UTILITY_RED}
                disabled={loading}
                onClick={confirmDeleteHome}
              />
            </Col>
            <Col span={22}>
              <CircleButton
                variant="viewHome"
                activeColor={UTILITY_GREEN}
                disabled={loading}
                onClick={toggleReadBy}
                clickedMode={showOwner}
              />

              <CircleButton
                variant="genericEdit"
                activeColor={UTILITY_YELLOW}
                disabled={loading}
                onClick={toggleEditMode}
                clickedMode={editMode}
              />

              <CircleButton
                variant="save"
                activeColor={UTILITY_BLUE}
                disabled={!editMode ? true : loading}
                onClick={validateEdit}
              />
            </Col>
          </Row>
        )
      }
      title={
        <Row>
          <Col xs={5} sm={4} md={3}>
            <FeatureIcon feature="home" />
          </Col>
          <Col xs={19} sm={20} md={21} style={{fontWeight: 'bold'}}>
            {editMode
              ? 'Editar ' + getHomesName()
              : getHomesName() + ' ' + homeNum}
          </Col>
        </Row>
      }>
      {loading ? (
        <LoadingContent />
      ) : error ? (
        <NoContent emptyWhat={'network'} />
      ) : showOwner ? (
        <Row>
          <Col span={24}>
            {homeDetail.users.length > 0 ? (
              <List
                locale={{emptyText: 'Sin Registros'}}
                bordered
                style={{
                  backgroundColor: '#FFFFFF',
                  borderWidth: 0,
                }}
                dataSource={homeDetail.users}
                renderItem={(item: any) => {
                  const statusIcon = (
                    <FeatureIcon feature="user" randomColor={randomColor} />
                  );

                  whichRandomColor();

                  return (
                    <List.Item
                      style={{
                        padding: '0 2.08%',
                        minHeight: '67px',
                        borderWidth: 0,
                      }}>
                      <Col
                        xs={4}
                        sm={3}
                        md={2}
                        style={{
                          padding: '0px 10px 0px 0px',
                        }}>
                        {statusIcon}
                      </Col>
                      <Col
                        xs={20}
                        sm={21}
                        md={22}
                        style={{
                          fontWeight: 'bold',
                          paddingRight: '10px',
                          paddingLeft: '10px',
                        }}>
                        {(item.name ? item.name : '') +
                          (item.last_name ? ' ' + item.last_name : '')}
                      </Col>
                    </List.Item>
                  );
                }}
              />
            ) : (
              <NoContent emptyWhat={'owners'} />
            )}
          </Col>
        </Row>
      ) : (
        <Row>
          <Col span={24}>
            <h3>{'Número de ' + getHomesName()}</h3>
            <Input
              value={homeNum}
              placeholder="Número de Casa"
              style={{marginBottom: '20px'}}
              onChange={onChangeHomeNum}
              readOnly={!editMode}
            />
            <h3>{'Número Interior'}</h3>
            <Input
              value={homeInsideNum}
              placeholder="Número Interior"
              style={{marginBottom: '20px'}}
              onChange={onChangeHomeInteriorNum}
              readOnly={!editMode}
            />
            <h3>{getStreetsName()}</h3>
            <Select
              disabled={!editMode}
              style={{width: '100%'}}
              dropdownStyle={{zIndex: 3000}}
              onChange={onChangeStreet}
              value={streetId}>
              {streets.length > 0 &&
                streets.map((item) => (
                  <Select.Option value={item.id} key={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
            </Select>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '30px',
              }}>
              <Switch
                size="small"
                disabled={!editMode}
                checked={activatedHome === 'ACTIVATED'}
                onChange={onCheckStatus}
                style={{marginTop: '3px'}}
              />
              <h4
                style={{
                  padding: '0px 0px 0px 20px',
                  marginBottom: '20px',
                }}>
                {'Habilitado'}
              </h4>
            </div>
          </Col>
        </Row>
      )}
    </Modal>
  );
};

export default HomeEditor;
